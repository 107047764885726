import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from './checkbox';
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import CloseIcon from '@mui/icons-material/Close';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "35%", md: "45%", sm: "60%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

function RestockModal ({ data,loading, onChangeDay,onChangeMonth, onChangeYear, text2, onChange1, inputStyle, buttonText, heading, onChange, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, text1 }) {
    

    let initialData = {
        qty : "",
        cost_price : "",
        trade_price : "",
        warehouse_price : "",
        retail_price : "" 
    }

    let [restockData,setRestockData] = useState(initialData)


    const departments = useSelector(
        (state) => state.loginReducer.productDepartments
      );


    let selectedDepart = departments && departments.length>0 && departments.filter((e,i)=>{

                return e?.departmentName == data?.department

    })

    let categories;

    if(selectedDepart && selectedDepart.length>0){

            let deparment = selectedDepart[0]

            categories = deparment?.categories

    }

    let category = categories && categories.length>0 &&   categories?.filter((e,i)=>{

            return e?.categoryName == data?.category

    })


    let categoryPer;

    if(category && category?.length>0){

        let selectedCat = category[0]

        categoryPer = selectedCat?.categoryPer


    }


const handleEnterCostPrice = (value) => {

    let cost_price = value

    let trade_price = Number(value) + ((value*categoryPer?.a)/100)

    let warehouse_price = Number(value) + ((value*categoryPer?.b)/100)

    let retail_price = Number(value) + ((value*categoryPer?.c)/100)

    setRestockData({
        ...restockData,
        cost_price : cost_price,
        trade_price : trade_price,
        warehouse_price : warehouse_price,
        retail_price : retail_price
    })
}


const handleRestockProduct = async () => {


    if(!Number(restockData?.qty)){
        alert("Kindly Enter Qty")
        return
    }




    let dataToSend = {

        qty : restockData?.qty,
        cost_price : restockData?.cost_price ? restockData?.cost_price : data?.cost_price,
        trade_price : restockData?.trade_price ? restockData?.trade_price : data?.trade_price,
        warehouse_price : restockData?.warehouse_price ? restockData?.warehouse_price : data?.warehouse_price,
        retail_price : restockData?.retail_price ? restockData?.retail_price : data?.retail_price,
        barcode : data?.barcode,
        supplier_name : data?.supplier_name,
        supplier_address : data?.supplier_address,
        supplier_mobile_number : data?.supplier_mobile_number,
        supplier_id : data?.supplier_id

    }

    
    let myData = await sessionStorage.getItem("userData");
    myData = JSON.parse(myData);
    let token = myData


    axios.put(`${Base_Uri}RestockProduct`,dataToSend,{
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
        },
    }).then((res)=>{

            let data = res.data

        if(!data?.status){
                alert(data.message)
        }else{

            alert(data.message)
            confirm()

        }

    
    })
    

}

    return (

        <Box>

<Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>
                    
                <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>
                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {"Restock Product"}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Qty
                        </Typography>

                        <TextField
                            onChange={(e)=> setRestockData({...restockData,qty:e.target.value})}
                            variant="standard" // <== changed this
                            margin="normal"
                            type="number"
                            required
                            value={restockData?.qty}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Cost Price
                        </Typography>

                        <TextField
                            onChange={(e)=>handleEnterCostPrice(e.target.value)}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            value={restockData?.cost_price ? restockData?.cost_price : data?.cost_price}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Trade Price
                        </Typography>

                        <TextField
                            onChange={(e)=>setRestockData({...restockData,trade_price:e.target.value})}
                            variant="standard" // <== changed this
                            margin="normal"
                            type="number"
                            value={restockData?.trade_price ? restockData?.trade_price : data?.trade_price}
                            required
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>


                    
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Warehouse Price
                        </Typography>

                        <TextField
                            onChange={(e)=>setRestockData({...restockData,warehouse_price:e.target.value})}
                            variant="standard" // <== changed this
                            margin="normal"
                            type="number"
                            required
                            value={restockData?.warehouse_price ? restockData?.warehouse_price : data?.warehouse_price}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>


                            
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Retail Price
                        </Typography>

                        <TextField
                            onChange={(e)=>setRestockData({...restockData,retail_price:e.target.value})}
                            variant="standard" // <== changed this
                            margin="normal"
                            type="number"
                            required
                            value={restockData?.retail_price ? restockData?.retail_price : data?.retail_price}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px",padding:1, border: `1px solid ${Colors.border}`, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>


                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >



                        <Button onClick={()=>handleRestockProduct()} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px",marginTop:2, fontFamily: "Poppins", ...buttonStyle2 }} >
                            {loading ? <RingLoader loading={loading} size={30} /> : "Restock"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </Box>

    )
}

export default RestockModal