import { Box, Button, Grid, IconButton, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../../Constant/Color'
import CustomButton from '../../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../../Components/checkbox';
import { CheckBox, DataArray } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import DatePickers from '../../../Components/DatePicker';
import { BiEdit } from "react-icons/bi";
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../../Components/modal';
import { Base_Uri } from '../../../Constant/BaseUri';
import axios from 'axios';
import RefundModal from '../../../Components/refundModal';
import { updateCustomers } from '../../../Redux/LoginReduces';
import TransactionDetailsModal from '../../../Components/transactionDetailsModal';
import { RingLoader } from 'react-spinners';
import DownloadIcon from "@mui/icons-material/Download";
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import MyFontRegular from '../../../fonts/Poppins-Medium.ttf';
import MyFontBold from '../../../fonts/Poppins-Bold.ttf';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import TransactionModal from '../../../Components/TransactionModal';
import SubAccountCustomemodal from '../../../Components/SubAccountmodal';




function SingleAccountDetails() {

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const customers = useSelector((state) => state?.loginReducer?.customers);
  const userData = useSelector((state) => state.loginReducer.updatedState);


  Font.register({
    family: 'MyFont',
    fonts: [
      { src: MyFontBold, fontWeight: 'bold' },
      { src: MyFontRegular, fontWeight: 'normal' },
    ]
  });

  const vat = useSelector(state => state?.loginReducer?.vat)


  const [customerLedger, setCustomerLedger] = useState([])
  const [selectedCustomer, setSelectedCustomer] = useState("")
  const [openUpdateChequeModal, setUpdateChequeModal] = useState(false)
  const [searchCustomer, setSearchCustomer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [gridKey, setGridKey] = useState(0)
  const [openCreditRefundModal, setOpenCreditRefundModal] = useState(false)
  const [dataClicked, setDataClicked] = useState("")
  const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState("select")
  const [searchDate, setSearchDate] = useState({
    selected: false
  })
  const [ledgerLoading, setLedgerLoading] = useState(false)
  const [dateSearchData, setDateSearchData] = useState([])
  const [deleteQuotationModal, setDeleteQuotationModal] = useState(false)
  const [deleteQuotationLoading, setDeleteQuotationLoading] = useState(false)


  const [openTransactionModal, setOpenTransactionModal] = useState(false)
  const [checkAdminData, setCheckAdminData] = useState({})
  useEffect(() => {
    const func = async () => {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;


      axios.get(`${Base_Uri}getAllEmployees?singleuser=1`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }).then((res) => {
        console.log(res.data, "ldldl")
        let data = res.data

        if (data?.status) {
          setCheckAdminData(data.data[0])
        }
      }
      )
    }
    func()
  }, [])


  const [category, setCategory] = useState([
    "Show All",
    "Invoice",
    "Quotation",
    "Return Invoice",
    "Return Quotation",
    "Exchange Invoice",
    "Exchange Quotation",
    "Refunds"

  ])

  const [paymentMethods, setPaymentMethods] = useState([
    "Show All", "Cash", "Credit", "Online", "Card", "Cheque", "Refund Cash", "Deduct Credit", "Cheque Cleared"
  ])
  const [selectedPaymentMethod, setSelectPaymentMethod] = useState("select")

  const [pdfDownloadView, setPdfDownloadView] = useState(false)
  const [searchData, setSearchData] = useState(false)
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [product, setProducts] = useState([])

  const [openModal, setOpenModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tempName, setTempName] = useState(false);
  const [description, setDescription] = useState('');
  const [openModalLoading, setOpenModalLoading] = useState(false);

  const handleEditClick = (cellValues) => {
    setSelectedRow(cellValues.row);
    setDescription(cellValues.row.subAccount || '');
    setOpenModal(true);
  };

  // console.log(description);
  const handleSaveClick = async (cellValues) => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;
    setOpenModalLoading(true)
    axios
      .post(`${Base_Uri}updatesubAcountCustomerLedger`, { ...selectedRow, subAccount: description }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;
        if (!data.status) {
          alert(data.message);
          setOpenModalLoading(false)
          return;
        }

        // console.log(data.data, '==========>');
        setOpenModalLoading(false)
        // setTempName(data?.status)
        setOpenModal(false)
        alert(data.message)
        handleSelectCustomer({ ...data.data, id: data.data?.customerId })

      })
      .catch((error) => {
        alert(error.message);
        setOpenModalLoading(false)
      });
    // setOpenModal(false);
  };
  const onChangeDescription = (text) => {
    let value = text.target.value
    if (value) {
      setDescription(value)
    }
  }

  const column = [
    {
      field: "date",
      headerName: "Date/Time",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p>{new Date(cellValues?.row?.date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        })} {new Date(cellValues?.row?.date).toLocaleTimeString()}</p>
      }
    },
    {
      field: "transactionType",
      headerName: "Transaction Type",
      headerClassName: 'super-app-theme--header',
      width: "150",
    },
    {
      field: "description",
      headerName: "Description",
      headerClassName: 'super-app-theme--header',
      width: "300",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row.subAccount ? cellValues?.row.subAccount : "-"}</p>
      }
    },
    {
      field: "paymentMethod",
      headerName: "Payment Method",
      headerClassName: 'super-app-theme--header',
      width: "200",
      renderCell: (cellValues) => {
        return cellValues?.row?.paymentMethod && <p >{Array.isArray(cellValues?.row?.paymentMethod) ? cellValues?.row.paymentMethod?.map((e) => e).join(",") : cellValues?.row?.paymentMethod}</p>
      }
    },
    {
      field: "invoiceAmount",
      headerName: "Total Amount",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row?.invoiceAmount ? Number(cellValues?.row?.invoiceAmount).toFixed(2) : cellValues?.row?.totalAmount ? cellValues?.row?.totalAmount : 0.00
        }</p>
      }
    },
    {
      field: "paid",
      headerName: "Paid",
      headerClassName: 'super-app-theme--header',
      width: "100",
      renderCell: (cellValues) => {

        return <p style={{ color: "green" }} >{cellValues?.row.paid ? Number(cellValues?.row.paid).toFixed(2) : 0}</p>

      }
    },
    {
      field: "toPay",
      headerName: "Topay",
      headerClassName: 'super-app-theme--header',
      width: "100",
      renderCell: (cellValues) => {

        return <p style={{ color: "purple" }} >{cellValues?.row.toPay ? Number(cellValues?.row.toPay).toFixed(2) : 0}</p>

      }

    },
    {
      field: "runningBalance",
      headerName: "Running Balance",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p style={{ color: "red", fontSize: 14 }} >{cellValues?.row.runningBalance ? Number(cellValues?.row.runningBalance).toFixed(2) : 0}</p>

      }

    },

    {
      field: "employeeName",
      headerName: "Employee Name",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row.employeeDetails ? cellValues?.row.employeeDetails?.fullName : "-"}</p>
      }
    },

    {
      field: "employeeEmail",
      headerName: "Employee Email",
      headerClassName: 'super-app-theme--header',
      width: "250",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row.employeeDetails ? cellValues?.row.employeeDetails?.emailAddress : "-"}</p>
      }
    },

    {
      field: "openingquotationBalance",
      headerName: "Opening Quotation Balance",
      headerClassName: 'super-app-theme--header',
      width: "250",
      renderCell: (cellValues) => {

        return <p style={{ color: "red", fontSize: 14 }} >{cellValues?.row.openingquotationBalance ? Number(cellValues?.row.openingquotationBalance).toFixed(2) : "-"}</p>

      }

    },

    {
      field: "openinginvoiceBalance",
      headerName: "Opening Invoice Balance",
      headerClassName: 'super-app-theme--header',
      width: "250",
      renderCell: (cellValues) => {

        return <p style={{ color: "red", fontSize: 14 }} >{cellValues?.row.openinginvoiceBalance ? Number(cellValues?.row.openinginvoiceBalance).toFixed(2) : "-"}</p>

      }

    },

    {
      field: "cash",
      headerName: "Cash",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p >{cellValues?.row?.cash ? Number(cellValues?.row?.cash).toFixed(2) : "-"}</p>

      }
    },

    {
      field: "credit",
      headerName: "on Account",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p >{cellValues?.row?.credit ? Number(cellValues?.row?.credit).toFixed(2) : "-"}</p>

      }
    },

    {
      field: "online",
      headerName: "Online",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p >{cellValues?.row?.online ? Number(cellValues?.row?.online).toFixed(2) : "-"}</p>

      }
    },

    {
      field: "card",
      headerName: "Card",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p >{cellValues?.row?.card ? Number(cellValues?.row?.card).toFixed(2) : "-"}</p>

      }
    },

    {
      field: "cheque",
      headerName: "Cheque",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p >{cellValues?.row?.cheque ? Number(cellValues?.row?.cheque).toFixed(2) : "-"}</p>

      }
    },



    {
      field: "creditDays",
      headerName: "Credit Days",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p style={{ color: "red" }} > {cellValues?.row.creditDays ? cellValues?.row.creditDays : "-"}</p>

      }

    },

    // {
    //   field: "discount",
    //   headerName: "Discount",
    //   headerClassName: 'super-app-theme--header',
    //   width: "100",
    //   renderCell: (cellValues) => {

    //     return <p style={{ color: "purple" }} >{cellValues?.row.discount ? Number(cellValues?.row.discount).toFixed(2) : 0}</p>

    //   }
    // },

    {
      field: "cheque_no",
      headerName: "Cheque no",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p>{cellValues?.row.cheque_no ? cellValues?.row.cheque_no : "-"}</p>

      }
    },
    {
      field: "bank_name",
      headerName: "Bank Name",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p>{cellValues?.row.bank_name ? cellValues?.row.bank_name : "-"}</p>

      }
    },

    {
      field: "clear_date",
      headerName: "Clear Date",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p>{cellValues?.row?.clear_date ? new Date(cellValues?.row?.clear_date).toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }) + " " + new Date(cellValues?.row?.clear_date).toLocaleTimeString() : "-"}</p>

      }
    },
    {
      field: "cheque_status",
      headerName: "Cheque Status",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {


        return <p style={{ color: cellValues?.row?.cheque_status?.toLowerCase() == "cleared" ? "green" : "red" }} >{Array.isArray(cellValues?.row?.paymentMethod) && cellValues?.row?.paymentMethod?.some((e, i) => e == "cheque") ? cellValues?.row?.cheque_status ? cellValues?.row?.cheque_status : "Pending" : "-"}</p>

      }
    },
    {
      field: "referenceId",
      headerName: "Reference Id",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {

        return <p>{cellValues?.row.referenceId ? cellValues?.row.referenceId : "-"}</p>

      }
    },
    {
      field: "transactionId",
      headerName: "Transaction Id",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row.transactionId ? cellValues?.row.transactionId : "-"}</p>
      }
    },



    {
      field: "invoiceRef",
      headerName: "Invoice Ref",
      headerClassName: 'super-app-theme--header',
      width: "150",
      renderCell: (cellValues) => {
        return <p>{cellValues?.row.returnInvoiceRef ? Array?.isArray(cellValues?.row?.returnInvoiceRef) ? cellValues?.row.returnInvoiceRef?.map((e) => e).join(",") : cellValues?.row.returnInvoiceRef : "-"}</p>
      }
    },




    {
      field: "reason",
      headerName: "Reason",
      headerClassName: 'super-app-theme--header',
      width: "500",
      renderCell: (cellValues) => {
        return <TextField value={cellValues?.row.reason ? cellValues?.row.reason : "-"} multiline={true}

          variant="standard"
          margin="none"

          numberOfLines={5}

          fullWidth
          InputProps={{
            disableUnderline: true,
            fontSize: "12px",
            style: {
              textAlign: "center",
              fontSize: "16px",
              alignSelf: "center",
            },
          }}
          type="text"
          inputProps={{
            disableUnderline: true,
            fontSize: "12px",

          }}
          placeholder={"Enter Reason"}
          sx={{
            width: 500,
            fontSize: "12px",
            textAlign: "center",
            fontFamily: "Poppins",
            fontWeight: "bold",

          }}

        />
      }
    },

    {
      field: "actions",
      headerName: "Actions",
      headerClassName: 'super-app-theme--header',
      width: 100,
      renderCell: (cellValues) => {
        return (
          <Button onClick={() => handleEditClick(cellValues)}>
            <BiEdit style={{ width: 20, height: 20 }} />
          </Button>
        );
      },
    }


  ]



  React.useEffect(() => {

    if (searchDate?.selected && startDate && endDate) {
      setDateSearchData(customerLedger && customerLedger.filter((e) => {
        let addDate = new Date(e.date);

        // Parse selectedDate and dateFrom
        let selectedDateParts = endDate?.split('/');
        let dateFromParts = startDate?.split('/');

        // Create Date objects from the parsed components
        let selectedDateTime = new Date(
          parseInt(selectedDateParts[2]),   // Year
          parseInt(selectedDateParts[1]),   // Month (zero-based)
          parseInt(selectedDateParts[0]),   // Day
          23, // Hours
          59, // Minutes
          59  // Seconds
        );

        let dateFromTime = new Date(
          parseInt(dateFromParts[2]),    // Year
          parseInt(dateFromParts[1]),     // Month (zero-based)
          parseInt(dateFromParts[0]),     // Day
          0,  // Hours
          0,  // Minutes
          0   // Seconds
        );


        // Check if addDate is between dateFrom and selectedDate
        return addDate >= dateFromTime && addDate <= selectedDateTime;
      }));

    } else {
      setDateSearchData([]);
    }


  }, [startDate, searchDate?.selected, endDate])



  const getAllRowData = (event) => {


  }

  const handleSelectionModelChange = (selectionModel) => {

    if (selectionModel.length > 0) {

      selectionModel = selectionModel.length > 1 ? selectionModel[1] : selectionModel[0]

      setCustomerLedger(
        customerLedger && customerLedger.length > 0
          ? customerLedger.map((e) => {
            if (selectionModel == e?.date && e?.transactionType?.toLowerCase() !== "opening balance") {
              return {
                ...e,
                selected: true,
              };
            }
            else {
              return {
                ...e,
                selected: false,
              };
            }
          })
          : []
      );
    }
    else {
      setCustomerLedger(customerLedger && customerLedger?.length > 0 && customerLedger.map((e, i) => {
        return {
          ...e,
          selected: false
        }
      }))
    }
  };

  const handleSearchCustomer = (text) => {

    let value = text.target.value

    if (!value) {
      setSearchCustomer([])
      return
    }

    setSearchCustomer(customers && customers.length > 0 && customers?.filter((e, i) => {

      if (e?.businessName?.toLowerCase().includes(value.toLowerCase()) || e?.accountNo == value || e?.customerName?.toLowerCase().includes(value.toLowerCase())) {
        return e
      }

    }))
    // setTempName(value)



  }



  const handleSelectCustomer = (customer) => {
    let ledger = [];
    let previousBalance = 0;
    let id = customer.id


    setLedgerLoading(true)

    axios.get(`${Base_Uri}getCustomerLedger/${id}`).then((res) => {

      let data = res.data

      if (!data?.status) {
        alert(data.message)
        setLedgerLoading(false)
        return
      }

      if (data?.status) {
        let myData = data?.data
        let customerData = data?.customerData

        if (!myData || myData?.length == 0) {
          alert("No Data Found")
          return
        }


        ledger = myData.map((entry) => {

          let refund = (entry.refund && entry?.paymentMethod[0]?.toLowerCase() == "onaccount") ? entry.refund : 0

          const currentRunningBalance =
            previousBalance +
            Number(entry.toPay || 0) -
            Number(refund) - Number(entry?.deductCredit || 0) - (Number(entry?.additionalDiscount || 0))


          previousBalance = currentRunningBalance;

          return {
            ...entry,
            runningBalance: Number(currentRunningBalance).toFixed(2),
            customerDetails: customerData
          };
        });






        setCustomerLedger(ledger);
        setSelectedCustomer(customerData);
        setLedgerLoading(false)
        setGridKey(gridKey + 1)
        setSearchCustomer([]);



      }





    }).catch((error) => {

      alert(error?.message)
      setLedgerLoading(false)

    })






    // if (customer?.customerLedger?.length > 0) {
    //   ledger = customer.customerLedger.map((entry) => {
    //     const currentRunningBalance =
    //       previousBalance +
    //       Number(entry.toPay || 0) -
    //       Number(entry.refund || 0) - Number(entry?.deductCredit || 0)


    //     previousBalance = currentRunningBalance;

    //     return {
    //       ...entry,
    //       runningBalance: Number(currentRunningBalance).toFixed(2),
    //     };
    //   });
    // }

    // setCustomerLedger(ledger);
    // setSelectedCustomer(customer);
    // setGridKey(gridKey + 1)
    // setSearchCustomer([]);


  };


  const handleUpdateCheque = async () => {


    setLoading(true)

    let dataToSend = {
      selectedLedger: customerLedger?.filter(e => e.selected)?.[0],
      ledger: customerLedger,
      selectedCustomer: selectedCustomer,
      employeeDetails: userData
    }

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;


    axios.put(`${Base_Uri}updateChequeStatus`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {


      let data = res.data

      if (data && !data.status) {

        alert(data.message)
        setLoading(false)
        return

      }

      if (data?.status) {

        let customerData = data?.data
        let Ledger = data?.customerLedger
        let updatedLedger = data?.updatedLedger

        let allLedger = [...customerLedger]

        let otherLedger = allLedger && allLedger?.length > 0 && allLedger?.filter((e, i) => e?._id !== updatedLedger?._id)
        otherLedger = otherLedger && otherLedger?.length > 0 ? otherLedger : []

        setSelectedCustomer(customerData)
        let ledgerToSet = [...otherLedger, updatedLedger, Ledger]
        let previousBalance = 0;
        let ledger = ledgerToSet.map((entry) => {

          let refund = (entry.refund && (Array.isArray(entry.paymentMethod) ? entry?.paymentMethod[0]?.toLowerCase() == "onaccount" : entry?.paymentMethod?.toLowerCase() == "onaccount")) ? entry.refund : 0

          const currentRunningBalance =
            previousBalance +
            Number(entry.toPay || 0) -
            Number(refund) - Number(entry?.deductCredit || 0) - Number(entry?.additionalDiscount || 0)

          previousBalance = currentRunningBalance;

          return {
            ...entry,
            runningBalance: Number(currentRunningBalance).toFixed(2),
          };
        });
        setCustomerLedger(ledger)
        let allCustomers = [...customers]
        let otherCustomers = allCustomers.filter((e, i) => e?.id !== customerData?.id)
        otherCustomers = otherCustomers && otherCustomers?.length > 0 ? otherCustomers : []
        let customerToDispatch = [...otherCustomers, customerData]
        dispatch(updateCustomers(customerToDispatch))
        setUpdateChequeModal(false)

      }



      setLoading(false)

    }).catch((error) => {
      setLoading(false)
      alert(error.message)
    })
  }

  const handleSeeTransactionDetails = (data) => {


    let selectedLedger = customerLedger.filter((e, i) => e.selected)


    selectedLedger = selectedLedger[0]


    setDataClicked(selectedLedger)

    setOpenTransactionDetailModal(true)


  }

  const handleOpenTransactionDetail = (data) => {


    let selectedLedger = customerLedger.filter((e, i) => e.selected)


    selectedLedger = selectedLedger[0]
    setDataClicked(selectedLedger)

    setOpenTransactionModal(true)


  }


  const handleSelectCategory = (category) => {


    if (category.toLowerCase() == "show all") {

      setSearchData([])
      setSelectedCategory(category)

      return
    }

    if (searchDate?.selected) {



      setSearchData(dateSearchData && dateSearchData?.length > 0 && dateSearchData?.filter((e, i) => {

        if (e?.transactionType?.toLowerCase() == category?.toLowerCase() || (category?.toLowerCase() == "refunds" && e?.transactionType?.toLowerCase() == "refund on invoice") || (category?.toLowerCase() == "refunds" && e?.transactionType?.toLowerCase() == "refund on quotation")) {
          return e
        }

        setSelectedCategory(category)

      }))


      return
    }

    setSearchData(customerLedger && customerLedger?.length > 0 && customerLedger?.filter((e, i) => {

      if (e?.transactionType?.toLowerCase() == category?.toLowerCase() || (category?.toLowerCase() == "refunds" && e?.transactionType?.toLowerCase() == "refund on invoice") || (category?.toLowerCase() == "refunds" && e?.transactionType?.toLowerCase() == "refund on quotation")) {
        return e
      }

      setSelectedCategory(category)

    }))

  }

  const handleSelectPaymentMethod = (method) => {



    if (method?.toLowerCase() == "show all") {

      setSearchData([])
      setSelectPaymentMethod(method)

      return
    }

    if (searchDate?.selected) {



      setSearchData(dateSearchData && dateSearchData?.length > 0 && dateSearchData?.filter((e, i) => {

        if (e?.paymentMethod && e?.paymentMethod?.length > 0 && e?.paymentMethod?.some((e, i) => e?.toLowerCase() == method?.toLowerCase())) {
          return e
        }

        setSelectPaymentMethod(method)

      }))
      return
    }


    setSearchData(customerLedger && customerLedger?.length > 0 && customerLedger?.filter((e, i) => {

      if (Array.isArray(e?.paymentMethod) && e?.paymentMethod && e?.paymentMethod?.length > 0 && e?.paymentMethod?.some((e, i) => e?.toLowerCase() == method?.toLowerCase())) {
        return e
      }


      setSelectPaymentMethod(method)

    }))



  }

  const handleDeleteQuotation = async () => {


    let invoiceData = customerLedger?.filter((e, i) => e?.selected)

    invoiceData = invoiceData[0]



    setDeleteQuotationLoading(true)

    let dataToSend = {
      invoiceData: invoiceData
    }



    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios.post(`${Base_Uri}deleteQuotation`, dataToSend, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }).then((res) => {

      let data = res.data

      if (data && !data.status) {

        alert(data.message)
        setDeleteQuotationLoading(false)
        return

      }

      if (data?.status) {

        let deletedData = data?.data

        let allLedger = [...customerLedger]

        let otherLedger = allLedger && allLedger?.length > 0 && allLedger?.filter((e, i) => e?._id !== deletedData?._id)
        otherLedger = otherLedger && otherLedger?.length > 0 ? otherLedger : []

        let ledgerToSet = [...otherLedger]
        let previousBalance = 0;
        let ledger = ledgerToSet.map((entry) => {

          let refund = (entry.refund && entry?.paymentMethod[0]?.toLowerCase() == "onaccount") ? entry.refund : 0

          const currentRunningBalance =
            previousBalance +
            Number(entry.toPay || 0) -
            Number(refund) - Number(entry?.deductCredit || 0) - Number(entry?.additionalDiscount || 0)

          previousBalance = currentRunningBalance;

          return {
            ...entry,
            runningBalance: Number(currentRunningBalance).toFixed(2),
          };
        });
        setCustomerLedger(ledger)


        if (data?.customerData) {

          let updatedCustomerData = data?.customerData

          let allCustomers = [...customers]
          let otherCustomers = allCustomers.filter((e, i) => e?.id !== updatedCustomerData?.id)
          otherCustomers = otherCustomers && otherCustomers?.length > 0 ? otherCustomers : []
          let customerToDispatch = [...otherCustomers, updatedCustomerData]
          dispatch(updateCustomers(customerToDispatch))
          setSelectedCustomer(updatedCustomerData)





        }


        alert(data.message)
        setDeleteQuotationModal(false)
        setDeleteQuotationLoading(false)

      }

    }).catch((error) => {
      setDeleteQuotationLoading(false)
      alert(error.message)
    })









  }




  const Header = () => {

    return (
      <View fixed >

        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, alignItems: "flex-start" }} >
          <View>

          </View>

          <View style={{ width: "30%" }} >

            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >


              <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                Print Date:

              </Text>

              <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }}>

                {
                  new Date().toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                } {new Date().toLocaleTimeString()}
              </Text>

            </View>


            <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >
              <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                Account No:
              </Text>

              <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }}>
                {selectedCustomer.accountNo}
              </Text>
            </View>
          </View>


        </View>


        <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", backgroundColor: "lightgray", alignItems: "center", padding: 10, marginTop: "20px" }}>
          <View style={{ flex: 1.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Date</Text>
          </View>

          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Transaction</Text>
          </View>

          <View style={{ flex: 2.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Payment Method</Text>
          </View>

          <View style={{ flex: 2 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Description</Text> {/* New column for Description */}
          </View>
          {/* <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>on Account</Text>
          </View>

           <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Cash</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>online</Text>
          </View>


          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>card</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>cheque</Text>
          </View> */}


          {/* <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>refund</Text>
          </View> */}


          {/* <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>discount</Text>
          </View> */}


          {/* <View style={{ flex: 1.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>cash refund</Text>
          </View> */}


          {/* <View style={{ flex: 1.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>credit deduct</Text>
          </View> */}



          <View style={{ flex: 1.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Total Amount</Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Paid</Text>
          </View>


          <View style={{ flex: 1 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Topay</Text>
          </View>



          {(selectedPaymentMethod == "select" || selectedPaymentMethod?.toLowerCase() == "show all") && (selectedCategory == "select" || selectedCategory?.toLowerCase() == "show all") && <View style={{ flex: 1.5 }}>
            <Text style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}>Running Balance</Text>
          </View>}


        </View>

      </View>
    )
  }

  const MyDocument = () => (
    <Document size="A4"  >

      {/* <Header /> */}

      <Page size="A4" orientation="landscape" style={{ padding: "0px 10px" }} >

        <Header />
        <View>

          {searchData && searchData?.length > 0 ?
            searchData?.map((item, index) => {

              return (
                <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                  <View style={{ flex: 1.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>

                      {new Date(item?.date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}

                    </Text>
                  </View>

                  <View style={{ flex: 2 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.transactionType}</Text>
                  </View>
                  <View style={{ flex: 2.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{Array.isArray(item.paymentMethod) ? item.paymentMethod?.map((e) => e).join(",") : item?.paymentMethod ? item?.paymentMethod : "-"}</Text>
                  </View>
                  <View style={{ flex: 2 }}> {/* New Description View */}
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.subAccount || "-"}</Text> {/* Display Description */}
                  </View>
                  <View style={{ flex: 1.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.invoiceAmount && item?.invoiceAmount !== "-" ? Number(item.invoiceAmount).toFixed(2) : item?.totalAmount ? Number(item?.totalAmount).toFixed(2) : "-"}</Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.paid && item?.paid !== "-" ? Number(item.paid).toFixed(2) : "-"}</Text>
                  </View>


                  <View style={{ flex: 1 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.toPay ? Number(item.toPay).toFixed(2) : "-"}</Text>
                  </View>

                </View>

              )


            }) :
            dateSearchData && dateSearchData?.length > 0 ?
              dateSearchData?.map((item, index) => {

                return (
                  <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                    <View style={{ flex: 1.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>

                        {new Date(item?.date).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "2-digit",
                        })}

                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.transactionType}</Text>
                    </View>


                    <View style={{ flex: 2.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{Array.isArray(item.paymentMethod) ? item.paymentMethod?.map((e) => e).join(",") : item?.paymentMethod ? item?.paymentMethod : "-"}</Text>
                    </View>


                    <View style={{ flex: 2 }}> {/* New Description View */}
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.subAccount || "-"}</Text> {/* Display Description */}
                    </View>

                    <View style={{ flex: 1.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.invoiceAmount && item?.invoiceAmount !== "-" ? Number(item.invoiceAmount).toFixed(2) : item?.totalAmount ? Number(item?.totalAmount).toFixed(2) : "-"}</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.paid && item?.paid !== "-" ? Number(item.paid).toFixed(2) : "-"}</Text>
                    </View>


                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.toPay ? Number(item.toPay).toFixed(2) : "-"}</Text>
                    </View>


                    <View style={{ flex: 1.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8, color: "red" }}>{item?.runningBalance && item?.runningBalance !== "-" ? Number(item.runningBalance).toFixed(2) : "-"}</Text>
                    </View>

                  </View>

                )


              }) :
              customerLedger && customerLedger?.length > 0 && customerLedger?.map((item, index) => (
                <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                  <View style={{ flex: 1.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>

                      {new Date(item?.date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}

                    </Text>
                  </View>

                  <View style={{ flex: 2 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.transactionType}</Text>
                  </View>



                  <View style={{ flex: 2.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{Array.isArray(item.paymentMethod) ? item.paymentMethod?.map((e) => e).join(",") : item?.paymentMethod ? item?.paymentMethod : "-"}</Text>
                  </View>

                  <View style={{ flex: 2 }}> {/* New Description View */}
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.subAccount || "-"}</Text> {/* Display Description */}
                  </View>

                  <View style={{ flex: 1.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.invoiceAmount && item?.invoiceAmount !== "-" ? Number(item.invoiceAmount).toFixed(2) : item?.totalAmount ? Number(item?.totalAmount).toFixed(2) : "-"}</Text>
                  </View>


                  <View style={{ flex: 1 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.paid && item?.paid !== "-" ? Number(item.paid).toFixed(2) : "-"}</Text>
                  </View>


                  <View style={{ flex: 1 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item?.toPay ? Number(item.toPay).toFixed(2) : "-"}</Text>
                  </View>



                  <View style={{ flex: 1.5 }}>
                    <Text style={{ fontFamily: "MyFont", fontSize: 8, color: "red" }}>{item?.runningBalance && item?.runningBalance !== "-" ? Number(item.runningBalance).toFixed(2) : "-"}</Text>
                  </View>


                </View>
              ))}


        </View>

      </Page>
    </Document>
  );

  const handleDownloadPdf = () => {

    setPdfDownloadView(true)
    window.scrollTo({
      top: 100,
      behavior: "smooth"
    })

  }

  return (
    ledgerLoading ? <Box style={{ width: "100vw", height: "80vh", justifyContent: "center", alignItems: "center", display: "flex" }} >

      <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />

    </Box> :

      pdfDownloadView ? <Box style={{ wodth: "100%", height: "100%", display: "flex", justifyContent: "center" }} >
        <PDFViewer width={window.innerWidth - 200} height={600} >
          <MyDocument />
        </PDFViewer>
        <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />
      </Box> :
        <Box sx={{ backgroundColor: Colors.main }} >
          <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }} >

            <Box sx={{ display: "flex" }} >
              <Grid container md={12} xs={12} sm={12} >


                <Grid item xs={12} sm={12} md={12} >
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }} >

                    <Grid md={12} xs={12} sm={12} >
                      <Box sx={{ width: "100%", marginTop: { xs: "10px", sm: "10px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                        <TextField
                          variant="standard" // <== changed this
                          margin="normal"
                          required
                          fullWidth
                          InputProps={{
                            // startAdornment: <AccountCircle />, // <== adjusted this
                            disableUnderline: true,
                            fontSize: "12px"
                            // <== added this
                            // fontSize: "2px"
                          }}
                          onChange={handleSearchCustomer}
                          placeholder="Search Customer Name ,Account No"
                          sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                        />
                        <img src={require("../../../Images/search.png")} style={{ height: "20px" }} />
                      </Box>
                    </Grid>

                  </Box>

                  {searchCustomer &&
                    searchCustomer.length > 0 &&
                    searchCustomer.map((e, i) => {
                      return (
                        <Button
                          onClick={() => handleSelectCustomer(e)}
                          sx={{
                            width: "100%",
                            mt: 1,
                            backgroundColor: Colors.white,
                            p: "0px 10px",
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            borderRadius: 1,
                            textAlign: "left",
                            display: "flex",
                            justifyContent: "flex-start",
                            mb: 1,
                            border: `1px solid ${Colors.border}`,
                          }}
                        >
                          <p style={{ fontFamily: "Poppins", textAlign: "left" }}>
                            {e.businessName}
                          </p>
                        </Button>
                      );
                    })}
                </Grid>



                <Grid item xs={12} sm={12} md={12} >
                  <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                    <Grid item xs={3} sm={3} md={3} lg={1.5}  >


                      <Box sx={{ display: "flex", marginTop: 1 }} >


                        <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                          Search By Date
                        </Typography>
                        <Checkbox
                          data={searchDate}
                          onClick={() => setSearchDate(prevState => ({ ...prevState, selected: !prevState.selected }))}
                        />

                      </Box>


                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={2} xl={2} style={{ marginRight: "5px" }} >

                      <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                        From
                      </Typography>
                      <DatePickers style={{ borderRadius: 10, marginTop: { xs: "5px", sm: "0px" } }} values={startDate} onChange={(e) => setStartDate(e)} />
                    </Grid>

                    <Grid item xs={12} sm={5} md={5} lg={2} xl={2} style={{ marginRight: "5px" }} >

                      <Typography sx={{ fontSize: "14px", fontFamily: "Poppins", mr: 1, color: Colors.text }} >
                        To
                      </Typography>
                      <DatePickers style={{ borderRadius: 10, marginTop: { xs: "5px", sm: "0px" } }} values={endDate} onChange={(e) => setEndDate(e)} />

                    </Grid>

                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                      <BasicSelect value={selectedCategory} status="Select Transaction Type" id={category} innerStyle={{ fontSize: "14px", border: `0px solid ${Colors.border}`, borderRadius: "5px", fontWeight: "bold", width: "100%", padding: "5px", color: Colors.button }} style={{ border: `0px solid ${Colors.buttonLight}`, width: "100%", padding: "5px" }} onChange={(e) => handleSelectCategory(e)} />
                    </Grid>



                    <Grid item xs={12} sm={6} md={6} lg={3} xl={3} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                      <BasicSelect value={selectedPaymentMethod} status="Select Payment Method" id={paymentMethods} innerStyle={{ fontSize: "14px", border: `0px solid ${Colors.border}`, borderRadius: "5px", fontWeight: "bold", width: "100%", padding: "5px", color: Colors.button }} style={{ border: `0px solid ${Colors.buttonLight}`, width: "100%", padding: "5px" }} onChange={(e) => handleSelectPaymentMethod(e)} />
                    </Grid>

                    {/* <Grid item xs={12} sm={5} md={5} lg={2.5} xl={2.5} style={{ marginRight: "5px", marginTop: { xs: "5px", sm: "0px" } }} >
                  <TextField
                    variant="standard" // <== changed this
                    margin="normal"
                    required
                    fullWidth
                    sx={{ width: "100%", fontSize: "10px", padding: "7px", borderRadius: "5px", margin: 0, fontFamily: "Poppins", fontWeight: "bold", border: `1px solid ${Colors.button}` }}
                    placeholder="Reference"
                    InputProps={{
                      // startAdornment: <AccountCircle />, // <== adjusted this
                      disableUnderline: true, // <== added this
                      // fontSize: "2px"
                    }}
                  />
                </Grid> */}

                  </Box>
                </Grid>
              </Grid >
            </Box >



            {selectedCustomer && <Box style={{ marginTop: "15px" }} >
              <Typography sx={{ fontSize: "20px", color: Colors.text, fontFamily: "Poppins", fontWeight: "600", textAlign: "left" }} >
                Account No : <span style={{ color: Colors.black }} >{selectedCustomer?.accountNo}</span>
              </Typography>
            </Box>}

            {selectedCustomer && <Box style={{ marginTop: "15px" }} >
              <Typography sx={{ fontSize: "20px", color: Colors.text, fontFamily: "Poppins", fontWeight: "600", textAlign: "left" }} >
                Customer : <span style={{ color: Colors.black }} >{selectedCustomer?.businessName}</span>
              </Typography>
            </Box>}

            <Grid container md={12} xs={12} sm={12} style={{ marginTop: "15px" }} >
              <Grid item xs={12} sm={6} >
                <Box sx={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }} >

                  <Box sx={{ marginTop: "5px" }} >
                    <Typography sx={{ color: Colors.text, fontSize: "16px", fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                      Invoice Balance
                    </Typography>

                    <Box style={{ width: "200px", fontSize: "22px", padding: "5px 5px 5px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.black, backgroundColor: Colors.main }} >
                      £{selectedCustomer ? Number(selectedCustomer?.creditBalance || 0).toFixed(2) : 0}
                    </Box>

                  </Box>
                  <Box sx={{ marginTop: "5px" }} >
                    <Typography sx={{ color: Colors.text, fontSize: "16px", fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                      Quotation Balance
                    </Typography>

                    <Box style={{ width: "200px", fontSize: "22px", padding: "5px 5px 5px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.black, backgroundColor: Colors.main }} >
                      £{selectedCustomer ? Number(selectedCustomer?.quotationBalance || 0).toFixed(2) : 0}
                    </Box>

                  </Box>
                  <Box sx={{ marginTop: "5px" }} >
                    <Typography sx={{ color: Colors.text, fontSize: "16px", fontFamily: "Poppins", fontWeight: "bold", textAlign: "left" }} >
                      Account Balance
                    </Typography>

                    <Box style={{ width: "200px", fontSize: "22px", padding: "5px 5px 5px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.red, backgroundColor: Colors.buttonLight }} >
                      £{selectedCustomer ? Number(((selectedCustomer?.creditBalance || 0) + (selectedCustomer?.quotationBalance || 0)) || 0).toFixed(2) : 0}
                    </Box>

                  </Box>

                </Box>

              </Grid>


              <Grid item xs={12} sm={6} sx={{ marginTop: { xs: "0px", sm: "-10px", md: "0px", lg: "-20px" } }}  >
                <Box sx={{ display: "flex", justifyContent: "flex-start", padding: { xs: "0px 0px 0px 0px", sm: "0px 20px 0px 20px" }, flexWrap: "wrap" }} >

                  <Button onClick={() => setOpenCreditRefundModal(true)} style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.text, backgroundColor: Colors.main }} >
                    Credit / Refund
                  </Button>

                  {/* <Button style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.text, backgroundColor: Colors.main }} >
                Update Running
              </Button> */}

                  {customerLedger && customerLedger?.length > 0 && customerLedger?.some((e, i) => e.selected && (e?.transactionType?.toLowerCase() == "invoice" || e?.transactionType?.toLowerCase() == "quotation" || e?.transactionType?.toLowerCase() == "return invoice" || e?.transactionType?.toLowerCase() == "return quotation" || e?.transactionType?.toLowerCase() == "exchange invoice" || e?.transactionType?.toLowerCase() == "exchange quotation")) ? <Button onClick={() => handleSeeTransactionDetails()} style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.text, backgroundColor: Colors.main }} >
                    Transaction Details
                  </Button> :

                    <Button onClick={() => handleOpenTransactionDetail()} style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.text, backgroundColor: Colors.main }} >
                      Transaction Details
                    </Button>
                  }

                  {customerLedger && customerLedger?.length > 0 && customerLedger?.some((e) => e.selected && e?.paymentMethod && (Array.isArray(e.paymentMethod) ? e?.paymentMethod?.some((e) => e?.toLowerCase() == "cheque") : e.paymentMethod == "cheque") && (e?.transactionType?.toLowerCase() == "invoice" || e?.transactionType?.toLowerCase() == "quotation" || e?.transactionType?.toLowerCase() == "exchange invoice" || e?.transactionType?.toLowerCase() == "exchange quotation") && !e?.isChequeCleared) && <Button onClick={() => setUpdateChequeModal(true)} style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "bold", color: Colors.text, backgroundColor: Colors.main }} >
                    Update Cheque
                  </Button>}

                  {/* {customerLedger && customerLedger?.length > 0 && customerLedger.some((e, i) => (e.selected && (e?.transactionType.toLowerCase() == "invoice" || e?.transactionType?.toLowerCase() == "quotation" || e?.transactionType?.toLowerCase() == "return invoice" || e?.transactionType?.toLowerCase() == "return quotation"))) && */}
                    <Button disabled={checkAdminData.role!="admin"} onClick={() => setDeleteQuotationModal(true)} style={{ margin: "5px 5px 0px 0px", width: "180px", fontSize: "14px", padding: "10px 5px 10px 5px  ", borderRadius: "5px", fontFamily: "Poppins", fontWeight: "600", color: Colors.red, backgroundColor: Colors.white, border: `1px solid ${Colors.red}` }} >
                      Delete Quotation
                    </Button>
                  {/* } */}


                </Box>
              </Grid>


            </Grid>



            {customerLedger && customerLedger?.length > 0 && <Button
              onClick={() => handleDownloadPdf()}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                // marginLeft: "10px",
                width: "95%",
                marginTop: "10px",
                backgroundColor: Colors.white,
              }}
            >
              <DownloadIcon />
            </Button>}

            <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
              {customerLedger ? <DataGrid
                getRowId={(row) => row?.date}
                key={gridKey}
                sx={{
                  '& .MuiDataGrid-cell': {
                    fontFamily: 'Poppins',
                    fontSize: "12px",
                    fontWeight: "600",
                    color: "#000"
                  },
                  '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                    padding: '0px', // Adjust this value as needed,
                    fontFamily: "Poppins"
                  },
                  '& .MuiDataGrid-columnHeaderCheckbox': {
                    backgroundColor: Colors.black, // Change this to your desired checkbox background color
                    color: 'white', // Change this to your desired checkbox color
                  },
                  '& .css-1vjf6hs-MuiDataGrid-root .css-ptiqhd-MuiSvgIcon-root': {
                    fill: Colors.white,
                  },

                  '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                    color: "gray"
                  }

                  ,
                  '& .MuiDataGrid-columnHeader': {
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  },
                }}
                checkboxSelection rowSelectionModel={customerLedger && customerLedger?.length > 0 ? customerLedger?.filter((e) => e?.selected).map((row) => row.date) : []} onRowSelectionModelChange={handleSelectionModelChange} rows={searchData && searchData?.length > 0 ? searchData : (searchDate?.selected && startDate && endDate) ? (dateSearchData && dateSearchData?.length > 0 ? dateSearchData : []) : customerLedger && customerLedger?.length > 0 ? customerLedger : []} columns={column} /> : ""}
            </Box>


          </Box >
          <Box style={{ display: "flex", justifyContent: "space-between" }} >
            <Grid container xs={12} sm={12} md={12} >

              <Grid item xs={12} sm={12} md={5.8} >


                {/* <Box sx={{ width: "100%", backgroundColor: Colors.button, borderRadius: "5px", padding: "15px", marginTop: "20px", marginBottom: "20px" }} >

              <Typography sx={{ color: Colors.white, fontFamily: "Poppins", fontWeight: "600", fontSize: "14px", textAlign: "left", marginTop: "5px" }} >
                Account Summary Type
              </Typography>


              <BasicSelect style={{ marginTop: 0.5, backgroundColor: Colors.white }} value={selectedSummaryType} status="Select" id={summaryType} width={"100%"} onChange={(e) => setSelectedSummaryType(e)} />


              <Box sx={{ display: "flex", flexWrap: "wrap", marginTop: "15px" }} >

                <Button sx={{ color: Colors.button, backgroundColor: Colors.white, borderRadius: "5px", width: "120px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                  View/Print
                </Button>
                <Button sx={{ color: Colors.button, backgroundColor: Colors.white, borderRadius: "5px", width: "fit-content", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                  Balance For All Customers
                </Button>
                <Button sx={{ color: Colors.button, backgroundColor: Colors.white, borderRadius: "5px", width: "120px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                  Deleted Invoices
                </Button>
                <Button sx={{ color: Colors.button, backgroundColor: Colors.white, borderRadius: "5px", width: "120px", fontFamily: "Poppins", fontSize: "10px", fontWeight: "bold", marginRight: "10px", marginTop: "5px" }} >
                  Posting Label
                </Button>

              </Box>


            </Box> */}

              </Grid>


              {/* <Grid item xs={12} sm={12} md={5.8} sx={{ marginLeft: { xs: "0px", sm: "0px", md: "20px" } }} >


            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", marginTop: 2, marginBottom: { xs: 2, sm: 2, md: 0 }, backgroundColor: Colors.white, padding: "20px", borderRadius: "5px" }} >
              <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "space-between", flexWrap: "wrap" }} >
                <CustomButton style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.text}`, color: Colors.text, width: { xs: "100%", sm: "fit-content" }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="New Account" />


                <CustomButton style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.text}`, color: Colors.text, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="Delete" />


                <CustomButton style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, color: Colors.button, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="Exit" />

                <CustomButton style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text="Save" />

              </Box>

            </Box>

          </Grid> */}


            </Grid>
          </Box>

          <CustomModal
            heading={"Update Cheque Status"}
            loading={loading}
            text={"Are you sure you want to update this cheque status"}
            open={openUpdateChequeModal}
            closeModal={() => setUpdateChequeModal(false)}
            confirm={handleUpdateCheque}
          />

          <CustomModal
            heading={"Delete Quotation"}
            loading={deleteQuotationLoading}
            text={"Are you sure you want to delete this quotation"}
            open={deleteQuotationModal}
            closeModal={() => setDeleteQuotationModal(false)}
            confirm={handleDeleteQuotation}
          />

          <SubAccountCustomemodal
            heading={"Ledger Sub Account"}
            loading={openModalLoading}
            buttonText={'Save Changes'}
            placeholder={'Enter Sub Account'}
            description={description}
            // text={"Are you sure you want to delete this quotation"}
            data={selectedRow}
            open={openModal}
            closeModal={() => setOpenModal(false)}
            confirm={handleSaveClick}
            status={tempName}
            onChange={onChangeDescription}
          />

          <RefundModal
            customerLedger={customerLedger}
            open={openCreditRefundModal}
            customerDetails={selectedCustomer}
            setRefundModal={setOpenCreditRefundModal}
            setCustomerLedger={setCustomerLedger}
            setSelectedCustomer={setSelectedCustomer}
            closeModal={() => setOpenCreditRefundModal(false)}
          />

          {
            <TransactionDetailsModal
              open={openTransactionDetailModal}
              closeModal={() => setOpenTransactionDetailModal(false)}
              ledgerData={dataClicked}
            />
          }

          {
            <TransactionModal
              open={openTransactionModal}
              closeModal={() => setOpenTransactionModal(false)}
              ledgerData={dataClicked}
            />
          }

          {/* <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <Box sx={{ padding: 2, width: 400, margin: 'auto', backgroundColor: 'white' }}>
              <Typography variant="h6">Ledger Details</Typography>
              <Divider sx={{ my: 2 }} />

              <Typography variant="body1">Date/Time: {new Date(selectedRow?.date).toLocaleString()}</Typography>
              <Typography variant="body1">Transaction Type: {selectedRow?.transactionType}</Typography>

              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                multiline
                sx={{ mt: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveClick}
                sx={{ mt: 2 }}
              >
                Save
              </Button>
            </Box>
          </Modal> */}

        </Box >



  )
}

export default SingleAccountDetails
