import { Box, Button, Grid, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Colors } from '../../Constant/Color'
import CustomButton from '../../Components/Button'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import BasicSelect from '../../Components/Dropdown';
import { DataGrid } from "@mui/x-data-grid"
import Checkbox from '../../Components/checkbox';
import { CheckBox } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../../Constant/BaseUri';
import { updateArrangedProducts } from '../../Redux/LoginReduces';




function ProductArrangement({barcodeData,setBarcodeRead}) {



    const navigate = useNavigate()
    const dispatch = useDispatch()


    const arrangedProducts = useSelector(state => state.loginReducer.arrangedProducts)


    const [category, setCategory] = useState([
        "mobile",
        "electronics",
        "clothes",
        "jewelery"
    ])

    const [product, setProducts] = useState([])



    const column = [
        {
            field: "id",
            headerName: "S.no",
            width: "50",
            headerClassName: 'super-app-theme--header',

        },
        {
            field: "productName",
            headerName: "Product Name",
            headerClassName: 'super-app-theme--header',
            width: "250",
        },
        {
            field: "category",
            headerName: "Category",
            headerClassName: 'super-app-theme--header',
            width: "150",
        },
        {
            field: "supplier_name",
            headerName: "Supplier Name",
            headerClassName: 'super-app-theme--header',
            width: "200",
        },
        {
            field: "qty",
            headerName: "Qty",
            headerClassName: 'super-app-theme--header',
            width: "100",
            renderCell: (cellValues) => {
                return <p style={{ color: Colors.red, fontFamily: "Poppins", fontWeight: "bold" }} >
                    {cellValues?.value}
                </p>

            }
        },
        {
            field: "cost_price",
            headerName: "Cost",
            headerClassName: 'super-app-theme--header',
            width: "100",
            renderCell: (cellValues) => {
                return <p style={{ color: Colors.green, fontFamily: "Poppins", fontWeight: "bold" }} >
                    {cellValues?.value}
                </p>
            }
        },

        {
            field: "total_cost",
            headerName: "Total Cost",
            headerClassName: 'super-app-theme--header',
            width: "100",
            renderCell: (cellValues) => {
                return <p style={{ color: "purple", fontFamily: "Poppins", fontWeight: "bold" }} >
                    {cellValues?.value}
                </p>

            }
        },

        {
            field: "paymentMethod",
            headerName: "Payment Method",
            headerClassName: 'super-app-theme--header',
            width: "100",
            renderCell: (cellValues) => {
                return <p style={{ color: cellValues?.row?.paymentMethod == "cash" ? "green" : "red", fontFamily: "Poppins", fontWeight: "bold" }} >
                    {cellValues?.value}
                </p>

            }
        },
        {
            field: "trade_price",
            headerName: "Trade Price",
            headerClassName: 'super-app-theme--header',
            width: "100",
        },
        {
            field: "warehouse_price",
            headerName: "Warehouse Price",
            headerClassName: 'super-app-theme--header',
            width: "150",
        },
        {
            field: "retail_price",
            headerName: "Retail Price",
            headerClassName: 'super-app-theme--header',
            width: "100",
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: 'super-app-theme--header',
            width: "150",
        },
    ]

    const [selectedCategory, setSelectedCategory] = useState("select")
    const [searchCategory, setSearchCategory] = useState([])


    // const getProducts = async () => {
    //     try {
    //         let data = await sessionStorage.getItem("userData");
    //         data = JSON.parse(data);
    //         let token = data;

    //         axios.get(`${Base_Uri}getProducts`, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`,
    //                 "Content-Type": "application/json",
    //             },
    //         }).then((res) => {
    //             let myData = res.data;
    //             let { data } = myData;

    //             if (data && data.length > 0) {
    //                 let productsBySupplier = [];

    //                 data.forEach((product, i) => {


    //                     axios.get(`${Base_Uri}getProductLedger/${product.barcode}`).then((res) => {

    //                         let data = res.data

    //                         if (!data.status) {
    //                             alert(data?.message)
    //                             return
    //                         }

    //                         let ledgerData = data?.data

    //                         console.log(ledgerData,"ledgerData")

    //                         if (
    //                             ledgerData &&
    //                             ledgerData?.length > 0
    //                         ) {
    //                             ledgerData.forEach((ledgerItem, ind) => {
    //                                 if (ledgerItem?.status === "local purchase") {
    //                                     const supplierName = ledgerItem?.supplierDetails?.supplier_name;


    //                                     productsBySupplier.push({

    //                                         productName: product.product_name,
    //                                         category: product?.category,
    //                                         supplier_name: supplierName,
    //                                         total_cost: Number(Number(ledgerItem?.cost_price) * Number(ledgerItem?.qty)).toFixed(2),
    //                                         qty: ledgerItem?.qty,
    //                                         cost_price: ledgerItem?.cost_price,
    //                                         trade_price: ledgerItem?.trade_price,
    //                                         warehouse_price: ledgerItem?.warehouse_price,
    //                                         retail_price: ledgerItem?.retail_price,
    //                                         paymentMethod: ledgerItem?.supplierDetails?.paymentMethod,
    //                                         status: ledgerItem?.status
    //                                     });
    //                                 }
    //                             });
    //                         }

    //                     }).catch((error) => {

    //                         alert(error?.message)
    //                         return
    //                     })





    //                 });


    //                 productsBySupplier = productsBySupplier && productsBySupplier?.length > 0 && productsBySupplier?.map((e, i) => {

    //                     return {
    //                         ...e,
    //                         id: i + 1
    //                     }

    //                 })

    //                 dispatch(updateArrangedProducts(productsBySupplier))
    //                 setProducts(productsBySupplier)



    //             }
    //         }).catch((error) => {
    //             console.log(error, "error");
    //             // setLoading(false)
    //         });
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };


    const getProducts = async () => {
        try {
            let data = await sessionStorage.getItem("userData");
            data = JSON.parse(data);
            let token = data;
    
            axios.get(`${Base_Uri}getProducts`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                let myData = res.data;
                let { data } = myData;
    
                if (data && data.length > 0) {
                    let productPromises = data.map(product => {

                    
                        return axios.get(`${Base_Uri}getProductLedger/${product.barcode}`)
                            .then((res) => {
                                let data = res.data;
                                if (!data.status) {
                                    alert(data?.message);
                                    return [];
                                }
                                let ledgerData = data?.data || [];
                                return ledgerData.filter(ledgerItem => ledgerItem?.status === "local purchase")
                                    .map(ledgerItem => ({
                                        productName: product.product_name,
                                        category: product?.category,
                                        supplier_name: ledgerItem?.supplierDetails?.supplier_name,
                                        total_cost: Number(Number(ledgerItem?.cost_price) * Number(ledgerItem?.qty)).toFixed(2),
                                        qty: ledgerItem?.qty,
                                        barcode  : product?.barcode,
                                        cost_price: ledgerItem?.cost_price,
                                        trade_price: ledgerItem?.trade_price,
                                        warehouse_price: ledgerItem?.warehouse_price,
                                        retail_price: ledgerItem?.retail_price,
                                        paymentMethod: ledgerItem?.supplierDetails?.paymentMethod,
                                        status: ledgerItem?.status
                                    }));
                            })
                            .catch((error) => {
                                // alert(error?.message);
                                return [];
                            });
                    });
    
                    Promise.all(productPromises).then(results => {
                        let productsBySupplier = results.flat();
                        productsBySupplier = productsBySupplier.map((e, i) => ({
                            ...e,
                            id: i + 1
                        }));
    
                        dispatch(updateArrangedProducts(productsBySupplier));
                        setProducts(productsBySupplier);
                    });
                }
            }).catch((error) => {
                console.log(error, "error");
                // setLoading(false)
            });
        } catch (error) {
            console.error("Error:", error);
        }
    };
    


    useEffect(() => {


        setProducts(arrangedProducts)


        let category = arrangedProducts && arrangedProducts.length > 0 && arrangedProducts.map((e, i) => {

            return e.category

        })

        if (category) {
            category = [...new Set(category)]
            category.unshift("All Categories")
            setCategory(category)
        }

        getProducts()

    }, [arrangedProducts?.length])


    const handleFilterCategory = (name) => {

        setSelectedCategory(name)

        if (name == "All Categories") {

            setSearchCategory(product)

        }
        else {

            setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

                if (e.category == name) {
                    return e
                }

            }))

        }



    }

    const handleSearchProducts = (text) => {

        let search = text.target.value

        if (!search && selectedCategory) {

            setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

                return e.category == selectedCategory

            }))

            return
        }


        if (selectedCategory == "select" && !search) {

            setSearchCategory([])
            return
        }



        if ((selectedCategory == "All Categories" || selectedCategory == "select") && search) {


            setSearchCategory(product && product.length > 0 && product.filter((e, i) => {

                if (e.productName.toLowerCase().includes(search.toLowerCase()) || e.supplier_name.toLowerCase().includes(search.toLowerCase()) || e?.barcode?.includes(search)) {
                    return e
                }

            }))
            return
        }

        if ((selectedCategory !== "All Categories" && selectedCategory !== "select")) {


            let searching = product && product.length > 0 && product.filter((e, i) => {

                if (e.category == selectedCategory && (e.productName.toLowerCase().includes(search.toLowerCase()) || e.supplier_name.toLowerCase().includes(search.toLowerCase()) ||  e?.barcode?.includes(search) ) ) {
                    return e
                }

            })

            if (searching.length > 0) {

                setSearchCategory(searching)
            }


        }



    }



    

  useEffect(() => {
    if (barcodeData?.length > 0) {

      let searchproduct =
        product &&
        product?.length > 0 &&
        product?.find((e) => e?.barcode == barcodeData);

      if (searchproduct) {
        setSearchCategory([searchproduct]);
        setBarcodeRead("");
      }else{
        setBarcodeRead("")
      }
    }
  }, [barcodeData]);

    return (
        <Box sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }} >

            <Box sx={{ display: "flex" }} >
                <Grid container md={12} xs={12} sm={12} >
                    <Grid item xs={12} sm={12} md={6} >

                        <Box sx={{ display: "flex", alignItems: "center" }} >
                            <Grid item xs={1} sm={1} md={1}  >
                                <img src={require("../../Images/filter.png")} height={"15px"} style={{ marginRight: "5px" }} />
                            </Grid>
                            <Grid item xs={3} sm={2} md={3} lg={3} xl={2.5} style={{ marginRight: "5px" }} >
                                <CustomButton text="Active" icon={<img src={require("../../Images/cross.png")} height="15px" />} style={{ width: "100%", padding: "5px 5px 5px 5px", fontSize: { xl: "12px", lg: "12px", md: "10px", sm: "10px", xs: "8px" }, display: "flex", justifyContent: "space-around", marginRight: "5px", alignItems: "center" }} />
                            </Grid>

                            <Grid item xs={4} sm={4} md={4} lg={4} xl={2.5} style={{ marginRight: "5px" }} >
                                <BasicSelect value={selectedCategory} status="Category" id={category} innerStyle={{ fontSize: "12px", border: `1px solid ${Colors.border}`, borderRadius: 10, fontWeight: "bold" }} style={{ border: "0px" }} onChange={(e) => handleFilterCategory(e)} />
                            </Grid>
                        </Box>

                    </Grid>
                    <Grid item xs={12} sm={12} md={6} >
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >

                            <Grid md={11} xs={10} sm={10} >
                                <Box sx={{ width: "100%", marginTop: { xs: "0px", sm: "0px", md: "0px" }, border: `1px solid ${Colors.button}`, borderRadius: "5px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "5px 10px 5px 10px", margin: 0 }} >
                                    <TextField
                                        onChange={(e) => handleSearchProducts(e)}
                                        variant="standard" // <== changed this
                                        margin="normal"
                                        required
                                        fullWidth
                                        InputProps={{
                                            // startAdornment: <AccountCircle />, // <== adjusted this
                                            disableUnderline: true,
                                            fontSize: "12px"
                                            // <== added this
                                            // fontSize: "2px"
                                        }}
                                        placeholder="Search Name ,Id , Phone & Bar Code"
                                        sx={{ width: "90%", fontSize: "12px", padding: 0, fontFamily: "Poppins", fontWeight: "bold", margin: 0 }}
                                    />
                                    <img src={require("../../Images/search.png")} style={{ height: "20px" }} />
                                </Box>
                            </Grid>


                            <Box sx={{ margin: 0, height: "35px", width: "35px", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: Colors.button }} >
                                <img src={require("../../Images/qrcode.png")} style={{ height: "20px" }} />
                            </Box>

                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }} >
                {<DataGrid sx={{
                    '& .MuiDataGrid-cell': {
                        fontFamily: 'Poppins',
                        fontSize: "12px",
                        fontWeight: "600",
                        color: "#000"
                        // Change to your desired cell text color
                    },

                    // '& .MuiDataGrid-columnHeader': {
                    //     backgroundColor: Colors.black,
                    //     color: "white",
                    //     fontWeight: 700,
                    //     border: "2px solid white",
                    //     margin: 0,
                    //     padding: '8px', // Adjust this value as needed
                    //     alignItems: "center"
                    // },
                    '& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle': {
                        padding: '0px', // Adjust this value as needed,
                        fontFamily: "Poppins"
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox': {
                        backgroundColor: Colors.black, // Change this to your desired checkbox background color
                        color: 'white', // Change this to your desired checkbox color
                    },
                    // '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked':{
                    //     color:"blue" 
                    // },

                    '& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root': {
                        color: "gray"
                    }
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },
                    ,
                    '& .MuiDataGrid-columnHeader': {
                        fontFamily: "Poppins",
                        // color: "black",
                        fontWeight: "bold",


                    },
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },
                    // '& .MuiDataGrid-columnHeader': {
                    //     backgroundColor: Colors.white,
                    //     color: "black",
                    //     fontWeight: 700,
                    //     border: "2px solid white",
                    //     margin: 0
                    // },
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },

                    // '& .MuiDataGrid-columnHeader': {
                    //     backgroundColor: Colors.white,
                    //     color: "black",
                    //     fontWeight: 700,
                    //     border: "2px solid white",
                    //     margin: 0
                    // },
                    // '& .MuiDataGrid-columnHeaderCheckbox': {
                    //     backgroundColor: Colors.white
                    // },


                }} rows={(searchCategory && searchCategory?.length > 0) ? searchCategory : (product && product.length > 0) ? product : []} columns={column} />}
            </Box>
        </Box>
    )
}

export default ProductArrangement
