import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';

export default function AdminChangePasswordModal({ heading, placeholder, text, emailLoading, buttonText, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, loading, credit, onChange, emailbutton, emailInvoice }) {


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { lg: emailbutton ? "35%" : "30%", md: "40%", sm: "55%", xs: "100%" },
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };



    const userData = useSelector((state) => state.loginReducer.updatedState);



    const [isConfirmed, setIsConfirmed] = React.useState(false)
    const [isVerified, setIsVerified] = React.useState(false)
    const [confirmData, setConfirmData] = React.useState({
        employeeId: "",
        password: ""
    })

    const [loginLoader, setLoginLoader] = React.useState(false)

    const [verificationCode, setVerificationCode] = React.useState('')
    const [verificationCodeToUser, setVerificationCodeToUser] = React.useState('')


    const [newPassword, setNewPassword] = React.useState('')


    const handleClose = () => {
        setIsConfirmed(false)
        setIsVerified(false)
        setNewPassword(false)
        closeModal()
    }



    console.log(userData, "iserData")

    const handleConfirmCredentials = () => {


        if (!confirmData?.employeeId) {
            alert("employee id is missing")
            return
        }

        if (!confirmData?.password) {
            alert("password is missing")
            return
        }


        let dataToSend = {
            employeeId: confirmData?.employeeId,
            password: confirmData?.password
        }

        setLoginLoader(true)

        try {
            axios.post(`${Base_Uri}login`, dataToSend).then(async (res) => {
                let data = res.data

                if (data?.status) {


                    if (data?.block) {

                        alert(data.message)
                        setLoginLoader(false)
                        return
                    }


                    if (data?.data?.role !== "admin") {


                        alert("unauthorized")
                        setLoginLoader(false)
                        return
                    }
                    else {


                        let data = await sessionStorage.getItem("userData");
                        data = JSON.parse(data);
                        let token = data;


                        const code = Math.floor(100000 + Math.random() * 900000);

                        let dataToSend = {
                            subject: "Update Password",
                            body: `Hey Admin the verfication code has been sent to your email
                            Verification code : ${code}
                        
                        `,
                            to: userData?.emailAddress
                        }

                        axios.post(`${Base_Uri}sendEmailToCustomer`, dataToSend, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json', // Include other headers as needed
                            },
                        }).then(() => {

                            setVerificationCodeToUser(code)
                            setIsConfirmed(true)
                            setLoginLoader(false)
                            alert("Code send to your email successfully")

                        }).catch((error) => {
                            setLoginLoader(false)
                            alert(data.message)
                        })

                    }


                } else {
                    alert(data.message)
                    setLoginLoader(false)
                }


            })
        } catch (error) {
            alert("Internal Server Error")
            setLoginLoader(false)
            return
        }


    }

    const handleConfirmVerificationCode = () => {


        if (!verificationCode) {
            alert("Kindy enter verification code")
            return
        }

        setLoginLoader(true)

        if (Number(verificationCode) !== Number(verificationCodeToUser)) {
            alert("Invalid Verification code")
            setLoginLoader(false)
            return
        }

        alert("verified successfully")
        setLoginLoader(false)
        setIsVerified(true)

    }


    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                    <Box onClick={() => handleClose()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>


                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {isVerified ? "Change Password" : heading}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", mt: 1, padding: "5px 10px 5px 10px ", textAlign: "center", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                        {text}
                    </Typography>

                    {!isConfirmed ? <Box>
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            onChange={(e) => setConfirmData({
                                ...confirmData,
                                employeeId: e.target.value
                            })}
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            placeholder={"Enter EmployeeId"}
                            sx={{
                                width: "100%",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 2,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",

                            }}
                        />


                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            onChange={(e) => setConfirmData({
                                ...confirmData,
                                password: e.target.value
                            })}
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            type="password"
                            placeholder={"Enter Password"}
                            sx={{
                                width: "100%",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 2,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",
                            }}
                        />

                    </Box> : !isVerified
                        ?
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            onChange={(e) => setVerificationCode(e.target.value)}
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            placeholder={"Enter Verification Code"}
                            sx={{
                                width: "100%",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 2,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",

                            }}
                        />
                        :
                        <TextField
                            variant="standard"
                            margin="normal"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                fontSize: "12px",
                                style: {
                                    textAlign: "center",
                                    fontSize: "16px",
                                    alignSelf: "center",
                                },
                            }}
                            inputProps={{
                                disableUnderline: true,
                                fontSize: "12px",

                            }}
                            placeholder={"Enter New Password"}
                            sx={{
                                width: "100%",
                                fontSize: "12px",
                                padding: 1,
                                border: `1px solid ${Colors.border}`,
                                borderRadius: "5px",
                                marginBottom: 2,
                                textAlign: "center", // Move textAlign to sx
                                fontFamily: "Poppins",
                                fontWeight: "bold",

                            }}
                        />
                    }
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >

                        <Button onClick={handleClose} sx={{ backgroundColor: Colors.white, color: Colors.button, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", ...buttonStyle }} >
                            Cancel
                        </Button>




                        <Button onClick={() => (loginLoader || loading) ? "" : !isConfirmed ? handleConfirmCredentials() : !isVerified ? handleConfirmVerificationCode() : confirm(newPassword)} sx={{
                            backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", fontFamily: "Poppins", '&:hover': {
                                color: Colors.button, // Set your desired hover color
                            }, ...buttonStyle2
                        }} >
                            {(loading || loginLoader) ? <RingLoader size={30} loading={loading || loginLoader} /> : buttonText ? buttonText : "Confirm"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </div>
    );
}