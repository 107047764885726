import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import React, { useEffect, useState } from "react";
import AddProduct from "./Screens/addProduct";
import Signup from "./Screens/Signup/signup";
import Login from "./Screens/Login/login";
import CustomerAccount from "./Screens/CustomerAccount/customerAccount";
import PointOfSale from "./Screens/PointOfSale/pointOfSale";
import CustomModal2 from "./Components/CustomModal2";
import NotFound from "./Screens/NotFound";
import { useDispatch, useSelector } from "react-redux";
import { updateAuth } from "./Redux/authenticationReducer";
import { Base_Uri } from "./Constant/BaseUri";
import axios from "axios";
import {
  edit,
  updateAllEmployees,
  updateAllExchangeInvoice,
  updateAllInvoices,
  updateAllReturnInvoices,
  updateArrangedProducts,
  updateCustomers,
  updateDamageProducts,
  updateDayReturnInvoiceEmp,
  updateDemandedProducts,
  updateInvoice,
  updateProductDepartment,
  updateProducts,
  updatePurchaseOrders,
  updateShift,
  updateShiftEnded,
  updateSuppliers,
  updateTodayEmployeeClaimInvoices,
  updateTodayEmployeeWarrantyInvoices,
  updateVat,
  updatetodayEmployeeExchangeInvoice,
} from "./Redux/LoginReduces";
import TodayInvoices from "./Screens/PointOfSale/Screens/todayInvoices/todayInvoices";
import Employees from "./Screens/Employees";
import Suppliers from "./Screens/Suppliers";
import { RingLoader } from "react-spinners";
import { Box, Typography } from "@mui/material";
import { useGridInitializeState } from "@mui/x-data-grid/internals";
import CompanyInformation from "./Screens/CompanyInformation";
import Reports from "./Screens/Reports/reports";
import ProfilePage from "./Screens/ProfilePage";
import Shifts from "./Screens/Shifts";

function AppRouter() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.loginReducer.updatedState);
  const auth = useSelector(
    (state) => state.authenticationReducer.authentication
  );

  const [loading, setLoading] = useState(null);
  const [token, setToken] = useState("");

  const [apiFetchLoading, setApiFetchLoading] = useState(false);

  const getToken = async () => {
    setLoading(true);
    let token = await sessionStorage.getItem("userData");
    if (token) {
      setToken(token);
      dispatch(updateAuth(true));
      setLoading(false);
    } else {
      dispatch(updateAuth(false));
      setLoading(false);
    }
  };

  const getCustomers = async () => {
    let token = await sessionStorage.getItem("userData");
    token = JSON.parse(token);

    axios
      .get(`${Base_Uri}getCustomers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {
          let customers = data?.data;

          let sortedData =
            customers &&
            customers?.length > 0 &&
            customers?.sort((a, b) => a?.accountNo - b?.accountNo);

          dispatch(
            updateCustomers(
              sortedData && sortedData.length > 0 ? sortedData : []
            )
          );

          // resolve(sortedData);
        }
      })
      .catch((error) => {
        // reject(error);
        console.log(error, "error");
      });
    // });
  };

  const getProducts = async () => {
    // setLoading(true)

    // return new Promise(async (resolve, reject) => {
    // Fetch data from API
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
            // resolve(allProducts);
          }
        })
        .catch((error) => {
          // reject(error);
          console.log(error, "error");
          // setLoading(false)
        });
    }
    // });
  };

  const getVat = async () => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getVat`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {
          dispatch(updateVat(data.data));
        }
      })
      .catch((error) => {});
  };

  const getLoginData = async () => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getEmployeeDetails`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let { data } = res;

          dispatch(edit(data.data));
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  };

  const getTodayInvoices = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(updateInvoice(data && data.length > 0 ? data : []));
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getTodayExchangeInvoices = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getExchangeInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(
            updatetodayEmployeeExchangeInvoice(
              data && data.length > 0 ? data : []
            )
          );
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getTodayReturnInvoices = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getReturnInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(
            updateDayReturnInvoiceEmp(data && data.length > 0 ? data : [])
          );
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getAllInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    // return new Promise((resolve, reject) => {
    // Fetch data from API

    axios
      .get(`${Base_Uri}getAllInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllInvoices(data && data.length > 0 ? data : []));
        // resolve(data)
      })
      .catch((error) => {
        // reject(error)
        console.log(error, "error");
      });
    // });
  };

  const getAllExchangeInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllExchangeInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllExchangeInvoice(data && data.length > 0 ? data : []));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getAllReturnInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllReturnInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllReturnInvoices(data && data.length > 0 ? data : []));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getAllSuppliers = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getSuppliers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          let suppliers = data.data;

          dispatch(
            updateSuppliers(suppliers && suppliers.length > 0 ? suppliers : [])
          );
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getDepartments = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProductDepartment`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            dispatch(updateProductDepartment(data.data));
          }
        });
    }
  };

  const getDamageProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getDamageProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            let myData = res.data;

            let { data } = myData;

            if (data && data.length > 0) {
              let allProducts = [];

              for (var i = 0; i < data.length; i++) {
                let product = data[i];

                product.id = i + 1;
                product["productName"] = product?.product_name;

                if (product?.status == "active") {
                  product.status = "Active";
                }

                allProducts.push(product);
              }

              dispatch(updateDamageProducts(allProducts));
            }
          }
        });
    }
  };

  const getDemandedProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getDemandedProduct`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            let myData = res.data;

            let { data } = myData;

            if (data && data.length > 0) {
              let allProducts = [];

              for (var i = 0; i < data.length; i++) {
                let product = data[i];

                product.id = i + 1;
                product["productName"] = product?.product_name;

                allProducts.push(product);
              }

              dispatch(updateDemandedProducts(allProducts));
            }
          }
        });
    }
  };

  const getArrangedProducts = async () => {
    try {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          let myData = res.data;
          let { data } = myData;

          if (data && data.length > 0) {
            let productsBySupplier = [];

            data.forEach((product, i) => {
              if (
                product?.productLedger &&
                product?.productLedger?.length > 0
              ) {
                product.productLedger.forEach((ledgerItem, ind) => {
                  if (ledgerItem?.status === "local purchase") {
                    const supplierName =
                      ledgerItem?.supplierDetails?.supplier_name;

                    productsBySupplier.push({
                      productName: product.product_name,
                      category: product?.category,
                      supplier_name: supplierName,
                      total_cost:
                        Number(ledgerItem?.cost_price) *
                        Number(ledgerItem?.qty),
                      qty: ledgerItem?.qty,
                      cost_price: ledgerItem?.cost_price,
                      trade_price: ledgerItem?.trade_price,
                      warehouse_price: ledgerItem?.warehouse_price,
                      retail_price: ledgerItem?.retail_price,
                      status: ledgerItem?.status,
                    });
                  }
                });
              }
            });

            productsBySupplier =
              productsBySupplier &&
              productsBySupplier?.length > 0 &&
              productsBySupplier?.map((e, i) => {
                return {
                  ...e,
                  id: i + 1,
                };
              });

            dispatch(updateArrangedProducts(productsBySupplier));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAllEmployees = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getAllEmployees`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          let data = res.data;

          if (data?.status) {
            let employee = data?.data;

            employee =
              employee &&
              employee.length > 0 &&
              employee.map((e, i) => {
                return {
                  ...e,
                  id: i + 1,
                };
              });

            dispatch(updateAllEmployees(employee));
          }
        })
        .catch((error) => {});
    }
  };

  const getPurchaseOrders = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllPurchaseOrders`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let myData = res.data;

        let { data } = myData;

        if (!data || data.length == 0) {
          dispatch(updatePurchaseOrders([]));
        } else {
          data =
            data &&
            data?.length > 0 &&
            data?.map((e, i) => {
              return {
                ...e,
                id: i + 1,
                supplier_name: e?.supplierDetails?.[0]?.supplier_name,
                supplier_address: e?.supplierDetails?.[0]?.supplier_address,

                supplier_mobile_number:
                  e?.supplierDetails?.[0]?.supplier_mobile_number,

                date: e?.created_at,
              };
            });
          data &&
            data.length > 0 &&
            data.sort((a, b) => {
              if (a.status === "pending" && b.status !== "pending") {
                return -1; // a comes before b
              } else if (a.status !== "pending" && b.status === "pending") {
                return 1; // b comes before a
              } else {
                return 0; // no change in order
              }
            });
          dispatch(updatePurchaseOrders(data));
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const callAllGetApis = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      // setApiFetchLoading(true);

      getCustomers();
      getProducts();
      getVat();
      // getLoginData();
      getAllInvoices();
      getTodayInvoices();
      getAllExchangeInvoices();
      getTodayExchangeInvoices();
      getTodayReturnInvoices();
      getDamageProducts();
      getAllReturnInvoices();
      getArrangedProducts();
      getDemandedProducts();
      getAllSuppliers();
      getDepartments();
      getAllEmployees();
      getPurchaseOrders();

      // try {
      //   await Promise.all([
      //     getCustomers(),
      //     getProducts(),
      //     getTodayInvoices(),
      //     getVat(),
      //     getLoginData(),
      //     getAllInvoices(),
      //     getAllExchangeInvoices(),
      //     getTodayExchangeInvoices(),
      //     getTodayReturnInvoices(),
      //     getDamageProducts(),
      //     getAllReturnInvoices(),
      //     getArrangedProducts(),
      //     getDemandedProducts(),
      //     getAllSuppliers(),
      //     getDepartments(),
      //     getAllEmployees(),
      //     getPurchaseOrders(),
      //   ])
      //     .then((res) => {
      //       setApiFetchLoading(false);
      //     })
      //     .catch((error) => {
      //       setApiFetchLoading(false);
      //     });
      // } catch (error) {
      //   alert(error.message);
      //   setApiFetchLoading(false);
      // }
    }
  };

  const handleShiftCheck = async () => {
    let shiftEnded = await sessionStorage.getItem("shiftEnded");
    shiftEnded = JSON.parse(shiftEnded);

    if (!shiftEnded) {
      let shift = await sessionStorage.getItem("shiftStarted");

      shift = JSON.parse(shift);

      if (!shift) {
        dispatch(updateShift(false));
      } else {
        dispatch(updateShift(true));
      }
    }
  };

  const handleShiftEndedCheck = async () => {
    let shiftEnded = await sessionStorage.getItem("shiftEnded");
    shiftEnded = JSON.parse(shiftEnded);

    if (!shiftEnded) {
      dispatch(updateShiftEnded(false));
    } else {
      dispatch(updateShiftEnded(true));
    }
  };

  useEffect(() => {
    getToken();
    getLoginData();
  }, []);

  useEffect(() => {
    if (userData && userData?.id) {
      callAllGetApis();
    }

    if (userData && userData.role !== "admin") {
      handleShiftCheck();
      handleShiftEndedCheck();
    }
  }, [userData?.id]);

  useEffect(() => {
    const handlePageReload = () => {
      if (performance.navigation.type === 1) {
        setLoading(true);
      } else {
        setLoading(false);
      }
    };

    // Add event listener for beforeunload to detect page reload
    window.addEventListener("beforeunload", handlePageReload);

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener("beforeunload", handlePageReload);
    };
  }, []); // useEffect runs only once when the component mounts
  return apiFetchLoading ? (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <RingLoader loading={apiFetchLoading} color={"black"} size={100} />
      <Typography
        style={{
          color: "black",
          fontSize: 24,
          fontFamily: "Poppins",
          marginTop: 20,
          textAlign: "center",
        }}
      >
        Fetching Data...
      </Typography>
    </Box>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        {auth &&
          userData &&
          (userData?.role == "admin" ||
            userData?.role_access?.some(
              (e, i) => e?.name == "Employees" && e?.selected
            )) && <Route path="/SignupEmployee" element={<Signup />} />}
        {auth && (
          <>
            {" "}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "StockBooking" && e?.selected
                )) && <Route path="Home/*" element={<AddProduct />} />}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "CustomerAccount" && e?.selected
                )) && (
                <Route path="CustomerAccount/*" element={<CustomerAccount />} />
              )}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "PointOfSale" && e?.selected
                )) && <Route path="PointOfSale/*" element={<PointOfSale />} />}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) =>
                    (e?.name == "AllInvoices" && e?.selected) ||
                    (e?.name == "TodayInvoices" && e?.selected)
                )) && (
                <Route
                  path={
                    userData?.role == "admin" ||
                    userData?.role_access?.some(
                      (e, i) => e?.name == "AllInvoices" && e?.selected
                    )
                      ? "AllInvoices"
                      : "TodayInvoices"
                  }
                  element={<TodayInvoices />}
                />
              )}
            <Route path="profilepage" element={<ProfilePage />} />
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "Employees" && e?.selected
                )) && <Route path={"Employees"} element={<Employees />} />}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "Suppliers" && e?.selected
                )) && <Route path={"Suppliers"} element={<Suppliers />} />}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "CompanyInformation" && e?.selected
                )) && (
                <Route
                  path={"CompanyInformation"}
                  element={<CompanyInformation />}
                />
              )}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "Reports" && e?.selected
                )) && <Route path={"Reports"} element={<Reports />} />}
            {userData &&
              (userData?.role == "admin" ||
                userData?.role_access?.some(
                  (e, i) => e?.name == "Shifts" && e?.selected
                )) && <Route path={"Shifts"} element={<Shifts />} />}
          </>
        )}
        {!loading && loading !== null && (
          <Route path="*" element={<NotFound />} />
        )}
      </Routes>
    </Router>
  );
}

export default AppRouter;
