import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Colors } from "../Constant/Color";
import { RingLoader } from "react-spinners";
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import BasicSelect from "./Dropdown";
import { Switch } from "antd";

export default function DepartmentModal({
  onChange1,
  onChange2,
  onChange3,
  selectedCategory,
  setSelectedCategory,
  showCategory,
  categoryPer,
  showSubcategoryOnly,
  categoryName,
  setSelectedDepartment,
  selectedDepartment,
  showDeparment,
  showCategoryOnly,
  heading,
  placeholder,
  headingTextStyle,
  containerStyle,
  open,
  closeModal,
  buttonContainerStyle,
  buttonStyle,
  buttonStyle2,
  confirm,
  loading,
  onChange,
  emailbutton,
  isQuick,
  setIsQuick,
}) {
  const departments = useSelector(
    (state) => state.loginReducer.productDepartments
  );

  let categories = [];

  if (selectedDepartment) {
    let getDepartment =
      departments &&
      departments.length > 0 &&
      departments.filter((e, i) => {
        return e.departmentName == selectedDepartment;
      });

    if (getDepartment && getDepartment.length > 0) {
      let dept = getDepartment[0];

      categories =
        dept.categories &&
        dept.categories.length > 0 &&
        dept.categories.map((e, i) => {
          return e?.categoryName;
        });
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: {
      lg: emailbutton ? "35%" : "30%",
      md: "40%",
      sm: "55%",
      xs: "100%",
    },
    borderRadius: "10px",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={[style, { containerStyle }]}>
          <Typography
            sx={{
              fontSize: "20px",
              color: Colors.button,
              fontWeight: "bold",
              fontFamily: "Poppins",
              textAlign: "center",
              ...headingTextStyle,
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            {heading}
          </Typography>

          {showDeparment && !showCategoryOnly && !showSubcategoryOnly && (
            <BasicSelect
              style={{ marginTop: 0.5 }}
              value={selectedDepartment}
              status="Select Department"
              id={
                departments &&
                departments.length > 0 &&
                departments.map((e, i) => e.departmentName)
              }
              width={"100%"}
              onChange={(e) => setSelectedDepartment(e)}
            />
          )}

          {showCategory && !showSubcategoryOnly && (
            <BasicSelect
              style={{ marginTop: 0.5 }}
              value={selectedCategory}
              status="Select Category"
              id={categories}
              width={"100%"}
              onChange={(e) => setSelectedCategory(e)}
            />
          )}

          <TextField
            variant="standard"
            margin="normal"
            required
            onChange={onChange}
            fullWidth
            InputProps={{
              disableUnderline: true,
              fontSize: "12px",
              style: {
                textAlign: "center",
                fontSize: "16px",
                alignSelf: "center",
              },
            }}
            inputProps={{
              disableUnderline: true,
              fontSize: "12px",
            }}
            placeholder={placeholder}
            sx={{
              width: "100%",
              // height: width < 600 ? "25px" : "35px",
              fontSize: "12px",
              padding: 1,
              border: `1px solid ${Colors.border}`,
              borderRadius: "5px",
              marginBottom: 2,
              textAlign: "center", // Move textAlign to sx
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          />

          {showDeparment && !showCategory && (
            <Box>
              <TextField
                variant="standard"
                margin="normal"
                required
                onChange={onChange1}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                  style: {
                    textAlign: "center",
                    fontSize: "16px",
                    alignSelf: "center",
                  },
                }}
                inputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                }}
                placeholder={
                  categoryPer?.a
                    ? categoryPer?.a
                    : "Select Price Percentage for A Category"
                }
                sx={{
                  width: "100%",
                  // height: width < 600 ? "25px" : "35px",
                  fontSize: "12px",
                  padding: 1,
                  border: `1px solid ${Colors.border}`,
                  borderRadius: "5px",
                  marginBottom: 2,
                  textAlign: "center", // Move textAlign to sx
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              />

              <TextField
                variant="standard"
                margin="normal"
                required
                onChange={onChange2}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                  style: {
                    textAlign: "center",
                    fontSize: "16px",
                    alignSelf: "center",
                  },
                }}
                inputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                }}
                placeholder={
                  categoryPer?.b
                    ? categoryPer?.b
                    : "Select Price Percentage for B Category"
                }
                sx={{
                  width: "100%",
                  // height: width < 600 ? "25px" : "35px",
                  fontSize: "12px",
                  padding: 1,
                  border: `1px solid ${Colors.border}`,
                  borderRadius: "5px",
                  marginBottom: 2,
                  textAlign: "center", // Move textAlign to sx
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                onChange={onChange3}
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                  style: {
                    textAlign: "center",
                    fontSize: "16px",
                    alignSelf: "center",
                  },
                }}
                inputProps={{
                  disableUnderline: true,
                  fontSize: "12px",
                }}
                placeholder={
                  categoryPer?.c
                    ? categoryPer?.c
                    : "Select Price Percentage for C Category"
                }
                sx={{
                  width: "100%",
                  // height: width < 600 ? "25px" : "35px",
                  fontSize: "12px",
                  padding: 1,
                  border: `1px solid ${Colors.border}`,
                  borderRadius: "5px",
                  marginBottom: 2,
                  textAlign: "center", // Move textAlign to sx
                  fontFamily: "Poppins",
                  fontWeight: "bold",
                }}
              />
              <Box sx={{ display: "flex", gap: 10, alignItems: "center",margin:"10px 0" }}>
                <Typography>Show in quick search:</Typography>
                <Switch
                  checked={isQuick}
                  onChange={(e) => {
                    setIsQuick(e)
                    console.log(e, "op");
                  }}
                />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              ...buttonContainerStyle,
            }}
          >
            <Button
              onClick={closeModal}
              sx={{
                backgroundColor: Colors.white,
                color: Colors.button,
                border: `1px solid ${Colors.button}`,
                borderRadius: "20px",
                width: "150px",
                fontFamily: "Poppins",
                ...buttonStyle,
              }}
            >
              Cancel
            </Button>

            <Button
              onClick={() => confirm()}
              sx={{
                backgroundColor: Colors.button,
                color: Colors.white,
                border: `1px solid ${Colors.button}`,
                borderRadius: "20px",
                width: "150px",
                fontFamily: "Poppins",
                "&:hover": {
                  color: Colors.button, // Set your desired hover color
                },
                ...buttonStyle2,
              }}
            >
              {loading ? <RingLoader size={30} loading={loading} /> : "Confirm"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
