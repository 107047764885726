import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import DatePickers from './DatePicker';
import CustomButton from './Button';
import BasicSelect from './Dropdown';
import CheckBox from './checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { Base_Uri } from '../Constant/BaseUri';
import { updateCustomers } from '../Redux/LoginReduces';
import axios from 'axios';
import { RingLoader } from 'react-spinners';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xl: "40%", md: "60%", sm: "75%", xs: "90%" },
    bgcolor: 'background.paper',
    border: '2px solid #000',
    height: "800px",
    overflow: "auto",
    borderRadius: "20px",
    boxShadow: 24,
    p: 4,
};

export default function CustomModal2({ open, closeModal, setCheck1, check1 }) {




    const dispatch = useDispatch()

    const customers = useSelector(state => state.loginReducer.customers)

    const [allAccounts, setAllAccounts] = useState([])
    const [loading, setLoading] = useState({
        save: false
    })


    useEffect(() => {

        setAllAccounts(customers)

    }, [customers])


    const [customerDetails, setCustomerDetails] = useState({

        customerName: "",
        created_at: "",
        address: "",
        city: "",
        email: "",
        postalCode: "",
        legalStatus: "",
        telephoneNo: "",
        telephoneNo2: "",
        mobileNumber: "",
        fax: "",
        accountManager: "select",
        orderLocation: [],
        creditLimits: "",
        creditDays: "",
        discount: "",
        deliveryAddress: "",
        deliveryCity: "",
        deliveryPostalCode: "",
        priceLevel: []
    })


    const [isCompanyAddress, setIsCompanyAddress] = useState({
        selected: false
    })

    const [byInvoiceNumber, setByInvoiceNumber] = useState({
        selected: false
    })

    const [summaryType, seySummaryType] = useState([
        "inprocess",
        "return",
        "trash"
    ])
    const [selectedSummaryType, setSelectedSummaryType] = useState("select")


    const [stockCategory, setStockCategory] = useState([
        {
            name: "Amazon",
            selected: false,
            id: 1
        },
        {
            name: "Ebay",
            selected: false,
            id: 2
        },
        {
            name: "Website",
            selected: false,
            id: 3
        },
        {
            name: "Physical Store",
            selected: false,
            id: 4
        },
        {
            name: "Online",
            selected: false,
            id: 5
        },
    ])

    const [priceLevel, setPriceLevel] = useState([
        {
            name: "Price Level A (Trader)",
            selected: false,
            id: 1
        },
        {
            name: "Warehouse Price",
            selected: false,
            id: 2
        },
        {
            name: "Retail Price (R)",
            selected: false,
            id: 3
        },
    ])




    const [stockDate, setStockDate] = useState("")

    const [compatilityData, setCompatibilityData] = useState([
        'A', 'B', 'C'
    ])
    const [selectedCompatibility, setSelectedCompatibility] = useState("select")

    const [options, setOptions] = useState([
        {
            option: "Delivery Address",
            selected: false
        },
        {
            option: "Contact Persons",
            selected: false
        },
        {
            option: "Comments",
            selected: true
        },
        {
            option: "Email/Other Details",
            selected: false
        },
        {
            option: "VAT Rate",
            selected: false
        },
    ])


    const selectStockCategory = (ind) => {

        setStockCategory(stockCategory.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    selected: e?.selected ? false : true
                }
            } else {
                return e
            }
        }))


    }

    const selectPriceLevel = (ind) => {

        setPriceLevel(priceLevel.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    selected: !e?.selected
                }
            } else {
                return e
            }
        }))


    }


    const handleAddCustomer = async () => {


        const selectedPriceLevel = priceLevel.filter((e, i) => e.selected)
        const selectedOrderLocation = stockCategory.filter((e, i) => e.selected)

        let accountDetails = { ...customerDetails }

        accountDetails.priceLevel = selectedPriceLevel
        accountDetails.orderLocation = selectedOrderLocation
        accountDetails.accountManager = accountDetails.accountManager == "select" ? "" : accountDetails.accountManager

        var dateParts = accountDetails?.created_at?.split('/');

        var formattedDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);


        accountDetails.created_at = formattedDate


        // const requiredFields = ['accountManager', 'accountNo', 'address', 'city', 'created_at', 'creditDays', 'creditLimits', 'discount', 'fax', 'legalStatus', 'mobileNumber', 'postalCode', 'telephoneNo', 'telephoneNo2'];

        // const missingFields = requiredFields.filter(field => !accouuntDetails[field] || accouuntDetails[field].trim() === '');

        // if (missingFields.length > 0) {
        //     alert(`Please fill in the required fields: ${missingFields.join(', ')}`);
        // }


        setLoading({
            ...loading,
            save: true
        })

        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data

        axios.post(`${Base_Uri}createCustomer`, accountDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json', // Include other headers as needed
            },
        }).then((res) => {

            let { data } = res

            if (!data.status) {
                alert(data.message)

                setLoading({
                    ...loading,
                    save: false
                })
                return
            }

            if (data.status) {


                alert(data.message)

                let allCustomers = [...customers, data.data]
                dispatch(updateCustomers(allCustomers))

                window.scrollTo({
                    top: 50,
                    behavior: "smooth"
                })

                setCustomerDetails({

                    customerName: "",
                    created_at: "",
                    address: "",
                    city: "",
                    email: "",
                    postalCode: "",
                    legalStatus: "",
                    telephoneNo: "",
                    telephoneNo2: "",
                    mobileNumber: "",
                    fax: "",
                    accountManager: "select",
                    orderLocation: [],
                    creditLimits: "",
                    creditDays: "",
                    discount: "",
                    deliveryAddress: "",
                    deliveryCity: "",
                    deliveryPostalCode: "",
                    priceLevel: []
                })


                setLoading({
                    ...loading,
                    save: false
                })
            }


        }).catch((error) => {


            setLoading({
                ...loading,
                save: false
            })


        })

    }


    const screenWidth = window.innerWidth


    const selectByInvoiceNumber = () => {


        setByInvoiceNumber({
            ...byInvoiceNumber,
            selected: !byInvoiceNumber?.selected
        })


    }


    const selectOption = (ind) => {

        setOptions(options.map((e, i) => {
            if (ind == i) {
                return {
                    ...e,
                    selected: true
                }
            } else {
                return {
                    ...e,
                    selected: false
                }
            }
        }))

    }

    const handleExitModal = () => {

        closeModal()

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>


                    <Grid item xs={12} sm={12} md={12}>
                        <Box style={{ padding: 10 }}>
                            {/* Content for the second column */}
                            {/* 
                            <TextField
                                variant="standard" // <== changed this
                                // margin="normal"
                                required
                                fullWidth
                                InputProps={{
                                    // startAdornment: <AccountCircle />, // <== adjusted this
                                    disableUnderline: true,
                                    fontSize: "12px",

                                    // <== added this
                                    // fontSize: "2px"
                                }}
                                placeholder="Search Customer"
                                sx={{ width: "100%", fontSize: "12px", padding: 1, fontFamily: "Poppins", fontWeight: "bold", marginTop: 1, border: `1px solid ${Colors.border}`, borderRadius: "5px" }}
                            /> */}


                            <Box style={{ marginTop: "10px", padding: 15, backgroundColor: Colors.white, borderTopRightRadius: "5px", borderTopLeftRadius: "5px", marginBottom: "0px" }} >

                                <Box style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", }} >


                                    <Typography sx={{ fontSize: "20px", fontWeight: "bold", fontFamily: "Poppins" }} >
                                        Account Details
                                    </Typography>
                                </Box>

                                <Box style={{ marginTop: 10 }} >
                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between" }} >
                                        <Grid xs={12} md={5.5} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Customer Name:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        customerName: e.target.value
                                                    })}
                                                    value={customerDetails.customerName}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="000"
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid xs={12} md={6} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Created Date/OP
                                            </Typography>
                                            <Box sx={{ width: "100%" }} >

                                                <DatePickers values={customerDetails?.created_at} onChange={(e) => setCustomerDetails({
                                                    ...customerDetails,
                                                    created_at: e
                                                })} label="Select Date" />

                                            </Box>
                                        </Grid>
                                    </Grid>



                                    {/* <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            Business Name
                                        </Typography>
                                    </Box>

                                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2px" }} >

                                        <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                            placeholder="Enter Business Name"
                                            InputProps={{
                                                // startAdornment: <AccountCircle />, // <== adjusted this
                                                disableUnderline: true, // <== added this
                                                // fontSize: "2px"
                                            }}
                                        />
                                    </Box> */}




                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            Address
                                        </Typography>


                                    </Box>

                                    <Box sx={{ marginTop: "2px" }} >
                                        <textarea
                                            value={customerDetails.address}
                                            // onChange={(e) =>
                                            //   setData((prev) => ({ ...prev, cityName: e.target.value }))
                                            // }
                                            onChange={(e) => setCustomerDetails({
                                                ...customerDetails,
                                                address: e.target.value
                                            })}

                                            multiline={true}
                                            row={10}
                                            placeholder="Enter Address"
                                            style={{
                                                width: "100%", fontSize: 14, padding: "5px 10px 5px 10px", marginTop: 1, fontFamily: "Poppins", border: `1px solid ${Colors.border}`, height: 100,
                                                // fontStyle: editorState?.italic ? "italic" : "normal",
                                                // textDecoration: editorState?.underline ? "underline" : "none",
                                                // textAlign: editorState?.alignment,
                                                borderRadius: "5px",

                                            }}
                                        />
                                    </Box>


                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            City
                                        </Typography>
                                    </Box>

                                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2px" }} >

                                        <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            onChange={(e) => setCustomerDetails({
                                                ...customerDetails,
                                                city: e.target.value
                                            })}
                                            value={customerDetails.city}
                                            required
                                            fullWidth
                                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                            placeholder="Enter City"
                                            InputProps={{
                                                // startAdornment: <AccountCircle />, // <== adjusted this
                                                disableUnderline: true, // <== added this
                                                // fontSize: "2px"
                                            }}
                                        />
                                    </Box>


                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            Email
                                        </Typography>


                                    </Box>

                                    <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "2px" }} >

                                        <TextField

                                            onChange={(e) => setCustomerDetails({
                                                ...customerDetails,
                                                email: e.target.value
                                            })}
                                            value={customerDetails.email}
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                            placeholder="Enter Email"
                                            InputProps={{
                                                // startAdornment: <AccountCircle />, // <== adjusted this
                                                disableUnderline: true, // <== added this
                                                // fontSize: "2px"
                                            }}
                                        />
                                    </Box>


                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                                        <Grid xs={12} md={5.5} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Postal Code:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        postalCode: e.target.value
                                                    })}
                                                    value={customerDetails.postalCode}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Potal Code..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid xs={12} md={6} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Legal Status:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >


                                                <TextField
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        legalStatus: e.target.value
                                                    })}
                                                    value={customerDetails.legalStatus}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="text"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Legal Status..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                                        <Grid xs={12} md={5.5} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Telephone No:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        telephoneNo: e.target.value
                                                    })}
                                                    value={customerDetails.telephoneNo}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Telephone No..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid xs={12} md={6} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Telephone No 2:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    value={customerDetails.telephoneNo2}
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        telephoneNo2: e.target.value
                                                    })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Telephone No 2..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }} >
                                        <Grid xs={12} md={5.5} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Mobile Number:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    value={customerDetails.mobileNumber}
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        mobileNumber: e.target.value
                                                    })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Mobile Number..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid xs={12} md={6} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Fax:
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }} >

                                                <TextField
                                                    value={customerDetails.fax}
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        fax: e.target.value
                                                    })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="Enter Fax Number..."
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>


                                    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                        <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins" }} >
                                            Account Manager
                                        </Typography>


                                    </Box>
                                    <BasicSelect style={{ marginTop: 0.5 }} value={customerDetails.accountManager} status="Select" id={compatilityData} width={"100%"} onChange={(e) => setCustomerDetails({
                                        ...customerDetails,
                                        accountManager: e
                                    })} />

                                    <Box style={{ width: "100%", marginTop: 20 }} >
                                        <Grid container sm={12} xs={12} md={12} >

                                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                                <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "bold", fontFamily: "Poppins" }} >
                                                    Order Location
                                                </Typography>


                                            </Box>

                                            {stockCategory && stockCategory.length > 0 && stockCategory?.map((e, i) => {
                                                return (

                                                    <Grid xl={2.3} xs={6} sm={2.3} lg={2.3} md={2.8} >

                                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "flex-start", sm: "flex-start" }, marginTop: "5px" }} >
                                                            <CheckBox onClick={() => selectStockCategory(i)} data={e} />
                                                            <Typography sx={{ fontFamily: "Poppins", fontWeight: "600", fontSize: { xs: "7px", sm: "8px", md: "12px" }, color: Colors.text, marginLeft: "5px" }} >
                                                                {e?.name}
                                                            </Typography>

                                                        </Box>

                                                    </Grid>

                                                )
                                            })}



                                        </Grid>

                                    </Box>


                                    <Grid container xs={12} md={12} xl={12} lg={12} sm={12} style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }} >
                                        <Grid xs={12} md={3.9} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Credit Limit
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                                                <TextField
                                                    value={customerDetails.creditLimits}
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        creditLimits: e.target.value
                                                    })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="0"
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>


                                        </Grid>
                                        <Grid xs={12} md={3.9} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Credit Days
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                                                <TextField
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        creditDays: e.target.value
                                                    })}
                                                    value={customerDetails.creditDays}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="0"
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>


                                        <Grid xs={12} md={3.9} >
                                            <Typography style={{ color: Colors.text, fontSize: 14, fontWeight: "bold", fontFamily: "Poppins", textAlign: "left" }} >
                                                Discount %
                                            </Typography>
                                            <Box sx={{ border: `1px solid ${Colors.border}`, padding: "5px 5px 5px 5px", borderRadius: "5px", fontSize: "10px", width: "100%", display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }} >

                                                <TextField
                                                    value={customerDetails.discount}
                                                    onChange={(e) => setCustomerDetails({
                                                        ...customerDetails,
                                                        discount: e.target.value
                                                    })}
                                                    variant="standard" // <== changed this
                                                    margin="normal"
                                                    required
                                                    type="number"
                                                    fullWidth
                                                    sx={{ width: "100%", fontSize: "10px", padding: 0, margin: 0, fontFamily: "Poppins", fontWeight: "bold", marginLeft: "10px" }}
                                                    placeholder="0"
                                                    InputProps={{
                                                        // startAdornment: <AccountCircle />, // <== adjusted this
                                                        disableUnderline: true, // <== added this
                                                        // fontSize: "2px"
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>



                                    <Box style={{ width: "100%", marginTop: 20 }} >
                                        <Grid container sm={12} xs={12} md={12} >

                                            <Box style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: 10 }} >
                                                <Typography style={{ color: Colors.text, fontSize: 12, fontWeight: "bold", fontFamily: "Poppins" }} >
                                                    Price Level
                                                </Typography>


                                            </Box>

                                            {priceLevel && priceLevel.length > 0 && priceLevel?.map((e, i) => {
                                                return (

                                                    <Grid xl={4} xs={6} sm={4} lg={4} md={4} >

                                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: { xs: "flex-start", sm: "flex-start" }, marginTop: "5px" }} >
                                                            <CheckBox onClick={() => selectPriceLevel(i)} data={e} />
                                                            <Typography sx={{ fontFamily: "Poppins", fontWeight: "600", fontSize: { xs: "7px", sm: "8px", md: "12px" }, color: Colors.text, marginLeft: "5px" }} >
                                                                {e?.name}
                                                            </Typography>

                                                        </Box>

                                                    </Grid>

                                                )
                                            })}



                                        </Grid>

                                    </Box>


                                </Box>














                            </Box>




                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap", marginTop: 2, marginBottom: { xs: 2, sm: 2, md: 0 }, backgroundColor: Colors.white, padding: "20px", borderRadius: "5px" }} >
                                <Box sx={{ width: "100%", display: "flex", flexDirection: { xs: "column", sm: "row" }, justifyContent: "flex-end", flexWrap: "wrap" }} >


                                    <CustomButton onClick={closeModal} style={{ backgroundColor: Colors.white, border: `1px solid ${Colors.button}`, color: Colors.button, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, marginRight: "10px", fontSize: { md: "10px", lg: "12px" } }} text="Exit" />

                                    <CustomButton onClick={handleAddCustomer} style={{ backgroundColor: Colors.button, border: `1px solid ${Colors.button}`, color: Colors.white, width: { xs: "100%", sm: 120 }, marginTop: { xs: "10px", sm: "10px" }, fontSize: { md: "10px", lg: "12px" } }} text={loading.save ? <RingLoader loading={loading.save} size={30} /> : "Save"} />

                                    {/* <Button onClick={() => navigate("./PriceDetails")} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: 5, padding: "10px 25px 10px 25px", fontSize: "14px", fontFamily: "Poppins", fontWeight: "600", width: "48%" }} >
                                        Next
                                    </Button> */}
                                </Box>

                            </Box>


                        </Box>
                    </Grid>





                </Box>
            </Modal>
        </div>
    );
}
