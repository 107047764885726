import {
  Box,
  Button,
  Card,
  Grid,
  TextField,
  Typography,
  selectClasses,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState, useRef } from "react";
import { Colors } from "../../../Constant/Color";
import { useDispatch, useSelector } from "react-redux";

import {
  edit,
  selectInvoicedCustomer,
  updateAllInvoices,
  updateCart,
  updateCustomers,
  updateDayReturnInvoiceEmp,
  updateInvoice,
  updateSuppliers,
  updateTodayEmployeeClaimInvoices,
  updateAllReturnInvoices,
  updateTodayEmployeeWarrantyInvoices,
  updateVat,
  viewSelectedInvoice,
  updateRevenue,
  updateTrashProducts,
  updateExhangeProducts,
  selectCustomers,
  updateProducts,
  updatetodayEmployeeExchangeInvoice,
  updateAllExchangeInvoice,
} from "../../../Redux/LoginReduces";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useNavigate } from "react-router-dom";
import CustomPOSModal from "../../../Components/PosModal";
import { startShift } from "../../../Redux/LoginReduces";

import OnlineTransactionModal from "../../../Components/onlineTransactionModal";
import CustomModal2 from "../../../Components/CustomModal2";
import ChequeModal from "../../../Components/CheckModal";
import BasicSelect from "../../../Components/Dropdown";
import CustomModal from "../../../Components/modal";
import CustomButton from "../../../Components/Button";
import { Base_Uri, Image_Uri } from "../../../Constant/BaseUri";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
import axios from "axios";
import Checkbox from "../../../Components/checkbox";
import InvoiceModal from "../../../Components/viewInvoiceDetailModal";
import printComponent from "../../../Components/printComponent";
import PrintComponent from "../../../Components/printComponent";
import html2canvas from "html2canvas";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import jsPDF from "jspdf";
import DownloadIcon from "@mui/icons-material/Download";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import PaymentMethodModal from "../../../Components/paymentMethodModal";
import Damages from "../../Damages/damages";
import OptionModal from "../../../Components/optionModal";
import CreditWarningModal from "../../../Components/creditLimitWarningModal";
import CartProductEditModal from "../../../Components/CartProductEditModal";

import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../../../fonts/Poppins-Medium.ttf";
import MyFontBold from "../../../fonts/Poppins-Bold.ttf";
import useScanDetection from "use-scan-detection";

const MainPage = ({ customerRef, barcodeRead, setBarcodeRead }) => {
  Font.register({
    family: "MyFont",
    fonts: [
      { src: MyFontBold, fontWeight: "bold" },
      { src: MyFontRegular, fontWeight: "normal" },
    ],
  });

  const dispatch = useDispatch();

  const promiseResolveRef = useRef();
  const customerInputRef = useRef(null);
  const productInputRef = useRef(null);

  const componentRef = useRef();
  const navigate = useNavigate();

  const startShifts = useSelector((state) => state.loginReducer.startShift);
  const [refHover, setRefHover] = useState(false);
  const startShiftsTime = useSelector(
    (state) => state.loginReducer.startShiftTime
  );
  const userData = useSelector((state) => state.loginReducer.updatedState);
  const exchangeProducts = useSelector(
    (state) => state.loginReducer?.exchangeProducts
  );

  const [productStatus, setProductStatus] = useState([
    "Sale",
    "Return",
    "Damage",
    "Bin",
  ]);

  const customers = useSelector((state) => state.loginReducer.customers);
  const products = useSelector((state) => state.loginReducer.products);
  const cart = useSelector((state) => state.loginReducer.cart);
  const todayInvoices = useSelector((state) => state.loginReducer.invoices);
  const customerType = useSelector((state) => state.loginReducer.customerType);

  const [editCartProductData, setEditCartProductData] = useState("");

  const vat = useSelector((state) => state.loginReducer.vat);

  const returnInvoices = useSelector(
    (state) => state.loginReducer.dayReturnInvoiceOfEmployee
  );
  const todayEmpClaimInvoices = useSelector(
    (state) => state.loginReducer.todayEmployeeClaimInvoices
  );
  const todayEmpWarrantyInvoices = useSelector(
    (state) => state.loginReducer.todayEmployeeWarrantyInvoices
  );

  const selectedInvoice = useSelector(
    (state) => state.loginReducer.selectedInvoice
  );

  const selectedCustomer = useSelector(
    (state) => state.loginReducer.selectedCustomer
  );

  const paymentMethod = useSelector(
    (state) => state.loginReducer.paymentMethod
  );

  const allInvoices = useSelector((state) => state.loginReducer.allInvoices);

  const allReturnInvoices = useSelector(
    (state) => state.loginReducer.allReturnInvoices
  );

  const totalRevenue = useSelector((state) => state.loginReducer.totalRevenue);
  const totalOrders = useSelector((state) => state.loginReducer.totalOrders);
  const Walkins = useSelector((state) => state.loginReducer.totalWalkins);

  const [hover, setHover] = useState(null);
  const [showProfit, setShowProfit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [checkOutModal, setCheckOutModal] = useState(false);
  const [exchangeInvoiceVisible, setExchangeInvoiceVisible] = useState(false);
  const [onlineModalVisible, setOnlineModalVisible] = useState(false);
  const [showData, setShowData] = useState("total");
  const [chequeModalVisible, setChequeModalVisible] = useState(false);
  const [creditAccountModal, setCreditAccountModal] = useState(false);
  const [cardModalVisible, setCardModalVisible] = useState(false);
  const [optionModal, setOptionModal] = useState(false);
  const [printInvoiceData, setPrintInvoiceData] = useState("");
  const [productText, setProductText] = useState("");
  const [lessAmount, setLessAmount] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [paymentMethodDetails, setPaymentMethodDetails] = useState("");
  const [openEditCartDetailModal, setOpenEditCartDetailModal] = useState(false);
  const [deductBalanceOption, setDeductBalanceOption] = useState("");
  const [pdfDownloadView, setPdfDownloadView] = useState(false);
  const [saleRevenue, setSaleRevenue] = useState({
    today: 0,
    monthly: 0,
    total: 0,
  });
  const [totalOrder, setTotalOrders] = useState({
    today: 0,
    monthly: 0,
    total: 0,
  });

  const [totalWalkins, setTotalWalkins] = useState({
    today: 0,
    monthly: 0,
    total: 0,
  });
  const [creditBalance, setCreditBalance] = useState({
    today: 0,
    total: 0,
  });
  const [showSaleInvoices, setShowSaleInvoices] = useState({
    invoices: true,
    returnInvoices: false,
    claimInvoices: false,
    warrantyInvoices: false,
  });
  const [successfulTransactionModal, setSuccessfulTransactionModal] =
    useState(false);
  const [creditModal, setCreditModal] = useState(false);
  const [isSaleConfirmed, setIsSaleConfirmed] = useState(false);
  const [onlineReferenceId, setOnlineReferenceId] = useState("");
  const [cardTransactionId, setCardTransactionId] = useState("");
  const [creditDays, setCreditDays] = useState(0);
  const [isVat, setIsVat] = useState({
    selected: false,
  });
  const [specialDiscount, setSpecialDiscount] = useState({
    selected: false,
    value: "0",
  });
  const [chequeDetails, setChequeDetails] = useState({
    chequeNo: "",
    bankName: "",
    day: "",
    month: "",
    year: "",
  });
  const [text, setText] = useState("");

  const [warrantyData, setWarrantyData] = useState(["Yes", "No"]);
  const [selectedWarranty, setSelectedWarranty] = useState("select");

  const [approvedByAdmin, setApprovedByAdmin] = useState(false);
  const [openingBalanceModalVisible, setOpeningBalanceModalVisible] =
    useState(false);
  const [paymentMethodModal, setPaymentMethodModal] = useState(false);
  const [selectedSearch, setSelectedSearch] = useState("products");
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [searchInvoices, setSearchInvoices] = useState([]);
  const [invoiceDetailModal, setInvoiceDetailModal] = useState(false);
  const [openCreditLimitWarningModal, setOpenCreditLimitWarningModal] =
    useState(false);

  const handleViewInvoiceDetails = (invoice) => {
    dispatch(viewSelectedInvoice(invoice));
    setInvoiceDetailModal(true);
  };

  useEffect(() => {
    getTodayInvoices();
    getCustomers();
    getVat();
    getAllInvoices();
    getTodayExchangeInvoices();
    getAllExchangeInvoices();
    getAllReturnInvoices();
    getTodayReturnInvoices();
    getTrashProducts();
    getAllSuppliers();
  }, []);
  // const [barcodeRead, setBarcodeRead] = useState("");

  // const barcode = {
  //   timing: 0,
  //   data: "",
  // };

  // const barcodeReaded = () => {
  //   if (barcode.data.length > 0) {
  //     let barcodeNumber = barcode.data.trim();

  //     if (barcodeNumber.includes("Enter")) {
  //       barcodeNumber = barcodeNumber.split("Enter")[0].trim();

  //       console.log(barcodeNumber, "split");

  //       let product = products.find((e) => e.barcode === barcodeNumber);

  //       if (product) {
  //         handleSelectProduct(product);
  //       }
  //     } else {
  //       let barcodeNumber = barcode.data.trim();
  //       let product = products.find((e) => e.barcode === barcodeNumber);

  //       if (product) {
  //         handleSelectProduct(product);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {

  //   const handleKeyPress = (e) => {

  //       console.log(barcode.data,"dataaaa")

  //       barcode.data += e.key;
  //       barcode.timing = e.timeStamp;
  //       // clearTimeout(timeout);
  //       console.log(barcode,"barcodeee")

  //       barcodeReaded()

  //     }

  //   // let timeout = setTimeout(barcodeReaded, 100);

  //   window.addEventListener("keydown", handleKeyPress);

  //   return () => {
  //     // clearTimeout(timeout);
  //     window.removeEventListener("keydown", handleKeyPress);
  //   };
  // }, []);

  // useScanDetection({
  //   onComplete: (code) => {
  //     productInputRef.current.blur();

  //     setBarcodeRead(code);
  //   },
  //   minLength: 3,
  // });

  useEffect(() => {
    if (barcodeRead?.length > 0) {
      productInputRef?.current?.blur();

      let product =
        products &&
        products?.length > 0 &&
        products?.find((e) => e?.barcode == barcodeRead);

      if (product) {
        handleSelectProduct(product);
        setBarcodeRead("");
      }
    }
  }, [barcodeRead]);

  useEffect(() => {
    if (selectedCustomer || (cart && cart.length > 0)) {
      productInputRef?.current?.focus();
    }
  }, [selectedCustomer, cart?.length]);

  useEffect(() => {
    if (exchangeProducts?.customerDetails?.customerName) {
      let selectCustomer =
        customers &&
        customers.length > 0 &&
        customers.filter(
          (e, i) => e?.id == exchangeProducts?.customerDetails?.id
        );

      if (selectCustomer && selectCustomer.length > 0) {
        dispatch(selectInvoicedCustomer(selectCustomer[0]));
      }

      if (exchangeProducts?.vatAmount) {
        setIsVat({
          ...isVat,
          selected: true,
        });
      } else {
        setIsVat({
          ...isVat,
          selected: false,
        });
      }
    }
  }, [
    exchangeProducts?.customerDetails?.customerName,
    exchangeProducts?.vatAmount,
  ]);

  const getProducts = async () => {
    // setLoading(true)
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    }
  };

  const calculateRevenue = () => {
    if (
      (allInvoices && allInvoices.length > 0) ||
      (allReturnInvoices && allReturnInvoices.length > 0)
    ) {
      let discludeReturnInvoice = allInvoices.filter((e, i) => {
        if (
          allReturnInvoices &&
          allReturnInvoices.length > 0 &&
          !allReturnInvoices.some((j) => j?.returnInvoiceRef == e.invoiceNumber)
        ) {
          return e;
        }
        if (allReturnInvoices.length == 0) {
          return e;
        }
      });

      let todayInvoices =
        discludeReturnInvoice &&
        discludeReturnInvoice.length > 0 &&
        discludeReturnInvoice.filter((e, i) => {
          let nowDate = new Date();
          let saleDate = new Date(e.saleDate);

          let nowDay = nowDate.getDate();
          let nowMonth = nowDate.getMonth();
          let nowYear = nowDate.getFullYear();

          let saleDay = saleDate.getDate();
          let saleMonth = saleDate.getMonth();
          let saleYear = saleDate.getFullYear();

          if (
            nowDay == saleDay &&
            nowMonth == saleMonth &&
            nowYear == saleYear
          ) {
            return e;
          }
        });

      let monthlyInvoices =
        discludeReturnInvoice &&
        discludeReturnInvoice.length > 0 &&
        discludeReturnInvoice.filter((e, i) => {
          let nowDate = new Date();
          let saleDate = new Date(e.saleDate);

          let nowMonth = nowDate.getMonth();
          let nowYear = nowDate.getFullYear();

          let saleMonth = saleDate.getMonth();
          let saleYear = saleDate.getFullYear();

          if (nowMonth == saleMonth && nowYear == saleYear) {
            return e;
          }
        });

      let totalCostPrice =
        discludeReturnInvoice &&
        discludeReturnInvoice.length > 0 &&
        discludeReturnInvoice.reduce((previous, current) => {
          return previous + current?.costPrice;
        }, 0);

      let todayCostPrice =
        todayInvoices &&
        todayInvoices.length > 0 &&
        todayInvoices.reduce((previous, current) => {
          return previous + current?.costPrice;
        }, 0);

      let monthlyCostPrice =
        monthlyInvoices &&
        monthlyInvoices.length > 0 &&
        monthlyInvoices.reduce((previous, current) => {
          return previous + current?.costPrice;
        }, 0);

      let salePrice =
        discludeReturnInvoice &&
        discludeReturnInvoice.length > 0 &&
        discludeReturnInvoice.reduce((previous, current) => {
          return previous + current?.total;
        }, 0);

      let monthlySalePrice =
        monthlyInvoices &&
        monthlyInvoices.length > 0 &&
        monthlyInvoices.reduce((previous, current) => {
          return previous + current?.total;
        }, 0);

      let todaySalePrice =
        todayInvoices &&
        todayInvoices.length > 0 &&
        todayInvoices.reduce((previous, current) => {
          return previous + current?.total;
        }, 0);

      let totalDiscount =
        discludeReturnInvoice &&
        discludeReturnInvoice.length > 0 &&
        discludeReturnInvoice.reduce((previous, current) => {
          return previous + current?.discount;
        }, 0);

      let monthlyDiscount =
        monthlyInvoices &&
        monthlyInvoices.length > 0 &&
        monthlyInvoices.reduce((previous, current) => {
          return previous + current?.discount;
        }, 0);

      let todayDiscount =
        todayInvoices &&
        todayInvoices.length > 0 &&
        todayInvoices.reduce((previous, current) => {
          return previous + current?.discount;
        }, 0);

      let revenue = salePrice - totalDiscount - totalCostPrice;

      let todayRevenue = todaySalePrice - todayDiscount - todayCostPrice;

      let monthlyRevenue =
        monthlySalePrice - monthlyDiscount - monthlyCostPrice;

      dispatch(
        updateRevenue({
          today: todayRevenue,
          monthly: monthlyRevenue,
          total: revenue,
        })
      );

      setSaleRevenue({
        ...saleRevenue,
        today: todayRevenue,
        monthly: monthlyRevenue,
        total: revenue,
      });
    }
  };

  const calculateOrders = () => {
    let todayInvoices =
      allInvoices &&
      allInvoices.length > 0 &&
      allInvoices.filter((e, i) => {
        let nowDate = new Date();
        let saleDate = new Date(e.saleDate);

        let nowDay = nowDate.getDate();
        let nowMonth = nowDate.getMonth();
        let nowYear = nowDate.getFullYear();

        let saleDay = saleDate.getDate();
        let saleMonth = saleDate.getMonth();
        let saleYear = saleDate.getFullYear();

        if (nowDay == saleDay && nowMonth == saleMonth && nowYear == saleYear) {
          return e;
        }
      });

    let monthlyInvoices =
      allInvoices &&
      allInvoices.length > 0 &&
      allInvoices.filter((e, i) => {
        let nowDate = new Date();
        let saleDate = new Date(e.saleDate);

        let nowMonth = nowDate.getMonth();
        let nowYear = nowDate.getFullYear();

        let saleMonth = saleDate.getMonth();
        let saleYear = saleDate.getFullYear();

        if (nowMonth == saleMonth && nowYear == saleYear) {
          return e;
        }
      });

    setTotalOrders({
      ...totalOrder,
      today: todayInvoices.length,
      monthly: monthlyInvoices.length,
      total: allInvoices.length,
    });
  };

  const calculateWalkins = () => {
    let differentWalkins = [];

    if (allInvoices && allInvoices.length > 0) {
      allInvoices.forEach((invoice) => {
        const customerId = invoice.customerDetails[0]?.id;

        if (
          !differentWalkins.some(
            (walkin) => walkin.customerDetails[0]?.id === customerId
          )
        ) {
          differentWalkins.push(invoice);
        }
      });
    }

    let todayInvoices =
      allInvoices &&
      allInvoices.length > 0 &&
      allInvoices.filter((e, i) => {
        let nowDate = new Date();
        let saleDate = new Date(e.saleDate);

        let nowDay = nowDate.getDate();
        let nowMonth = nowDate.getMonth();
        let nowYear = nowDate.getFullYear();

        let saleDay = saleDate.getDate();
        let saleMonth = saleDate.getMonth();
        let saleYear = saleDate.getFullYear();

        if (nowDay == saleDay && nowMonth == saleMonth && nowYear == saleYear) {
          return e;
        }
      });

    let todayWalkins = [];

    if (todayInvoices && todayInvoices.length > 0) {
      todayInvoices.forEach((invoice) => {
        const customerId = invoice.customerDetails[0]?.id;

        if (
          !todayWalkins.some(
            (walkin) => walkin.customerDetails[0]?.id === customerId
          )
        ) {
          todayWalkins.push(invoice);
        }
      });
    }

    let monthlyInvoices =
      allInvoices &&
      allInvoices.length > 0 &&
      allInvoices.filter((e, i) => {
        let nowDate = new Date();
        let saleDate = new Date(e.saleDate);

        let nowMonth = nowDate.getMonth();
        let nowYear = nowDate.getFullYear();

        let saleMonth = saleDate.getMonth();
        let saleYear = saleDate.getFullYear();

        if (nowMonth == saleMonth && nowYear == saleYear) {
          return e;
        }
      });

    let monthlyWalkins = [];

    if (monthlyInvoices && monthlyInvoices.length > 0) {
      monthlyInvoices.forEach((invoice) => {
        const customerId = invoice.customerDetails[0]?.id;

        if (
          !monthlyWalkins.some(
            (walkin) => walkin.customerDetails[0]?.id === customerId
          )
        ) {
          monthlyWalkins.push(invoice);
        }
      });
    }

    setTotalWalkins({
      ...totalWalkins,
      today: todayWalkins.length,
      monthly: monthlyWalkins.length,
      total: differentWalkins.length,
    });
  };

  const calculateCredit = () => {
    let todayInvoices =
      allInvoices &&
      allInvoices.length > 0 &&
      allInvoices.filter((e, i) => {
        let nowDate = new Date();
        let saleDate = new Date(e.saleDate);

        let nowDay = nowDate.getDate();
        let nowMonth = nowDate.getMonth();
        let nowYear = nowDate.getFullYear();

        let saleDay = saleDate.getDate();
        let saleMonth = saleDate.getMonth();
        let saleYear = saleDate.getFullYear();

        if (
          nowDay == saleDay &&
          nowMonth == saleMonth &&
          nowYear == saleYear &&
          e?.paymentMethod?.some((e) => e.toLowerCase() == "credit") == "credit"
        ) {
          return e;
        }
      });

    let todayCreditBalance =
      todayInvoices &&
      todayInvoices.length > 0 &&
      todayInvoices.reduce((previous, current) => {
        return previous + current.subtotal;
      }, 0);

    let totalCreditBalance =
      customers &&
      customers.length > 0 &&
      customers.reduce((previous, current) => {
        return previous + (current.creditBalance ? current.creditBalance : 0);
      }, 0);

    setCreditBalance({
      ...creditBalance,
      today: todayCreditBalance ? todayCreditBalance : 0,
      total: totalCreditBalance ? totalCreditBalance : 0,
    });
  };

  useEffect(() => {
    calculateRevenue();
    calculateOrders();
    calculateWalkins();
    if (customers && customers.length > 0) {
      calculateCredit();
    }
  }, [allInvoices.length, allReturnInvoices.length, customers.length]);

  const Header = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            {printInvoiceData?.vatAmount && (
              <View>
                <View>
                  <Image
                    style={{ height: 50, borderRadius: 10 }}
                    source={{
                      uri: `${Image_Uri}companyImage/${vat?.companyLogo}`,
                    }}
                  />

                  <Text
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginTop: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "bold",
                    }}
                  >
                    {vat?.companyName}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    {vat?.companyAddress}
                  </Text>

                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    {vat?.city}
                  </Text>

                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    {vat?.postCode}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      marginTop: "3px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Tel: {vat?.mobileNumber}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Email: {vat?.companyEmail}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    VAT No: {vat?.vatNumber}
                  </Text>

                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Company No: {vat?.companyNumber}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View>
            <View style={{ alignItems: "center" }}>
              <Image
                src={`${Image_Uri}products/${printInvoiceData?.barcodeImagePath}`}
                style={{
                  height: "35px",
                  width: "150px",
                }}
              />
            </View>

            <View style={{ alignItems: "center", marginTop: "10px" }}>
              <Text
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "MyFont",
                  // marginTop:"10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {printInvoiceData?.vatAmount ? "Invoice" : "Quotation"}
              </Text>
            </View>

            <View>
              {printInvoiceData?.vatAmount && (
                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    marginTop: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Invoice no: {printInvoiceData?.invoiceNumber}
                </Text>
              )}

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  // marginTop:"10px",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Date:{" "}
                {new Date(
                  printInvoiceData?.exchangeDate
                    ? printInvoiceData?.exchangeDate
                    : printInvoiceData.saleDate
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Text>

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Time:{" "}
                {new Date(
                  printInvoiceData?.exchangeDate
                    ? printInvoiceData?.exchangeDate
                    : printInvoiceData.saleDate
                ).toLocaleTimeString()}
              </Text>

              {(printInvoiceData?.saleReturnDate ||
                printInvoiceData?.exchangeDate) &&
                printInvoiceData?.vatAmount && (
                  <View style={{ marginTop: "10px" }}>
                    <Text
                      style={{
                        color: "black",
                        fontSize: "10px",
                        fontFamily: "MyFont",
                        // marginTop:"10px",
                        fontWeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Return Inv Ref.:
                      {printInvoiceData?.returnInvoiceRef?.map((e, i) => {
                        return (
                          <Text
                            style={{
                              color: "black",
                              fontSize: "8px",
                              fontFamily: "MyFont",
                              // marginTop:"10px",
                              fontWeight: "normal",
                              textAlign: "center",
                            }}
                          >
                            {e},
                          </Text>
                        );
                      })}
                    </Text>
                  </View>
                )}

              <View style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Bill to:
                </Text>

                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Account No: {selectedCustomer?.accountNo}
                </Text>

                {printInvoiceData?.vatAmount && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop:"10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Business Name: {selectedCustomer?.businessName}
                  </Text>
                )}

                {printInvoiceData?.vatAmount && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop:"10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Email: {selectedCustomer?.email}
                  </Text>
                )}

                {printInvoiceData?.vatAmount && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Payment Method:{" "}
                    {Array?.isArray(printInvoiceData?.paymentMethod)
                      ? printInvoiceData?.paymentMethod
                          ?.map((e, i) => e)
                          .join(",")
                      : printInvoiceData?.paymentMethod}
                  </Text>
                )}

                {printInvoiceData?.vatAmount && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Status: {printInvoiceData?.status}
                  </Text>
                )}

                {printInvoiceData?.referenceId && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Reference Id: {printInvoiceData?.referenceId}
                  </Text>
                )}

                {printInvoiceData?.transactionId &&
                  printInvoiceData?.vatAmount && (
                    <Text
                      style={{
                        color: "black",
                        fontSize: "10px",
                        fontFamily: "MyFont",
                        // marginTop: "10px",
                        fontWeight: "normal",
                        textAlign: "center",
                      }}
                    >
                      Transaction Id: {printInvoiceData?.transactionId}
                    </Text>
                  )}

                {printInvoiceData?.vatAmount &&
                  Array?.isArray(printInvoiceData?.paymentMethod) &&
                  printInvoiceData?.paymentMethod?.some(
                    (e) => e?.toLowerCase() == "cheque"
                  ) && (
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque No: {printInvoiceData?.cheque_no}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Bank Name: {printInvoiceData?.bank_name}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque Date:{" "}
                        {new Date(
                          printInvoiceData.clear_date
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Item
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Barcode
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Product Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Price
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Disc
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Amount
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const MyDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <Header />
        {/* Data Rows */}
        <View>
          {printInvoiceData &&
            printInvoiceData?.productDetails &&
            printInvoiceData?.productDetails?.length > 0 &&
            printInvoiceData?.productDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderColor: "#000",
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  padding: "3px 10px",
                }}
              >
                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>1</Text>
                </View>

                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item?.barcode}
                  </Text>
                </View>

                <View style={{ flex: 4 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item?.productName}
                  </Text>
                  <View>
                    {item?.IMEI != "" &&
                      (typeof item?.IMEI == "string" ||
                        typeof item?.IMEI == "object") && (
                        <View
                          style={{
                            display: "flex",
                            gap: 5,
                            flexDirection: "row",
                          }}
                        >
                          <View>
                            <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                              IMEI:
                            </Text>
                          </View>
                          {typeof item?.IMEI == "object" ? (
                            <View>
                              {item?.IMEI?.map((it, ind) => {
                                return (
                                  <View>
                                    <Text
                                      style={{
                                        fontFamily: "MyFont",
                                        fontSize: 8,
                                      }}
                                    >
                                      {it}
                                    </Text>
                                  </View>
                                );
                              })}
                            </View>
                          ) : (
                            <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                              {item?.IMEI}
                            </Text>
                          )}
                        </View>
                      )}
                  </View>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {item?.discountPrice >
                    (printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                    1
                      ? item?.trade_price
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 2
                      ? item?.warehouse_price
                      : item?.retail_price)
                      ? item?.discountPrice
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 1
                      ? Number(item?.trade_price).toFixed(2)
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 2
                      ? Number(item?.warehouse_price).toFixed(2)
                      : Number(item?.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {item.discountPrice <
                    (printInvoiceData?.customerDetails[0]?.priceLevel[0]?.id ==
                    1
                      ? item?.trade_price
                      : printInvoiceData?.customerDetails[0]?.priceLevel[0]
                          ?.id == 2
                      ? item?.warehouse_price
                      : item?.retail_price)
                      ? Number(
                          (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                            ?.id == 1
                            ? item?.trade_price
                            : printInvoiceData?.customerDetails[0]
                                ?.priceLevel[0]?.id == 2
                            ? item?.warehouse_price
                            : item?.retail_price) - item?.discountPrice
                        ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £
                    {item?.discountPrice
                      ? Number(item?.discountPrice * item?.saleQty).toFixed(2)
                      : Number(
                          (printInvoiceData?.customerDetails[0]?.priceLevel[0]
                            ?.id == 1
                            ? item?.trade_price
                            : printInvoiceData?.customerDetails[0]
                                ?.priceLevel[0]?.id == 2
                            ? item?.warehouse_price
                            : item?.retail_price) * item?.saleQty
                        ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          {printInvoiceData &&
            printInvoiceData?.returnProductDetails &&
            printInvoiceData?.returnProductDetails?.length > 0 &&
            printInvoiceData?.returnProductDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: "#000",
                  padding: "3px 10px",
                }}
              >
                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>-1</Text>
                </View>

                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item?.barcode}
                  </Text>
                </View>

                <View style={{ flex: 4 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item?.productName}
                  </Text>
                  {item?.IMEI &&
                    item?.IMEI?.length > 0 &&
                    item?.IMEI?.map((e, i) => {
                      return (
                        <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                          {e}
                        </Text>
                      );
                    })}
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    -{item?.DamageQty ? item?.DamageQty : item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £ -
                    {exchangeProducts?.customerDetails?.priceLevel[0]?.id == 1
                      ? Number(item.trade_price).toFixed(2)
                      : exchangeProducts?.customerDetails?.priceLevel[0]?.id ==
                        2
                      ? Number(item?.warehouse_price).toFixed(2)
                      : Number(item?.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £ -
                    {item.discountPrice
                      ? Number(
                          (exchangeProducts?.customerDetails?.priceLevel[0]
                            ?.id == 1
                            ? item?.trade_price
                            : exchangeProducts?.customerDetails?.priceLevel[0]
                                ?.id == 2
                            ? item?.warehouse_price
                            : item?.retail_price) - item?.discountPrice
                        ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £ -
                    {item?.discountPrice
                      ? Number(
                          item?.discountPrice *
                            (item?.DamageQty ? item?.DamageQty : item?.saleQty)
                        ).toFixed(2)
                      : Number(
                          (exchangeProducts?.customerDetails?.priceLevel[0]
                            ?.id == 1
                            ? item?.trade_price
                            : exchangeProducts?.customerDetails?.priceLevel[0]
                                ?.id == 2
                            ? item?.warehouse_price
                            : item?.retail_price) *
                            (item?.DamageQty ? item?.DamageQty : item?.saleQty)
                        ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              padding: "5px 10px",
              borderLeftWidth: 1,
              borderRightWidth: 1,
            }}
          >
            <View style={{ flex: 0.5 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {printInvoiceData?.totalItems}
              </Text>
            </View>

            <View style={{ flex: 5.5, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,

                  fontWeight: "bold",
                }}
              >
                Subtotal
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {printInvoiceData?.totalQty}
              </Text>
            </View>

            <View style={{ flex: 1 }}>price</View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £{Number(printInvoiceData?.discount).toFixed(2)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £
                {Number(
                  printInvoiceData?.total - printInvoiceData?.discount
                ).toFixed(2)}
              </Text>
            </View>
          </View>

          {printInvoiceData?.vatAmount && (
            <View
              // key={index}
              style={{
                flexDirection: "row",
                // borderWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderColor: "#000",
                borderTopWidth: 1,
                padding: "5px 10px",
              }}
            >
              <View style={{ flex: 9, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  Vat
                </Text>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,

                    fontWeight: "bold",
                  }}
                >
                  £{Number(printInvoiceData?.vatAmount).toFixed(2)}
                </Text>
              </View>
            </View>
          )}

          {printInvoiceData?.SDAmount && (
            <View
              // key={index}
              style={{
                flexDirection: "row",
                // borderWidth: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderColor: "#000",
                borderTopWidth: 1,
                padding: "5px 10px",
              }}
            >
              <View style={{ flex: 9, alignItems: "center" }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  Special Discount
                </Text>
              </View>

              <View style={{ flex: 1 }}>
                <Text
                  style={{
                    fontFamily: "MyFont",
                    fontSize: 10,
                    fontWeight: "bold",
                  }}
                >
                  - £{Number(printInvoiceData?.SDAmount).toFixed(2)}
                </Text>
              </View>
            </View>
          )}

          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              borderWidth: 1,
              padding: "5px 10px",
              // borderBottomWidth:1,
              backgroundColor: "#DDD",
              opacity: 0.7,
            }}
          >
            <View style={{ flex: 9, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                £{Number(printInvoiceData?.subtotal).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const getTodayInvoices = async () => {
    if (userData && userData.id) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }
          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          data =
            data &&
            data.length > 0 &&
            data.sort(
              (a, b) =>
                new Date(b.saleDate).getTime() - new Date(a.saleDate).getTime()
            );

          dispatch(updateInvoice(data && data.length > 0 ? data : []));
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getTodayExchangeInvoices = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getExchangeInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(
            updatetodayEmployeeExchangeInvoice(
              data && data.length > 0 ? data : []
            )
          );
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getAllExchangeInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllExchangeInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllExchangeInvoice(data && data.length > 0 ? data : []));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getTrashProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getTrashProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateTrashProducts(data.data));
        }
      });
  };

  const getAllSuppliers = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getSuppliers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          let suppliers = data.data;

          dispatch(
            updateSuppliers(suppliers && suppliers.length > 0 ? suppliers : [])
          );
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getTodayReturnInvoices = async () => {
    if (userData) {
      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getReturnInvoices/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          if (!res?.status) {
            alert(res.message);
            return;
          }

          let { data } = res.data;

          data =
            data &&
            data.length > 0 &&
            data.map((e, i) => {
              return {
                ...e,
                id: e._id,
              };
            });

          dispatch(
            updateDayReturnInvoiceEmp(data && data.length > 0 ? data : [])
          );
        })
        .catch((error) => {
          console.log(error, "error");
        });
    }
  };

  const getAllReturnInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllReturnInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllReturnInvoices(data && data.length > 0 ? data : []));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getAllInvoices = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllInvoices`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        if (!res?.status) {
          alert(res.message);
          return;
        }

        let { data } = res.data;
        data =
          data &&
          data.length > 0 &&
          data.map((e, i) => {
            return {
              ...e,
              id: e._id,
            };
          });

        dispatch(updateAllInvoices(data && data.length > 0 ? data : []));
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getVat = async () => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);

    // if (data) {
    let token = data;

    axios
      .get(`${Base_Uri}getVat`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {
          dispatch(updateVat(data.data));
        }
      })
      .catch((error) => {});
  };

  const getCustomers = async () => {
    let token = await sessionStorage.getItem("userData");
    token = JSON.parse(token);

    axios
      .get(`${Base_Uri}getCustomers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {
          let customers = data.data;

          dispatch(
            updateCustomers(customers && customers.length > 0 ? customers : [])
          );
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const handleSearchProducts = (product) => {
    // console.log(product, "product");

    setProductText(product);

    if (!product) {
      setSearchProducts([]);
      return;
    }

    const searchTokens = product.split(" ");

    // Build filtered products
    const filteredProducts =
      products &&
      products.length > 0 &&
      products.filter((e, i) => {
        // Check if product matches search tokens
        const matchesSearch = searchTokens.every((token) => {
          return [
            e.productName,
            e.color,
            Number(product.length) > 3 ? e.sku : "",
            Number(product.length) > 3 ? e.barcode?.toString() : "",
            e.category,
            e.subcategory,
          ]
            .filter(Boolean) // Filter out undefined/null values
            .some((field) => field.toLowerCase().includes(token));
        });
        return matchesSearch;
      });

    setSearchProducts(filteredProducts);
  };

  // console.log(cart, "carrtttt");

  const closeCheckoutModal = () => {
    setCheckOutModal(false);
  };

  let chequeDate;

  const handleConfirmSale = async (reason, days) => {
    if (!paymentMethodDetails?.transactionId && paymentMethodDetails?.card) {
      alert("Write Transaction Id");
      return;
    }

    if (paymentMethodDetails?.cheque) {
      if (!paymentMethodDetails?.chequeDetails?.chequeNo) {
        alert("cheque number is missing");
        return;
      }

      if (!paymentMethodDetails?.chequeDetails?.bankName) {
        alert("Bank Name is missing");
        return;
      }

      if (!paymentMethodDetails?.chequeDetails?.day) {
        alert("cheque day is missing");
        return;
      }
      if (!paymentMethodDetails?.chequeDetails?.month) {
        alert("cheque month is missing");
        return;
      }
      if (!paymentMethodDetails?.chequeDetails?.year) {
        alert("cheque year is missing");
        return;
      }

      let date = new Date(
        `${paymentMethodDetails?.chequeDetails?.month}/${paymentMethodDetails?.chequeDetails?.day}/${paymentMethodDetails?.chequeDetails?.year}`
      );

      if (date == "Invalid Date") {
        alert("Invalid Date");
        return;
      }
      chequeDate = date;
    }
    let vatAmount = isVat?.selected
      ? ((totalAmount - discount) * vat.vatRate) / 100
      : 0;

    let specialDiscountAmount =
      specialDiscount?.selected && isVat?.selected
        ? (((totalAmount - discount) * specialDiscount?.value) / 100) *
          (1 + vat.vatRate / 100)
        : specialDiscount?.selected && !isVat?.selected
        ? ((totalAmount - discount) * specialDiscount.value) / 100
        : 0;

    let total =
      (paymentMethodDetails?.cash ? Number(paymentMethodDetails?.cash) : 0) +
      (paymentMethodDetails?.card ? Number(paymentMethodDetails?.card) : 0) +
      (paymentMethodDetails?.online
        ? Number(paymentMethodDetails?.online)
        : 0) +
      (paymentMethodDetails?.cheque ? Number(paymentMethodDetails?.cheque) : 0);

    let additional =
      total +
      (paymentMethodDetails?.credit
        ? Number(paymentMethodDetails?.credit)
        : 0) -
      (totalAmount - discount + vatAmount + specialDiscountAmount);

    let dataToSend = {
      customerDetails: selectedCustomer,
      productDetails: cart,
      total: totalAmount,
      employeeId: userData.id,
      discount: discount,
      customerName: selectedCustomer.customerName,
      totalItems: cart.length,
      totalQty: totalQty,
      subtotal: totalAmount - discount + vatAmount - specialDiscountAmount,
      employeeDetails: userData,
      costPrice: costPrice,
      saleDate: new Date(),
      customerType: customerType,
      status: "Sale",
      cheque_no: paymentMethodDetails?.chequeDetails?.chequeNo,
      SDAmount: specialDiscountAmount,
      vatAmount: vatAmount,
      vatNumber: vatAmount ? vat?.vatNumber : "",
      companyNumber: vatAmount ? vat?.companyNumber : "",
      bank_name: paymentMethodDetails?.chequeDetails?.bankName,
      clear_date: chequeDate,
      paymentMethod: [
        Number(paymentMethodDetails?.cash) ? "cash" : "",
        Number(paymentMethodDetails?.card) ? "card" : "",
        Number(paymentMethodDetails?.online) ? "online" : "",
        Number(paymentMethodDetails?.cheque) ? "cheque" : "",
        Number(paymentMethodDetails?.credit) ? "on Account" : "",
      ].filter((method) => method !== "" && method !== 0),
      reason: reason ? reason : "",
      transactionId: paymentMethodDetails?.transactionId,
      additionalAmount: additional,
      cash: paymentMethodDetails?.cash ? Number(paymentMethodDetails?.cash) : 0,
      credit: paymentMethodDetails?.credit
        ? Number(paymentMethodDetails?.credit)
        : 0,
      card: paymentMethodDetails?.card ? Number(paymentMethodDetails?.card) : 0,
      online: paymentMethodDetails?.online
        ? Number(paymentMethodDetails?.online)
        : 0,
      cheque: paymentMethodDetails?.cheque
        ? Number(paymentMethodDetails?.cheque)
        : 0,
    };

    let method =
      dataToSend &&
      dataToSend?.paymentMethod &&
      dataToSend?.paymentMethod?.length > 0 &&
      dataToSend?.paymentMethod?.filter((e, i) => e);

    dataToSend.paymentMethod = method;

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    setLoading(true);
    axios
      .post(`${Base_Uri}createInvoice`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          alert(data.message);
          setLoading(false);
          return;
        }

        console.log(data.data, "-==========>");

        setPrintInvoiceData(data.data);
        setSuccessfulTransactionModal(true);
        getCustomers();
        getTodayInvoices();
        getAllInvoices();
        getProducts();
        setCreditDays("");
        setOnlineReferenceId("");
        setCardTransactionId("");
        setChequeDetails({
          bankName: "",
          chequeNo: "",
        });
        setCheckOutModal(false);
        setPaymentMethodModal(false);
        setOpenCreditLimitWarningModal(false);
        setOnlineModalVisible(false);
        setChequeModalVisible(false);
        setCardModalVisible(false);
        setCreditModal(false);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleConfirmExchangeSale = async (reason, days) => {
    let subtotal =
      totalAmount - discount + vatAmount - exchangeProducts.subtotal;

    let roundTotal = Math.round(subtotal);

    if (Number(roundTotal) < 0) {
      if (selectedOption == "cash") {
        let vatAmount = isVat?.selected
          ? ((totalAmount - discount) * vat.vatRate) / 100
          : 0;
        let specialDiscountAmount =
          specialDiscount?.selected && isVat?.selected
            ? (((totalAmount - discount) * specialDiscount?.value) / 100) *
              (1 + vat.vatRate / 100)
            : specialDiscount?.selected && !isVat?.selected
            ? ((totalAmount - discount) * specialDiscount.value) / 100
            : 0;

        let subtotal = totalAmount - discount + vatAmount;

        let exchangeDataToSend = {
          customerDetails: selectedCustomer,
          productDetails: cart,
          returnProductDetails: exchangeProducts.productDetails,
          employeeId: userData.id,
          customerName: selectedCustomer.customerName,
          employeeDetails: userData,
          exchangeDate: new Date(),
          customerType: customerType,
          status: exchangeProducts?.status,
          vatNumber: vatAmount ? vat?.vatNumber : "",
          companyNumber: vatAmount ? vat?.companyNumber : "",
          paymentMethod: "refund cash",
          type: vatAmount ? "invoice" : "quotation",
          deductCash: true,
          deductCreditBalance: false,
          returnInvoiceRef: exchangeProducts?.returnInvoiceRef,
          invoiceRefDate: exchangeProducts?.invoiceRefDate,
          total: Number(
            Number(totalAmount) - Number(exchangeProducts?.total)
          ).toFixed(2),
          discount: Number(
            Number(discount) - Number(exchangeProducts?.discount)
          ).toFixed(2),
          costPrice: Number(costPrice - exchangeProducts?.costPrice).toFixed(2),
          totalItems: cart.length - exchangeProducts?.totalItems,
          totalQty: totalQty - exchangeProducts?.totalQty,
          subtotal: Number(
            Number(totalAmount - discount + vatAmount) -
              Number(exchangeProducts?.subtotal) -
              Number(specialDiscountAmount)
          ).toFixed(2),
          returnSubtotal: exchangeProducts?.subtotal,
          saleSubtotal: Number(
            totalAmount - discount + vatAmount - specialDiscountAmount
          ),
          vatAmount: Number(
            Number(vatAmount) - Number(exchangeProducts?.vatAmount)
          ),
          SDAmount: Number(
            Number(specialDiscountAmount) - Number(exchangeProducts?.vatAmount)
          ),
          returnVat: Number(exchangeProducts?.vatAmount),
          reason: reason ? reason : "",
        };

        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        setLoading(true);

        axios
          .post(`${Base_Uri}createExchangeInvoice`, exchangeDataToSend, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Include other headers as needed
            },
          })
          .then((res) => {
            let data = res.data;

            if (!data.status) {
              alert(data.message);
              setLoading(false);
              return;
            }

            setPrintInvoiceData(data.data);

            if (data.status) {
              setSuccessfulTransactionModal(true);
              getCustomers();
              getTodayInvoices();
              getAllInvoices();
              getProducts();
              getAllExchangeInvoices();
              getTodayExchangeInvoices();
              setCheckOutModal(false);
              setOptionModal(false);
              setExchangeInvoiceVisible(false);
              setOnlineModalVisible(false);
              setPaymentMethodModal(false);
              setOpenCreditLimitWarningModal(false);
              setChequeModalVisible(false);
              setCardModalVisible(false);
              setCreditModal(false);
              setLoading(false);
              setSpecialDiscount({
                ...specialDiscount,
                selected: false,
              });
              setIsVat({
                ...isVat,
                selected: !false,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        let vatAmount = isVat?.selected
          ? ((totalAmount - discount) * vat.vatRate) / 100
          : 0;
        let specialDiscountAmount =
          specialDiscount?.selected && isVat?.selected
            ? (((totalAmount - discount) * specialDiscount?.value) / 100) *
              (1 + vat.vatRate / 100)
            : specialDiscount?.selected && !isVat?.selected
            ? ((totalAmount - discount) * specialDiscount.value) / 100
            : 0;
        let deductAmount = isVat?.selected
          ? (
              totalAmount -
              exchangeProducts?.subtotal -
              discount +
              ((totalAmount - discount) * vat.vatRate) / 100
            ).toFixed(2)
          : Number(totalAmount - discount - exchangeProducts?.subtotal).toFixed(
              2
            );

        let exchangeDataToSend = {
          customerDetails: selectedCustomer,
          productDetails: cart,
          returnProductDetails: exchangeProducts.productDetails,
          employeeId: userData.id,
          customerName: selectedCustomer.customerName,
          employeeDetails: userData,
          exchangeDate: new Date(),
          customerType: customerType,
          status: exchangeProducts?.status,
          vatNumber: vatAmount ? vat?.vatNumber : "",
          companyNumber: vatAmount ? vat?.companyNumber : "",
          paymentMethod: "Credit Note",
          deductAmount: Math.abs(deductAmount),
          type: vatAmount ? "invoice" : "quotation",
          deductCash: false,
          reason: reason ? reason : "",
          deductCreditBalance: true,
          selectedDeductedBalanceOption: deductBalanceOption,
          returnInvoiceRef: exchangeProducts?.returnInvoiceRef,
          invoiceRefDate: exchangeProducts?.invoiceRefDate,
          total: Number(
            Number(totalAmount) -
              Number(exchangeProducts?.total) -
              Number(specialDiscountAmount)
          ).toFixed(2),
          discount: Number(
            Number(discount) - Number(exchangeProducts?.discount)
          ).toFixed(2),
          costPrice: Number(costPrice - exchangeProducts?.costPrice).toFixed(2),
          totalItems: cart.length - exchangeProducts?.totalItems,
          totalQty: totalQty - exchangeProducts?.totalQty,
          subtotal: Number(
            Number(totalAmount - discount + vatAmount) -
              Number(exchangeProducts?.subtotal) -
              Number(specialDiscountAmount)
          ).toFixed(2),
          returnSubtotal: exchangeProducts?.subtotal,
          saleSubtotal: Number(
            totalAmount - discount + vatAmount - specialDiscountAmount
          ),
          vatAmount: Number(
            Number(vatAmount) - Number(exchangeProducts?.vatAmount)
          ),
          SDAmount: Number(
            Number(specialDiscountAmount) - Number(exchangeProducts?.vatAmount)
          ),
          returnVat: Number(exchangeProducts?.vatAmount),
        };

        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        setLoading(true);

        axios
          .post(`${Base_Uri}createExchangeInvoice`, exchangeDataToSend, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Include other headers as needed
            },
          })
          .then((res) => {
            let data = res.data;

            if (!data.status) {
              alert(data.message);
              setLoading(false);
              return;
            }

            setPrintInvoiceData(data.data);

            if (data.status) {
              setSuccessfulTransactionModal(true);
              getCustomers();
              getTodayInvoices();
              getAllInvoices();
              getProducts();
              setCheckOutModal(false);
              setOptionModal(false);
              setPaymentMethodModal(false);
              setOpenCreditLimitWarningModal(false);
              setExchangeInvoiceVisible(false);
              setOnlineModalVisible(false);
              setChequeModalVisible(false);
              setCardModalVisible(false);
              setCreditModal(false);
              setLoading(false);
              setSpecialDiscount({
                ...specialDiscount,
                selected: false,
              });
              setIsVat({
                ...isVat,
                selected: !false,
              });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    } else if (Number(roundTotal) > 0) {
      if (!paymentMethodDetails?.transactionId && paymentMethodDetails?.card) {
        alert("Write Transaction Id");
        return;
      }

      if (paymentMethodDetails?.cheque) {
        if (!paymentMethodDetails?.chequeDetails?.chequeNo) {
          alert("cheque number is missing");
          return;
        }

        if (!paymentMethodDetails?.chequeDetails?.bankName) {
          alert("Bank Name is missing");
          return;
        }

        if (!paymentMethodDetails?.chequeDetails?.day) {
          alert("cheque day is missing");
          return;
        }
        if (!paymentMethodDetails?.chequeDetails?.month) {
          alert("cheque month is missing");
          return;
        }
        if (!paymentMethodDetails?.chequeDetails?.year) {
          alert("cheque year is missing");
          return;
        }

        let date = new Date(
          `${paymentMethodDetails?.chequeDetails?.month}/${paymentMethodDetails?.chequeDetails?.day}/${paymentMethodDetails?.chequeDetails?.year}`
        );

        if (date == "Invalid Date") {
          alert("Invalid Date");
          return;
        }
        chequeDate = date;
      }

      let vatAmount = isVat?.selected
        ? ((totalAmount - discount) * vat.vatRate) / 100
        : 0;

      let deductAmount = isVat?.selected
        ? (
            totalAmount -
            exchangeProducts?.subtotal -
            discount +
            ((totalAmount - discount) * vat.vatRate) / 100
          ).toFixed(2)
        : Number(totalAmount - discount - exchangeProducts?.subtotal).toFixed(
            2
          );

      let total =
        (paymentMethodDetails?.cash ? Number(paymentMethodDetails?.cash) : 0) +
        (paymentMethodDetails?.card ? Number(paymentMethodDetails?.card) : 0) +
        (paymentMethodDetails?.online
          ? Number(paymentMethodDetails?.online)
          : 0) +
        (paymentMethodDetails?.cheque
          ? Number(paymentMethodDetails?.cheque)
          : 0);

      let additional =
        total +
        (paymentMethodDetails?.credit
          ? Number(paymentMethodDetails?.credit)
          : 0) -
        Number(
          Number(totalAmount - discount + vatAmount) -
            Number(exchangeProducts?.subtotal)
        );

      let exchangeDataToSend = {
        customerDetails: selectedCustomer,
        productDetails: cart,
        returnProductDetails: exchangeProducts.productDetails,
        employeeId: userData.id,
        customerName: selectedCustomer.customerName,
        employeeDetails: userData,
        exchangeDate: new Date(),
        additionalAmount: additional,
        customerType: customerType,
        status: exchangeProducts?.status,
        type: vatAmount ? "invoice" : "quotation",
        vatNumber: vatAmount ? vat?.vatNumber : "",
        companyNumber: vatAmount ? vat?.companyNumber : "",
        bank_name: paymentMethodDetails?.chequeDetails?.bankName,
        clear_date: chequeDate,
        cheque_no: paymentMethodDetails?.chequeDetails?.chequeNo,
        returnInvoiceRef: exchangeProducts?.returnInvoiceRef,
        invoiceRefDate: exchangeProducts?.invoiceRefDate,
        total: Number(
          Number(totalAmount) - Number(exchangeProducts?.total)
        ).toFixed(2),
        discount: Number(
          Number(discount) - Number(exchangeProducts?.discount)
        ).toFixed(2),
        costPrice: Number(costPrice - exchangeProducts?.costPrice).toFixed(2),
        totalItems: cart.length - exchangeProducts?.totalItems,
        totalQty: totalQty - exchangeProducts?.totalQty,
        subtotal: Number(
          Number(totalAmount - discount + vatAmount) -
            Number(exchangeProducts?.subtotal)
        ).toFixed(2),
        returnSubtotal: exchangeProducts?.subtotal,
        saleSubtotal: Number(totalAmount - discount + vatAmount),
        vatAmount: Number(
          Number(vatAmount) - Number(exchangeProducts?.vatAmount)
        ),
        returnVat: Number(exchangeProducts?.vatAmount),
        paymentMethod: [
          Number(paymentMethodDetails?.cash) ? "cash" : "",
          Number(paymentMethodDetails?.card) ? "card" : "",
          Number(paymentMethodDetails?.online) ? "online" : "",
          Number(paymentMethodDetails?.cheque) ? "cheque" : "",
          Number(paymentMethodDetails?.credit) ? "on Account" : "",
        ].filter((method) => method !== "" && method !== 0),
        reason: reason ? reason : "",
        transactionId: paymentMethodDetails?.transactionId,
        cash: paymentMethodDetails?.cash
          ? Number(paymentMethodDetails?.cash)
          : 0,
        credit: paymentMethodDetails?.credit
          ? Number(paymentMethodDetails?.credit)
          : 0,
        card: paymentMethodDetails?.card
          ? Number(paymentMethodDetails?.card)
          : 0,
        online: paymentMethodDetails?.online
          ? Number(paymentMethodDetails?.online)
          : 0,
        cheque: paymentMethodDetails?.cheque
          ? Number(paymentMethodDetails?.cheque)
          : 0,
      };

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      setLoading(true);

      axios
        .post(`${Base_Uri}createExchangeInvoice`, exchangeDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setLoading(false);
            return;
          }

          setPrintInvoiceData(data.data);

          if (data.status) {
            setSuccessfulTransactionModal(true);
            getCustomers();
            getTodayInvoices();
            getAllInvoices();
            getProducts();
            setCheckOutModal(false);

            setPaymentMethodModal(false);
            setOpenCreditLimitWarningModal(false);
            setOptionModal(false);
            setExchangeInvoiceVisible(false);
            setOnlineModalVisible(false);
            setChequeModalVisible(false);
            setCardModalVisible(false);
            setCreditModal(false);
            setLoading(false);
            setSpecialDiscount({
              ...specialDiscount,
              selected: false,
            });
            setIsVat({
              ...isVat,
              selected: !false,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      let vatAmount = isVat?.selected
        ? ((totalAmount - discount) * vat.vatRate) / 100
        : 0;

      let deductAmount = isVat?.selected
        ? (
            totalAmount -
            exchangeProducts?.subtotal -
            discount +
            ((totalAmount - discount) * vat.vatRate) / 100
          ).toFixed(2)
        : Number(totalAmount - discount - exchangeProducts?.subtotal).toFixed(
            2
          );

      let exchangeDataToSend = {
        customerDetails: selectedCustomer,
        productDetails: cart,
        returnProductDetails: exchangeProducts.productDetails,
        employeeId: userData.id,
        customerName: selectedCustomer.customerName,
        employeeDetails: userData,
        exchangeDate: new Date(),
        customerType: customerType,
        status: exchangeProducts?.status,
        vatNumber: vatAmount ? vat?.vatNumber : "",
        companyNumber: vatAmount ? vat?.companyNumber : "",
        paymentMethod: ["Exchange"],
        returnInvoiceRef: exchangeProducts?.returnInvoiceRef,
        invoiceRefDate: exchangeProducts?.invoiceRefDate,
        total: Number(
          Number(totalAmount) - Number(exchangeProducts?.total)
        ).toFixed(2),
        discount: Number(
          Number(discount) - Number(exchangeProducts?.discount)
        ).toFixed(2),
        costPrice: Number(costPrice - exchangeProducts?.costPrice).toFixed(2),
        totalItems: cart.length - exchangeProducts?.totalItems,
        totalQty: totalQty - exchangeProducts?.totalQty,
        subtotal: Number(
          Number(totalAmount - discount + vatAmount) -
            Number(exchangeProducts?.subtotal)
        ).toFixed(2),
        returnSubtotal: exchangeProducts?.subtotal,
        saleSubtotal: Number(totalAmount - discount + vatAmount),
        vatAmount: Number(
          Number(vatAmount) - Number(exchangeProducts?.vatAmount)
        ).toFixed(2),
        returnVat: Number(exchangeProducts?.vatAmount),
      };

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      setLoading(true);

      axios
        .post(`${Base_Uri}createExchangeInvoice`, exchangeDataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setLoading(false);
            return;
          }

          setPrintInvoiceData(data.data);

          if (data.status) {
            setSuccessfulTransactionModal(true);
            getCustomers();
            getTodayInvoices();
            getAllInvoices();
            getProducts();
            setCheckOutModal(false);
            setOptionModal(false);
            setPaymentMethodModal(false);
            setOpenCreditLimitWarningModal(false);
            setExchangeInvoiceVisible(false);
            setOnlineModalVisible(false);
            setChequeModalVisible(false);
            setCardModalVisible(false);
            setCreditModal(false);
            setLoading(false);
            setSpecialDiscount({
              ...specialDiscount,
              selected: false,
            });
            setIsVat({
              ...isVat,
              selected: !false,
            });
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const handleSelectProduct = (product) => {
    if (cart && cart.length > 0 && cart.some((e, i) => e._id == product._id)) {
      let newCart = cart.map((e, i) => {
        if (e._id == product._id) {
          return {
            ...e,
            saleQty: e.saleQty + 1,
          };
        } else {
          return e;
        }
      });

      dispatch(updateCart(newCart));
      setProductText("");
      setSearchProducts([]);
    } else {
      let newProduct = { ...product };
      newProduct.saleQty = 1;
      let updatedCart = [...cart, newProduct];
      dispatch(updateCart(updatedCart));
      setProductText("");
      setSearchProducts([]);
    }
  };

  const handleIncreaseProduct = (product) => {
    if (cart && cart.length > 0 && cart.some((e, i) => e._id == product._id)) {
      let newCart = cart.map((e, i) => {
        if (e._id == product._id) {
          return {
            ...e,
            saleQty: e.saleQty + 1,
          };
        } else {
          return e;
        }
      });

      dispatch(updateCart(newCart));
      setText("");
      setSearchProducts([]);
    }
  };

  const handleDecreaseProduct = (product) => {
    if (cart && cart.length > 0 && cart.some((e, i) => e._id == product._id)) {
      let newCart = cart
        .map((e, i) => {
          if (e._id == product._id && e.saleQty <= 1) {
            return;
          } else if (e._id == product._id) {
            return {
              ...e,
              saleQty: e.saleQty - 1,
            };
          } else {
            return e;
          }
        })
        .filter(Boolean);

      dispatch(updateCart(newCart));
      setText("");
      setSearchProducts([]);
    }
  };

  const handleDeleteProduct = (product) => {
    let newCart = cart.filter((e, i) => {
      return e._id !== product._id;
    });
    dispatch(updateCart(newCart));
    setText("");
    setSearchProducts([]);
  };

  const emailInvoice = async () => {
    let dataToSend = {
      printInvoiceData: printInvoiceData,
    };

    setEmailLoading(true);

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .post(`${Base_Uri}pdfSendToCustomer`, dataToSend, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        let data = res.data;

        setEmailLoading(false);

        if (data && data.status) {
          alert("Invoice Send Succesfully");
        }
        setPrintInvoiceData("");
        setSuccessfulTransactionModal(false);
        setSelectedOption("");
        setLessAmount(false);
        dispatch(updateExhangeProducts({}));
        dispatch(selectInvoicedCustomer(""));
        setIsVat(false);
        dispatch(updateCart([]));
      })
      .catch((error) => {
        console.log(error, "errorrrr");
        setEmailLoading(false);
        alert("There is a server error in email sending");
      });
  };

  const handleCloseSuccessfullTransactionModal = () => {
    setPrintInvoiceData("");
    setPdfDownloadView(false);
    setSuccessfulTransactionModal(false);
    setLessAmount(false);
    dispatch(updateExhangeProducts({}));
    dispatch(selectInvoicedCustomer(""));
    setIsVat(false);
    dispatch(updateCart([]));
    setSpecialDiscount({
      ...specialDiscount,
      selected: false,
    });
    setIsVat({
      ...isVat,
      selected: false,
    });
  };

  const handleDiscountPrice = (price, product) => {
    let newCart =
      cart &&
      cart.length > 0 &&
      cart.map((e, i) => {
        if (e._id == product._id) {
          return {
            ...e,
            discountPrice: price,
          };
        } else {
          return e;
        }
      });
    dispatch(updateCart(newCart));
  };

  let discount = 0;
  let totalAmount = 0;
  let vatAmount = 0;
  let costPrice = 0;
  let totalQty = 0;

  if (cart && cart.length > 0 && selectedCustomer) {
    costPrice =
      cart &&
      cart.length > 0 &&
      cart.reduce(
        (previous, current) =>
          previous + Number(current?.cost_price * current.saleQty),
        0
      );

    totalQty =
      cart &&
      cart.length > 0 &&
      cart.reduce((previous, current) => previous + Number(current.saleQty), 0);

    discount =
      cart && cart.length > 0 && selectedCustomer?.priceLevel[0]?.id === 1
        ? cart.reduce((previous, current) => {
            if (current.discountPrice < current?.trade_price) {
              return (
                previous +
                (Number(current.trade_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0)
        : selectedCustomer?.priceLevel[0]?.id === 2
        ? cart.reduce((previous, current) => {
            if (current.discountPrice < current?.warehouse_price) {
              return (
                previous +
                (Number(current.warehouse_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0)
        : cart.reduce((previous, current) => {
            if (current.discountPrice < current?.retail_price) {
              return (
                previous +
                (Number(current.retail_price) * Number(current.saleQty) -
                  Number(current.discountPrice) * Number(current.saleQty))
              );
            }
            return previous;
          }, 0);

    totalAmount =
      cart &&
      cart.length > 0 &&
      selectedCustomer &&
      cart &&
      cart.length > 0 &&
      cart?.reduce((pre, current) => {
        if (
          (selectedCustomer?.priceLevel[0].id === 1
            ? current?.trade_price
            : selectedCustomer?.priceLevel[0].id === 2
            ? current?.warehouse_price
            : current?.retail_price) < current?.discountPrice
        ) {
          return (
            (pre || 0) +
            Number(current?.discountPrice) * Number(current?.saleQty)
          );
        } else if (selectedCustomer?.priceLevel[0]?.id == 1) {
          return (
            (pre || 0) + Number(current?.trade_price) * Number(current?.saleQty)
          );
        } else if (selectedCustomer?.priceLevel[0]?.id == 2) {
          return (
            (pre || 0) +
            Number(current?.warehouse_price) * Number(current?.saleQty)
          );
        } else {
          return (
            (pre || 0) +
            Number(current?.retail_price) * Number(current?.saleQty)
          );
        }
      }, 0);

    // selectedCustomer?.priceLevel[0].id === 1
    //   ? cart.reduce(
    //       (previous, current) =>
    //         previous + Number(current.saleQty) *  Number(current.trade_price),
    //       0
    //     )
    //   : selectedCustomer?.priceLevel[0]?.id === 2
    //   ? cart.reduce(
    //       (previous, current) =>
    //         previous +
    //         Number(current.saleQty) * Number(current.warehouse_price),
    //       0
    //     )
    //   : cart.reduce(
    //       (previous, current) =>
    //         previous + Number(current.saleQty) * Number(current.retail_price),
    //       0
    //     );

    totalAmount = Number(totalAmount).toFixed(2);

    if (isVat.selected) {
      vatAmount = ((totalAmount - discount) * vat.vatRate) / 100;
    } else {
      vatAmount = 0;
    }
  }

  const changeSaleQty = (qty, ind) => {
    let allproducts = [...cart];

    let productDetails =
      allproducts &&
      allproducts.length > 0 &&
      allproducts.map((e, i) => {
        if (ind == i) {
          return {
            ...e,
            saleQty: Number(qty),
          };
        } else {
          return e;
        }
      });

    dispatch(updateCart(productDetails));
  };

  const handleProceedSale = () => {
    if (!selectedCustomer) {
      alert("Kindly add customer");
      return;
    }

    if (cart && cart.length == 0) {
      alert("Kindly add product");
      return;
    }

    if (exchangeProducts && Object.keys(exchangeProducts).length > 0) {
      let vatAmount = isVat?.selected
        ? Number(((totalAmount - discount) * vat.vatRate) / 100).toFixed(2)
        : 0;
      let specialDiscountAmount =
        specialDiscount?.selected && isVat?.selected
          ? Number(
              (((totalAmount - discount) * specialDiscount?.value) / 100) *
                (1 + vat.vatRate / 100)
            ).toFixed(2)
          : specialDiscount?.selected && !isVat?.selected
          ? Number(
              ((totalAmount - discount) * specialDiscount.value) / 100
            ).toFixed(2)
          : 0;
      let subtotal =
        Number(totalAmount) -
        Number(discount) +
        Number(vatAmount) -
        Number(exchangeProducts.subtotal) -
        Number(specialDiscountAmount);

      subtotal = Math.round(subtotal);

      if (Number(subtotal) < 0) {
        setOptionModal(true);
      } else if (Number(subtotal) > 0) {
        setPaymentMethodModal(true);
      } else {
        setExchangeInvoiceVisible(true);
      }
      return;
    }
    // console.log('helos');
    // return
    setPaymentMethodModal(true);
  };

  const handleConfirmPaymentMethod = (details) => {
    if (selectedCustomer && cart && cart.length > 0) {
      setPaymentMethodDetails(details);

      if (details.credit) {
        let vatAmount = isVat?.selected
          ? ((totalAmount - discount) * vat.vatRate) / 100
          : 0;

        let subtotal = totalAmount - discount + vatAmount;

        let customerCreditBalance =
          selectedCustomer?.creditBalance + selectedCustomer?.quotationBalance;

        let totalDue = Number(details?.credit) + Number(customerCreditBalance);

        if (Number(totalDue) > Number(selectedCustomer?.creditLimits)) {
          setOpenCreditLimitWarningModal(true);
        } else {
          setCheckOutModal(true);
          // setSpecialDiscount({
          //   ...specialDiscount,
          //   selected: false,
          // });
          // setIsVat({
          //   ...isVat,
          //   selected: false,
          // });
        }

        return;
      }

      setCheckOutModal(true);
      // setSpecialDiscount({
      //   ...specialDiscount,
      //   selected: false,
      // });
      // setIsVat({
      //   ...isVat,
      //   selected: false,
      // });
    }

    setPaymentMethodModal(false);
  };

  const handleConfirmOptionModal = (option, selectedDeductedBalanceOption) => {
    let selectedOption = option.filter((e, i) => e.selected);

    if (selectedOption.length == 0) {
      alert("Kindly choose selected Option");
    }

    let choosenoption = selectedOption[0].name;

    if (choosenoption?.toLowerCase() == "on account") {
      if (
        !selectedDeductedBalanceOption ||
        selectedDeductedBalanceOption == "select"
      ) {
        alert("kindly select credit deduct option");
        return;
      }

      let subtotal = isVat.selected
        ? (
            totalAmount -
            exchangeProducts?.subtotal -
            discount +
            ((totalAmount - discount) * vat.vatRate) / 100
          ).toFixed(2)
        : Number(totalAmount - discount - exchangeProducts?.subtotal).toFixed(
            2
          );

      subtotal = Number(subtotal).toFixed(2);

      setSelectedOption("credit");
      setDeductBalanceOption(selectedDeductedBalanceOption);
      setExchangeInvoiceVisible(true);
      return;
    }

    if (choosenoption == "Cash") {
      setSelectedOption("cash");
      setExchangeInvoiceVisible(true);
      return;
    }
  };

  const handleGoBack = () => {
    dispatch(selectInvoicedCustomer(""));
    dispatch(updateCart([]));
  };

  const closeSuccessfulTransactionModal = () => {
    dispatch(selectInvoicedCustomer(""));
    dispatch(updateCart([]));
    setLessAmount(false);
    setSelectedOption("");
    setIsVat(false);
    dispatch(updateExhangeProducts({}));
    setSuccessfulTransactionModal(false);
    setSpecialDiscount({
      ...specialDiscount,
      selected: false,
    });
    setIsVat({
      ...isVat,
      selected: false,
    });
  };

  const handleCreditDays = (event) => {
    let days = event.target.value;

    setCreditDays(days);
  };

  const handleResetProcess = () => {
    dispatch(updateCart([]));
    dispatch(updateExhangeProducts({}));
    dispatch(selectInvoicedCustomer(""));
    // setSpecialDiscount({
    //   ...specialDiscount,
    //   selected: false,
    // });
    // setIsVat({
    //   ...isVat,
    //   selected: false,
    // });
  };

  const handleShowDescription = (ind) => {
    setSearchProducts(
      searchProducts &&
        searchProducts?.length > 0 &&
        searchProducts?.map((e, i) => {
          if (ind == i) {
            return {
              ...e,
              showDescription: !e?.showDescription,
            };
          } else {
            return e;
          }
        })
    );
  };

  const openNewWindow = (path) => {
    // Open a new window with the specified path
    // window.open(`http://localhost:3000/PointOfSale`, "_blank");
    window.open(`https://solutionplus.online/PointOfSale`, "_blank");
  };

  const handleChangeProductDetails = (product) => {
    setEditCartProductData(product);
    setOpenEditCartDetailModal(true);
  };

  const handleScan = (result) => {
    if (result) {
    }
  };

  const handleError = (error) => {
    console.error("Barcode Scanner Error:", error);
  };

  const handleVatClicked = () => {
    if (exchangeProducts && Object.keys(exchangeProducts).length > 0) {
      alert("You are not allowed to change vat option in exchange invoice");
      return;
    }

    setIsVat({
      ...isVat,
      selected: !isVat.selected,
    });
  };

  const handleSpecialDiscount = () => {
    if (exchangeProducts && Object.keys(exchangeProducts).length > 0) {
      alert("You are not allowed to change vat option in exchange invoice");

      return;
    }

    setSpecialDiscount({
      ...specialDiscount,
      selected: !specialDiscount.selected,
    });
  };

  const width = window.innerWidth;

  return pdfDownloadView ? (
    <Box
      style={{
        wodth: "100%",
        height: "100%",
        // border: "1px solid red",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <PDFViewer width={850} height={700} style={{ marginTop: 20 }}>
        <MyDocument />
      </PDFViewer>
      <CloseOutlinedIcon
        fontSize="large"
        style={{ color: Colors.black, marginTop: "20px", cursor: "pointer" }}
        onClick={() => handleCloseSuccessfullTransactionModal()}
      />
    </Box>
  ) : (
    <div style={{ marginBottom: 10 }}>
      {/* <BarcodeReader onError={handleError} onScan={handleScan} /> */}

      {/* <BarcodeScanner onScan={handleScan} onError={handleError} /> */}

      {/* {userData?.role == "admin" && (
        <div
          style={{
            width: "100%",
            marginBottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* {userData?.role == "admin" && (
            <Grid container xs={12} spacing={4}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Button
                  sx={{
                    border: `1px solid ${Colors.button}`,
                    marginTop: "20px",
                  }}
                  onClick={() =>
                    setShowData(
                      showData == "total"
                        ? "monthly"
                        : showData == "monthly"
                        ? "today"
                        : "total"
                    )
                  }
                >
                  {showData == "total"
                    ? "monthly"
                    : showData == "monthly"
                    ? "today"
                    : "total"}
                </Button>
              </Box>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Card
                  style={{
                    backgroundColor: "#003049",
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <div style={{ textAlign: "left", marginTop: 20 }}>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        fontWeight: "600",
                        letterSpacing: 2,
                      }}
                    >
                      £
                      {showData == "total"
                        ? saleRevenue.total.toFixed(2)
                        : showData == "monthly"
                        ? saleRevenue.monthly.toFixed(2)
                        : saleRevenue.today.toFixed(2)}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: "400",
                        // letterSpacing:2
                      }}
                    >
                      {showData == "total"
                        ? "Total"
                        : showData == "monthly"
                        ? "Monthly"
                        : "Today"}{" "}
                      Revenue
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Card
                  style={{
                    backgroundColor: "#003049",
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <img
                    src={require("../../../Images/menu.png")}
                    style={{ width: 30, height: 30 }}
                    alt=""
                  />
                  <div style={{ textAlign: "left", marginTop: 20 }}>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        fontWeight: "600",
                        letterSpacing: 2,
                      }}
                    >
                      {showData == "total"
                        ? totalOrder?.total
                        : showData == "monthly"
                        ? totalOrder.monthly
                        : totalOrder.today}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: "400",
                        // letterSpacing:2
                      }}
                    >
                      {showData == "total"
                        ? "Total"
                        : showData == "monthly"
                        ? "Monthly"
                        : "Today"}{" "}
                      Orders
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Card
                  style={{
                    backgroundColor: "#003049",
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <img
                    src={require("../../../Images/users.png")}
                    style={{ width: 30, height: 30 }}
                    alt=""
                  />
                  <div style={{ textAlign: "left", marginTop: 20 }}>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        fontWeight: "600",
                        letterSpacing: 2,
                      }}
                    >
                      {showData == "total"
                        ? totalWalkins?.total
                        : showData == "monthly"
                        ? totalWalkins.monthly
                        : totalWalkins.today}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: "400",
                        // letterSpacing:2
                      }}
                    >
                      {showData == "total"
                        ? "Total"
                        : showData == "monthly"
                        ? "Monthly"
                        : "Today"}{" "}
                      Walk-ins
                    </div>
                  </div>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <Card
                  style={{
                    backgroundColor: "#003049",
                    width: "100%",
                    height: 150,
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 10,
                  }}
                >
                  <img
                    src={require("../../../Images/mouse-pointer.png")}
                    style={{ width: 30, height: 30 }}
                    alt=""
                  />
                  <div style={{ textAlign: "left", marginTop: 20 }}>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 18,
                        fontWeight: "600",
                        letterSpacing: 2,
                      }}
                    >
                      £
                      {showData == "total" || showData == "monthly"
                        ? Number(creditBalance?.total).toFixed(2)
                        : Number(creditBalance?.today).toFixed(2)}
                    </div>
                    <div
                      style={{
                        color: "white",
                        fontFamily: "Poppins",
                        fontSize: 14,
                        fontWeight: "400",
                        // letterSpacing:2
                      }}
                    >
                      {showData == "total"
                        ? "Total"
                        : showData == "monthly"
                        ? "Total"
                        : "Today"}{" "}
                      Credit
                    </div>
                  </div>
                </Card>
              </Grid>
            </Grid>
          )} */}
      {/* </div> */}
      {/* )}  */}

      <Card
        style={{ width: "100%", marginBottom: 10, padding: 10, marginTop: 10 }}
      >
        <Box>
          <Box
            sx={{
              width: "100%",
              marginTop: { xs: "0px", sm: "10px", md: "10px" },
              border: `1px solid ${Colors.button}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 10px 5px 10px",

              backgroundColor: Colors.white,
            }}
          >
            <TextField
              variant="standard" // <== changed this
              margin="normal"
              onChange={(e) => handleSearchProducts(e.target.value)}
              required
              value={productText}
              fullWidth
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              inputRef={productInputRef}
              placeholder="Search Products"
              sx={{
                width: "90%",
                fontSize: "12px",
                padding: 0,
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />
            <img
              src={require("../../../Images/search.png")}
              style={{ height: "20px" }}
            />
          </Box>

          {searchProducts &&
            searchProducts.length > 0 &&
            searchProducts.map((e, i) => {
              return (
                <Button
                  onClick={() => handleSelectProduct(e)}
                  sx={{
                    width: "100%",
                    // mt: 1,
                    backgroundColor: Colors.white,
                    p: "10px 10px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    cursor: "pointer",
                    borderRadius: 1,
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    border: `1px solid ${Colors.border}`,
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Poppins",
                      textAlign: "left",
                      marginBottom: 0,
                      color: Colors.button,
                    }}
                  >
                    {e.product_name}
                    <br />
                    Available qty: {e.qty}
                  </p>

                  {/* {e?.showDescription && (
                    <p
                      style={{
                        fontFamily: "Poppins",
                        textAlign: "left",
                        marginBottom: 0,
                        fontSize: 12,
                        color: Colors.black,
                      }}
                    >
                      {e.product_description
                        ? e.product_description
                        : "No Product Description"}
                    </p>
                  )} */}

                  {/* <CustomButton
                    text={
                      e?.showDescription
                        ? "Hide Description"
                        : "Show Description"
                    }
                    onClick={() => handleShowDescription(i)}
                    style={{
                      fontFamily: "Poppins",
                      textAlign: "left",
                      marginBottom: 0,
                      fontSize: 14,
                      color: "black",
                      width: 250,
                      border: "none",
                      justifyContent: "flex-start",
                      padding: 0,
                      marginTop: "5px",
                      backgroundColor: "transparent",
                    }}
                  /> */}

                  {/* <CustomButton
                    text={"Select"}
                    style={{
                      fontFamily: "Poppins",
                      textAlign: "left",
                      marginBottom: "10px",
                      fontSize: 14,
                      // color: "black",
                      borderRadius: 2,
                      width: 250,
                      // border: "none",
                      // justifyContent: "flex-start",
                      // padding: 0,
                      marginTop: "10px",
                      // backgroundColor: "transparent",
                    }}
                  /> */}
                </Button>
              );
            })}
        </Box>

        <div style={{ width: "100%", display: "flex" }}>
          <button
            onClick={() => navigate("QuickSearch")}
            style={{
              color: "#231F20",
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: 14,
              textAlign: "left",
              marginTop: 10,
            }}
          >
            Quick Search
          </button>
        </div>
      </Card>

      <Box>
        <CustomModal
          heading={`Total ${vatAmount ? "Invoice" : "Quotation"} Amount: £${
            (exchangeProducts && Object.keys(exchangeProducts).length) > 0
              ? Number(
                  totalAmount -
                    discount +
                    vatAmount -
                    exchangeProducts?.subtotal
                ).toFixed(2)
              : specialDiscount?.selected && isVat?.selected
              ? (
                  Number(totalAmount - discount + vatAmount) -
                  Number(
                    (((totalAmount - discount) * specialDiscount?.value) /
                      100) *
                      (1 + vat.vatRate / 100)
                  )
                ).toFixed(2)
              : specialDiscount?.selected && !isVat?.selected
              ? (
                  Number(totalAmount - discount + vatAmount) -
                  Number(
                    ((totalAmount - discount) * specialDiscount.value) / 100
                  )
                ).toFixed(2)
              : Number(totalAmount - discount + vatAmount).toFixed(2)
          }`}
          loading={loading}
          text={
            Number(paymentMethodDetails.cash) &&
            !Number(paymentMethodDetails.card) &&
            !Number(paymentMethodDetails.credit) &&
            !Number(paymentMethodDetails.online) &&
            !Number(paymentMethodDetails.cheque)
              ? `Do you confirm that you received £${paymentMethodDetails?.cash} cash?`
              : !Number(paymentMethodDetails.cash) &&
                !Number(paymentMethodDetails.card) &&
                Number(paymentMethodDetails.credit) &&
                !Number(paymentMethodDetails.online) &&
                !Number(paymentMethodDetails.cheque)
              ? `Confirm to put £${paymentMethodDetails?.credit} on Account?`
              : !Number(paymentMethodDetails.cash) &&
                Number(paymentMethodDetails.card) &&
                !Number(paymentMethodDetails.credit) &&
                !Number(paymentMethodDetails.online) &&
                !Number(paymentMethodDetails.cheque)
              ? `Confirm to put £${paymentMethodDetails?.card} on card?`
              : !Number(paymentMethodDetails.cash) &&
                !Number(paymentMethodDetails.card) &&
                !Number(paymentMethodDetails.credit) &&
                Number(paymentMethodDetails.online) &&
                !Number(paymentMethodDetails.cheque)
              ? `Do you confirm £${paymentMethodDetails?.online} online transaction?`
              : !Number(paymentMethodDetails.cash) &&
                !Number(paymentMethodDetails.card) &&
                !Number(paymentMethodDetails.credit) &&
                !Number(paymentMethodDetails.online) &&
                Number(paymentMethodDetails.cheque)
              ? `Do you confirm that you have received £${paymentMethodDetails?.cheque} cheque?`
              : `Confirm received ${
                  Number(paymentMethodDetails?.cash)
                    ? `£${paymentMethodDetails?.cash} cash and`
                    : ""
                }   ${
                  Number(paymentMethodDetails?.card)
                    ? `£${paymentMethodDetails?.card} by card ${
                        paymentMethodDetails?.online ||
                        paymentMethodDetails?.cheque ||
                        paymentMethodDetails?.credit
                          ? "and"
                          : ""
                      }`
                    : ""
                } ${
                  Number(paymentMethodDetails?.online)
                    ? `£${paymentMethodDetails?.online} by bank transfer ${
                        paymentMethodDetails?.cheque ||
                        paymentMethodDetails?.credit
                          ? "and"
                          : ""
                      }`
                    : ""
                } ${
                  Number(paymentMethodDetails?.cheque)
                    ? `£${paymentMethodDetails?.cheque} by cheque ${
                        paymentMethodDetails?.credit ? "and" : ""
                      }  `
                    : ""
                } 
              ${
                Number(paymentMethodDetails?.credit)
                  ? `rest £${paymentMethodDetails?.credit} on Account`
                  : ""
              }
              
              `
          }
          open={checkOutModal}
          closeModal={closeCheckoutModal}
          confirm={() =>
            exchangeProducts && Object.keys(exchangeProducts).length > 0
              ? !loading && handleConfirmExchangeSale()
              : !loading && handleConfirmSale()
          }
        />

        <CustomModal
          heading={`Total ${
            vatAmount ? "Invoice" : "Quotation"
          } Amount: £${Number(
            totalAmount - discount + vatAmount - exchangeProducts?.subtotal
          ).toFixed(2)}`}
          loading={loading}
          text={
            Number(
              Number(totalAmount) -
                Number(discount) +
                Number(vatAmount) -
                Number(exchangeProducts?.subtotal)
            ) > 0
              ? paymentMethodDetails.cash &&
                !paymentMethodDetails.card &&
                !paymentMethodDetails.credit &&
                !paymentMethodDetails.online &&
                !paymentMethodDetails.cheque
                ? `Do you confirm that you have received £${paymentMethodDetails?.cash} cash?`
                : !paymentMethodDetails.cash &&
                  !paymentMethodDetails.card &&
                  paymentMethodDetails.credit &&
                  !paymentMethodDetails.online &&
                  !paymentMethodDetails.cheque
                ? `Confirm to put £${paymentMethodDetails?.credit} on Account?`
                : !paymentMethodDetails.cash &&
                  paymentMethodDetails.card &&
                  !paymentMethodDetails.credit &&
                  !paymentMethodDetails.online &&
                  !paymentMethodDetails.cheque
                ? `Confirm to put £${paymentMethodDetails?.card} on card?`
                : !paymentMethodDetails.cash &&
                  !paymentMethodDetails.card &&
                  !paymentMethodDetails.credit &&
                  paymentMethodDetails.online &&
                  !paymentMethodDetails.cheque
                ? `Do you confirm £${paymentMethodDetails?.online} online transaction?`
                : !paymentMethodDetails.cash &&
                  !paymentMethodDetails.card &&
                  !paymentMethodDetails.credit &&
                  !paymentMethodDetails.online &&
                  paymentMethodDetails.cheque
                ? `Do you confirm that you have received £${paymentMethodDetails?.cheque} cheque?`
                : ` ${
                    paymentMethodDetails?.cash
                      ? `Do you confirm that you have received £${paymentMethodDetails?.cash} cash and`
                      : ""
                  } ${
                    paymentMethodDetails?.credit
                      ? `do you confirm to put £${
                          paymentMethodDetails?.credit
                        } on Account ${
                          paymentMethodDetails?.card ||
                          paymentMethodDetails?.online ||
                          paymentMethodDetails?.cheque
                            ? "and"
                            : ""
                        }`
                      : ""
                  }  ${
                    paymentMethodDetails?.card
                      ? `received £${paymentMethodDetails?.card} through card ${
                          (paymentMethodDetails?.online ||
                            paymentMethodDetails?.cheque) &&
                          "and"
                        }`
                      : ""
                  } ${
                    paymentMethodDetails?.online
                      ? `received £${paymentMethodDetails?.online} online ${
                          paymentMethodDetails?.cheque && "and"
                        }`
                      : ""
                  } ${
                    paymentMethodDetails?.cheque
                      ? `received £${paymentMethodDetails?.cheque} through cheque`
                      : ""
                  }   `
              : selectedOption?.toLowerCase() == "cash"
              ? "Are you sure you want to refund cash"
              : selectedOption?.toLowerCase() == "credit"
              ? "Are you sure you want to Credit Note on Account?"
              : "Are you sure you want to make this exchange invoice"
          }
          open={exchangeInvoiceVisible}
          closeModal={() => setExchangeInvoiceVisible(false)}
          confirm={handleConfirmExchangeSale}
        />

        <PaymentMethodModal
          heading={`Select Payment Method`}
          loading={loading}
          // text={"Can you confirm that you have received this amount?"}
          open={paymentMethodModal}
          subtotal={
            exchangeProducts && Object?.keys(exchangeProducts)?.length > 0
              ? specialDiscount?.selected && isVat?.selected
                ? Number(totalAmount - discount + vatAmount) -
                  Number(exchangeProducts?.subtotal) -
                  Number(
                    (((totalAmount - discount) * specialDiscount?.value) /
                      100) *
                      (1 + vat.vatRate / 100)
                  )
                : specialDiscount?.selected && !isVat?.selected
                ? Number(totalAmount - discount + vatAmount) -
                  Number(exchangeProducts?.subtotal) -
                  Number(
                    ((totalAmount - discount) * specialDiscount.value) / 100
                  )
                : Number(totalAmount - discount + vatAmount) -
                  Number(exchangeProducts?.subtotal)
              : specialDiscount?.selected && isVat?.selected
              ? Number(totalAmount - discount + vatAmount) -
                Number(
                  (((totalAmount - discount) * specialDiscount?.value) / 100) *
                    (1 + vat.vatRate / 100)
                )
              : specialDiscount?.selected && !isVat?.selected
              ? Number(totalAmount - discount + vatAmount) -
                Number(((totalAmount - discount) * specialDiscount.value) / 100)
              : Number(totalAmount - discount + vatAmount)
          }
          closeModal={() => setPaymentMethodModal(false)}
          confirm={handleConfirmPaymentMethod}
        />

        <OptionModal
          heading={`Select Refund Option`}
          loading={loading}
          // text={"Can you confirm that you have received this amount?"}
          open={optionModal}
          closeModal={() => setOptionModal(false)}
          confirm={handleConfirmOptionModal}
        />

        {creditModal && (
          <CustomModal
            heading={`Subtotal: £${((exchangeProducts &&
              Object.keys(exchangeProducts).length) > 0
              ? totalAmount - discount + vatAmount - exchangeProducts?.subtotal
              : totalAmount - discount + vatAmount
            ).toFixed(2)}`}
            loading={loading}
            credit={true}
            onChange={handleCreditDays}
            text={"Are you sure you want to make this transaction on credit?"}
            open={creditModal}
            closeModal={() => setCreditModal(false)}
            confirm={() =>
              exchangeProducts && Object.keys(exchangeProducts).length > 0
                ? !loading && handleConfirmExchangeSale()
                : !loading && handleConfirmSale()
            }
          />
        )}

        {successfulTransactionModal && (
          <CustomModal
            heading={`Transaction Successful`}
            loading={loading}
            emailbutton={true}
            emailInvoice={emailInvoice}
            emailLoading={emailLoading}
            text={"Do You want to print the invoice?"}
            open={successfulTransactionModal}
            closeModal={() => closeSuccessfulTransactionModal()}
            confirm={() => setPdfDownloadView(true)}
          />
        )}

        <CustomModal2
          open={creditAccountModal}
          closeModal={() => setCreditAccountModal(false)}
        />
        <OnlineTransactionModal
          heading={"Online Transaction"}
          text={"Reference ID:"}
          onChange={(e) => setOnlineReferenceId(e.target.value)}
          open={onlineModalVisible}
          closeModal={() => setOnlineModalVisible(false)}
          loading={loading}
          buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
          buttonText="Received Amount"
          confirm={() =>
            exchangeProducts && Object.keys(exchangeProducts).length > 0
              ? !loading && handleConfirmExchangeSale()
              : !loading && handleConfirmSale()
          }
        />

        <OnlineTransactionModal
          heading={"Card Transaction"}
          text={"Transaction ID:"}
          onChange={(e) => setCardTransactionId(e.target.value)}
          open={cardModalVisible}
          closeModal={() => setCardModalVisible(false)}
          loading={loading}
          buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
          buttonText="Confirm"
          confirm={() =>
            exchangeProducts && Object.keys(exchangeProducts).length > 0
              ? !loading && handleConfirmExchangeSale()
              : !loading && handleConfirmSale()
          }
        />

        <ChequeModal
          heading={"Cheque"}
          text={"Cheque No:"}
          text1={"Bank Name:"}
          text2={"Clear Date:"}
          loading={loading}
          onChange={(e) =>
            setChequeDetails({ ...chequeDetails, chequeNo: e.target.value })
          }
          onChange1={(e) =>
            setChequeDetails({ ...chequeDetails, bankName: e.target.value })
          }
          onChangeDay={(e) =>
            setChequeDetails({ ...chequeDetails, day: e.target.value })
          }
          onChangeMonth={(e) =>
            setChequeDetails({ ...chequeDetails, month: e.target.value })
          }
          onChangeYear={(e) =>
            setChequeDetails({ ...chequeDetails, year: e.target.value })
          }
          open={chequeModalVisible}
          closeModal={() => setChequeModalVisible(false)}
          buttonStyle2={{ width: "200px", fontSize: "14px", mt: 2 }}
          buttonText="Confirm Purchase"
          confirm={() =>
            exchangeProducts && Object.keys(exchangeProducts).length > 0
              ? !loading && handleConfirmExchangeSale()
              : !loading && handleConfirmSale()
          }
        />
        <Box
          sx={{
            backgroundColor: Colors.white,
            border: `1px solid ${Colors.border}`,
            width: "100%",
            maxHeight: window.innerHeight - 450,
            minHeight: "200px",
            overflow: "auto",
            borderRadius: "5px",
            boxSizing: "border-box",
            padding: "0px 0px 0px 0px",
          }}
        >
          <Box sx={{ marginTop: "0px" }}>
            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              sx={{
                backgroundColor: Colors.black,
                padding: "10px 0px 10px 0px",
              }}
            >
              <Grid item xl={0.8} lg={0.8} md={1} sm={0.8} xs={0.5}>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                  }}
                >
                  Item
                </Typography>
              </Grid>

              <Grid item xl={4.2} lg={4.2} md={4.5} sm={4.2} xs={3.5}>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                    textAlign: "left",
                  }}
                >
                  Product Description
                </Typography>
              </Grid>

              <Grid item xl={2.5} lg={3} md={3} sm={3} xs={3.5}>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                    textAlign: "left",
                  }}
                >
                  Quantity
                </Typography>
              </Grid>

              {/* <Grid item xl={1.5} lg={2} md={1.5} sm={2} xs={1.5}>
                  <Typography
                    sx={{
                      color: Colors.white,
                      fontFamily: "Poppins",
                      fontWeight: "500",
                      fontSize: {
                        lg: "16px",
                        md: "12px",
                        sm: "10px",
                        xs: "8px",
                      },
                      textAlign: "left",
                    }}
                  >
                    Warranty
                  </Typography>
                </Grid> */}
              <Grid item xl={1.5} lg={1} md={1} sm={1.5} xs={1.5}>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                    textAlign: { md: "center", lg: "left" },
                  }}
                >
                  Price(£)
                </Typography>
              </Grid>

              <Grid item xl={1.5} lg={1} md={1} sm={1.5} xs={1.5}>
                <Typography
                  sx={{
                    color: Colors.white,
                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                    textAlign: { md: "center", lg: "left" },
                  }}
                >
                  Total(£)
                </Typography>
              </Grid>
              <Grid item xl={1.5} lg={1} md={2} sm={2} xs={2.5}>
                <Typography
                  sx={{
                    color: Colors.white,

                    fontFamily: "Poppins",
                    fontWeight: "500",
                    fontSize: {
                      lg: "12px",
                      md: "10px",
                      sm: "8px",
                      xs: "6px",
                    },
                    textAlign: "center",
                  }}
                >
                  Action
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ marginTop: "0px", backgroundColor: Colors.white }}>
            {cart && cart?.length > 0 ? (
              cart?.map((e, i) => {
                return (
                  <Grid
                    container
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      backgroundColor: Colors.white,
                      padding: "2px 0px 0px 0px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Grid
                      item
                      xl={0.8}
                      lg={0.8}
                      md={1}
                      sm={0.8}
                      xs={0.5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          color: Colors.text,
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: {
                            lg: "12px",
                            md: "10px",
                            sm: "8px",
                            xs: "6px",
                          },
                        }}
                      >
                        {i + 1}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xl={4.2}
                      lg={4.2}
                      md={4.5}
                      sm={4.2}
                      xs={3.5}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "background: #000",
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: {
                            lg: "12px",
                            md: "10px",
                            sm: "8px",
                            xs: "6px",
                          },
                          textAlign: "left",
                        }}
                      >
                        {e?.productName}
                      </Typography>

                      {/* <Typography
                            sx={{
                              color: "background: #939598",
                              fontFamily: "Poppins",
                              fontWeight: "500",
                              fontSize: {
                                lg: "12px",
                                md: "10px",
                                sm: "8px",
                                xs: "6px",
                              },
                              textAlign: "left",
                            }}
                          >
                            Category: {e?.category}
                          </Typography> */}
                    </Grid>

                    <Grid
                      item
                      xl={2.5}
                      lg={3}
                      md={3.5}
                      sm={3}
                      xs={3.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          // justifyContent: "space-between",
                          width: "100%",
                          //   border: "1px solid red",
                        }}
                      >
                        <button
                          onClick={() => handleDecreaseProduct(e)}
                          style={{
                            width: width < 600 ? "15px" : "20px",
                            padding: "0px 0px 0px 0px",
                            height: width < 600 ? "15px" : "20px",
                            borderRadius: "5px",
                            marginRight: "10px",
                            backgroundColor: Colors.white,
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            border: `1px solid ${Colors?.border}`,
                          }}
                        >
                          -
                        </button>

                        <TextField
                          variant="standard"
                          margin="none"
                          required
                          onChange={(e) => changeSaleQty(e.target.value, i)}
                          value={e?.saleQty}
                          fullWidth
                          inputProps={{
                            disableUnderline: true,
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: Colors.red,
                            style: {
                              textAlign: "center",
                              fontSize: "12px",
                              color: Colors.red,
                              fontWeight: "bold",
                            },
                          }}
                          placeholder="Price"
                          style={{
                            width: width < 600 ? "15px" : "20px",
                            height: width < 600 ? "15px" : "20px",
                            padding: "0px 0px 0px 0px",

                            borderRadius: "5px",
                            color: Colors.red,
                            backgroundColor: Colors.white,
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            margin: 0,
                            marginRight: 10,
                            // border: `1px solid ${Colors?.button}`,
                          }}
                        />

                        <button
                          style={{
                            width: width < 600 ? "15px" : "20px",
                            height: width < 600 ? "15px" : "20px",
                            padding: "0px 0px 0px 0px",
                            borderRadius: "5px",
                            color: Colors.button,
                            backgroundColor: Colors.white,
                            fontFamily: "Poppins",
                            fontWeight: "bold",
                            border: `1px solid ${Colors?.button}`,
                          }}
                          onClick={() => handleIncreaseProduct(e)}
                        >
                          +
                        </button>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        value={
                          e?.discountPrice
                            ? Number(e?.discountPrice)
                            : selectedCustomer &&
                              selectedCustomer?.priceLevel[0]?.id == 1
                            ? Number(e?.trade_price).toFixed(2)
                            : selectedCustomer &&
                              selectedCustomer?.priceLevel[0]?.id == 2
                            ? Number(e?.warehouse_price).toFixed(2)
                            : Number(e?.retail_price).toFixed(2)
                        }
                        onChange={(text) =>
                          userData?.role_access?.some(
                            (e, i) =>
                              e.name.toLowerCase() == "showcostprice" &&
                              e?.selected
                          ) || userData?.role == "admin"
                            ? handleDiscountPrice(text.target.value, e)
                            : ""
                        }
                        required
                        fullWidth
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",

                          // <== added this
                          // fontSize: "2px"
                        }}
                        placeholder="Search Product/Customer/Invoice"
                        sx={{
                          width: "90%",
                          fontSize: "12px",
                          padding: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          margin: 0,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xl={1.5}
                      lg={1.5}
                      md={1}
                      sm={1}
                      xs={1}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="standard" // <== changed this
                        margin="normal"
                        value={(
                          Number(
                            e?.discountPrice
                              ? Number(e?.discountPrice)
                              : selectedCustomer &&
                                selectedCustomer?.priceLevel[0]?.id == 1
                              ? Number(e?.trade_price)
                              : selectedCustomer &&
                                selectedCustomer?.priceLevel[0]?.id == 2
                              ? Number(e?.warehouse_price)
                              : Number(e?.retail_price)
                          ) * Number(e?.saleQty)
                        ).toFixed(2)}
                        // onChange={(text) =>
                        //   handleDiscountPrice(text.target.value, e)
                        // }
                        required
                        fullWidth
                        InputProps={{
                          // startAdornment: <AccountCircle />, // <== adjusted this
                          disableUnderline: true,
                          fontSize: "12px",

                          // <== added this
                          // fontSize: "2px"
                        }}
                        placeholder="Search Product/Customer/Invoice"
                        sx={{
                          width: "90%",
                          fontSize: "12px",
                          padding: 0,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          margin: 0,
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xl={1.5}
                      lg={1}
                      md={2}
                      sm={2}
                      xs={2.5}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          width:
                            !userData?.role_access?.some(
                              (e, i) =>
                                e.name.toLowerCase() == "showcostprice" &&
                                e?.selected
                            ) && !userData?.role == "admin"
                              ? "100%"
                              : "20%",

                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                        onClick={() => handleDeleteProduct(e)}
                      >
                        <img
                          src={require("../../../Images/delete.png")}
                          style={{ height: "15px" }}
                        />
                      </Box>
                      {(userData?.role_access?.some(
                        (e, i) =>
                          e.name.toLowerCase() == "showcostprice" && e?.selected
                      ) ||
                        userData?.role == "admin") && (
                        <Box>
                          {refHover?.hover && refHover.ind == i && (
                            <Typography style={{ fontSize: "10px" }}>
                              {e?.cost_price}.000
                            </Typography>
                          )}

                          <Button
                            // onMouseEnter={() =>
                            //   setRefHover({
                            //     hover: true,
                            //     ind: i,
                            //   })
                            // }
                            // onMouseLeave={() =>
                            //   setRefHover({
                            //     hover: false,
                            //   })
                            // }

                            onClick={() => {
                              setRefHover({
                                hover: !refHover?.hover,
                                ind: i,
                              });
                            }}
                            sx={{
                              fontFamily: "Poppins",
                              fontSize: "12px",
                              padding: 0,
                              marginLeft: 1,
                              // border: `1px solid ${Colors.border}`,
                              borderRadius: 1,
                            }}
                          >
                            Ref
                          </Button>

                          {/* <BasicSelect
                          value={e?.status}
                          status="Select"
                          id={productStatus}
                          width={{ lg: "80%", md: "60%", xs: "50%" }}
                          onChange={(j) => changeProductStatus(j, i)}
                        /> */}
                        </Box>
                      )}

                      <Button
                        // onMouseEnter={() =>
                        //   setRefHover({
                        //     hover: true,
                        //     ind: i,
                        //   })
                        // }
                        // onMouseLeave={() =>
                        //   setRefHover({
                        //     hover: false,
                        //   })
                        // }

                        onClick={() => {
                          handleChangeProductDetails(e);
                        }}
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "12px",
                          padding: 0,
                          marginLeft: 1,
                          // border: `1px solid ${Colors.border}`,
                          borderRadius: 1,
                        }}
                      >
                        Edit
                      </Button>
                    </Grid>
                  </Grid>
                );
              })
            ) : (
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  }}
                >
                  No Product Added
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            backgroundColor: Colors.white,
            marginTop: "10px",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <Grid container xs={12} sm={12} md={12}>
            <Grid item xs={12} sm={12} md={7}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: `1px solid ${Colors.border}`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  Total Products Sale
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  {cart?.length}
                </Typography>
              </Box>

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Total Products Return
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.red,
                      }}
                    >
                      -{exchangeProducts?.totalItems}
                    </Typography>
                  </Box>
                )}

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Total Products
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      {cart?.length - exchangeProducts?.totalItems}
                    </Typography>
                  </Box>
                )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: `1px solid ${Colors.border}`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  Total Quantity Sale
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  {cart && cart.length > 0
                    ? cart.reduce(
                        (previous, current) =>
                          previous + Number(current.saleQty),
                        0
                      )
                    : 0}
                </Typography>
              </Box>

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Total Quantity Return
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.red,
                      }}
                    >
                      -{exchangeProducts?.totalQty}
                    </Typography>
                  </Box>
                )}

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Total Quantity
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      {(cart && cart.length > 0
                        ? cart.reduce(
                            (previous, current) =>
                              previous + Number(current.saleQty),
                            0
                          )
                        : 0) - exchangeProducts?.totalQty}
                    </Typography>
                  </Box>
                )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: `1px solid ${Colors.border}`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  {exchangeProducts && Object.keys(exchangeProducts).length > 0
                    ? "Total Sale Amount"
                    : "Subtotal"}
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  £{Number(totalAmount).toFixed(2)}
                </Typography>
              </Box>

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Total Return Amount
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: Colors.red,
                      }}
                    >
                      £-{Number(exchangeProducts?.subtotal).toFixed(2)}
                    </Typography>
                  </Box>
                )}

              {exchangeProducts &&
                Object.keys(exchangeProducts).length > 0 &&
                exchangeProducts?.productDetails && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "10px",
                      borderBottom: `1px solid ${Colors.border}`,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      Subtotal
                    </Typography>

                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: Colors.black,
                      }}
                    >
                      £
                      {Number(
                        Number(totalAmount) - Number(exchangeProducts?.subtotal)
                      ).toFixed(2)}
                    </Typography>
                  </Box>
                )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: `1px solid ${Colors.border}`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  Discount
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.black,
                  }}
                >
                  £{Number(discount).toFixed(2)}
                </Typography>
              </Box>

              {isVat?.selected && discount ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    borderBottom: `1px solid ${Colors.border}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    Subtotal after discount
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    £
                    {exchangeProducts && exchangeProducts?.subtotal
                      ? Number(
                          totalAmount - discount - exchangeProducts?.subtotal
                        ).toFixed(2)
                      : Number(totalAmount - discount).toFixed(2)}
                  </Typography>
                </Box>
              ) : (
                ""
              )}

              {isVat?.selected && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    borderBottom: `1px solid ${Colors.border}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    VAT
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    £
                    {Number(
                      ((totalAmount - discount) * vat.vatRate) / 100
                    ).toFixed(2)}
                  </Typography>
                </Box>
              )}

              {specialDiscount?.selected && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "10px",
                    borderBottom: `1px solid ${Colors.border}`,
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    Special Discount
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: Colors.black,
                    }}
                  >
                    - £
                    {isVat?.selected
                      ? Number(
                          (((totalAmount - discount) * specialDiscount?.value) /
                            100) *
                            (1 + vat.vatRate / 100)
                        ).toFixed(2)
                      : Number(
                          ((totalAmount - discount) * specialDiscount?.value) /
                            100
                        ).toFixed(2)}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  borderBottom: `1px solid ${Colors.border}`,
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.button,
                  }}
                >
                  Total
                </Typography>

                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: Colors.button,
                  }}
                >
                  £
                  {exchangeProducts && Object.keys(exchangeProducts).length > 0
                    ? isVat.selected && !specialDiscount?.selected
                      ? (
                          totalAmount -
                          exchangeProducts?.subtotal -
                          discount +
                          ((totalAmount - discount) * vat.vatRate) / 100
                        ).toFixed(2)
                      : isVat.selected && specialDiscount?.selected
                      ? (
                          totalAmount -
                          exchangeProducts?.subtotal -
                          discount +
                          ((totalAmount - discount) * vat.vatRate) / 100 -
                          (((totalAmount - discount) * specialDiscount?.value) /
                            100) *
                            (1 + vat.vatRate / 100)
                        ).toFixed(2)
                      : !isVat.selected && specialDiscount?.selected
                      ? (
                          totalAmount -
                          discount -
                          exchangeProducts?.subtotal -
                          ((totalAmount - discount) * specialDiscount?.value) /
                            100
                        ).toFixed(2)
                      : Number(
                          totalAmount - discount - exchangeProducts?.subtotal
                        ).toFixed(2)
                    : isVat.selected && !specialDiscount?.selected
                    ? (
                        totalAmount -
                        discount +
                        ((totalAmount - discount) * vat.vatRate) / 100
                      ).toFixed(2)
                    : isVat.selected && specialDiscount?.selected
                    ? (
                        totalAmount -
                        discount +
                        ((totalAmount - discount) * vat.vatRate) / 100 -
                        (((totalAmount - discount) * specialDiscount?.value) /
                          100) *
                          (1 + vat.vatRate / 100)
                      ).toFixed(2)
                    : !isVat.selected && specialDiscount?.selected
                    ? (
                        totalAmount -
                        discount -
                        ((totalAmount - discount) * specialDiscount?.value) /
                          100
                      ).toFixed(2)
                    : Number(totalAmount - discount).toFixed(2)}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={5}>
              <Button
                onClick={() => openNewWindow()}
                sx={{
                  backgroundColor: Colors.green,
                  color: Colors.white,
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  padding: "5px",
                  borderRadius: "20px",
                  border: `1px solid ${Colors.button}`,
                  width: "150px",
                  "&:hover": {
                    color: Colors.button, // Set your desired hover color
                  },
                }}
              >
                New Invoice
              </Button>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  alignItems: "center",
                  height:
                    exchangeProducts && Object.keys(exchangeProducts).length > 0
                      ? "400px"
                      : "280px",

                  width: "100%",
                  mt: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      mr: 1,
                    }}
                  >
                    include Vat
                  </Typography>

                  <Checkbox
                    data={isVat}
                    color={Colors.white}
                    style={{
                      backgroundColor: isVat.selected
                        ? Colors.button
                        : Colors.white,
                    }}
                    onClick={() => handleVatClicked()}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    marginBottom: 2,
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "bold",
                      mr: 1,
                    }}
                  >
                    Special Discount
                  </Typography>

                  <Checkbox
                    data={specialDiscount}
                    color={Colors.white}
                    style={{
                      backgroundColor: specialDiscount.selected
                        ? Colors.button
                        : Colors.white,
                    }}
                    onClick={() => handleSpecialDiscount()}
                  />
                </Box>
                {specialDiscount.selected && (
                  <TextField
                    variant="standard" // <== changed this
                    margin="normal"
                    onChange={(e) => {
                      const value = e.target.value.replace("%", "");
                      if (!isNaN(value) && value >= 0 && value <= 100) {
                        setSpecialDiscount({
                          ...specialDiscount,
                          value: value,
                        });
                      }
                    }}
                    required
                    value={`${specialDiscount?.value}%`}
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      sx: {
                        textAlign: "center", // Center-aligns the text
                        fontSize: "12px",
                      },
                    }}
                    // inputRef={productInputRef}
                    placeholder="Enter discount"
                    sx={{
                      backgroundColor: Colors.white,
                      color: Colors.button,
                      fontFamily: "Poppins",
                      paddingLeft: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "20px",
                      border: `1px solid ${Colors.button}`,
                      width: "150px",
                      "& .MuiInputBase-input": {
                        textAlign: "center", // Center-aligns the text
                      },
                      "&:hover": {
                        color: Colors.button, // Set your desired hover color
                      },
                    }}
                  />
                )}

                <Button
                  onClick={() => handleResetProcess()}
                  sx={{
                    backgroundColor: Colors.white,
                    color: Colors.button,
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    padding: "5px",
                    borderRadius: "20px",
                    border: `1px solid ${Colors.button}`,
                    width: "150px",
                    "&:hover": {
                      color: Colors.button, // Set your desired hover color
                    },
                  }}
                >
                  Reset
                </Button>

                <Button
                  onClick={() => handleProceedSale()}
                  sx={{
                    backgroundColor: Colors.button,
                    color: Colors.white,
                    marginTop: "15px",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    padding: "5px",
                    borderRadius: "20px",
                    border: `1px solid ${Colors.button}`,
                    width: "150px",
                    "&:hover": {
                      color: Colors.button, // Set your desired hover color
                    },
                  }}
                >
                  Proceed to pay
                </Button>

                {showProfit && (
                  <Typography
                    sx={{
                      color: Colors.black,
                      fontSize: "14px",
                      marginTop: 1,
                      fontFamily: "Poppins",
                    }}
                  >
                    {cart &&
                      cart?.length > 0 &&
                      selectedCustomer &&
                      (totalAmount - discount - costPrice).toFixed(2)}
                    .000
                  </Typography>
                )}
                {(userData?.role == "admin" ||
                  userData?.role_access?.some(
                    (e, i) =>
                      e.name.toLowerCase() == "showprofit" && e?.selected
                  )) && (
                  <Button
                    style={{ marginTop: 10 }}
                    onClick={() => setShowProfit(!showProfit)}
                    // onMouseEnter={() => setShowProfit(true)}
                    // onMouseLeave={() => setShowProfit(false)}
                  >
                    <QuestionMarkIcon
                      fontSize="medium"
                      style={{ marginTop: 10 }}
                    />
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {/* // )} */}

      {/* {openingBalanceModalVisible && userData && userData.role == "employee" && (
        <CustomPOSModal
          open={openingBalanceModalVisible}
          closeModal={closeOpeningModal}
          buttonText={approvedByAdmin ? "Start Shift" : "Approved By Admin"}
          buttonStyle={{
            backgroundColor: approvedByAdmin ? Colors.button : "#5DC002",
          }}
          text={"Your Today Opening Balance Is"}
          specialText="€3000.00"
          heading={`Hello ${userData?.fullName}`}
        />
      )} */}

      {invoiceDetailModal && (
        <InvoiceModal
          open={invoiceDetailModal}
          closeModal={() => setInvoiceDetailModal(false)}
        />
      )}

      <CreditWarningModal
        heading={`Credit Limit Warning`}
        loading={loading}
        text={
          "The customer has been reached out of his credit limit are you sure you want to done this transaction?"
        }
        open={openCreditLimitWarningModal}
        closeModal={() => setOpenCreditLimitWarningModal(false)}
        confirm={(reason, days) =>
          exchangeProducts && Object.keys(exchangeProducts).length > 0
            ? handleConfirmExchangeSale(reason, days)
            : handleConfirmSale(reason, days)
        }
      />

      <CartProductEditModal
        heading={"Edit Product Details"}
        loading={loading}
        text={"Can you confirm that you have received this amount?"}
        open={openEditCartDetailModal}
        closeModal={() => setOpenEditCartDetailModal(false)}
        product={editCartProductData}
      />
    </div>
  );
};

export default MainPage;
