import React, { useState } from 'react'
import { Colors } from '../../Constant/Color'
import { Box, Grid, Typography, TextField } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import MessageIcon from '@mui/icons-material/Message';
import CustomButton from '../../Components/Button';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Uri } from '../../Constant/BaseUri';
import { useDispatch } from 'react-redux';
import { edit } from '../../Redux/LoginReduces';
import { updateAuth } from '../../Redux/authenticationReducer';
import { RingLoader } from 'react-spinners';


function Login() {

    const dispatch = useDispatch()


    const initialError = {
        employeeId: "",
        password: ""
    }

    const [error, setError] = useState(initialError)

    const initialData = {
        employeeId: "",
        password: ""
    }

    const [userData, setUserData] = useState(initialData)
    const [loginLoader, setLoginLoader] = useState(false)

    const [otp, setOtp] = useState("");
    const [isOtpRequired, setIsOtpRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const navigate = useNavigate()


    const handleLogin = async () => {

        const loginError = {}

        if (!userData?.employeeId) {
            loginError.employeeId = true
        }


        if (!userData?.password) {
            loginError.password = true
        }


        if (loginError && Object.keys(loginError).length > 0) {
            setError(loginError)
            return
        }

        else {

            setLoginLoader(true)

            try {
                axios.post(`${Base_Uri}login`, userData).then(async (res) => {
                    let data = res.data
                    console.log(data, "data")
                    { data?.two_step && setIsOtpRequired(true) }
                    if (data?.status) {


                        if (data?.block) {

                            alert(data.message)
                            setLoginLoader(false)
                            return
                        }

                        let token = data.data.token

                        let myData = JSON.stringify(token)

                        await sessionStorage.setItem("userData", myData)


                        if (data?.data?.role !== "admin") {


                            let response = await axios.get(`${Base_Uri}getShift/${data?.data?.id}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json',
                                },
                            })


                            let shiftData = response?.data

                            if (shiftData.status) {

                                let shift = shiftData.data

                                if (!shift) {
                                    await sessionStorage.setItem("shiftStarted", false)
                                } else {
                                    await sessionStorage.setItem("shiftStarted", true)
                                }
                            }

                            let dataToSend = {
                                subject: "Employee Login",
                                body: `Hey Admin the Employee has been logged in
                            Employee Id : ${userData?.employeeId}
                            Login Date : ${new Date().toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })}
                            Login Time : ${new Date().toLocaleTimeString()} 
                            `,
                                to: "zainshakeel65@gmail.com"
                            }

                            axios.post(`${Base_Uri}sendEmailToCustomer`, dataToSend, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'Content-Type': 'application/json', // Include other headers as needed
                                },
                            }).then(() => {


                                dispatch(updateAuth(true))
                                dispatch(edit(data?.data))
                                setLoginLoader(false)
                                navigate("/PointOfSale")
                                alert("Login Succesfully")

                            }).catch((error) => {
                                setLoginLoader(false)
                                alert(data.message)
                            })

                        } else {

                            dispatch(updateAuth(true))
                            dispatch(edit(data?.data))
                            navigate("/PointOfSale")
                            setLoginLoader(false)
                            alert("Login Succesfully")

                        }


                    } else {
                        alert(data.message)
                        setLoginLoader(false)
                    }


                })
            } catch (error) {
                alert("Internal Server Error")
                setLoginLoader(false)
                return
            }
        }
    }

    const handleOtpVerification = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${Base_Uri}verify-otp`, { employeeId: userData?.employeeId, otp });
            setMessage(response.data.message);

            let data = response.data

            if (data?.status) {


                if (data?.block) {

                    alert(data.message)
                    setLoginLoader(false)
                    return
                }

                let token = data.data.token

                let myData = JSON.stringify(token)

                await sessionStorage.setItem("userData", myData)


                if (data?.data?.role !== "admin") {


                    let response = await axios.get(`${Base_Uri}getShift/${data?.data?.id}`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        },
                    })


                    let shiftData = response?.data

                    if (shiftData.status) {

                        let shift = shiftData.data

                        if (!shift) {
                            await sessionStorage.setItem("shiftStarted", false)
                        } else {
                            await sessionStorage.setItem("shiftStarted", true)
                        }
                    }

                    let dataToSend = {
                        subject: "Employee Login",
                        body: `Hey Admin the Employee has been logged in
                            Employee Id : ${userData?.employeeId}
                            Login Date : ${new Date().toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                        })}
                            Login Time : ${new Date().toLocaleTimeString()} 
                            `,
                        to: "zainshakeel65@gmail.com"
                    }

                    axios.post(`${Base_Uri}sendEmailToCustomer`, dataToSend, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json', // Include other headers as needed
                        },
                    }).then(() => {


                        dispatch(updateAuth(true))
                        dispatch(edit(data?.data))
                        setLoginLoader(false)
                        navigate("/PointOfSale")
                        alert("Login Succesfully")

                    }).catch((error) => {
                        setLoginLoader(false)
                        alert(data.message)
                    })

                } else {

                    dispatch(updateAuth(true))
                    dispatch(edit(data?.data))
                    navigate("/PointOfSale")
                    setLoginLoader(false)
                    alert("Login Succesfully")

                }


            } else {
                alert(data.message)
                setLoginLoader(false)
            }
            // Store the token and redirect user to dashboard or homepage
        } catch (error) {
            setMessage(error.response?.data?.message || "OTP verification failed");
        } finally {
            setLoading(false);
        }
    };


    return (
        <Box style={{ color: Colors.black }} >
            <Grid container md={12} sm={12} xs={12} >

                <Grid item xs={0} sm={0} md={7} >

                    <Box className="signupBackground" sx={{ display: { xs: "none", sm: "none", md: "flex" } }} >
                        <Box sx={{ display: "flex", alignItems: "center", minHeight: "100vh", justifyContent: "center" }} >

                            {/* <img src={require("../../Images/signicon.png")} style={{ height: 90 }} /> */}
                            <img src={require("../../Images/saleIcon.png")} style={{ height: 500, backgroundColor: "white", width: 500, borderRadius: 250 }} />

                        </Box>
                    </Box>
                </Grid>



                <Grid item xs={12} sm={12} md={5} >

                    <Box sx={{ backgroundColor: "#EEE", minHeight: "100%", display: "flex", justifyContent: "center" }} >

                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", minHeight: "100vh", width: { xs: "90%", sm: "80%" }, }} >

                            <Typography sx={{ fontFamily: "Poppins", fontSize: "28px", fontWeight: "bold", alignSelf: "flex-start" }} >
                                Hello Again!
                            </Typography>

                            <Typography sx={{ fontFamily: "Poppins", fontSize: "16px", fontWeight: "500", alignSelf: "flex-start" }} >
                                Welcome Back
                            </Typography>
                            {
                                !isOtpRequired ?
                                    <Box sx={{ marginTop: "20px", width: "90%", alignSelf: "flex-start" }} >


                                        <TextField
                                            onChange={(e) => setUserData({ ...userData, employeeId: e?.target?.value })}
                                            placeholder="Employee ID"
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            type='text'
                                            sx={{ border: error?.employeeId ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}

                                            InputProps={{
                                                disableUnderline: true, // <== added this
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                        <img src={require("../../Images/mail.png")} style={{ height: 25 }} />

                                                    </InputAdornment>
                                                ),
                                            }}
                                        />



                                        <TextField

                                            onChange={(e) => setUserData({ ...userData, password: e?.target?.value })}
                                            placeholder="Password"
                                            type="password"
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            sx={{ border: error.password ? "2px solid red" : "0px", width: "100%", fontSize: "10px", padding: "10px", marginTop: "15px", fontFamily: "Poppins", fontWeight: "500", backgroundColor: Colors.white, borderRadius: 10, width: "95%" }}


                                            InputProps={{
                                                disableUnderline: true, // <== added this
                                                startAdornment: (
                                                    <InputAdornment position="start">

                                                        <img src={require("../../Images/lock.png")} style={{ height: 25 }} />

                                                    </InputAdornment>
                                                ),
                                            }}
                                        />



                                        <CustomButton onClick={() => !loginLoader && handleLogin()} text={loginLoader ? <RingLoader loading={loginLoader} size="30" color={Colors.black} /> : "Login"} style={{ backgroundColor: Colors.button, margin: "15px 0px 0px 0px", width: "100%", borderRadius: 10, padding: "15px 10px 15px 10px", fontWeight: "500", alignSelf: "center" }} />

                                        <CustomButton text="Forgot Password" style={{ backgroundColor: "#EEE", marginTop: "15px", width: "100%", borderRadius: 10, padding: "15px 10px 15px 10px", fontWeight: "500", border: "0px", color: Colors.text, textTransform: "capitalize" }} />



                                    </Box> : <div>
                                        <TextField
                                            variant="standard" // <== changed this
                                            margin="normal"
                                            required
                                            fullWidth
                                            value={otp}
                                            onChange={(e) => setOtp(e.target.value)}
                                            placeholder="Enter OTP"
                                        />
                                        <CustomButton onClick={handleOtpVerification} text={loading ? <RingLoader loading={loginLoader} size="30" color={Colors.black} /> : "Verify OTP"} style={{ backgroundColor: Colors.button, margin: "15px 0px 0px 0px", width: "100%", borderRadius: 10, padding: "15px 10px 15px 10px", fontWeight: "500", alignSelf: "center" }} />
                                        {/* <button onClick={handleOtpVerification} disabled={loading}>
            {loading ? "Verifying..." : "Verify OTP"}
          </button> */}
                                    </div>
                            }
                            {message && <p>{message}</p>}
                        </Box>




                    </Box>
                </Grid>


            </Grid>
        </Box>
    )
}

export default Login
