import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Colors } from "../../Constant/Color";
import CustomButton from "../../Components/Button";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import BasicSelect from "../../Components/Dropdown";
import { DataGrid } from "@mui/x-data-grid";
import Checkbox from "../../Components/checkbox";
import { CheckBox, ProductionQuantityLimitsTwoTone } from "@mui/icons-material";
import { Navigate, useNavigate } from "react-router-dom";
import { Base_Uri } from "../../Constant/BaseUri";
import axios from "axios";
import {
  updateDamageProducts,
  updateProducts,
  updateTrashProducts,
} from "../../Redux/LoginReduces";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../Components/modal";
import TrashLedgerModal from "../../Components/TrashLedgerModal";
import TrashReturnHistoryModal from "../../Components/TrashReturnModal";
import TrashTransferModal from "../../Components/TrashTransferModal";
import { RingLoader } from "react-spinners";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  PDFDownloadLink,
  StyleSheet,
} from "@react-pdf/renderer";
const styles = StyleSheet.create({
  page: { padding: 10, fontSize: 10 },
  header: {
    flexDirection: "row",
    borderBottom: "1px solid #000",
    marginBottom: 5,
  },
  headerText: { fontWeight: "bold", padding: 5 },
  row: {
    flexDirection: "row",
    borderBottom: "1px solid #ccc",
    marginBottom: 5,
  },
  cell: { padding: 5 },
  faultyStatus: { color: "red", fontWeight: "bold" },
});
const MyDocument = ({ data }) => (
  <Document>
    <Page style={styles.page}>
      {/* Header */}
      <View style={styles.header}>
        <Text style={[styles.headerText, { width: "10%" }]}>ID</Text>
        <Text style={[styles.headerText, { width: "50%" }]}>Product Name</Text>
        {/* <Text style={[styles.headerText,{width:"10%"}]}>Category</Text>
        <Text style={[styles.headerText,{width:"10%"}]}>Model</Text> */}
        <Text style={[styles.headerText, { width: "10%" }]}>Quantity</Text>
        <Text style={[styles.headerText, { width: "10%" }]}>Cost</Text>
        {/* <Text style={[styles.headerText,{width:"10%"}]}>Price</Text> */}
        <Text style={[styles.headerText, { width: "10%" }]}>Status</Text>
      </View>

      {/* Rows */}
      {data.map((item) => (
        <View style={styles.row} key={item.id}>
          <Text style={[styles.cell, { width: "10%" }]}>{item.id}</Text>
          <Text style={[styles.cell, { width: "50%" }]}>
            {item.product_name}
          </Text>
          {/* <Text style={[styles.cell,{width:"10%"}]}>{item.category}</Text>
          <Text style={[styles.cell,{width:"10%"}]}>{item.model}</Text> */}
          <Text style={[styles.cell, { width: "10%" }]}>
            {Number(item.qty)?.toFixed(2)}
          </Text>
          <Text style={[styles.cell, { width: "10%" }]}>
            {Number(item.cost_price)?.toFixed(2)}
          </Text>
          {/* <Text style={[styles.cell,{width:"10%"}]}>{item.retail_price}</Text> */}
          <Text style={[styles.cell, styles.faultyStatus, { width: "10%" }]}>
            Trash
          </Text>
        </View>
      ))}
      <View style={styles.row} key={"total"}>
        <Text style={[styles.cell, { width: "60%" }]}>Total</Text>
        <Text style={[styles.cell, { width: "10%" }]}>
          {(
            (data.length != 0 &&
              data?.reduce(
                (total, item) => total + (Number(item?.qty) || 0),
                0
              )) ||
            0
          )?.toFixed(2)}
        </Text>
        <Text style={[styles.cell, { width: "10%" }]}>
          {(
            (data.length != 0 &&
              data?.reduce(
                (total, item) =>
                  total + (Number(item?.cost_price) * Number(item?.qty) || 0),
                0
              )) ||
            0
          )?.toFixed(2)}
        </Text>
      </View>
    </Page>
  </Document>
);
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  maxHeight: "80vh",
  overflowY: "auto",
};

function Trash({ barcodeData, setBarcodeRead }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [category, setCategory] = useState([
    "mobile",
    "electronics",
    "clothes",
    "jewelery",
  ]);

  const trashProducts = useSelector(
    (state) => state.loginReducer.trashProducts
  );

  const [addInInventoryModalVisible, setAddInInventoryModalVisible] =
    useState(false);
  const [addInDamagesModalVisible, setAddInDamagesModalVisible] =
    useState(false);
  const [quantityAddInInventory, setQuantityAddInInventory] = useState(0);
  const [quantityAddInDamages, setQuantityAddInDamages] = useState(0);
  const [loading, setLoading] = useState(false);
  const [gridKey, setGridKey] = useState(0);
  const [notes, setNotes] = useState([]);
  const [open, setOpen] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [noteContent, setNoteContent] = useState("");

  const [openLedgerModal, setOpenLedgerModal] = useState(false);
  const [openReturnHistoryModal, setOpenReturnHistoryModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const [ledgerLoading, setLedgerLoading] = useState(false);

  const [productClicked, setProductClicked] = useState([]);

  const [product, setProducts] = useState([]);
  const [searchCategory, setSearchCategory] = useState([]);
  const [searchIsFilter, setSearchIsFilter] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState({
    start: null,
    end: null,
  });
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [totalCost, setTotalCost] = useState(0);
  const [totalQun, setTotalQun] = useState(0);

  useEffect(() => {
    if (
      (Array.isArray(product) && product.length > 0) ||
      (searchIsFilter &&
        Array.isArray(searchCategory) &&
        searchCategory.length > 0)
    ) {
      let totalCost =
        Array.isArray(searchCategory) && searchCategory.length != 0
          ? searchCategory.reduce((total, e) => total + e.cost_price * e.qty, 0)
          : product.reduce((total, e) => total + e.cost_price * e.qty, 0);

      let totalQuantity =
        Array.isArray(searchCategory) && searchCategory.length != 0
          ? searchCategory.reduce((total, e) => total + e.qty, 0)
          : product.reduce((total, e) => total + e.qty, 0);

      setTotalCost(totalCost.toFixed(2));
      setTotalQun(totalQuantity);
    }
  }, [product, searchIsFilter]);

  const filterProductsByDateRange = (dateRange) => {
    setSearchIsFilter(false);

    const { start, end } = dateRange;
    if (!start || !end) return;

    const filtered = product.filter((product) => {
      const createdAtDate = new Date(product.created_at);
      // Check if the created date is within the range
      return createdAtDate >= start && createdAtDate <= end;
    });

    setSearchCategory(filtered.length !== 0 ? filtered : []);
    setSearchIsFilter(filtered.length === 0);
  };

  const handleDateRangeChange = (dates) => {
    // Expecting `dates` as an array with [start, end]
    const [start, end] = dates;
    setSelectedDateRange({ start, end });
    // setCalendarVisible(false);

    if (start && end) {
      filterProductsByDateRange({ start, end });
    }
  };
  const getAuthHeaders = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    const token = data;
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
  };

  useEffect(() => {
    fetchNotes();
  }, []);

  const fetchNotes = async () => {
    try {
      const headers = await getAuthHeaders();
      console.log(headers, "headers");
      const response = await axios.get(`${Base_Uri}notes?type=bin`, headers);
      setNotes(response.data.map((e) => ({ ...e, id: e._id })));
    } catch (error) {
      console.error("Error fetching notes:", error);
    }
  };

  const handleAdd = async () => {
    try {
      const headers = await getAuthHeaders();
      await axios.post(
        `${Base_Uri}notes`,
        { note: noteContent, type: "bin" },
        headers
      );
      fetchNotes();
      setNoteContent("");
      setIsAdding(false);
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const handleEdit = async () => {
    try {
      const headers = await getAuthHeaders();
      await axios.put(
        `${Base_Uri}notes/${currentNote.id}`,
        { note: noteContent },
        headers
      );
      fetchNotes();
      setNoteContent("");
      setCurrentNote(null);
      setIsEditing(false);
      // setOpen(false);
    } catch (error) {
      console.error("Error updating note:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const headers = await getAuthHeaders();
      await axios.delete(`${Base_Uri}notes/${id}`, headers);
      fetchNotes();
    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsAdding(false);
    setIsEditing(false);
    setNoteContent("");
    setCurrentNote(null);
  };

  const columns = [
    // { field: 'id', headerName: 'ID', width: 100 },
    { field: "note", headerName: "Content", width: 300 },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      renderCell: (params) => <>{moment(params.row.createdAt).calendar()}</>,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton
            onClick={() => {
              setIsEditing(true);
              setCurrentNote(params.row);
              setNoteContent(params.row.note);
              handleOpen();
            }}
          >
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const column = [
    {
      field: "id",
      headerName: "ID",
      width: "50",
      headerClassName: "super-app-theme--header",
    },
    {
      field: "product_name",
      headerName: "Product Name",
      headerClassName: "super-app-theme--header",
      width: "300",
    },
    {
      field: "category",
      headerName: "Category",
      headerClassName: "super-app-theme--header",
      width: "200",
    },
    {
      field: "model",
      headerName: "Model",
      headerClassName: "super-app-theme--header",
      width: "200",
    },

    {
      field: "qty",
      headerClassName: "super-app-theme--header",
      headerName: "Quantity",
      width: "150",
    },
    {
      field: "cost_price",
      headerClassName: "super-app-theme--header",
      headerName: "Cost",

      width: "150",
    },
    {
      field: "retail_price",
      headerName: "Price",
      headerClassName: "super-app-theme--header",
      width: "150",
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "super-app-theme--header",
      width: "150",
      renderCell: (cellValues) => {
        return (
          <p
            style={{
              color: Colors.red,
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            {"Trash"}
          </p>
        );
      },
    },
    // {
    //     field: "action",
    //     headerName: "Action",
    //     headerClassName: 'super-app-theme--header',

    //     width: "150",
    // renderCell: (cellValues) => {
    //     return <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "30%", backgroundColor: Colors.white }} >
    //         <button onClick={() => navigate("/Home")} style={{ padding: 0, margin: 0, backgroundColor: Colors.white, border: "0px" }} >
    //             <img src={require("../../Images/edit.png")} style={{ height: "20px", backgroundColor: Colors.white }} />
    //         </button>
    //         {/* <button style={{ padding: 0, margin: 0, border: "0px" }}  >
    //             <img src={require("../../Images/dots.png")} style={{ height: "20px" }} />
    //         </button> */}

    //     </Box>
    // }
    // },
  ];

  const [selectedCategory, setSelectedCategory] = useState("select");

  useEffect(() => {
    getTrashProducts();
  }, []);

  useEffect(() => {
    let updateId =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.map((e, i) => {
        return {
          ...e,
          id: i + 1,
          productName: e.product_name,
        };
      });

    setProducts(updateId);

    let category =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.map((e, i) => {
        return e.category;
      });

    if (category) {
      category = [...new Set(category)];
      category.unshift("All Categories");
      setCategory(category);
    }
  }, [trashProducts, trashProducts?.length]);

  const handleFilterCategory = (name) => {
    setSelectedCategory(name);

    if (name == "All Categories") {
      setSearchCategory(product);
    } else {
      setSearchCategory(
        product &&
          product.length > 0 &&
          product.filter((e, i) => {
            if (e.category == name) {
              return e;
            }
          })
      );
    }
  };

  const getTrashProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getTrashProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (data.status) {
          dispatch(updateTrashProducts(data.data));
        }
      });
  };

  const getDamageProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getDamageProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            let myData = res.data;

            let { data } = myData;

            if (data && data.length > 0) {
              let allProducts = [];

              for (var i = 0; i < data.length; i++) {
                let product = data[i];

                product.id = i + 1;
                product["productName"] = product?.product_name;

                if (product?.status == "active") {
                  product.status = "Active";
                }

                allProducts.push(product);
              }

              dispatch(updateDamageProducts(allProducts));
            }
          }
        });
    }
  };

  const getProducts = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getProducts`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let myData = res.data;

        let { data } = myData;

        if (data && data.length > 0) {
          let allProducts = [];

          for (var i = 0; i < data.length; i++) {
            let product = data[i];

            product.id = i + 1;
            product["productName"] = product?.product_name;

            if (product.status == "active") {
              product.status = "Active";
            }

            allProducts.push(product);
          }

          updateProducts(allProducts);
        }
      })
      .catch((error) => {
        console.log(error, "error");
        // setLoading(false)
      });
  };

  const getAllRowData = (event) => {};

  const handleSelectionModelChange = (selectionModel) => {
    if (selectionModel.length > 0) {
      selectionModel =
        selectionModel.length > 1 ? selectionModel[1] : selectionModel[0];

      let allProducts =
        product && product.length > 0
          ? product.map((e) => {
              if (selectionModel?.toString().includes(e.id)) {
                return {
                  ...e,
                  selected: true,
                };
              } else {
                return {
                  ...e,
                  selected: false,
                };
              }
            })
          : [];
      // setGridKey(gridKey + 1);
      setProducts(allProducts);
      dispatch(updateTrashProducts(allProducts));
    } else {
      let allProducts =
        product &&
        product?.length > 0 &&
        product.map((e, i) => {
          return {
            ...e,
            selected: false,
          };
        });
      // setGridKey(gridKey + 1);
      setProducts(allProducts);
      dispatch(updateTrashProducts(allProducts));
    }
  };

  const handleSearchProducts = (text) => {
    let search = text.target.value;

    if (!search && selectedCategory) {
      setSearchCategory(
        product &&
          product.length > 0 &&
          product.filter((e, i) => {
            return e.category == selectedCategory;
          })
      );

      return;
    }

    if (selectedCategory == "select" && !search) {
      setSearchCategory([]);
      return;
    }

    if (
      (selectedCategory == "All Categories" || selectedCategory == "select") &&
      search
    ) {
      setSearchCategory(
        product &&
          product.length > 0 &&
          product.filter((e, i) => {
            if (
              e.productName.toLowerCase().includes(search.toLowerCase()) ||
              e?.barcode?.includes(search)
            ) {
              return e;
            }
          })
      );
      return;
    }

    if (
      selectedCategory !== "All Categories" &&
      selectedCategory !== "select"
    ) {
      let searching =
        product &&
        product.length > 0 &&
        product.filter((e, i) => {
          if (
            e.category == selectedCategory &&
            (e.productName.toLowerCase().includes(search.toLowerCase()) ||
              e?.barcode?.includes(search))
          ) {
            return e;
          }
        });

      if (searching.length > 0) {
        setSearchCategory(searching);
      }
    }
  };

  const handleAddInventory = async () => {
    if (!quantityAddInInventory) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...product];

    let productDetail = myProducts.filter((e, i) => e.selected);

    if (Number(quantityAddInInventory) > Number(productDetail[0]?.DamageQty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInInventory,
        };
      });

    setLoading(true);

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addTrashProductsInInventory`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setAddInInventoryModalVisible(false);
          setQuantityAddInInventory(0);
          setSearchCategory([]);
          getTrashProducts();
          getProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleAddDamages = async () => {
    if (!quantityAddInDamages) {
      alert("Kindly enter quantity");
      return;
    }

    let myProducts = [...product];

    let productDetail = myProducts.filter((e, i) => e.selected);

    if (Number(quantityAddInDamages) > Number(productDetail[0]?.qty)) {
      alert("The given quantity exceed the available quantity");
      return;
    }

    productDetail =
      productDetail.length > 0 &&
      productDetail.map((e, i) => {
        return {
          ...e,
          DamageQty: quantityAddInDamages,
          qty: quantityAddInDamages,
        };
      });

    setLoading(true);
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .post(`${Base_Uri}addTrashProductsInDamages`, productDetail[0], {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (!data.status) {
            alert(data.message);
            setLoading(false);
            return;
          }

          alert(data.message);
          setGridKey(gridKey + 1);
          setLoading(false);
          setQuantityAddInDamages(0);
          setAddInDamagesModalVisible(false);
          setSearchCategory([]);
          getDamageProducts();
          getTrashProducts();
        })
        .catch((error) => {
          alert(error.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleOpenLedgerModal = () => {
    setLedgerLoading(true);

    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    let barcode = clickedProduct?.barcode;

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenLedgerModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenReturnHistoryModal = () => {
    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    let barcode = clickedProduct?.barcode;

    setLedgerLoading(true);

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;

        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenReturnHistoryModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  const handleOpenTransferHistoryModal = () => {
    let clickedProduct =
      trashProducts &&
      trashProducts.length > 0 &&
      trashProducts.filter((e, i) => e.selected);
    if (clickedProduct.length > 0) {
      clickedProduct = clickedProduct[0];
    }

    let barcode = clickedProduct?.barcode;

    setLedgerLoading(true);

    axios
      .get(`${Base_Uri}getTrashProductLedger/${barcode}`)
      .then((res) => {
        let data = res.data;

        if (!data.status) {
          setLedgerLoading(false);
          alert(data?.message);
          return;
        }

        let ledgerData = data?.data;
        let dataToSend = { ...clickedProduct };
        dataToSend.productLedger = ledgerData;

        // clickedProduct.productLedger = ledgerData;

        setProductClicked(dataToSend);
        setLedgerLoading(false);
        setOpenTransferModal(true);
      })
      .catch((error) => {
        alert(error?.message);
        setLedgerLoading(false);
        return;
      });
  };

  useEffect(() => {
    if (barcodeData?.length > 0) {
      let searchproduct =
        product &&
        product?.length > 0 &&
        product?.find((e) => e?.barcode == barcodeData);

      if (searchproduct) {
        setSearchCategory([searchproduct]);
        setBarcodeRead("");
      }
    }
  }, [barcodeData]);

  return ledgerLoading ? (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "70vh",
        width: "100%",
      }}
    >
      <RingLoader loading={ledgerLoading} size={100} color={Colors.black} />
    </Box>
  ) : (
    <Box
      sx={{ padding: "20px", backgroundColor: Colors.white, marginTop: "20px" }}
    >
      <Box sx={{ display: "flex" }}>
        <Grid container md={12} xs={12} sm={12}>
          <Grid item xs={12} sm={12} md={6}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Grid item xs={1} sm={1} md={1}>
                <img
                  src={require("../../Images/filter.png")}
                  height={"15px"}
                  style={{ marginRight: "5px" }}
                />
              </Grid>
              <Grid
                item
                xs={3}
                sm={2}
                md={3}
                lg={3}
                xl={2.5}
                style={{ marginRight: "5px" }}
              >
                <CustomButton
                  text="Bin"
                  icon={
                    <img
                      src={require("../../Images/cross.png")}
                      height="15px"
                    />
                  }
                  style={{
                    width: "100%",
                    padding: "5px 5px 5px 5px",
                    fontSize: {
                      xl: "12px",
                      lg: "12px",
                      md: "10px",
                      sm: "10px",
                      xs: "8px",
                    },
                    display: "flex",
                    justifyContent: "space-around",
                    marginRight: "5px",
                    alignItems: "center",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={2.5}
                style={{ marginRight: "5px" }}
              >
                <BasicSelect
                  value={selectedCategory}
                  status="Category"
                  id={category}
                  innerStyle={{
                    fontSize: "12px",
                    border: `1px solid ${Colors.border}`,
                    borderRadius: 10,
                    fontWeight: "bold",
                  }}
                  style={{ border: "0px" }}
                  onChange={(e) => handleFilterCategory(e)}
                />
              </Grid>
              <Grid
                item
                xs={10}
                sm={8}
                md={9}
                lg={8}
                xl={7}
                style={{ marginRight: "5px" }}
              >
                <CustomButton
                  style={{ marginTop: 2, marginLeft: 1 }}
                  text="Check Product By Date Range"
                  onClick={() => setCalendarVisible(true)}
                />
              </Grid>
              {calendarVisible && (
                <Box>
                  <DatePicker
                    selected={selectedDateRange.start}
                    onChange={handleDateRangeChange}
                    startDate={selectedDateRange.start}
                    endDate={selectedDateRange.end}
                    selectsRange
                    inline
                  />
                  <CustomButton
                    style={{ marginTop: 5, marginLeft: 1 }}
                    text="Clear Filter"
                    onClick={() => {
                      setSearchCategory([]);
                      setSearchIsFilter(false);
                      setCalendarVisible(false);
                      setSelectedDateRange({ start: null, end: null });
                    }}
                  />
                </Box>
              )}
              <Grid
                item
                xs={6}
                sm={4}
                md={5}
                lg={4}
                xl={3}
                style={{ marginRight: "5px" }}
              >
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    // setIsAdding(true);
                    handleOpen();
                  }}
                  sx={{ mb: 2 }}
                >
                  Show Notes
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid md={11} xs={10} sm={10}>
                <Box
                  sx={{
                    width: "100%",
                    marginTop: { xs: "10px", sm: "10px", md: "0px" },
                    border: `1px solid ${Colors.button}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "5px 10px 5px 10px",
                    margin: 0,
                  }}
                >
                  <TextField
                    variant="standard" // <== changed this
                    margin="normal"
                    required
                    fullWidth
                    InputProps={{
                      // startAdornment: <AccountCircle />, // <== adjusted this
                      disableUnderline: true,
                      fontSize: "12px",
                      // <== added this
                      // fontSize: "2px"
                    }}
                    onChange={handleSearchProducts}
                    placeholder="Search Name ,Id , Phone & Bar Code"
                    sx={{
                      width: "90%",
                      fontSize: "12px",
                      padding: 0,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      margin: 0,
                    }}
                  />
                  <img
                    src={require("../../Images/search.png")}
                    style={{ height: "20px" }}
                  />
                </Box>
              </Grid>

              <Box
                sx={{
                  margin: 0,
                  height: "35px",
                  width: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: Colors.button,
                }}
              >
                <img
                  src={require("../../Images/qrcode.png")}
                  style={{ height: "20px" }}
                />
              </Box>
            </Box>
          </Grid>
          <div>
            {/* <PDFViewer width="100%" height="600px">
              <MyDocument
                data={
                  searchIsFilter
                    ? []
                    : searchProducts.length > 0
                    ? searchProducts
                    : damageProducts && damageProducts?.length > 0
                    ? damageProducts
                    : []
                }
              />
            </PDFViewer> */}
            <PDFDownloadLink
              document={
                <MyDocument
                  data={
                    searchIsFilter
                      ? []
                      : searchCategory.length > 0
                      ? searchCategory
                      : product && product?.length > 0
                      ? product
                      : []
                  }
                />
              }
              fileName="report.pdf"
            >
              {({ loading }) => (
                <Button variant="contained">
                  {loading ? "Loading document..." : "Download PDF"}
                </Button>
              )}
            </PDFDownloadLink>
          </div>
          
          {product &&
            product?.length > 0 &&
            product.filter((e, i) => e.selected).length == 1 && (
              <Box sx={{ display: "flex", mt: 1, ml: 1 }}>
                <Grid container md={12} xs={12} sm={12}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "80vw",
                      }}
                    >
                      <Grid
                        item
                        xs={3}
                        sm={2}
                        md={3}
                        lg={3}
                        xl={2.5}
                        style={{ marginRight: "5px" }}
                      >
                        <CustomButton
                          onClick={() => setAddInInventoryModalVisible(true)}
                          text={"Add In Inventory"}
                          //   onClick={() => setAddInInventoryModalVisible(true)}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            color: Colors.green,
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px" }}
                      >
                        <CustomButton
                          onClick={() => setAddInDamagesModalVisible(true)}
                          text={"Add In Damages"}
                          //   onClick={() => setAddInTrashModalVisible(true)}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.red,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>
                    </Box>

                    <Box sx={{ display: "flex", width: "100%" }}>
                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Ledger"}
                          onClick={() => handleOpenLedgerModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.green,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Return History"}
                          onClick={() => handleOpenReturnHistoryModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.button,
                            color: Colors.white,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={3}
                        md={4}
                        lg={5}
                        xl={2.5}
                        style={{ marginRight: "5px", marginTop: "10px" }}
                      >
                        <CustomButton
                          text={"Show Transfer History"}
                          onClick={() => handleOpenTransferHistoryModal()}
                          style={{
                            width: "100%",
                            padding: "5px 5px 5px 5px",
                            fontSize: {
                              xl: "12px",
                              lg: "12px",
                              md: "10px",
                              sm: "10px",
                              xs: "8px",
                            },
                            display: "flex",
                            justifyContent: "space-around",
                            marginRight: "5px",
                            alignItems: "center",
                            backgroundColor: Colors.white,
                            color: Colors.green,
                            border: `1px solid green`,
                          }}
                        />
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
        </Grid>
      </Box>
      <Box sx={{ height: "500px", backgroundColor: "white", marginTop: 5 }}>
        {
          <DataGrid
            key={gridKey}
            sx={{
              "& .MuiDataGrid-cell": {
                fontFamily: "Poppins",
                fontSize: "12px",
                fontWeight: "600",
                color: "#000",
              },
              "& .MuiDataGrid-colCell, & .MuiDataGrid-colCellTitle": {
                padding: "0px", // Adjust this value as needed,
                fontFamily: "Poppins",
              },
              "& .MuiDataGrid-columnHeaderCheckbox": {
                backgroundColor: Colors.black,
                color: "white",
              },
              "& .css-1vjf6hs-MuiDataGrid-root .css-ptiqhd-MuiSvgIcon-root": {
                fill: Colors.white,
              },

              "& .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root": {
                color: "gray",
              },
              "& .MuiDataGrid-columnHeader": {
                fontFamily: "Poppins",
                fontWeight: "bold",
              },
            }}
            onColumnHeaderClick={(e) => getAllRowData(e)}
            checkboxSelection
            onRowSelectionModelChange={handleSelectionModelChange}
            rowSelectionModel={
              product && product.length > 0
                ? product.filter((e, i) => e?.selected)?.map((row) => row.id)
                : []
            }
            rows={
              searchIsFilter
                ? []
                : searchCategory.length > 0
                ? searchCategory
                : product && product?.length > 0
                ? product
                : []
            }
            columns={column}
          />
        }
      </Box>

      {addInInventoryModalVisible && (
        <CustomModal
          open={addInInventoryModalVisible}
          closeModal={() => setAddInInventoryModalVisible(false)}
          heading={"Add In Inventory"}
          credit={true}
          loading={loading}
          placeholder={"Enter Quantity"}
          onChange={(e) => setQuantityAddInInventory(e.target.value)}
          confirm={() => handleAddInventory()}
          text={"Are your sure you want to add this product in inventory."}
        />
      )}

      {addInDamagesModalVisible && (
        <CustomModal
          open={addInDamagesModalVisible}
          closeModal={() => setAddInDamagesModalVisible(false)}
          heading={"Add In Damages"}
          credit={true}
          loading={loading}
          placeholder={"Enter Quantity"}
          onChange={(e) => setQuantityAddInDamages(e.target.value)}
          confirm={() => handleAddDamages()}
          text={"Are your sure you want to add this product in Damage."}
        />
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" gutterBottom>
            {isAdding ? "Add Note" : isEditing ? "Edit Note" : "Notes List"}
          </Typography>
          {isAdding || isEditing ? (
            <>
              <TextField
                fullWidth
                variant="outlined"
                label="Content"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                sx={{ mb: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={isAdding ? handleAdd : handleEdit}
                sx={{ mb: 2 }}
              >
                {isAdding ? "Add Note" : "Update Note"}
              </Button>
            </>
          ) : (
            <div style={{ height: 400, width: "100%" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsAdding(true)}
                sx={{ mb: 2 }}
              >
                Add Note
              </Button>
              <DataGrid rows={notes} columns={columns} pageSize={5} />
            </div>
          )}
        </Box>
      </Modal>

      {openLedgerModal && (
        <TrashLedgerModal
          data={productClicked}
          open={openLedgerModal}
          closeModal={() => setOpenLedgerModal(false)}
        />
      )}
      {openReturnHistoryModal && (
        <TrashReturnHistoryModal
          data={productClicked}
          open={openReturnHistoryModal}
          closeModal={() => setOpenReturnHistoryModal(false)}
        />
      )}
      {openTransferModal && (
        <TrashTransferModal
          data={productClicked}
          open={openTransferModal}
          closeModal={() => setOpenTransferModal(false)}
        />
      )}
    </Box>
  );
}

export default Trash;
