import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { Form, Input, Modal, Spin } from "antd";
import { BiLeftArrow } from "react-icons/bi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";

const ProfilePage = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isEnabled, setIsEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const userData = useSelector((state) => state.loginReducer.updatedState);
    const navigate = useNavigate();

    useEffect(() => {
        if (userData) {
            console.log(userData?.two_step,"userData?.two_step")
            setUserEmail(userData?.emailAddress || "");
            setIsEnabled(userData?.two_step);
        }
    }, [userData]);


    const requestOTP = async () => {
        let userToken = JSON.parse(sessionStorage.getItem("userData"))
        setLoading(true);
        try {
            const response = await axios.post(`${Base_Uri}two-step/enable`, {
                email: userEmail,
            }, { headers: { Authorization: `Bearer ${userToken}` } });
            alert(response.data.message);
            setOtpSent(true);
        } catch (error) {
            console.error("Error requesting OTP:", error);
            alert("Failed to send OTP");
        } finally {
            setLoading(false);
        }
    };

    const verifyOTP = async () => {
        let userToken = JSON.parse(sessionStorage.getItem("userData"))
        setLoading(true);
        try {
            const response = await axios.post(`${Base_Uri}two-step/verify`, {
                otp,
            }, { headers: { Authorization: `Bearer ${userToken}` } });
            alert(response.data.message);
            setIsEnabled(true);
            setOtpSent(false);
            setIsModalVisible(false);
        } catch (error) {
            console.error("Error verifying OTP:", error);
            alert("Invalid or expired OTP");
        } finally {
            setLoading(false);
        }
    };

    const disableTwoStep = async () => {
        setLoading(true);
        let userToken = JSON.parse(sessionStorage.getItem("userData"))
        try {
            const response = await axios.post(`${Base_Uri}two-step/disable`, {
                email: userEmail,
            }, { headers: { Authorization: `Bearer ${userToken}` } });
            alert(response.data.message);
            setIsEnabled(false);
        } catch (error) {
            console.error("Error disabling two-step:", error);
            alert("Failed to disable Two-Step Verification");
        } finally {
            setLoading(false);
        }
    };

    const styles = {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            backgroundColor: "#f4f4f4",
            fontFamily: "Arial, sans-serif",
        },
        card: {
            backgroundColor: "#fff",
            borderRadius: "10px",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
            maxWidth: "400px",
            width: "100%",
            position: "relative",
        },
        image: {
            width: "150px",
            height: "150px",
            borderRadius: "50%",
            objectFit: "cover",
            marginBottom: "15px",
        },
        name: {
            fontSize: "1.5rem",
            margin: "10px 0",
        },
        contact: {
            fontSize: "0.9rem",
            color: "#333",
        },
    };

    return (
        <>
            <div style={styles.container}>
                <div style={styles.card}>
                    <div style={{ position: "absolute", top: 10, left: 10 }}>
                        <Button
                            onClick={() => navigate(-1)}
                            startIcon={<BiLeftArrow />}
                        >
                            Back
                        </Button>
                    </div>
                    <img
                        src={`https://placehold.co/600x400/000000/FFFFFF/png?text=${userData?.fullName[0] + userData?.fullName[1]
                            }`}
                        alt="Profile"
                        style={styles.image}
                    />
                    <h1 style={styles.name}>
                        {userData?.fullName} ({userData?.employeeId})
                    </h1>
                    <div style={styles.contact}>
                        <p>Email: {userData?.emailAddress}</p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                            style={{ fontFamily: "Poppins", fontWeight: "600", color: "black" }}
                        >
                            Two-Step Verification
                        </div>
                        {isEnabled ? (
                            <Button
                                disabled={loading}
                                onClick={disableTwoStep}
                                variant="contained"
                            >
                                {loading ? <Spin /> : "Disable"}
                            </Button>
                        ) : (
                            <Button
                                onClick={() => setIsModalVisible(true)}
                                variant="contained"
                            >
                                Enable
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                title="Two-Step Verification"
                footer={null}
            >
                {!otpSent ? (
                    <Form onFinish={requestOTP}>
                        <Form.Item
                            rules={[{ required: true, message: "Email is required!" }]}
                        >
                            <Input
                                value={userEmail}
                                onChange={(e) => setUserEmail(e.target.value)}
                                placeholder="Enter your email"
                                style={{ height: 50 }}
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{ width: "100%" }}
                            loading={loading}
                        >
                            Send OTP
                        </Button>
                    </Form>
                ) : (
                    <Form onFinish={verifyOTP}>
                        <Form.Item
                            rules={[{ required: true, message: "OTP is required!" }]}
                        >
                            <Input
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                                style={{ height: 50 }}
                            />
                        </Form.Item>
                        <Button
                            htmlType="submit"
                            type="primary"
                            style={{ width: "100%" }}
                            loading={loading}
                        >
                            Verify OTP
                        </Button>
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default ProfilePage;
