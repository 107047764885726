import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';
import { paymentMethod } from '../Redux/LoginReduces';
import DownloadIcon from "@mui/icons-material/Download";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  PDFDownloadLink,
  Image,
  Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../fonts/Poppins-Medium.ttf";
import MyFontBold from "../fonts/Poppins-Bold.ttf";
import { Base_Uri, Image_Uri } from '../Constant/BaseUri';
import axios from 'axios';
import CustomButton from './Button';
import { RingLoader } from 'react-spinners';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: "80vh",
  overflow: "auto",
  width: { lg: "70%", md: "90%", sm: "90%", xs: "100%" },
  borderRadius: "10px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
};




function TransactionDetailsModal({ ledgerData, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, specialTextStyle, buttonText, specialText }) {


  const [imagePath, setImagePath] = React.useState("")

  Font.register({
    family: "MyFont",
    fonts: [
      { src: MyFontBold, fontWeight: "bold" },
      { src: MyFontRegular, fontWeight: "normal" },
    ],
  });

  const vat = useSelector((state) => state.loginReducer.vat)
  const [pdfDownloadView, setPdfDownloadView] = React.useState(false);
  const [loading, setLoading] = React.useState(false)


  let otherDetails = { ...ledgerData }

  otherDetails.productDetails = otherDetails?.productDetails?.map((e, i) => {
    return {
      ...e,
      rate: otherDetails?.customerDetails?.priceLevel[0]?.id == 1 ? e.trade_price :
        otherDetails?.customerDetails?.priceLevel[0]?.id == 2 ? e?.warehouse_price : e?.retail_price
    }

  })


  const handleOpenPdf = async () => {

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;



    try {
      let response = await axios.get(`${Base_Uri}getsingleinvoice/${otherDetails?.invoiceNumber}/${otherDetails?.invoiceType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })



      let data = response?.data

      if (data?.status) {

        otherDetails.barcodeImagePath = data?.data?.barcodeImagePath


        setImagePath(data?.data?.barcodeImagePath)
        setPdfDownloadView(true)

        return
      }


      setPdfDownloadView(true)

    } catch (err) {
      setPdfDownloadView(true)
    }

  }




  const emailInvoice = async () => {



    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    try {


      let response = await axios.get(`${Base_Uri}getsingleinvoice/${otherDetails?.invoiceNumber}/${otherDetails?.invoiceType}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })

      let data = response?.data

      if (data?.status) {

        let invoice = data.data

        let dataToSend = {
          printInvoiceData: invoice,
        };

        setLoading(true);

        axios
          .post(`${Base_Uri}pdfSendToCustomer`, dataToSend, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            let data = res.data;

            setLoading(false);

            if (data && data.status) {
              alert("Invoice Send Succesfully");
            }

          })
          .catch((error) => {
            console.log(error, "errorrrr");
            setLoading(false);
            alert("There is a server error in email sending");
          });



        return
      }


    } catch (err) {

      alert("Internal Server Error")
      setLoading(false)

    }


  };


  const Header = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            {otherDetails?.vatAmount && (
              <View>
                <View>
                  <Image
                    style={{ height: 50, borderRadius: 10 }}
                    source={{
                      uri: `${Image_Uri}companyImage/${vat?.companyLogo}`,
                    }}
                  />

                  <Text
                    style={{
                      color: "black",
                      fontSize: "14px",
                      marginTop: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "bold",
                    }}
                  >
                    {vat?.companyName}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    {vat?.companyAddress}
                  </Text>

                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >{vat?.city}</Text>

                  <Text

                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >{vat?.postCode}</Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      marginTop: "3px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Tel: {vat?.mobileNumber}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Email: {vat?.companyEmail}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    VAT No: {vat?.vatNumber}
                  </Text>
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      fontWeight: "normal",
                    }}
                  >
                    Company No: {vat?.companyNumber}
                  </Text>

                </View>
              </View>
            )}
          </View>


          <View>

            <View style={{ alignItems: "center" }}>
              <Image
                src={`${Image_Uri}products/${imagePath}`}
                style={{
                  height: "35px",
                  width: "150px",
                }}
              />
            </View>
            <View style={{ alignItems: "center", marginTop: "10px" }}>
              <Text
                style={{
                  color: "black",
                  fontSize: "16px",
                  fontFamily: "MyFont",
                  // marginTop:"10px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {otherDetails?.vatAmount ? "Invoice" : "Quotation"}
              </Text>
            </View>

            <View>
              {otherDetails?.vatAmount && <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  marginTop: "10px",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Invoice no: {otherDetails?.invoiceNumber}
              </Text>}

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  // marginTop:"10px",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Date:{" "}
                {new Date(
                  otherDetails?.date
                ).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })}
              </Text>

              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                Time:{" "}
                {new Date(
                  otherDetails?.date
                ).toLocaleTimeString()}
              </Text>

              {otherDetails?.vatAmount && (otherDetails?.returnInvoiceRef && otherDetails?.returnInvoiceRef?.length > 0) && <View style={{ marginTop: "10px" }} >

                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Return Inv Ref.:

                  {otherDetails?.returnInvoiceRef?.map((e, i) => {
                    return (

                      <Text
                        style={{
                          color: "black",
                          fontSize: "8px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        {e},
                      </Text>
                    )
                  })}


                </Text>





              </View>}


              <View style={{ marginTop: "10px" }}>
                <Text
                  style={{
                    color: "black",
                    fontSize: "12px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }}
                >
                  Bill to:
                </Text>

                <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Account No: {otherDetails?.customerDetails?.accountNo}
                </Text>

                {otherDetails?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Business Name: {otherDetails?.customerDetails?.businessName}
                </Text>}

                {otherDetails?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    // marginTop:"10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Email: {otherDetails?.customerDetails?.email}
                </Text>}

                {otherDetails?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    marginTop: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Payment Method:{" "}
                  {Array?.isArray(otherDetails?.paymentMethod)
                    ? otherDetails?.paymentMethod
                      ?.map((e, i) => e)
                      .join(",")
                    : otherDetails?.paymentMethod}
                </Text>}

                {otherDetails?.vatAmount && <Text
                  style={{
                    color: "black",
                    fontSize: "10px",
                    fontFamily: "MyFont",
                    marginTop: "10px",
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  Status: {otherDetails?.status}
                </Text>}


                {/* {otherDetails?.creditDays && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Credit Days: {otherDetails?.creditDays}
                  </Text>
                )} */}

                {/* {otherDetails?.referenceId && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Reference Id: {otherDetails?.referenceId}
                  </Text>
                )} */}

                {otherDetails?.transactionId && (
                  <Text
                    style={{
                      color: "black",
                      fontSize: "10px",
                      fontFamily: "MyFont",
                      // marginTop: "10px",
                      fontWeight: "normal",
                      textAlign: "center",
                    }}
                  >
                    Transaction Id: {otherDetails?.transactionId}
                  </Text>
                )}

                {otherDetails?.vatAmount && Array?.isArray(otherDetails?.paymentMethod) &&
                  otherDetails?.paymentMethod?.some(
                    (e) => e?.toLowerCase() == "cheque"
                  ) && (
                    <View>
                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque No: {otherDetails?.cheque_no}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Bank Name: {otherDetails?.bank_name}
                      </Text>

                      <Text
                        style={{
                          color: "black",
                          fontSize: "10px",
                          fontFamily: "MyFont",
                          // marginTop:"10px",
                          fontWeight: "normal",
                          textAlign: "center",
                        }}
                      >
                        Cheque Date:{" "}
                        {new Date(
                          otherDetails.clear_date
                        ).toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })}
                      </Text>
                    </View>
                  )}
              </View>
            </View>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          {/* <View style={{ flex: 0.5 }}>
                <Text
                  style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                >
                  Item
                </Text>
              </View> */}

          {/* <View style={{ flex: 1 }}>
                <Text
                  style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
                >
                  Barcode
                </Text>
              </View> */}

          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Item
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Barcode
            </Text>
          </View>


          <View style={{ flex: 5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Product Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Price
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Disc
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Amount
            </Text>
          </View>
        </View>
      </View>
    );
  };


  const MyDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <Header />
        {/* Data Rows */}
        <View>
          {otherDetails &&
            otherDetails?.productDetails &&
            otherDetails?.productDetails?.length > 0 &&
            otherDetails?.productDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderColor: "#000",
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  padding: "3px 10px",
                }}
              >


                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {index + 1}
                  </Text>
                </View>

                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.barcode}
                  </Text>
                </View>

                <View style={{ flex: 5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.productName}
                  </Text>
                  {item?.IMEI && item?.IMEI?.length > 0 &&
                      item?.IMEI?.map((e, i) => {
                        return (
                          <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                            {e}
                          </Text>
                        );
                      })}
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"} {item?.DamageQty ? item?.DamageQty : item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}

                    {item?.discountPrice > (otherDetails?.customerDetails?.priceLevel[0]?.id ==
                      1 ? item?.trade_price : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                        2 ? item?.warehouse_price : item?.retail_price) ? item?.discountPrice : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                          1
                      ? Number(item.trade_price).toFixed(2)
                      : otherDetails?.customerDetails?.priceLevel[0]
                        ?.id == 2
                        ? Number(item.warehouse_price).toFixed(2)
                        : Number(item.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}

                    {(item.discountPrice < (otherDetails?.customerDetails?.priceLevel[0]?.id ==
                      1 ? item?.trade_price : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                        2 ? item?.warehouse_price : item?.retail_price))
                      ? Number(
                        (otherDetails?.customerDetails?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : otherDetails?.customerDetails
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) - item.discountPrice
                      ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £
                    {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"} {item.discountPrice
                      ? Number(item.discountPrice * item.saleQty).toFixed(2)
                      : Number(
                        (otherDetails?.customerDetails?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : otherDetails?.customerDetails
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) * (item?.DamageQty ? item?.DamageQty : item?.saleQty)
                      ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          {otherDetails &&
            otherDetails?.returnProductDetails &&
            otherDetails?.returnProductDetails?.length > 0 &&
            otherDetails?.returnProductDetails?.map((item, index) => (
              <View
                key={index}
                style={{
                  flexDirection: "row",
                  borderBottomWidth: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: "#000",
                  padding: "3px 10px",
                }}
              >

                <View style={{ flex: 0.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {otherDetails?.productDetails?.length + (index + 1)}
                  </Text>
                </View>


                <View style={{ flex: 1.5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.barcode}
                  </Text>
                </View>

                <View style={{ flex: 5 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    {item.productName}
                  </Text>
                  {item?.IMEI && item?.IMEI?.length > 0 &&
                      item?.IMEI?.map((e, i) => {
                        return (
                          <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                            {e}
                          </Text>
                        );
                      })}
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    -{item?.DamageQty ? item?.DamageQty : item?.saleQty}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    -{otherDetails?.customerDetails?.priceLevel[0]?.id ==
                      1
                      ? Number(item.trade_price).toFixed(2)
                      : otherDetails?.customerDetails?.priceLevel[0]
                        ?.id == 2
                        ? Number(item.warehouse_price).toFixed(2)
                        : Number(item.retail_price).toFixed(2)}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                    £
                    {item.discountPrice
                      ? Number(
                        (otherDetails?.customerDetails?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : otherDetails?.customerDetails
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) - item.discountPrice
                      ).toFixed(2)
                      : 0}
                  </Text>
                </View>

                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 8,
                      color: "black",
                      fontWeight: "bold",
                    }}
                  >
                    £
                    {item.discountPrice
                      ? Number(
                        item.discountPrice *
                        (item?.DamageQty ? item?.DamageQty : item.saleQty)
                      ).toFixed(2)
                      : Number(
                        (otherDetails?.customerDetails?.priceLevel[0]
                          ?.id == 1
                          ? item.trade_price
                          : otherDetails?.customerDetails
                            ?.priceLevel[0]?.id == 2
                            ? item.warehouse_price
                            : item.retail_price) *
                        (item?.DamageQty ? item?.DamageQty : item.saleQty)
                      ).toFixed(2)}
                  </Text>
                </View>
              </View>
            ))}

          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              padding: "5px 10px",
              borderLeftWidth: 1,
              borderRightWidth: 1,
            }}
          >
            {/* <View style={{ flex: 0.5, }}>
                  <Text
                    style={{
                      fontFamily: "MyFont",
                      fontSize: 10,
                      fontWeight: "bold",
                    }}
                  >
                    {otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation"  && "-"}{otherDetails?.totalItems}
                  </Text>
                </View> */}

            <View style={{ flex: 7, alignItems: "center", }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,

                  fontWeight: "bold",
                }}
              >
                Subtotal
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}{otherDetails?.totalQty}
              </Text>
            </View>

            <View style={{ flex: 1 }}>

              price

            </View>

            <View style={{ flex: 1, }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £{Number(otherDetails?.discount).toFixed(2)}
              </Text>
            </View>

            <View style={{ flex: 1, }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                £ {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}{Number(otherDetails?.total - otherDetails?.discount).toFixed(2)}
              </Text>
            </View>
          </View>


          {otherDetails?.vatAmount && <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderColor: "#000",
              borderTopWidth: 1,
              padding: "5px 10px",
            }}
          >
            <View style={{ flex: 10, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  fontWeight: "bold",
                }}
              >
                Vat
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,

                  fontWeight: "bold",
                }}
              >
                £ {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}{Number(otherDetails?.vatAmount).toFixed(2)}
              </Text>
            </View>


          </View>}


          <View
            // key={index}
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              borderWidth: 1,
              padding: "5px 10px",
              // borderBottomWidth:1,
              backgroundColor: "#DDD",
              opacity: 0.7
            }}
          >
            <View style={{ flex: 10, alignItems: "center" }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 12,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 10,
                  color: "red",
                  fontWeight: "bold",
                }}
              >
                £ {(otherDetails?.transactionType?.toLowerCase() == "return invoice" || otherDetails?.transactionType?.toLowerCase() == "return quotation") && "-"}{Number(otherDetails?.subtotal).toFixed(2)}
              </Text>
            </View>


          </View>


        </View>
      </Page>
    </Document>
  );

  return (
    <div  >
      <Modal
        open={open}
        // onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {pdfDownloadView ? (
          <Box
            style={{
              wodth: "100%",
              height: "100%",
              // border: "1px solid red",
              display: "flex",
              justifyContent: "center",
            }}
          >

            <PDFViewer width={850} height={700} style={{ marginTop: 20 }}>
              <MyDocument />
            </PDFViewer>
            <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />

          </Box>
        ) :
          <Box sx={[style, { containerStyle }]}>
            <Grid xs={12} container  >
              <Button
                onClick={() => handleOpenPdf()}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  // marginLeft: "10px",
                  width: "95%",
                  marginTop: "10px",
                  backgroundColor: Colors.white,
                }}
              >
                <DownloadIcon />
              </Button>




              <Grid xs={8} sm={6} >


                {otherDetails.customerDetails && otherDetails?.customerDetails?.accountNo && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                  Account Number: {otherDetails?.customerDetails?.accountNo}
                </Typography>}

                {otherDetails.customerDetails && otherDetails?.customerDetails?.businessName && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                  Business Name: {otherDetails?.customerDetails?.businessName}
                </Typography>}

                {otherDetails?.customerDetails && otherDetails?.customerDetails?.email && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "left" }} >
                  Customer Email: {otherDetails?.customerDetails?.email}
                </Typography>}

              </Grid>


              <Grid xs={4} sm={6} >

                {otherDetails?.invoiceNumber && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Invoice no: {otherDetails.invoiceNumber}

                </Typography>}


                {otherDetails?.date && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Date: {new Date(otherDetails.date).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                  })

                  }


                </Typography>}
                {otherDetails?.date && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Time : {new Date(otherDetails.date).toLocaleTimeString()}

                </Typography>}

                {(otherDetails.returnInvoiceRef && otherDetails?.returnInvoiceRef?.length > 0) && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Invoice Ref #: {Array?.isArray(otherDetails?.returnInvoiceRef) ? otherDetails?.returnInvoiceRef?.map((e) => e + ",") : otherDetails?.returnInvoiceRef}


                </Typography>}




                {(otherDetails?.paymentMethod) && <Typography sx={{ color: Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e) => e.toLowerCase() == "credit") ? Colors.red : Colors.green, fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Payment Method : {Array.isArray(otherDetails.paymentMethod) ? otherDetails?.paymentMethod?.map((e, i) => e).join(",") : otherDetails?.paymentMethod}


                </Typography>}




                {otherDetails?.status && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Status : {otherDetails?.status}


                </Typography>}

                {otherDetails?.reason && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right", color: Colors.red, fontWeight: "bold" }} >

                  Reason : {otherDetails?.reason}


                </Typography>}


                {(Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "cheque") && !otherDetails.saleReturnDate) && <Box> <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Cheque No : {otherDetails?.cheque_no}


                </Typography>

                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                    Bank Name : {otherDetails?.bank_name}


                  </Typography>

                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                    Cheque Date : {new Date(otherDetails?.clear_date)?.toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    })}


                  </Typography>

                </Box>



                }




                {Array.isArray(otherDetails?.paymentMethod) && otherDetails?.paymentMethod?.some((e, i) => e.toLowerCase() == "card") && <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px" }, textAlign: "right" }} >

                  Transaction Id : {otherDetails?.transactionId}

                </Typography>}

              </Grid>



              <Button onClick={() => emailInvoice()} sx={{
                backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "10px", width: "150px", fontFamily: "Poppins", '&:hover': {
                  color: Colors.button, // Set your desired hover color
                }, ...buttonStyle2
              }} >
                {loading ? <RingLoader size={30} loading={loading} /> : buttonText ? buttonText : "Email"}
              </Button>

              <Grid container xs={12} sx={{ marginTop: 2 }} >
                <Grid xs={6} >

                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    Description

                  </Typography>

                  {otherDetails?.productDetails?.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        {e.product_name}

                      </Typography>

                    )

                  })}

                  {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails?.returnProductDetails.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        {e.product_name}

                      </Typography>

                    )

                  })}

                </Grid>

                <Grid xs={1} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    Quantity

                  </Typography>

                  {otherDetails?.productDetails?.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        {e.DamageQty ? e.DamageQty : e.saleQty}

                      </Typography>

                    )

                  })}

                  {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {



                    return (



                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        -{e.DamageQty ? e.DamageQty : e.saleQty}

                      </Typography>

                    )

                  })}

                </Grid>

                <Grid xs={1} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    Price

                  </Typography>



                  {otherDetails?.productDetails?.map((e, i) => {




                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £
                        {otherDetails?.saleReturnDate && "-"}
                        {e?.discountPrice > (otherDetails?.customerDetails?.priceLevel[0]?.id ==
                          1 ? e?.trade_price : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                            2 ? e?.warehouse_price : e?.retail_price) ? e?.discountPrice : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                              1
                          ? Number(e.trade_price).toFixed(2)
                          : otherDetails?.customerDetails?.priceLevel[0]
                            ?.id == 2
                            ? Number(e.warehouse_price).toFixed(2)
                            : Number(e.retail_price).toFixed(2)}
                      </Typography>

                    )

                  })}


                  {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £-
                        {otherDetails?.customerDetails?.accountManager == "A" ? Number(e?.trade_price).toFixed(2) : otherDetails?.customerDetails?.accountManager == "B" ? Number(e?.warehouse_price).toFixed(2) : Number(e?.retail_price).toFixed(2)}

                      </Typography>

                    )

                  })}

                </Grid>

                <Grid xs={1} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    Discount

                  </Typography>

                  {otherDetails?.productDetails?.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £
                        {(e.discountPrice < (otherDetails?.customerDetails?.priceLevel[0]?.id ==
                          1 ? e?.trade_price : otherDetails?.customerDetails?.priceLevel[0]?.id ==
                            2 ? e?.warehouse_price : e?.retail_price))
                          ? Number(
                            (otherDetails?.customerDetails?.priceLevel[0]
                              ?.id == 1
                              ? e.trade_price
                              : otherDetails?.customerDetails
                                ?.priceLevel[0]?.id == 2
                                ? e.warehouse_price
                                : e.retail_price) - e.discountPrice
                          ).toFixed(2)
                          : 0}
                      </Typography>

                    )

                  })}

                  {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £{Number(e?.discountPrice ? (otherDetails?.customerDetails?.accountManager == "A" ? e?.trade_price : otherDetails?.customerDetails?.accountManager == "B" ? e?.warehouse_price : e?.retail_price) - e?.discountPrice : 0).toFixed(2)}

                      </Typography>

                    )

                  })}

                </Grid>


                <Grid xs={2} >


                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderBottom: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    Amount

                  </Typography>

                  {otherDetails?.productDetails?.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £{Number(e?.discountPrice ? e?.discountPrice * (e.DamageQty ? e.DamageQty : e.saleQty).toFixed(2) : (otherDetails?.customerDetails?.accountManager == "A" ? e?.trade_price : otherDetails?.customerDetails?.accountManager == "B" ? e?.warehouse_price : e?.retail_price) * (e.DamageQty ? e.DamageQty : e.saleQty)).toFixed(2)}

                      </Typography>

                    )

                  })}


                  {otherDetails?.returnProductDetails && otherDetails?.returnProductDetails?.length > 0 && otherDetails.returnProductDetails.map((e, i) => {

                    return (


                      <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "14px" }, fontWeight: "400" }} >

                        £-{Number(e?.discountPrice ? e?.discountPrice * (e.DamageQty ? e.DamageQty : e.saleQty).toFixed(2) : (otherDetails?.customerDetails?.accountManager == "A" ? e?.trade_price : otherDetails?.customerDetails?.accountManager == "B" ? e?.warehouse_price : e?.retail_price) * (e.DamageQty ? e.DamageQty : e.saleQty)).toFixed(2)}

                      </Typography>

                    )

                  })}

                </Grid>




              </Grid>




              <Grid container xs={12} sx={{ marginTop: 2 }} >

                <Grid xs={6} >



                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    {otherDetails?.vatAmount ? "Subtotal" : "Total"}

                  </Typography>


                </Grid>

                <Grid xs={1} >



                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    {otherDetails?.totalQty}

                  </Typography>


                </Grid>

                <Grid xs={1} >



                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >


                  </Typography>


                </Grid>




                <Grid xs={1} >



                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    £{otherDetails.discount ? Number(otherDetails.discount).toFixed(2) : 0}

                  </Typography>


                </Grid>

                <Grid xs={2} >



                  <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", borderTop: `1px solid ${Colors.border}`, marginBottom: 2 }} >

                    £{Number(otherDetails?.vatAmount ? (otherDetails?.total - (otherDetails?.discount || 0)) : (otherDetails.subtotal - (otherDetails?.discount || 0))).toFixed(2)}

                  </Typography>


                </Grid>



              </Grid>


              {otherDetails.vatAmount ?
                <Grid container xs={12} >

                  <Grid item xs={9} >


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2 }} >

                      VAT

                    </Typography>


                  </Grid>


                  <Grid item xs={2} >


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2 }} >

                      £{Number(otherDetails?.vatAmount).toFixed(2)}

                    </Typography>


                  </Grid>

                </Grid> : ""}



              {otherDetails?.vatAmount ?
                <Grid container xs={12} >

                  <Grid item xs={9} sx={{ borderTop: `1px solid ${Colors.border}` }} >


                    <Typography sx={{ textAlign: "center", fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2, color: Colors.red }} >

                      Total

                    </Typography>


                  </Grid>


                  <Grid item xs={3} sx={{ borderTop: `1px solid ${Colors.border}` }} >


                    <Typography sx={{ fontFamily: "Poppins", fontSize: { xs: "10px", sm: "12px", md: "14px", lg: "16px" }, fontWeight: "bold", marginBottom: 2, color: Colors.red }} >

                      £{Number(otherDetails?.subtotal).toFixed(2)}

                    </Typography>


                  </Grid>

                </Grid> : ""}




            </Grid>

            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", ...buttonContainerStyle }} >

              <Button onClick={closeModal} sx={{
                backgroundColor: "#5DC002", color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "200px", fontSize: "14px", textTransform: "capitalize", mt: 1, fontFamily: "Poppins",
                '&:hover': {
                  color: '#5DC002',
                },
                ...buttonStyle
              }} >
                Close
              </Button>
            </Box>
          </Box>}
      </Modal>
    </div>
  );
}

export default React.forwardRef(TransactionDetailsModal)






