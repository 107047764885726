import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Colors } from "../../Constant/Color";
import CustomButton from "../../Components/Button";
import DepartmentModal from "../../Components/departmentModal";
import { LabelTwoTone, TroubleshootTwoTone } from "@mui/icons-material";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import {
  updateProductDepartment,
  updateProductMake,
} from "../../Redux/LoginReduces";
import MakeModal from "../../Components/MakeModal";
import CustomModal from "../../Components/modal";

function ProductMake() {
  const dispatch = useDispatch();

  const make = useSelector((state) => state?.loginReducer?.make);

  const departments = useSelector(
    (state) => state?.loginReducer?.productDepartments
  );

  const [allDepartment, setAllDepartments] = useState(departments);

  const [allMake, setAllMake] = useState(make);

  const [editDepartmentModal, setEditDepartmentModal] = useState(false);
  const [editCategoryModal, setEditCategoryModal] = useState(false);
  const [categoryClicked, setCategoryClicked] = useState("");
  const [departmentClicked, setDepartmentClicked] = useState("");
  const [loading, setLoading] = useState(false);
  const [departmentName, setDepartmentName] = useState(false);

  const [categoryName, setCategoryName] = useState("");
  const [categoryPer, setCategoryPer] = useState({
    a: "",
    b: "",
    c: "",
  });

  const [makeClicked, setMakeClicked] = useState("");
  const [makeName, setMakeName] = useState("");

  const [modelClicked, setModelClicked] = useState("");
  const [modelName, setModelName] = useState("");

  const [openMakeDeleteModal, setOpenMakeDeleteModal] = useState(false);

  const [openModelDeleteModal, setOpenModelDeleteModal] = useState(false);

  useEffect(() => {
    setAllMake(make);
  }, [make?.length]);

  const getMake = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProductMake`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res.data;

          if (data.status) {
            dispatch(updateProductMake(data.data));
            // setProductDepartment(data?.data);
          }
        });
    }
  };

  useEffect(() => {
    getMake();
  }, []);

  const handleShowCategory = (ind) => {
    setAllMake(
      allMake &&
        allMake?.length > 0 &&
        allMake?.map((e, i) => {
          if (i == ind) {
            return {
              ...e,
              showCategory: !e?.showCategory,
            };
          } else {
            return e;
          }
        })
    );
  };

  const handleChooseDepartment = (make, index) => {
    setMakeClicked(make);
    setMakeName(make?.make);
    setEditDepartmentModal(true);
  };

  const handleEditDepartment = async () => {

    if (!makeName) {
      alert("Make Name is missing");
      return;
    }

    setLoading(true);

    let dataToSend = {
      make: makeClicked,
      editedName: makeName,
    };

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}editProductMake`, dataToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res?.data;

          if (!data?.status) {
            alert(data?.message);
            setLoading(false);
            return;
          }

          let make = data?.data;

          let otherMake =
            allMake &&
            allMake?.length > 0 &&
            allMake?.filter((e, i) => e?._id !== make?._id);
          otherMake = otherMake && otherMake?.length > 0 ? otherMake : [];
          let all = [...otherMake, make];

          dispatch(updateProductMake(all));
          setAllMake(all);
          alert(data.message);
          setLoading(false);
          setEditDepartmentModal(false);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleEditCategory = async () => {
    if (!modelName) {
      alert("Model name is missing");
      return;
    }

    let models = { ...modelClicked };

    let oldName = models?.model;
    models.model = modelName;

    let { model } = makeClicked;

    let otherModels = model?.filter((e, i) => e?._id !== models?._id);

    otherModels = otherModels && otherModels?.length > 0 ? otherModels : [];

    let allModel = [...otherModels, models];

    let departmentToSend = { ...makeClicked };

    departmentToSend.model = allModel;
    departmentToSend.oldName = oldName;
    departmentToSend.newName = modelName;

    setLoading(true);

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .put(`${Base_Uri}editProductModel`, departmentToSend, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let data = res?.data;

          if (!data?.status) {
            alert(data?.message);
            setLoading(false);
            return;
          }

          let make = data?.data;

          let otherMake =
            allMake &&
            allMake?.length > 0 &&
            allMake?.filter((e, i) => e?._id !== make?._id);
          otherMake = otherMake && otherMake?.length > 0 ? otherMake : [];
          let all = [...otherMake, make];

          dispatch(updateProductMake(all));
          setAllMake(all);
          alert(data.message);
          setLoading(false);
          setEditCategoryModal(false);
        })
        .catch((error) => {
          alert(error?.message);
          setLoading(false);
          return;
        });
    }
  };

  const handleChooseCategory = (make, model) => {
    setMakeClicked(make);
    setModelClicked(model);
    setModelName(model?.model);
    setEditCategoryModal(true);
  };

  const handleOpenDeleteMakeModal = (make) => {
    setMakeClicked(make);
    setOpenMakeDeleteModal(true);
  };

  const handleDeleteMake = async () => {
    setLoading(true);

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .delete(`${Base_Uri}deleteProductMake/${makeClicked?._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let data = res.data;

        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let otherMake =
          make &&
          make?.length > 0 &&
          make?.filter((e, i) => e?._id !== makeClicked?._id);

        dispatch(
          updateProductMake(otherMake && otherMake?.length > 0 ? otherMake : [])
        );
        setAllMake(otherMake);
        alert(data?.message);
        setOpenMakeDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
      });
  };

  const handleOpenDeleteModelModal = (selectedMake, selectedModel) => {
    setMakeClicked(selectedMake);
    setModelClicked(selectedModel);
    setOpenModelDeleteModal(true);
  };

  const handleDeleteModel = async () => {
    setLoading(true);

    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .delete(
        `${Base_Uri}deleteProductModel/${makeClicked?._id}/${modelClicked?._id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        }
      )

      .then((res) => {
        let data = res.data;
        if (!data?.status) {
          alert(data?.message);
          setLoading(false);
          return;
        }

        let otherMake =
          make &&
          make?.length > 0 &&
          make?.filter((e, i) => e?._id !== makeClicked?._id);

        let makeModels = makeClicked?.model;

        makeModels =
          makeModels &&
          makeModels?.length > 0 &&
          makeModels?.filter((e, i) => e?._id !== modelClicked?._id);

        let updatedMake = { ...makeClicked };

        updatedMake.model = makeModels;

        let allMake = [...otherMake, updatedMake];

        dispatch(
          updateProductMake(allMake && allMake?.length > 0 ? allMake : [])
        );
        setAllMake(allMake);
        alert(data?.message);
        setOpenModelDeleteModal(false);
        setLoading(false);
      })
      .catch((error) => {
        alert(error?.message);
        setLoading(false);
      });
  };

  return (
    <div>
      <Box style={{ padding: 10, width: "100%" }}>
        <Typography
          style={{
            fontFamily: "Poppins",
            fontSize: 24,
            fontWeight: "bold",
            color: Colors.black,
            textAlign: "left",
            // borderBottom:"1px solid"
          }}
        >
          Make:
        </Typography>

        <Box style={{ marginTop: 20 }}>
          {allMake &&
            allMake?.length > 0 &&
            allMake?.map((e, i) => {
              return (
                <Box style={{ borderBottom: "1px solid" }} key={i}>
                  <Box
                    style={{
                      marginBottom: 20,
                      marginTop: 10,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 20,
                        fontWeight: "bold",
                        color: Colors.red,
                        textAlign: "left",
                        flex: 1, // Make the department name occupy remaining space
                      }}
                    >
                      {e?.make}
                    </Typography>

                    <Box style={{ display: "flex" }}>
                      <CustomButton
                        onClick={() => handleShowCategory(i)}
                        text="show Model"
                        style={{
                          width: "150px",
                          borderRadius: 1,
                          padding: 1,
                          marginRight: 10,
                        }}
                      />
                      <CustomButton
                        onClick={() => handleChooseDepartment(e, i)}
                        text="Edit Make"
                        style={{
                          width: "150px",
                          borderRadius: 1,
                          padding: 1,
                          backgroundColor: Colors.red,
                        }}
                      />
                      <CustomButton
                        onClick={() => handleOpenDeleteMakeModal(e, i)}
                        text="Delete Make"
                        style={{
                          width: "150px",
                          borderRadius: 1,
                          padding: 1,
                          backgroundColor: Colors.red,
                        }}
                      />
                    </Box>
                  </Box>

                  {e?.showCategory && (
                    <Typography
                      style={{
                        fontFamily: "Poppins",
                        fontSize: 24,
                        fontWeight: "bold",
                        color: Colors.black,
                        textAlign: "left",
                        flex: 1, // Make the department name occupy remaining space
                      }}
                    >
                      Model:
                    </Typography>
                  )}

                  {e?.showCategory &&
                    e?.model?.map((model, ind) => {
                      return (
                        <Box>
                          <Box
                            style={{
                              marginBottom: 20,
                              // borderBottom: "1px solid",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              style={{
                                fontFamily: "Poppins",
                                fontSize: 20,
                                fontWeight: "bold",
                                color: Colors.button,
                                textAlign: "left",
                                flex: 1, // Make the department name occupy remaining space
                              }}
                            >
                              {model?.model}
                            </Typography>

                            <CustomButton
                              onClick={() => handleChooseCategory(e, model)}
                              text="Edit Model"
                              style={{
                                width: "200px",
                                borderRadius: 1,
                                padding: 1,
                                backgroundColor: Colors.red,
                              }}
                            />

                            <CustomButton
                              onClick={() =>
                                handleOpenDeleteModelModal(e, model)
                              }
                              text="Delete Model"
                              style={{
                                width: "200px",
                                borderRadius: 1,
                                padding: 1,
                                backgroundColor: Colors.red,
                              }}
                            />
                          </Box>
                        </Box>
                      );
                    })}
                </Box>
              );
            })}
        </Box>

        {editDepartmentModal && (
          <DepartmentModal
            open={editDepartmentModal}
            placeholder={makeName ? makeName : "Enter Make Name..."}
            loading={loading}
            onChange={(e) => setMakeName(e.target.value)}
            closeModal={() => setEditDepartmentModal(false)}
            heading={"Edit Make"}
            confirm={() => handleEditDepartment()}
          />
        )}

        {editCategoryModal && (
          <MakeModal
            open={editCategoryModal}
            placeholder={modelName ? modelName : "Enter Model Name..."}
            loading={loading}
            showMake={true}
            showOnlyModel={true}
            onChange={(e) => setModelName(e.target.value)}
            closeModal={() => setEditCategoryModal(false)}
            heading={"Edit Model"}
            confirm={() => handleEditCategory()}
          />
        )}

        {openMakeDeleteModal && (
          <CustomModal
            heading={`Delete Make`}
            loading={loading}
            text={"Are you sure you want to delete this Make?"}
            open={openMakeDeleteModal}
            closeModal={() => setOpenMakeDeleteModal(false)}
            confirm={handleDeleteMake}
          />
        )}

        {openModelDeleteModal && (
          <CustomModal
            heading={`Delete Model`}
            loading={loading}
            text={"Are you sure you want to delete this Model?"}
            open={openModelDeleteModal}
            closeModal={() => setOpenModelDeleteModal(false)}
            confirm={handleDeleteModel}
          />
        )}
      </Box>
    </div>
  );
}

export default ProductMake;
