import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import Navbar from "../../Components/Navbar";
import { Colors } from "../../Constant/Color";
import Checkbox from "../../Components/checkbox";
import DatePickers from "../../Components/DatePicker";
import BasicSelect from "../../Components/Dropdown";
import { useEffect, useRef, useState } from "react";
import CustomButton from "../../Components/Button";
import {
  updateAllDayInvoices,
  updateAllEmployees,
  updateCustomers,
  updateDayCreditPayment,
  updateDayExpenses,
  updateDayRefund,
  updateProducts,
  updateShiftCreditPayment,
} from "../../Redux/LoginReduces";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import { RingLoader } from "react-spinners";
import {
  Document,
  Page,
  Text,
  View,
  PDFViewer,
  PDFDownloadLink,
  Font,
} from "@react-pdf/renderer";
import MyFontRegular from "../../fonts/Poppins-Medium.ttf";
import MyFontBold from "../../fonts/Poppins-Bold.ttf";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import React from "react";

function Reports() {
  Font.register({
    family: "MyFont",
    fonts: [
      { src: MyFontBold, fontWeight: "bold" },
      { src: MyFontRegular, fontWeight: "normal" },
    ],
  });

  const dispatch = useDispatch();

  const userData = useSelector((state) => state.loginReducer.updatedState);

  const vat = useSelector((state) => state?.loginReducer?.vat);
  const customers = useSelector((state) => state.loginReducer.customers);
  const products = useSelector((state) => state.loginReducer.products);

  const expenses = useSelector((state) => state.loginReducer.dayExpenses);
  const employeeRefundData = useSelector(
    (state) => state.loginReducer.dayRefunds
  );
  const employeeCreditPayment = useSelector(
    (state) => state.loginReducer.dayCreditPayment
  );
  const dayAllInvoices = useSelector(
    (state) => state.loginReducer.allDayInvoices
  );
  const employeeShiftCreditPayment = useSelector(
    (state) => state?.loginReducer?.shiftCreditPayment
  );

  const [userExpenses, setUserExpenses] = useState(false);
  const [userRefunds, setUserRefunds] = useState(false);

  const [refundInvoices, setRefundInvoices] = React.useState([]);
  const [shiftInvoices, setShiftInvoices] = React.useState([]);

  const [creditPayment, setCreditPayment] = React.useState([]);

  const [loading, setLoading] = useState(false);

  const [reportType, setReportType] = useState([
    "Day Report",
    "User Sale Report",
    "Stock Report",
    "Sale Report Between Dates",
    "Sale Report By Department",
    "Product Sale Report For Selected Customer",
    "Customer Specific Product Sale Report",
    "Best Seller (Top 100)",
    "Product Sale Report For Selected Dates",
    "Minimum Stock Level",
  ]);

  const customerInputRef = useRef(null);
  const [pdfDownloadView, setPdfDownloadView] = useState({
    stockReport: false,
    dayReport: false,
    saleReport: false,
    minimumStockLevel: false,
    userSaleReport: false,
    saleReportBetweenDates: false,
    productSaleReportForSelectedCustomer: false,
    customerSpecificProductSaleReport: false,
    productSaleReportForSelectedDates: false,
    bestSellerTop100: false,
    saleReportByDepartment: false,
  });

  const [selectedSearch, setSelectedSearch] = useState("customers");
  const [text, setText] = useState("");
  const [selectedReportType, setSelectedReportType] = useState("select");
  const [searchProducts, setSearchProducts] = useState([]);
  const [searchCustomers, setSearchCustomers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchDate, setSearchDate] = useState({
    selected: false,
  });

  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");

  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("select");

  const [allProducts, setAllProducts] = useState([]);
  const [minimumStockProduct, setMinimumStockProduct] = useState([]);

  const [employeeInvoices, setEmployeeInvoices] = useState([]);
  const [userAllInvoices, setUserAllInvoices] = useState([]);

  const [allInvoices, setAllInvoices] = useState([]);
  const [allUngroupedInvoice, setAllUngroupedInvoice] = useState([]);

  const [customerInvoices, setCustomerInvoices] = useState([]);

  const [customerProductsSale, setCustomerProductsSale] = useState([]);

  const [productSaleData, setProductSaleData] = useState([]);

  const getProducts = async () => {
    // setLoading(true)
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    if (token) {
      axios
        .get(`${Base_Uri}getProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then((res) => {
          let myData = res.data;

          let { data } = myData;

          if (data && data.length > 0) {
            let allProducts = [];

            for (var i = 0; i < data.length; i++) {
              let product = data[i];

              product.id = i + 1;
              product["productName"] = product?.product_name;

              if (product.status == "active") {
                product.status = "Active";
              }

              allProducts.push(product);
            }

            dispatch(updateProducts(allProducts));
          }
        })
        .catch((error) => {
          console.log(error, "error");
          // setLoading(false)
        });
    }
  };

  const getCustomers = async () => {
    let token = await sessionStorage.getItem("userData");
    token = JSON.parse(token);

    axios
      .get(`${Base_Uri}getCustomers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json", // Include other headers as needed
        },
      })
      .then((res) => {
        let { data } = res;

        if (data.status) {
          let customers = data.data;

          dispatch(
            updateCustomers(customers && customers.length > 0 ? customers : [])
          );
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  };

  const getAllEmployees = async () => {
    let data = await sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;

    axios
      .get(`${Base_Uri}getAllEmployees`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        let data = res.data;

        if (data?.status) {
          let employee = data?.data;

          setEmployees(employee);
        } else {
          alert(data?.message);
        }
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  useEffect(() => {
    setTimeout(() => {
      customerInputRef?.current?.focus();
    }, 3000);
  }, [customerInputRef?.current]);

  useEffect(() => {
    getProducts();
    getCustomers();
    getAllEmployees();
  }, []);

  const handleSearch = (text) => {
    setText(text);

    if (!text) {
      setSearchCustomers([]);
      setSearchProducts([]);
      return;
    }

    if (selectedSearch == "customers") {
      setSearchProducts([]);

      setSearchCustomers(
        customers &&
          customers.length > 0 &&
          customers.filter((e, i) => {
            if (
              e?.customerName?.toLowerCase().includes(text.toLowerCase()) ||
              e?.businessName?.toLowerCase().includes(text.toLowerCase()) ||
              e?.email?.toLowerCase().includes(text.toLowerCase()) ||
              e?.accountNo == text
            ) {
              return e;
            }
          })
      );
    }

    if (selectedSearch == "products") {
      setSearchCustomers([]);

      setSearchProducts(
        products &&
          products.length > 0 &&
          products.filter((e, i) => {
            if (
              e?.productName?.toLowerCase().includes(text.toLowerCase()) ||
              e?.category?.toLowerCase().includes(text.toLowerCase()) ||
              e?.sub_category?.toLowerCase().includes(text.toLowerCase())
            ) {
              return e;
            }
          })
      );
    }
  };

  const handleSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setText("");
    setSearchCustomers([]);
  };

  const handleSelectProduct = (product) => {
    setSelectedProduct(product);
    setText("");
    setSearchProducts([]);
  };

  const handleGenerateReports = async () => {
    if (!selectedReportType || selectedReportType == "select") {
      alert("Kindly Select Report Type");
      return;
    }

    if (selectedReportType?.toLowerCase() == "day report") {
      if (!startDate) {
        alert("Start Date is missing");
        return;
      }

      if (!endDate) {
        alert("End Date is missing");
        return;
      }

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      setLoading(true);

      try {
        // Parse selectedDate and dateFrom
        let selectedDateParts = endDate?.split("/");
        let dateFromParts = startDate?.split("/");

        // Create Date objects from the parsed components
        let selectedDateTime = new Date(
          parseInt(selectedDateParts[2]), // Year
          parseInt(selectedDateParts[1]), // Month (zero-based)
          parseInt(selectedDateParts[0]), // Day
          23, // Hours
          59, // Minutes
          59 // Seconds
        );

        let dateFromTime = new Date(
          parseInt(dateFromParts[2]), // Year
          parseInt(dateFromParts[1]), // Month (zero-based)
          parseInt(dateFromParts[0]), // Day
          0, // Hours
          0, // Minutes
          0 // Seconds
        );

        let dataSend = {
          startDate: dateFromTime,
          endDate: selectedDateTime,
        };

        let invoicesData = await axios.post(
          `${Base_Uri}getDateWiseAllInvoices`,
          dataSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let returnInvoicesData = await axios.post(
          `${Base_Uri}getDateWiseAllReturnInvoices`,
          dataSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let exchangeInvoicesData = await axios.post(
          `${Base_Uri}getDateWiseAllExchangeInvoices`,
          dataSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        invoicesData = invoicesData?.data;
        returnInvoicesData = returnInvoicesData?.data;
        exchangeInvoicesData = exchangeInvoicesData?.data;

        let invoices;
        let returnInvoices;
        let exchangeInvoices;

        if (invoicesData?.status) {
          invoices = invoicesData.data;
        }

        if (returnInvoicesData?.status) {
          returnInvoices = returnInvoicesData?.data;
        }

        if (exchangeInvoicesData?.status) {
          exchangeInvoices = exchangeInvoicesData?.data;
        }

        invoices = invoices && invoices?.length > 0 ? invoices : [];
        returnInvoices =
          returnInvoices && returnInvoices?.length > 0 ? returnInvoices : [];
        exchangeInvoices =
          exchangeInvoices && exchangeInvoices?.length > 0
            ? exchangeInvoices
            : [];

        let allInvoices = [...invoices, ...returnInvoices, ...exchangeInvoices];

        const groupedData = {};
        allInvoices &&
          allInvoices?.length > 0 &&
          allInvoices.forEach((invoice) => {
            const { businessName } = invoice?.customerDetails[0];

            if (!groupedData[businessName]) {
              groupedData[businessName] = [];
            }
            groupedData[businessName].push(invoice);
          });

        setShiftInvoices(allInvoices);
        setEmployeeInvoices(groupedData);

        let dataToSend = {
          startDate: dateFromTime,
          endDate: selectedDateTime,
        };

        let expenseResponse = await axios.post(
          `${Base_Uri}getDateWiseExpense`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let expenseData = expenseResponse.data;

        if (expenseData.status) {
          let allExpenses = expenseData.data;
          dispatch(updateDayExpenses(allExpenses));
        } else {
          alert(expenseData.message);
          setLoading(false);
          return;
        }

        let refundResponse = await axios.post(
          `${Base_Uri}getDateWiseRefund`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let refundData = refundResponse.data;

        if (refundData.status) {
          let dayRefunds = refundData.data;

          dispatch(updateDayRefund(dayRefunds));

          const groupedData = {};

          dayRefunds &&
            dayRefunds?.length > 0 &&
            dayRefunds.forEach((invoice) => {
              const { business_name } = invoice?.customerData;

              if (!groupedData[business_name]) {
                groupedData[business_name] = [];
              }
              groupedData[business_name].push(invoice);
            });

          setRefundInvoices(groupedData);
        } else {
          alert(refundData.message);
          setLoading(false);
          return;
        }

        let creditResponse = await axios.post(
          `${Base_Uri}getDateWiseCredit`,
          dataToSend,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        let creditData = creditResponse.data;

        if (creditData.status) {
          let creditPayments = creditData.data;

          dispatch(updateDayCreditPayment(creditPayments));

          const groupedData = {};

          creditPayments &&
            creditPayments?.length > 0 &&
            creditPayments.forEach((invoice) => {
              const { business_name } = invoice?.customerData;

              if (!groupedData[business_name]) {
                groupedData[business_name] = [];
              }
              groupedData[business_name].push(invoice);
            });

          setCreditPayment(groupedData);

          setPdfDownloadView({
            ...pdfDownloadView,
            dayReport: true,
          });
          setLoading(false);
        } else {
          alert(creditData.message);
          setLoading(false);
          return;
        }
      } catch (err) {
        console.log(err, "errrrrr");
        setLoading(false);
        alert("Internal Server Error");
      }
    }

    if (selectedReportType?.toLowerCase() == "stock report") {
      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      if (token) {
        axios
          .get(`${Base_Uri}getProducts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Include other headers as needed
            },
          })
          .then((res) => {
            let myData = res.data;

            let { data } = myData;

            setAllProducts(data);
            setPdfDownloadView({
              ...pdfDownloadView,
              stockReport: true,
            });
            setLoading(false);
          })
          .catch((error) => {
            console.log(error, "error");
            setLoading(false);
          });
      }
    } else if (selectedReportType?.toLowerCase() == "minimum stock level") {
      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      if (token) {
        axios
          .get(`${Base_Uri}getMinimumStockProducts`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json", // Include other headers as needed
            },
          })
          .then((res) => {
            let myData = res.data;

            if (myData?.status) {
              let { data } = myData;
              setMinimumStockProduct(data);
              setPdfDownloadView({
                ...pdfDownloadView,
                minimumStockLevel: true,
              });
              setLoading(false);
            } else {
              alert(myData?.message);
            }
          })
          .catch((error) => {
            alert(error?.message);
            console.log(error, "error");
            setLoading(false);
          });
      }
    } else if (selectedReportType?.toLowerCase() == "user sale report") {
      if (!selectedEmployee || selectedEmployee == "select") {
        alert("Employee is missing");
        return;
      }

      if (searchDate?.selected && !startDate) {
        alert("Kindly Choose Start Date");
        return;
      }

      if (searchDate?.selected && !endDate) {
        alert("Kindly Choose End Date");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      if (token) {
        try {
          let employee = employees.find((e) => e.full_name == selectedEmployee);

          axios
            .get(`${Base_Uri}getEmployeeInvoices/${employee?._id}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
              },
            })
            .then(async (res) => {
              let myData = res.data;

              if (myData?.status) {
                let { data } = myData;

                if (searchDate?.selected) {
                  let filteredData =
                    data &&
                    data?.length > 0 &&
                    data?.filter((e, i) => {
                      let date = e?.saleDate
                        ? e?.saleDate
                        : e?.saleReturnDate
                        ? e?.saleReturnDate
                        : e?.exchangeDate;

                      let addDate = new Date(date);

                      // Parse selectedDate and dateFrom
                      let selectedDateParts = endDate?.split("/");
                      let dateFromParts = startDate?.split("/");

                      // Create Date objects from the parsed components
                      let selectedDateTime = new Date(
                        parseInt(selectedDateParts[2]), // Year
                        parseInt(selectedDateParts[1]), // Month (zero-based)
                        parseInt(selectedDateParts[0]), // Day
                        23, // Hours
                        59, // Minutes
                        59 // Seconds
                      );

                      let dateFromTime = new Date(
                        parseInt(dateFromParts[2]), // Year
                        parseInt(dateFromParts[1]), // Month (zero-based)
                        parseInt(dateFromParts[0]), // Day
                        0, // Hours
                        0, // Minutes
                        0 // Seconds
                      );

                      // Check if addDate is between dateFrom and selectedDate
                      return (
                        addDate >= dateFromTime && addDate <= selectedDateTime
                      );
                    });

                  setShiftInvoices(
                    filteredData && filteredData?.length > 0 ? filteredData : []
                  );

                  const groupedData = {};
                  filteredData &&
                    filteredData?.length > 0 &&
                    filteredData.forEach((invoice) => {
                      const { businessName } = invoice?.customerDetails[0];

                      if (!groupedData[businessName]) {
                        groupedData[businessName] = [];
                      }
                      groupedData[businessName].push(invoice);
                    });

                  setEmployeeInvoices(groupedData);

                  let selectedDateParts = endDate?.split("/");
                  let dateFromParts = startDate?.split("/");

                  // Create Date objects from the parsed components
                  let selectedDateTime = new Date(
                    parseInt(selectedDateParts[2]), // Year
                    parseInt(selectedDateParts[1]), // Month (zero-based)
                    parseInt(selectedDateParts[0]), // Day
                    23, // Hours
                    59, // Minutes
                    59 // Seconds
                  );

                  let dateFromTime = new Date(
                    parseInt(dateFromParts[2]), // Year
                    parseInt(dateFromParts[1]), // Month (zero-based)
                    parseInt(dateFromParts[0]), // Day
                    0, // Hours
                    0, // Minutes
                    0 // Seconds
                  );

                  let dataToSend = {
                    startDate: dateFromTime,
                    endDate: selectedDateTime,
                    id: employee?._id,
                  };

                  let expenseResponse = await axios.post(
                    `${Base_Uri}getDateWiseExpense`,
                    dataToSend,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  let expenseData = expenseResponse.data;

                  if (expenseData.status) {
                    let allExpenses = expenseData.data;

                    setUserExpenses(allExpenses);
                  } else {
                    alert(expenseData.message);
                    setLoading(false);
                    return;
                  }

                  let CreditResponse = await axios.post(
                    `${Base_Uri}getDateWiseCredit`,
                    dataToSend,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  let creditData = CreditResponse.data;

                  if (creditData.status) {
                    let allCredit = creditData.data;
                    console.log(allCredit, "allCreidt");
                    dispatch(updateShiftCreditPayment(allCredit));

                    const mygroupedData = {};
                    allCredit &&
                      allCredit?.length > 0 &&
                      allCredit.forEach((invoice) => {
                        const { business_name } = invoice?.customerData;

                        if (!mygroupedData[business_name]) {
                          mygroupedData[business_name] = [];
                        }
                        mygroupedData[business_name].push(invoice);
                      });

                    setCreditPayment(mygroupedData);
                  } else {
                    alert(creditData.message);
                    setLoading(false);
                    return;
                  }

                  let refundResponse = await axios.post(
                    `${Base_Uri}getDateWiseRefund`,
                    dataToSend,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  let refundData = refundResponse.data;

                  if (refundData.status) {
                    let dayRefunds = refundData.data;

                    setUserRefunds(dayRefunds);

                    const groupedData = {};

                    dayRefunds &&
                      dayRefunds?.length > 0 &&
                      dayRefunds.forEach((invoice) => {
                        const { business_name } = invoice?.customerData;

                        if (!groupedData[business_name]) {
                          groupedData[business_name] = [];
                        }
                        groupedData[business_name].push(invoice);
                      });

                    setRefundInvoices(groupedData);

                    setPdfDownloadView({
                      ...pdfDownloadView,
                      userSaleReport: true,
                    });
                    setLoading(false);
                  } else {
                    alert(refundData.message);
                    setLoading(false);
                    return;
                  }

                  setLoading(false);

                  return;
                }

                setShiftInvoices(data && data?.length > 0 ? data : []);

                const groupedData = {};
                data &&
                  data?.length > 0 &&
                  data.forEach((invoice) => {
                    const { businessName } = invoice?.customerDetails[0];

                    if (!groupedData[businessName]) {
                      groupedData[businessName] = [];
                    }
                    groupedData[businessName].push(invoice);
                  });

                setEmployeeInvoices(groupedData);

                let createDate = new Date(employee.created_at);

                let dataToSend = {
                  startDate: createDate,
                  endDate: new Date(),
                  id: employee?._id,
                };

                let expenseResponse = await axios.post(
                  `${Base_Uri}getDateWiseExpense`,
                  dataToSend,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                let expenseData = expenseResponse.data;

                if (expenseData.status) {
                  let allExpenses = expenseData.data;
                  setUserExpenses(allExpenses);
                } else {
                  alert(expenseData.message);
                  setLoading(false);
                  return;
                }

                let CreditResponse = await axios.post(
                  `${Base_Uri}getDateWiseCredit`,
                  dataToSend,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                let creditData = CreditResponse.data;

                if (creditData.status) {
                  let allCredit = creditData.data;
                  dispatch(updateShiftCreditPayment(allCredit));

                  const mygroupedData = {};
                  allCredit &&
                    allCredit?.length > 0 &&
                    allCredit.forEach((invoice) => {
                      const { business_name } = invoice?.customerData;

                      if (!mygroupedData[business_name]) {
                        mygroupedData[business_name] = [];
                      }
                      mygroupedData[business_name].push(invoice);
                    });

                  setCreditPayment(mygroupedData);
                } else {
                  alert(creditData.message);
                  setLoading(false);
                  return;
                }

                let refundResponse = await axios.post(
                  `${Base_Uri}getDateWiseRefund`,
                  dataToSend,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );

                let refundData = refundResponse.data;

                if (refundData.status) {
                  let dayRefunds = refundData.data;

                  setUserRefunds(dayRefunds);

                  const groupedData = {};

                  dayRefunds &&
                    dayRefunds?.length > 0 &&
                    dayRefunds.forEach((invoice) => {
                      const { business_name } = invoice?.customerData;

                      if (!groupedData[business_name]) {
                        groupedData[business_name] = [];
                      }
                      groupedData[business_name].push(invoice);
                    });

                  setRefundInvoices(groupedData);

                  setPdfDownloadView({
                    ...pdfDownloadView,
                    userSaleReport: true,
                  });
                  setLoading(false);
                } else {
                  alert(refundData.message);
                  setLoading(false);
                  return;
                }

                setLoading(false);
              } else {
                setLoading(false);
                alert(myData?.message);
              }
            })
            .catch((error) => {
              alert(error?.message);
              console.log(error, "error");
              setLoading(false);
            });
        } catch (err) {
          alert("Internal Server Error");
          return;
        }
      }
    } else if (
      selectedReportType?.toLowerCase() == "sale report between dates"
    ) {
      if (!startDate) {
        alert("Start Date is missing");
        return;
      }

      if (!endDate) {
        alert("End Date is missing");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getAllInvoices`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          let filteredData =
            data &&
            data?.length > 0 &&
            data?.filter((e, i) => {
              let date = e?.saleDate
                ? e?.saleDate
                : e?.saleReturnDate
                ? e?.saleReturnDate
                : e?.exchangeDate;

              let addDate = new Date(date);

              // Parse selectedDate and dateFrom
              let selectedDateParts = endDate?.split("/");
              let dateFromParts = startDate?.split("/");

              // Create Date objects from the parsed components
              let selectedDateTime = new Date(
                parseInt(selectedDateParts[2]), // Year
                parseInt(selectedDateParts[1]), // Month (zero-based)
                parseInt(selectedDateParts[0]), // Day
                23, // Hours
                59, // Minutes
                59 // Seconds
              );

              let dateFromTime = new Date(
                parseInt(dateFromParts[2]), // Year
                parseInt(dateFromParts[1]), // Month (zero-based)
                parseInt(dateFromParts[0]), // Day
                0, // Hours
                0, // Minutes
                0 // Seconds
              );

              // Check if addDate is between dateFrom and selectedDate
              return addDate >= dateFromTime && addDate <= selectedDateTime;
            });

          setAllUngroupedInvoice(
            filteredData && filteredData?.length > 0 ? filteredData : []
          );

          const groupedData = {};
          let promise =
            filteredData &&
            filteredData?.length > 0 &&
            filteredData.map((invoice) => {
              let dateKey;
              if (invoice?.saleDate) {
                const date = new Date(invoice.saleDate);
                date.setHours(0, 0, 0, 0); // Reset time to midnight
                dateKey = date.toISOString(); // Convert to ISO string for accurate comparison
              } else if (invoice?.saleReturnDate) {
                const date = new Date(invoice.saleReturnDate);
                date.setHours(0, 0, 0, 0); // Reset time to midnight
                dateKey = date.toISOString();
              } else if (invoice?.exchangeDate) {
                const date = new Date(invoice.exchangeDate);
                date.setHours(0, 0, 0, 0); // Reset time to midnight
                dateKey = date.toISOString();
              }

              if (!groupedData[dateKey]) {
                groupedData[dateKey] = [];
              }
              groupedData[dateKey].push(invoice);
            });

          await Promise.all(promise);

          setAllInvoices(groupedData);

          setPdfDownloadView({
            ...pdfDownloadView,
            saleReportBetweenDates: true,
          });
          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          console.log(error, "error");
        });
    } else if (
      selectedReportType?.toLowerCase() ==
      "product sale report for selected customer"
    ) {
      if (!selectedCustomer) {
        alert("Kindly Select Customer");
        return;
      }

      if (searchDate?.selected && !startDate) {
        alert("Start Date is missing");
        return;
      }

      if (searchDate?.selected && !endDate) {
        alert("End Date is missing");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getCustomerInvoices/${selectedCustomer?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          if (searchDate?.selected) {
            let filteredData =
              data &&
              data?.length > 0 &&
              data?.filter((e, i) => {
                let addDate = new Date(
                  e?.saleDate
                    ? e?.saleDate
                    : e?.saleReturnDate
                    ? e?.saleReturnDate
                    : e?.exchangeDate
                );

                let selectedDateParts = endDate?.split("/");
                let dateFromParts = startDate?.split("/");

                let selectedDateTime = new Date(
                  parseInt(selectedDateParts[2]), // Year
                  parseInt(selectedDateParts[1]), // Month (zero-based)
                  parseInt(selectedDateParts[0]), // Day
                  23, // Hours
                  59, // Minutes
                  59 // Seconds
                );

                let dateFromTime = new Date(
                  parseInt(dateFromParts[2]), // Year
                  parseInt(dateFromParts[1]), // Month (zero-based)
                  parseInt(dateFromParts[0]), // Day
                  0, // Hours
                  0, // Minutes
                  0 // Seconds
                );

                return addDate >= dateFromTime && addDate <= selectedDateTime;
              });

            let sortData =
              filteredData &&
              filteredData?.length > 0 &&
              filteredData?.sort((a, b) => {
                return (
                  new Date(
                    a?.saleDate
                      ? a?.saleDate
                      : a?.saleReturnDate
                      ? a?.saleReturnDate
                      : a?.exchangeDate
                  ).getTime() -
                  new Date(
                    b?.saleDate
                      ? b?.saleDate
                      : b?.saleReturnDate
                      ? b?.saleReturnDate
                      : b?.exchangeDate
                  ).getTime()
                );
              });

            setCustomerInvoices(sortData);

            setPdfDownloadView({
              ...pdfDownloadView,
              productSaleReportForSelectedCustomer: true,
            });
            setLoading(false);

            return;
          }

          let sortData =
            data &&
            data?.length > 0 &&
            data?.sort((a, b) => {
              return (
                new Date(
                  a?.saleDate
                    ? a?.saleDate
                    : a?.saleReturnDate
                    ? a?.saleReturnDate
                    : a?.exchangeDate
                ).getTime() -
                new Date(
                  b?.saleDate
                    ? b?.saleDate
                    : b?.saleReturnDate
                    ? b?.saleReturnDate
                    : b?.exchangeDate
                ).getTime()
              );
            });

          setCustomerInvoices(sortData && sortData?.length > 0 ? sortData : []);

          setPdfDownloadView({
            ...pdfDownloadView,
            productSaleReportForSelectedCustomer: true,
          });
          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
        });
    } else if (
      selectedReportType?.toLowerCase() ==
      "customer specific product sale report"
    ) {
      if (!selectedCustomer) {
        alert("Kindly Select Customer");
        return;
      }

      if (!selectedProduct) {
        alert("Kindly Select Product");
        return;
      }

      if (searchDate?.selected && !startDate) {
        alert("Start Date is missing");
        return;
      }

      if (searchDate?.selected && !endDate) {
        alert("End Date is missing");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}getCustomerInvoices/${selectedCustomer?.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          if (searchDate?.selected) {
            let selectedProducts =
              data &&
              data?.length > 0 &&
              data?.filter((e, i) => {
                let { productDetails } = e;

                let { returnProductDetails } = e;

                let selectedProductData =
                  productDetails &&
                  productDetails?.length > 0 &&
                  productDetails?.filter((j) => {
                    if (j?.barcode == selectedProduct?.barcode) {
                      return j;
                    }
                  });

                let selectedReturnProductData =
                  returnProductDetails &&
                  returnProductDetails?.length > 0 &&
                  returnProductDetails?.filter((j) => {
                    if (j?.barcode == selectedProduct?.barcode) {
                      return j;
                    }
                  });

                e.productDetails = selectedProductData;
                e.returnProductDetails = selectedReturnProductData;

                if (
                  (e?.productDetails && e?.productDetails?.length > 0) ||
                  (e?.returnProductDetails &&
                    e?.returnProductDetails?.length > 0)
                ) {
                  return e;
                }
              });

            let filteredData =
              selectedProducts &&
              selectedProducts?.length > 0 &&
              selectedProducts?.filter((e, i) => {
                let addDate = new Date(
                  e?.saleDate
                    ? e?.saleDate
                    : e?.saleReturnDate
                    ? e?.saleReturnDate
                    : e?.exchangeDate
                );

                let selectedDateParts = endDate?.split("/");
                let dateFromParts = startDate?.split("/");

                let selectedDateTime = new Date(
                  parseInt(selectedDateParts[2]), // Year
                  parseInt(selectedDateParts[1]), // Month (zero-based)
                  parseInt(selectedDateParts[0]), // Day
                  23, // Hours
                  59, // Minutes
                  59 // Seconds
                );

                let dateFromTime = new Date(
                  parseInt(dateFromParts[2]), // Year
                  parseInt(dateFromParts[1]), // Month (zero-based)
                  parseInt(dateFromParts[0]), // Day
                  0, // Hours
                  0, // Minutes
                  0 // Seconds
                );

                return addDate >= dateFromTime && addDate <= selectedDateTime;
              });

            let sortData =
              filteredData &&
              filteredData?.length > 0 &&
              filteredData?.sort((a, b) => {
                return (
                  new Date(
                    a?.saleDate
                      ? a?.saleDate
                      : a?.saleReturnDate
                      ? a?.saleReturnDate
                      : a?.exchangeDate
                  ).getTime() -
                  new Date(
                    b?.saleDate
                      ? b?.saleDate
                      : b?.saleReturnDate
                      ? b?.saleReturnDate
                      : b?.exchangeDate
                  ).getTime()
                );
              });

            setCustomerProductsSale(sortData);

            setPdfDownloadView({
              ...pdfDownloadView,
              customerSpecificProductSaleReport: true,
            });
            setLoading(false);

            return;
          }

          let selectedProducts =
            data &&
            data?.length > 0 &&
            data?.filter((e, i) => {
              let { productDetails } = e;

              let { returnProductDetails } = e;

              let selectedProductData =
                productDetails &&
                productDetails?.length > 0 &&
                productDetails?.filter((j) => {
                  if (j?.barcode == selectedProduct?.barcode) {
                    return j;
                  }
                });

              let selectedReturnProductData =
                returnProductDetails &&
                returnProductDetails?.length > 0 &&
                returnProductDetails?.filter((j) => {
                  if (j?.barcode == selectedProduct?.barcode) {
                    return j;
                  }
                });

              e.productDetails = selectedProductData;
              e.returnProductDetails = selectedReturnProductData;

              if (
                (e?.productDetails && e?.productDetails?.length > 0) ||
                (e?.returnProductDetails && e?.returnProductDetails?.length > 0)
              ) {
                return e;
              }
            });

          let sortData =
            selectedProducts &&
            selectedProducts?.length > 0 &&
            selectedProducts?.sort((a, b) => {
              return (
                new Date(
                  a?.saleDate
                    ? a?.saleDate
                    : a?.saleReturnDate
                    ? a?.saleReturnDate
                    : a?.exchangeDate
                ).getTime() -
                new Date(
                  b?.saleDate
                    ? b?.saleDate
                    : b?.saleReturnDate
                    ? b?.saleReturnDate
                    : b?.exchangeDate
                ).getTime()
              );
            });

          setCustomerProductsSale(
            sortData && sortData?.length > 0 ? sortData : []
          );

          setPdfDownloadView({
            ...pdfDownloadView,
            customerSpecificProductSaleReport: true,
          });
          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
        });
    } else if (
      selectedReportType?.toLowerCase() ==
      "product sale report for selected dates"
    ) {
      if (!selectedProduct) {
        alert("Kindly Select Customer");
        return;
      }

      if (!startDate) {
        alert("Start Date is missing");
        return;
      }

      if (!endDate) {
        alert("End Date is missing");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}get-all-type-invoice`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          let selectedProducts =
            data &&
            data?.length > 0 &&
            data?.filter((e, i) => {
              let { productDetails } = e;

              let { returnProductDetails } = e;

              let selectedProductData =
                productDetails &&
                productDetails?.length > 0 &&
                productDetails?.filter((j) => {
                  if (j?.barcode == selectedProduct?.barcode) {
                    return j;
                  }
                });

              let selectedReturnProductData =
                returnProductDetails &&
                returnProductDetails?.length > 0 &&
                returnProductDetails?.filter((j) => {
                  if (j?.barcode == selectedProduct?.barcode) {
                    return j;
                  }
                });

              e.productDetails = selectedProductData;
              e.returnProductDetails = selectedReturnProductData;

              if (
                (e?.productDetails && e?.productDetails?.length > 0) ||
                (e?.returnProductDetails && e?.returnProductDetails?.length > 0)
              ) {
                return e;
              }
            });

          let filteredData =
            selectedProducts &&
            selectedProducts?.length > 0 &&
            selectedProducts?.filter((e, i) => {
              let date = e?.saleDate
                ? e?.saleDate
                : e?.saleReturnDate
                ? e?.saleReturnDate
                : e?.exchangeDate;

              let addDate = new Date(date);

              // Parse selectedDate and dateFrom
              let selectedDateParts = endDate?.split("/");
              let dateFromParts = startDate?.split("/");

              // Create Date objects from the parsed components
              let selectedDateTime = new Date(
                parseInt(selectedDateParts[2]), // Year
                parseInt(selectedDateParts[1]), // Month (zero-based)
                parseInt(selectedDateParts[0]), // Day
                23, // Hours
                59, // Minutes
                59 // Seconds
              );

              let dateFromTime = new Date(
                parseInt(dateFromParts[2]), // Year
                parseInt(dateFromParts[1]), // Month (zero-based)
                parseInt(dateFromParts[0]), // Day
                0, // Hours
                0, // Minutes
                0 // Seconds
              );

              // Check if addDate is between dateFrom and selectedDate
              return addDate >= dateFromTime && addDate <= selectedDateTime;
            });

          let sortData =
            filteredData &&
            filteredData?.length > 0 &&
            filteredData?.sort((a, b) => {
              return (
                new Date(
                  a?.saleDate
                    ? a?.saleDate
                    : a?.saleReturnDate
                    ? a?.saleReturnDate
                    : a?.exchangeDate
                ).getTime() -
                new Date(
                  b?.saleDate
                    ? b?.saleDate
                    : b?.saleReturnDate
                    ? b?.saleReturnDate
                    : b?.exchangeDate
                ).getTime()
              );
            });

          setProductSaleData(sortData && sortData?.length > 0 ? sortData : []);

          setPdfDownloadView({
            ...pdfDownloadView,
            productSaleReportForSelectedDates: true,
          });

          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          console.log(error, "error");
        });
    } else if (selectedReportType?.toLowerCase() == "best seller (top 100)") {
      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}get-all-type-invoice`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          let productWiseData = [];

          data &&
            data?.length > 0 &&
            data?.forEach((e, i) => {
              e?.productDetails &&
                e?.productDetails?.length > 0 &&
                e?.productDetails?.map((product, i) => {
                  if (e?.saleReturnDate) {
                    if (
                      productWiseData &&
                      productWiseData?.length > 0 &&
                      productWiseData?.some(
                        (j) => j.barcode == product?.barcode
                      )
                    ) {
                      productWiseData?.map((change) => {
                        if (change.barcode == product?.barcode) {
                          return {
                            ...change,
                            qty: (change.qty -= product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty),
                            totalAmount: (change.totalAmount -=
                              (product?.DamageQty
                                ? product?.DamageQty
                                : product?.saleQty) *
                              (product?.discountPrice
                                ? product?.discountPrice
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 1
                                ? product?.trade_price
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 2
                                ? product?.warehouse_price
                                : product?.retail_price)),
                          };
                        } else {
                          return change;
                        }
                      });
                    } else {
                      if (e?.saleReturnDate) {
                        product.qty = -(product.DamageQty
                          ? product.DamageQty
                          : product.saleQty);
                        product.totalAmount = -(
                          (product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty) *
                          (product?.discountPrice
                            ? product?.discountPrice
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                            ? product?.trade_price
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                            ? product?.warehouse_price
                            : product?.retail_price)
                        );

                        productWiseData?.push(product);
                        return;
                      }
                      product.qty = product.DamageQty
                        ? product.DamageQty
                        : product.saleQty;
                      product.totalAmount =
                        (product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty) *
                        (product?.discountPrice
                          ? product?.discountPrice
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                          ? product?.trade_price
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                          ? product?.warehouse_price
                          : product?.retail_price);

                      productWiseData?.push(product);
                    }
                  } else {
                    if (
                      productWiseData &&
                      productWiseData?.length > 0 &&
                      productWiseData?.some(
                        (j) => j.barcode == product?.barcode
                      )
                    ) {
                      productWiseData?.map((change) => {
                        if (change.barcode == product?.barcode) {
                          return {
                            ...change,
                            qty: (change.qty += product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty),
                            totalAmount: (change.totalAmount +=
                              (product?.DamageQty
                                ? product?.DamageQty
                                : product?.saleQty) *
                              (product?.discountPrice
                                ? product?.discountPrice
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 1
                                ? product?.trade_price
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 2
                                ? product?.warehouse_price
                                : product?.retail_price)),
                          };
                        } else {
                          return change;
                        }
                      });
                    } else {
                      if (e?.saleReturnDate) {
                        product.qty = -(product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty);
                        product.totalAmount = -(
                          (product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty) *
                          (product?.discountPrice
                            ? product?.discountPrice
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                            ? product?.trade_price
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                            ? product?.warehouse_price
                            : product?.retail_price)
                        );

                        productWiseData?.push(product);
                        return;
                      }
                      product.qty = product?.DamageQty
                        ? product?.DamageQty
                        : product?.saleQty;
                      product.totalAmount =
                        (product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty) *
                        (product?.discountPrice
                          ? product?.discountPrice
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                          ? product?.trade_price
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                          ? product?.warehouse_price
                          : product?.retail_price);

                      productWiseData?.push(product);
                    }
                  }
                });

              e?.returnProductDetails &&
                e?.returnProductDetails?.length > 0 &&
                e?.returnProductDetails?.map((product, i) => {
                  if (
                    productWiseData &&
                    productWiseData?.length > 0 &&
                    productWiseData?.some((j) => j.barcode == product?.barcode)
                  ) {
                    productWiseData?.map((change) => {
                      if (change.barcode == product?.barcode) {
                        return {
                          ...change,
                          qty: (change.qty -= product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty),
                          totalAmount: (change.totalAmount +=
                            (product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty) *
                            (product?.discountPrice
                              ? product?.discountPrice
                              : e?.customerDetails?.[0]?.priceLevel?.[0]?.id ==
                                1
                              ? product?.trade_price
                              : e?.customerDetails?.[0]?.priceLevel?.[0]?.id ==
                                2
                              ? product?.warehouse_price
                              : product?.retail_price)),
                        };
                      } else {
                        return change;
                      }
                    });
                  } else {
                    product.qty = -(product?.DamageQty
                      ? product?.DamageQty
                      : product?.saleQty);
                    product.totalAmount = -(
                      (product?.DamageQty
                        ? product?.DamageQty
                        : product?.saleQty) *
                      (product?.discountPrice
                        ? product?.discountPrice
                        : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                        ? product?.trade_price
                        : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                        ? product?.warehouse_price
                        : product?.retail_price)
                    );
                    productWiseData?.push(product);
                    return;
                  }
                });
            });

          productWiseData =
            productWiseData &&
            productWiseData?.length > 0 &&
            productWiseData?.filter((e) => {
              return Number(e?.qty) > 0;
            });

          let sortData =
            productWiseData &&
            productWiseData?.length > 0 &&
            productWiseData?.sort((a, b) => {
              return b.qty - a?.qty;
            });

          sortData.length = 100;

          setProductSaleData(sortData && sortData?.length > 0 ? sortData : []);

          setPdfDownloadView({
            ...pdfDownloadView,
            bestSellerTop100: true,
          });

          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          console.log(error, "error");
        });
    } else if (
      selectedReportType?.toLowerCase() == "sale report by department"
    ) {
      if (searchDate?.selected && !startDate) {
        alert("Start Date is missing");
        return;
      }

      if (searchDate?.selected && !endDate) {
        alert("End Date is missing");
        return;
      }

      setLoading(true);

      let data = await sessionStorage.getItem("userData");
      data = JSON.parse(data);
      let token = data;

      axios
        .get(`${Base_Uri}get-all-type-invoice`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Include other headers as needed
          },
        })
        .then(async (res) => {
          if (!res?.status) {
            setLoading(false);
            alert(res.message);
            return;
          }

          let { data } = res.data;

          let allInvoices = [...data];

          if (searchDate?.selected && startDate && endDate) {
            let filteredData =
              data &&
              data?.length > 0 &&
              data?.filter((e, i) => {
                let date = e?.saleDate
                  ? e?.saleDate
                  : e?.saleReturnDate
                  ? e?.saleReturnDate
                  : e?.exchangeDate;

                let addDate = new Date(date);

                // Parse selectedDate and dateFrom
                let selectedDateParts = endDate?.split("/");
                let dateFromParts = startDate?.split("/");

                // Create Date objects from the parsed components
                let selectedDateTime = new Date(
                  parseInt(selectedDateParts[2]), // Year
                  parseInt(selectedDateParts[1]), // Month (zero-based)
                  parseInt(selectedDateParts[0]), // Day
                  23, // Hours
                  59, // Minutes
                  59 // Seconds
                );

                let dateFromTime = new Date(
                  parseInt(dateFromParts[2]), // Year
                  parseInt(dateFromParts[1]), // Month (zero-based)
                  parseInt(dateFromParts[0]), // Day
                  0, // Hours
                  0, // Minutes
                  0 // Seconds
                );

                // Check if addDate is between dateFrom and selectedDate
                return addDate >= dateFromTime && addDate <= selectedDateTime;
              });

            allInvoices = filteredData;
          }

          let productWiseData = [];

          allInvoices &&
            allInvoices?.length > 0 &&
            allInvoices?.forEach((e, i) => {
              e?.productDetails &&
                e?.productDetails?.length > 0 &&
                e?.productDetails?.map((product, i) => {
                  if (e?.saleReturnDate) {
                    if (
                      productWiseData &&
                      productWiseData?.length > 0 &&
                      productWiseData?.some(
                        (j) => j.department == product?.department
                      )
                    ) {
                      productWiseData?.map((change) => {
                        if (change.department == product?.department) {
                          return {
                            ...change,
                            qty: (change.qty -= product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty),
                            totalAmount: (change.totalAmount -=
                              (product?.DamageQty
                                ? product?.DamageQty
                                : product?.saleQty) *
                              (product?.discountPrice
                                ? product?.discountPrice
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 1
                                ? product?.trade_price
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 2
                                ? product?.warehouse_price
                                : product?.retail_price)),
                          };
                        } else {
                          return change;
                        }
                      });
                    } else {
                      if (e?.saleReturnDate) {
                        product.qty = -(product.DamageQty
                          ? product.DamageQty
                          : product.saleQty);
                        product.totalAmount = -(
                          (product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty) *
                          (product?.discountPrice
                            ? product?.discountPrice
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                            ? product?.trade_price
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                            ? product?.warehouse_price
                            : product?.retail_price)
                        );

                        productWiseData?.push(product);
                        return;
                      }
                      product.qty = product.DamageQty
                        ? product.DamageQty
                        : product.saleQty;
                      product.totalAmount =
                        (product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty) *
                        (product?.discountPrice
                          ? product?.discountPrice
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                          ? product?.trade_price
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                          ? product?.warehouse_price
                          : product?.retail_price);

                      productWiseData?.push(product);
                    }
                  } else {
                    if (
                      productWiseData &&
                      productWiseData?.length > 0 &&
                      productWiseData?.some(
                        (j) => j.department == product?.department
                      )
                    ) {
                      productWiseData?.map((change) => {
                        if (change.department == product?.department) {
                          return {
                            ...change,
                            qty: (change.qty += product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty),
                            totalAmount: (change.totalAmount +=
                              (product?.DamageQty
                                ? product?.DamageQty
                                : product?.saleQty) *
                              (product?.discountPrice
                                ? product?.discountPrice
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 1
                                ? product?.trade_price
                                : e?.customerDetails?.[0]?.priceLevel?.[0]
                                    ?.id == 2
                                ? product?.warehouse_price
                                : product?.retail_price)),
                          };
                        } else {
                          return change;
                        }
                      });
                    } else {
                      if (e?.saleReturnDate) {
                        product.qty = -(product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty);
                        product.totalAmount = -(
                          (product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty) *
                          (product?.discountPrice
                            ? product?.discountPrice
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                            ? product?.trade_price
                            : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                            ? product?.warehouse_price
                            : product?.retail_price)
                        );

                        productWiseData?.push(product);
                        return;
                      }
                      product.qty = product?.DamageQty
                        ? product?.DamageQty
                        : product?.saleQty;
                      product.totalAmount =
                        (product?.DamageQty
                          ? product?.DamageQty
                          : product?.saleQty) *
                        (product?.discountPrice
                          ? product?.discountPrice
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                          ? product?.trade_price
                          : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                          ? product?.warehouse_price
                          : product?.retail_price);

                      productWiseData?.push(product);
                    }
                  }
                });

              e?.returnProductDetails &&
                e?.returnProductDetails?.length > 0 &&
                e?.returnProductDetails?.map((product, i) => {
                  if (
                    productWiseData &&
                    productWiseData?.length > 0 &&
                    productWiseData?.some(
                      (j) => j.department == product?.department
                    )
                  ) {
                    productWiseData?.map((change) => {
                      if (change.department == product?.department) {
                        return {
                          ...change,
                          qty: (change.qty -= product?.DamageQty
                            ? product?.DamageQty
                            : product?.saleQty),
                          totalAmount: (change.totalAmount +=
                            (product?.DamageQty
                              ? product?.DamageQty
                              : product?.saleQty) *
                            (product?.discountPrice
                              ? product?.discountPrice
                              : e?.customerDetails?.[0]?.priceLevel?.[0]?.id ==
                                1
                              ? product?.trade_price
                              : e?.customerDetails?.[0]?.priceLevel?.[0]?.id ==
                                2
                              ? product?.warehouse_price
                              : product?.retail_price)),
                        };
                      } else {
                        return change;
                      }
                    });
                  } else {
                    product.qty = -(product?.DamageQty
                      ? product?.DamageQty
                      : product?.saleQty);
                    product.totalAmount = -(
                      (product?.DamageQty
                        ? product?.DamageQty
                        : product?.saleQty) *
                      (product?.discountPrice
                        ? product?.discountPrice
                        : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 1
                        ? product?.trade_price
                        : e?.customerDetails?.[0]?.priceLevel?.[0]?.id == 2
                        ? product?.warehouse_price
                        : product?.retail_price)
                    );
                    productWiseData?.push(product);
                    return;
                  }
                });
            });

          productWiseData =
            productWiseData &&
            productWiseData?.length > 0 &&
            productWiseData?.filter((e) => {
              return Number(e?.qty) > 0;
            });

          let sortData =
            productWiseData &&
            productWiseData?.length > 0 &&
            productWiseData?.sort((a, b) => {
              return b.qty - a?.qty;
            });

          setProductSaleData(sortData && sortData?.length > 0 ? sortData : []);

          setPdfDownloadView({
            ...pdfDownloadView,
            saleReportByDepartment: true,
          });

          setLoading(false);
        })
        .catch((error) => {
          alert(error?.message);
          console.log(error, "error");
        });
    }
  };

  const StockReportHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "normal",
                marginTop: "20px",
              }}
            >
              Stock Report
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              S.no
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Product
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Cost
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Sell (A)
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Sell (B)
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Sell (C)
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Avg Sell
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const StockReportDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <StockReportHeader />

        <View>
          {allProducts && allProducts?.length > 0
            ? allProducts?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      borderBottomWidth: 1,
                      borderColor: "#000",
                      padding: "3px 10px",
                    }}
                  >
                    <View style={{ flex: 0.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {index + 1}
                      </Text>
                    </View>

                    <View style={{ flex: 4 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item.product_name}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        £
                        {Number(
                          Number(item.cost_price || 0) * Number(item?.qty || 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        £
                        {Number(
                          Number(item.trade_price || 0) * Number(item?.qty || 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        £
                        {Number(
                          Number(item.warehouse_price || 0) *
                            Number(item?.qty || 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        £
                        {Number(
                          Number(item.retail_price || 0) *
                            Number(item?.qty || 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        £
                        {Number(
                          ((Number(item?.trade_price) +
                            Number(item?.warehouse_price) +
                            Number(item?.retail_price)) /
                            3) *
                            (item?.qty || 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.qty}
                      </Text>
                    </View>
                  </View>
                );
              })
            : ""}

          <View
            style={{
              flexDirection: "row",
              // borderWidth: 1,
              borderColor: "#000",
              padding: "5px 10px",
              borderLeftWidth: 1,
              borderRightWidth: 1,
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flex: 4.5 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 9,
                  fontWeight: "bold",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                £
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return Number(
                      Number(pre || 0) +
                        Number((curr.qty || 0) * curr?.cost_price)
                    ).toFixed(2);
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                £
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return Number(
                      Number(pre || 0) +
                        Number((curr.qty || 0) * curr?.trade_price)
                    ).toFixed(2);
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                £
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return Number(
                      Number(pre || 0) +
                        Number((curr.qty || 0) * curr?.warehouse_price)
                    ).toFixed(2);
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                £
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return Number(
                      Number(pre || 0) +
                        Number((curr.qty || 0) * curr?.retail_price)
                    ).toFixed(2);
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                £
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return Number(
                      Number(pre || 0) +
                        Number(
                          (curr.qty || 0) *
                            ((Number(curr?.retail_price) +
                              Number(curr?.trade_price) +
                              Number(curr?.warehouse_price)) /
                              3)
                        )
                    ).toFixed(2);
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontFamily: "MyFont",
                  fontSize: 8,
                  fontWeight: "bold",
                }}
              >
                {allProducts &&
                  allProducts?.length > 0 &&
                  allProducts?.reduce((pre, curr) => {
                    return pre + Number(curr?.qty);
                  }, 0)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const MinimumStockReportHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              Minumum Stock Level
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 3.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              DESCRIPTION
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              DEPT
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              CATEGORY
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              COLOR
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              BARCODE
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              MAKE
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              MODEL
            </Text>
          </View>

          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 0.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              ML
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const MinimumStockReportDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <MinimumStockReportHeader />

        <View>
          {minimumStockProduct && minimumStockProduct?.length > 0
            ? minimumStockProduct?.map((item, index) => {
                return (
                  <View
                    key={index}
                    style={{
                      flexDirection: "row",
                      borderBottomWidth: 1,
                      borderColor: "#000",
                      padding: "3px 10px",
                    }}
                  >
                    <View style={{ flex: 3.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item.product_name}
                      </Text>
                    </View>

                    <View style={{ flex: 1.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.department}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.category}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.product_color}
                      </Text>
                    </View>

                    <View style={{ flex: 1.5 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.barcode}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.make}
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>
                        {item?.model}
                      </Text>
                    </View>

                    <View style={{ flex: 0.5 }}>
                      <Text
                        style={{
                          fontFamily: "MyFont",
                          fontSize: 8,
                          color: Colors.red,
                        }}
                      >
                        {item?.qty || 0}
                      </Text>
                    </View>

                    <View style={{ flex: 0.5 }}>
                      <Text
                        style={{
                          fontFamily: "MyFont",
                          fontSize: 8,
                          color: Colors.red,
                        }}
                      >
                        {item?.reminder_qty || 0}
                      </Text>
                    </View>
                  </View>
                );
              })
            : ""}
        </View>
      </Page>
    </Document>
  );

  const UserSaleReportHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: "12px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              User report for {selectedEmployee}
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const UserSaleReportDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }} orientation="landscape">
        <UserSaleReportHeader />

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Invoice Details:
          </Text>

          {Object.entries(employeeInvoices).map(([key, value]) => {
            return (
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    fontFamily: "MyFont",
                    color: "red",
                  }}
                >
                  {key}
                </Text>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Date
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Invoice Amount
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Paid
                    </Text>
                  </View>

                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      MOP
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Cash
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      On Account
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Card
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Online
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Cheque
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Invoice Type
                    </Text>
                  </View>
                </View>

                {value &&
                  value.length > 0 &&
                  value?.map((e, i) => {
                    return (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottomWidth: 1,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              fontFamily: "MyFont",
                            }}
                          >
                            {new Date(
                              e?.saleDate
                                ? e?.saleDate
                                : e?.saleReturnDate
                                ? e?.saleReturnDate
                                : e?.exchangeDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.saleReturnDate && "-"} £
                            {Number(e?.subtotal).toFixed(2)}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £
                            {Number(e?.cash || 0) +
                              Number(e?.online || 0) +
                              Number(e?.card || 0)}
                          </Text>
                        </View>

                        <View style={{ flex: 2 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            Array?.isArray(e?.paymentMethod)
                              ? e?.paymentMethod?.map((e) => e).join(",")
                              : e?.paymentMethod}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "refund cash"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "refund cash"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.cash || 0}`}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "credit note"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "credit note"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.credit || 0}`}
                          </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "refund card"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "refund card"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.card || 0}`}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £{e?.online || 0}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £{e?.cheque || 0}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.exchangeDate ? "Exchange" : e?.status}
                          </Text>
                        </View>
                      </View>
                    );
                  })}

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      Total
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (curr?.saleReturnDate) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.subtotal);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (
                              (pre || 0) +
                              Number(
                                Number(curr?.cash || 0) +
                                  Number(curr?.online || 0) +
                                  Number(curr?.card || 0)
                              )
                            );
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 2 }}></View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund cash"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "refund cash"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.cash || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.credit || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.card || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.online || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.cheque || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}></View>
                </View>
              </View>
            );
          })}
        </View>

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Refund Details:
          </Text>

          {Object.keys(refundInvoices).length > 0 &&
            Object.entries(refundInvoices).map(([key, value]) => {
              return (
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      fontFamily: "MyFont",
                      color: "red",
                    }}
                  >
                    {key}
                  </Text>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Date
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Refund Amount
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Payment Method
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Transaction Type
                      </Text>
                    </View>

                    <View style={{ flex: 5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Reason
                      </Text>
                    </View>
                  </View>

                  {value &&
                    value.length > 0 &&
                    value?.map((e, i) => {
                      return (
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "bold",
                                fontFamily: "MyFont",
                              }}
                            >
                              {new Date(e?.date).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                            </Text>
                          </View>

                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              £{Number(e?.refund).toFixed(2)}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.paymentMethod[0]}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.transactionType}
                            </Text>
                          </View>

                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.reason}
                            </Text>
                          </View>
                        </View>
                      );
                    })}

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "normal",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        £
                        {Number(
                          value &&
                            value?.length > 0 &&
                            value?.reduce((pre, curr) => {
                              return (pre || 0) + Number(curr?.refund);
                            }, 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 9 }}></View>
                  </View>
                </View>
              );
            })}
        </View>

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Credit Payment By Customer:
          </Text>

          {Object.keys(creditPayment).length > 0 &&
            Object.entries(creditPayment).map(([key, value]) => {
              return (
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      fontFamily: "MyFont",
                      color: "red",
                    }}
                  >
                    {key}
                  </Text>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Date
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total Amount
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Payment Method
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Transaction Type
                      </Text>
                    </View>

                    <View style={{ flex: 5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Reason
                      </Text>
                    </View>
                  </View>

                  {value &&
                    value.length > 0 &&
                    value?.map((e, i) => {
                      return (
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "bold",
                                fontFamily: "MyFont",
                              }}
                            >
                              {new Date(e?.date).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                            </Text>
                          </View>

                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              £{Number(e?.deductCredit).toFixed(2)}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.paymentMethod[0]}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.transactionType}
                            </Text>
                          </View>

                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.reason}
                            </Text>
                          </View>
                        </View>
                      );
                    })}

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "normal",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        £
                        {Number(
                          value &&
                            value?.length > 0 &&
                            value?.reduce((pre, curr) => {
                              return (pre || 0) + Number(curr?.deductCredit);
                            }, 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 9 }}></View>
                  </View>
                </View>
              );
            })}
        </View>

        <View style={{ marginTop: "20px" }}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
            }}
          >
            Expense Details:
          </Text>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Date
              </Text>
            </View>

            <View style={{ flex: 9 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                description
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Amount
              </Text>
            </View>
          </View>

          {userExpenses &&
            userExpenses.length > 0 &&
            userExpenses.map((e, i) => {
              return (
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      {new Date(
                        e?.date ? e?.date : e?.created_at
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                    </Text>
                  </View>

                  <View style={{ flex: 9 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      {e?.description}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      £{e?.amount || 0}
                    </Text>
                  </View>
                </View>
              );
            })}

          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flex: 10 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  userExpenses &&
                    userExpenses?.length > 0 &&
                    userExpenses?.reduce((pre, curr) => {
                      return (pre || 0) + Number(curr?.amount || 0);
                    }, 0)
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: "20px" }}>
          <Text
            style={{
              fontSize: 10,
              fontWeight: "bold",
              fontFamily: "MyFont",
              borderBottomWidth: 1,
              width: "100%",
            }}
          >
            Payment Summary:
          </Text>

          <View style={{ marginTop: "10px" }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.cash || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "refund cash"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "refund cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Refunds:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  userRefunds && userRefunds?.length > 0
                    ? userRefunds?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  employeeShiftCreditPayment &&
                    employeeShiftCreditPayment?.length > 0
                    ? employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.deductCredit || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Expenses:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  userExpenses && userExpenses?.length > 0
                    ? userExpenses?.reduce((pre, curr) => {
                        return (pre || 0) + (Number(curr?.amount) || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Remaining Cash: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "refund cash"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "refund cash"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.cash || 0);
                      }
                    }, 0)
                ) +
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0 &&
                      employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    userRefunds &&
                      userRefunds?.length > 0 &&
                      userRefunds?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    userExpenses &&
                      userExpenses?.length > 0 &&
                      userExpenses?.reduce((pre, curr) => {
                        return (pre || 0) + Number(curr?.amount);
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.credit || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "credit note"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "credit note"
                          )
                        ) {
                          return (pre || 0) + (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account Refunds :{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  userRefunds && userRefunds?.length > 0
                    ? userRefunds?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "onaccount"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total On Accounts: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "credit note"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "credit note"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.credit || 0);
                      }
                    }, 0)
                ) -
                  Number(
                    userRefunds &&
                      userRefunds?.length > 0 &&
                      userRefunds?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "onaccount"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.card || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "refund card"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "refund card"
                          )
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card Refunds :{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  userRefunds && userRefunds?.length > 0
                    ? userRefunds?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  employeeShiftCreditPayment &&
                    employeeShiftCreditPayment?.length > 0
                    ? employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + (curr?.deductCredit || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Remaining Card:- £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "refund card"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "refund card"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.card || 0);
                      }
                    }, 0)
                ) +
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0 &&
                      employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    userRefunds &&
                      userRefunds?.length > 0 &&
                      userRefunds?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Bank Transfer:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.online || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0
                      ? employeeShiftCreditPayment?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) => e.toLowerCase() == "bank transfer"
                            )
                          ) {
                            return (pre || 0) + (curr?.deductCredit || 0);
                          } else {
                            return pre;
                          }
                        }, 0)
                      : 0
                  )
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Bank Transfer: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.online || 0);
                    }, 0)
                ) +
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0 &&
                      employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "bank transfer"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cheques:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.cheque || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0
                      ? employeeShiftCreditPayment?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) => e.toLowerCase() == "cheque"
                            )
                          ) {
                            return (pre || 0) + (curr?.deductCredit || 0);
                          } else {
                            return pre;
                          }
                        }, 0)
                      : 0
                  )
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              Total Cheques: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.cheque || 0);
                    }, 0)
                ) +
                  Number(
                    employeeShiftCreditPayment &&
                      employeeShiftCreditPayment?.length > 0 &&
                      employeeShiftCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cheque"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <View style={{ marginTop: "10px", borderTopWidth: 1 }}>
              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Sales: £
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.saleDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Returns: £-
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.saleReturnDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Exchange: £
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.exchangeDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  color: "black",
                  borderTopWidth: 1,
                }}
              >
                Net Sales:{" "}
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "normal",
                    fontFamily: "MyFont",
                    color: "red",
                  }}
                >
                  £
                  {Number(
                    shiftInvoices && shiftInvoices?.length > 0
                      ? shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            !Array.isArray(curr?.paymentMethod) &&
                            (curr?.paymentMethod?.toLowerCase() ==
                              "refund cash" ||
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note" ||
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card")
                          ) {
                            return (pre || 0) - (curr?.subtotal || 0);
                          }

                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) =>
                                e?.toLowerCase() == "refund cash" ||
                                e?.toLowerCase() == "credit note" ||
                                e?.toLowerCase() == "refund card"
                            )
                          ) {
                            return (pre || 0) + (curr?.subtotal || 0);
                          } else {
                            return (pre || 0) + (curr?.subtotal || 0);
                          }
                        }, 0)
                      : 0
                  ).toFixed(2)}{" "}
                </Text>
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Gross Collection:{" "}
                <Text
                  style={{
                    color: "red",
                    fontFamily: "MyFont",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  £
                  {Number(
                    Number(
                      shiftInvoices &&
                        shiftInvoices?.length > 0 &&
                        shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.saleReturnDate &&
                            curr?.paymentMethod?.toLowerCase() == "refund cash"
                          ) {
                            return (pre || 0) - curr?.subtotal;
                          } else if (
                            curr?.exchangeDate &&
                            Array?.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e, i) => e?.toLowerCase() == "refund cash"
                            )
                          ) {
                            return (pre || 0) + curr?.subtotal;
                          } else {
                            return (pre || 0) + (curr?.cash || 0);
                          }
                        }, 0)
                    ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.card || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.online || 0);
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.credit || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.cheque || 0);
                          }, 0)
                      )
                  ).toFixed(2)}
                </Text>
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                }}
              >
                Extra cash received on invoices:{" "}
                <Text
                  style={{
                    color: "red",
                    fontFamily: "MyFont",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  £
                  {Number(
                    Number(
                      shiftInvoices &&
                        shiftInvoices?.length > 0 &&
                        shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.saleReturnDate &&
                            curr?.paymentMethod?.toLowerCase() == "refund cash"
                          ) {
                            return (pre || 0) - curr?.subtotal;
                          } else if (
                            curr?.exchangeDate &&
                            Array?.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e, i) => e?.toLowerCase() == "refund cash"
                            )
                          ) {
                            return (pre || 0) + curr?.subtotal;
                          } else {
                            return (pre || 0) + (curr?.cash || 0);
                          }
                        }, 0)
                    ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.card || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.online || 0);
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.credit || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.cheque || 0);
                          }, 0)
                      ) -
                      Number(
                        shiftInvoices &&
                          shiftInvoices.length > 0 &&
                          shiftInvoices.reduce((pre, curr) => {
                            if (curr?.saleReturnDate) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.subtotal);
                            }
                          }, 0)
                      )
                  ).toFixed(2)}
                </Text>
              </Text>

              <Text></Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  const SaleReportBetweenDatesHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName} (Sales Report)
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              {vat?.companyName} (Sales Report)
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              From Date: {startDate} to Date: {endDate}
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Date
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Cash
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              on Account
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Online
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Card
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Cheque
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Total
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const SaleReportBetweenDatesDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }}>
        <SaleReportBetweenDatesHeader />

        <View>
          {Object.entries(allInvoices).map(([key, value]) => {
            return (
              <View style={{ marginTop: "10px" }}>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      {new Date(key)?.toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                      })}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund cash"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j && j?.toLowerCase() == "refund cash"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.cash || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j && j?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.credit || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.card || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.online || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.cheque || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array.isArray(curr?.paymentMethod) &&
                              (curr.paymentMethod?.toLowerCase() ==
                                "refund cash" ||
                                curr.paymentMethod?.toLowerCase() ==
                                  "credit note")
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.subtotal);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })}
        </View>

        <View
          style={{ padding: "5px 0px", width: "100%", flexDirection: "row" }}
        >
          <View style={{ flex: 1 }}>
            <Text
              style={{
                fontSize: 10,
                fontWeight: "bold",
                fontFamily: "MyFont",
                width: "100%",
              }}
            >
              Total
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      if (
                        curr?.paymentMethod &&
                        !Array.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.toLowerCase() == "refund cash"
                      ) {
                        return (pre || 0) - (curr?.subtotal || 0);
                      } else if (
                        curr?.paymentMethod &&
                        Array.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (j) => j && j?.toLowerCase() == "refund cash"
                        )
                      ) {
                        return (pre || 0) + (curr?.subtotal || 0);
                      } else {
                        return (pre || 0) + (curr?.cash || 0);
                      }
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      if (
                        curr?.paymentMethod &&
                        !Array.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.toLowerCase() == "credit note"
                      ) {
                        return (pre || 0) - (curr?.subtotal || 0);
                      } else if (
                        curr?.paymentMethod &&
                        Array.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (j) => j && j?.toLowerCase() == "credit note"
                        )
                      ) {
                        return (pre || 0) + (curr?.subtotal || 0);
                      } else {
                        return (pre || 0) + (curr?.credit || 0);
                      }
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.card || 0);
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.online || 0);
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.cheque || 0);
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              £
              {Number(
                allUngroupedInvoice && allUngroupedInvoice?.length > 0
                  ? allUngroupedInvoice?.reduce((pre, curr) => {
                      if (
                        curr?.paymentMethod &&
                        !Array.isArray(curr?.paymentMethod) &&
                        (curr?.paymentMethod?.toLowerCase() == "refund cash" ||
                          curr?.paymentMethod?.toLowerCase() == "credit note")
                      ) {
                        return (pre || 0) - (curr?.subtotal || 0);
                      }

                      if (
                        curr?.paymentMethod &&
                        Array.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e) =>
                            e?.toLowerCase() == "refund cash" ||
                            e?.toLowerCase() == "credit note"
                        )
                      ) {
                        return (pre || 0) + (curr?.subtotal || 0);
                      } else {
                        return (pre || 0) + (curr?.subtotal || 0);
                      }
                    }, 0)
                  : 0
              ).toFixed(2)}{" "}
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );

  const ProductSaleReportForSelectedCustomerHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Product sale report by customer: Customer:{" "}
              {selectedCustomer?.customerName}
            </Text>

            {startDate && endDate && searchDate?.selected && (
              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                }}
              >
                From Date: {startDate} to Date: {endDate}
              </Text>
            )}
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Account no: {selectedCustomer?.accountNo}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Trans ID
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Date
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Unit Price
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Total
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ProductSaleReportSelectedCustomerDocument = () => {
    let allProducts =
      customerInvoices &&
      customerInvoices?.length > 0 &&
      customerInvoices
        ?.flatMap((e) => {
          let status = e?.saleReturnDate ? "Return" : "Sale";

          if (e.productDetails && e.productDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.productDetails.forEach((product) => {
              product.productStatus = status;
            });

            // Return productDetails array with productStatus set
            return e.productDetails;
          } else {
            // Return an empty array if productDetails is empty or null
            return [];
          }
        })
        .filter(Boolean);

    let returnProduct =
      customerInvoices &&
      customerInvoices?.length > 0 &&
      customerInvoices
        ?.flatMap((e) => {
          let status = "Return";

          if (e.returnProductDetails && e.returnProductDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.returnProductDetails.forEach((product) => {
              product.productStatus = status;
            });

            // Return returnProductDetails array with productStatus set
            return e.returnProductDetails;
          } else {
            // Return an empty array if returnProductDetails is empty or null
            return [];
          }
        })
        .filter(Boolean);

    allProducts = allProducts && allProducts?.length > 0 ? allProducts : [];
    returnProduct =
      returnProduct && returnProduct?.length > 0 ? returnProduct : [];

    let products = [...allProducts, ...returnProduct];

    return (
      <Document size="A4">
        {/* <Header /> */}

        <Page style={{ padding: "0px 10px" }}>
          <ProductSaleReportForSelectedCustomerHeader />

          <View>
            {customerInvoices &&
              customerInvoices?.length > 0 &&
              customerInvoices?.map((e, i) => {
                return (
                  <View style={{ width: "100%" }}>
                    {e?.productDetails &&
                      e?.productDetails?.length > 0 &&
                      e?.productDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}{" "}
                                {j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"} £
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}£
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}

                    {e?.returnProductDetails &&
                      e?.returnProductDetails?.length > 0 &&
                      e?.returnProductDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -{j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                - £
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                - £
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                );
              })}
          </View>

          <View
            style={{
              padding: "5px 0px",
              width: "100%",
              flexDirection: "row",
              borderTopWidth: 1,
            }}
          >
            <View style={{ flex: 7 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  width: "100%",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                {products &&
                  products?.length > 0 &&
                  products?.reduce((pre, curr) => {
                    if (curr?.productStatus?.toLowerCase() == "return") {
                      return (
                        (pre || 0) -
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    } else {
                      return (
                        (pre || 0) +
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    }
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}></View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  customerInvoices &&
                    customerInvoices?.length > 0 &&
                    customerInvoices?.reduce((pre, curr) => {
                      if (curr?.saleReturnDate) {
                        return (
                          (pre || 0) -
                          (Number(curr?.total || 0) -
                            Number(curr?.discount || 0))
                        );
                      } else {
                        return (
                          (pre || 0) +
                          (Number(curr?.total || 0) -
                            Number(curr?.discount || 0))
                        );
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const CustomerSpecificProductSaleReportHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Product sale report by customer:{" "}
              <Text style={{ marginLeft: "10px", fontSize: "8px" }}>
                {" "}
                Customer: {selectedCustomer?.customerName}{" "}
              </Text>{" "}
            </Text>

            {startDate && endDate && searchDate?.selected && (
              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                }}
              >
                From Date: {startDate} to Date: {endDate}
              </Text>
            )}
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
                marginTop: "10px",
              }}
            >
              Account no: {selectedCustomer?.accountNo}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Trans ID
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Date
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Unit Price
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Total
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const CustomerSpecificProductSaleReportDocument = () => {
    let allProducts =
      customerProductsSale &&
      customerProductsSale?.length > 0 &&
      customerProductsSale
        ?.flatMap((e) => {
          let status = e?.saleReturnDate ? "Return" : "Sale";

          if (e.productDetails && e.productDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.productDetails.forEach((product) => {
              product.productStatus = status;
              product.customerDetails = e?.customerDetails;
            });

            // Return productDetails array with productStatus set
            return e.productDetails;
          } else {
            // Return an empty array if productDetails is empty or null
            return [];
          }
        })
        .filter(Boolean);

    let returnProduct =
      customerProductsSale &&
      customerProductsSale?.length > 0 &&
      customerProductsSale
        ?.flatMap((e) => {
          let status = "Return";

          if (e.returnProductDetails && e.returnProductDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.returnProductDetails.forEach((product) => {
              product.productStatus = status;
              product.customerDetails = e?.customerDetails;
            });

            // Return returnProductDetails array with productStatus set
            return e.returnProductDetails;
          } else {
            // Return an empty array if returnProductDetails is empty or null
            return [];
          }
        })
        .filter(Boolean);

    allProducts = allProducts && allProducts?.length > 0 ? allProducts : [];
    returnProduct =
      returnProduct && returnProduct?.length > 0 ? returnProduct : [];

    let products = [...allProducts, ...returnProduct];

    return (
      <Document size="A4">
        <Page style={{ padding: "0px 10px" }}>
          <CustomerSpecificProductSaleReportHeader />

          <View>
            {customerProductsSale &&
              customerProductsSale?.length > 0 &&
              customerProductsSale?.map((e, i) => {
                return (
                  <View style={{ width: "100%" }}>
                    {e?.productDetails &&
                      e?.productDetails?.length > 0 &&
                      e?.productDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}{" "}
                                {j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"} £
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}£
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}

                    {e?.returnProductDetails &&
                      e?.returnProductDetails?.length > 0 &&
                      e?.returnProductDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -{j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -£
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -£
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel[0]?.id == 2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                );
              })}
          </View>

          <View
            style={{
              padding: "5px 0px",
              width: "100%",
              flexDirection: "row",
              borderTopWidth: 1,
            }}
          >
            <View style={{ flex: 7 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  width: "100%",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                {products &&
                  products?.length > 0 &&
                  products?.reduce((pre, curr) => {
                    if (curr?.productStatus?.toLowerCase() == "return") {
                      return (
                        (pre || 0) -
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    } else {
                      return (
                        (pre || 0) +
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    }
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}></View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {products &&
                  products?.length > 0 &&
                  products?.reduce((pre, curr) => {
                    if (curr?.productStatus?.toLowerCase() == "return") {
                      return (
                        (pre || 0) -
                        (curr?.discountPrice
                          ? curr?.discountPrice
                          : curr?.customerDetails?.priceLevel[0]?.id == 1
                          ? curr?.trade_price
                          : curr?.customerDetails?.priceLevel[0]?.id == 2
                          ? curr?.discountPrice
                          : curr?.retail_price) *
                          (curr?.DamageQty
                            ? Number(curr?.DamageQty)
                            : Number(curr?.saleQty))
                      );
                    } else {
                      return (
                        (pre || 0) +
                        (curr?.discountPrice
                          ? curr?.discountPrice
                          : curr?.customerDetails?.priceLevel[0]?.id == 1
                          ? curr?.trade_price
                          : curr?.customerDetails?.priceLevel[0]?.id == 2
                          ? curr?.discountPrice
                          : curr?.retail_price) *
                          (curr?.DamageQty
                            ? Number(curr?.DamageQty)
                            : Number(curr?.saleQty))
                      );
                    }
                  }, 0)}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const ProductSaleReportForSelectedDatesHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Product sale report by customer:{" "}
              <Text style={{ marginLeft: "10px", fontSize: "8px" }}>
                {" "}
                Customer: All{" "}
              </Text>{" "}
            </Text>

            {startDate && endDate && searchDate?.selected && (
              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                }}
              >
                From Date: {startDate} to Date: {endDate}
              </Text>
            )}

            {
              <Text
                style={{
                  color: "black",
                  fontSize: "10px",
                  fontFamily: "MyFont",
                  fontWeight: "normal",
                }}
              >
                Product:
                <Text style={{ marginLeft: "10px", fontSize: "8px" }}>
                  {" "}
                  {selectedProduct?.productName}{" "}
                </Text>{" "}
              </Text>
            }
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Trans ID
            </Text>
          </View>

          <View style={{ flex: 1.5 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Date
            </Text>
          </View>

          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Unit Price
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Total
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const ProductSaleReportForSelectedDatesDocument = () => {
    let allProducts =
      productSaleData &&
      productSaleData?.length > 0 &&
      productSaleData
        ?.flatMap((e) => {
          let status = e?.saleReturnDate ? "Return" : "Sale";

          if (e.productDetails && e.productDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.productDetails.forEach((product) => {
              product.productStatus = status;
              product.customerDetails =
                e?.customerDetails && e?.customerDetails?.length > 0
                  ? e?.customerDetails[0]
                  : e?.customerDetails;
            });

            // Return productDetails array with productStatus set
            return e.productDetails;
          } else {
            // Return an empty array if productDetails is empty or null
            return [];
          }
        })
        .filter(Boolean);

    let returnProduct =
      productSaleData &&
      productSaleData?.length > 0 &&
      productSaleData
        ?.flatMap((e) => {
          let status = "Return";

          if (e.returnProductDetails && e.returnProductDetails.length > 0) {
            // Iterate through each productDetail and set productStatus
            e.returnProductDetails.forEach((product) => {
              product.productStatus = status;
              product.customerDetails =
                e?.customerDetails && e?.customerDetails?.length > 0
                  ? e?.customerDetails[0]
                  : e?.customerDetails;
            });

            return e.returnProductDetails;
          } else {
            return [];
          }
        })
        .filter(Boolean);

    allProducts = allProducts && allProducts?.length > 0 ? allProducts : [];
    returnProduct =
      returnProduct && returnProduct?.length > 0 ? returnProduct : [];

    let products = [...allProducts, ...returnProduct];

    return (
      <Document size="A4">
        <Page style={{ padding: "0px 10px" }}>
          <ProductSaleReportForSelectedDatesHeader />

          <View>
            {productSaleData &&
              productSaleData?.length > 0 &&
              productSaleData?.map((e, i) => {
                e.customerDetails =
                  e?.customerDetails && e?.customerDetails?.length > 0
                    ? e?.customerDetails[0]
                    : e?.customerDetails;

                return (
                  <View style={{ width: "100%" }}>
                    {e?.productDetails &&
                      e?.productDetails?.length > 0 &&
                      e?.productDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}{" "}
                                {j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"} £
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel?.[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel?.[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleReturnDate && "-"}£
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel?.[0]?.id ==
                                      1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel?.[0]?.id ==
                                      2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}

                    {e?.returnProductDetails &&
                      e?.returnProductDetails?.length > 0 &&
                      e?.returnProductDetails?.map((j) => {
                        return (
                          <View
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {e?.saleDate
                                  ? `Invoice no ${e?.invoiceNumber}`
                                  : e?.saleReturnDate
                                  ? `return no ${e?.invoiceNumber}`
                                  : e?.invoiceNumber}
                              </Text>
                            </View>

                            <View style={{ flex: 1.5 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                )?.toLocaleDateString("en-GB", {
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "2-digit",
                                })}{" "}
                                {new Date(
                                  e?.saleDate
                                    ? e?.saleDate
                                    : e?.saleReturnDate
                                    ? e?.saleReturnDate
                                    : e?.exchangeDate
                                ).toLocaleTimeString()}
                              </Text>
                            </View>

                            <View style={{ flex: 4 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                {j?.productName}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -{j?.DamageQty ? j?.DamageQty : j?.saleQty}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -£
                                {j?.discountPrice
                                  ? Number(j?.discountPrice).toFixed(2)
                                  : e?.customerDetails?.priceLevel?.[0]?.id == 1
                                  ? Number(j?.trade_price).toFixed(2)
                                  : e?.customerDetails?.priceLevel?.[0]?.id == 2
                                  ? Number(j?.warehouse_price).toFixed(2)
                                  : Number(j?.retail_price).toFixed(2)}
                              </Text>
                            </View>

                            <View style={{ flex: 1 }}>
                              <Text
                                style={{
                                  color: "black",
                                  fontSize: "8px",
                                  fontFamily: "MyFont",
                                  fontWeight: "normal",
                                }}
                              >
                                -£
                                {Number(
                                  (j?.discountPrice
                                    ? Number(j?.discountPrice).toFixed(2)
                                    : e?.customerDetails?.priceLevel?.[0]?.id ==
                                      1
                                    ? Number(j?.trade_price).toFixed(2)
                                    : e?.customerDetails?.priceLevel?.[0]?.id ==
                                      2
                                    ? Number(j?.warehouse_price).toFixed(2)
                                    : Number(j?.retail_price).toFixed(2)) *
                                    (j?.DamageQty ? j?.DamageQty : j?.saleQty)
                                ).toFixed(2)}
                              </Text>
                            </View>
                          </View>
                        );
                      })}
                  </View>
                );
              })}
          </View>

          <View
            style={{
              padding: "5px 0px",
              width: "100%",
              flexDirection: "row",
              borderTopWidth: 1,
            }}
          >
            <View style={{ flex: 7 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  width: "100%",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                {products &&
                  products?.length > 0 &&
                  products?.reduce((pre, curr) => {
                    if (curr?.productStatus?.toLowerCase() == "return") {
                      return (
                        (pre || 0) -
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    } else {
                      return (
                        (pre || 0) +
                        (curr?.DamageQty
                          ? Number(curr?.DamageQty)
                          : Number(curr?.saleQty))
                      );
                    }
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}></View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {products &&
                  products?.length > 0 &&
                  products?.reduce((pre, curr) => {
                    if (curr?.productStatus?.toLowerCase() == "return") {
                      return (
                        (pre || 0) -
                        (curr?.discountPrice
                          ? curr?.discountPrice
                          : curr?.customerDetails?.priceLevel?.[0]?.id == 1
                          ? curr?.trade_price
                          : curr?.customerDetails?.priceLevel?.[0]?.id == 2
                          ? curr?.discountPrice
                          : curr?.retail_price) *
                          (curr?.DamageQty
                            ? Number(curr?.DamageQty)
                            : Number(curr?.saleQty))
                      );
                    } else {
                      return (
                        (pre || 0) +
                        (curr?.discountPrice
                          ? curr?.discountPrice
                          : curr?.customerDetails?.priceLevel?.[0]?.id == 1
                          ? curr?.trade_price
                          : curr?.customerDetails?.priceLevel?.[0]?.id == 2
                          ? curr?.discountPrice
                          : curr?.retail_price) *
                          (curr?.DamageQty
                            ? Number(curr?.DamageQty)
                            : Number(curr?.saleQty))
                      );
                    }
                  }, 0)}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const BestSellerProductHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Best Seller (Top 100)
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Barcode
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Unit Price
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Value
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const BestSellerProductDocument = () => {
    return (
      <Document size="A4">
        <Page style={{ padding: "0px 10px" }}>
          <BestSellerProductHeader />

          <View>
            {productSaleData &&
              productSaleData?.length > 0 &&
              productSaleData?.map((e, i) => {
                return (
                  <View style={{ width: "100%" }}>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 4 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          {e?.productName}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          {e?.barcode}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          {e?.qty}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          £{Number(e?.totalAmount / e?.qty).toFixed(2)}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          £{Number(e?.totalAmount).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
          </View>
        </Page>
      </Document>
    );
  };

  const DepartmentSaleReportHeader = () => {
    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "10px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Sale Report By Department
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            borderBottomWidth: 1,
            borderColor: "#000",
            paddingBottom: 5,
            paddingTop: 5,
            border: "1px solid black",
            backgroundColor: "gray",
            alignItems: "center",
            padding: 10,
            marginTop: "20px",
          }}
        >
          <View style={{ flex: 4 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Description
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Qty
            </Text>
          </View>

          <View style={{ flex: 1 }}>
            <Text
              style={{ fontFamily: "MyFont", fontSize: 8, fontWeight: "bold" }}
            >
              Amount
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const DepartmentSaleReportDocument = () => {
    return (
      <Document size="A4">
        <Page style={{ padding: "0px 10px" }}>
          <DepartmentSaleReportHeader />

          <View>
            {productSaleData &&
              productSaleData?.length > 0 &&
              productSaleData?.map((e, i) => {
                return (
                  <View style={{ width: "100%" }}>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <View style={{ flex: 4 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          {e?.department}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          {e?.qty}
                        </Text>
                      </View>

                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            color: "black",
                            fontSize: "8px",
                            fontFamily: "MyFont",
                            fontWeight: "normal",
                          }}
                        >
                          £{Number(Math.round(e?.totalAmount)).toFixed(2)}
                        </Text>
                      </View>
                    </View>
                  </View>
                );
              })}
          </View>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderTopWidth: 1,
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Total:-
              </Text>
            </View>

            <View style={{ flex: 3 }}>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Total Count:- {productSaleData?.length}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                {productSaleData &&
                  productSaleData?.length > 0 &&
                  productSaleData?.reduce((pre, curr) => {
                    return (pre || 0) + curr?.qty;
                  }, 0)}
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  Math.round(
                    productSaleData &&
                      productSaleData?.length > 0 &&
                      productSaleData?.reduce((pre, curr) => {
                        return (pre || 0) + curr?.totalAmount;
                      }, 0)
                  )
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </Page>
      </Document>
    );
  };

  const DayReportHeader = () => {
    let selectedDateParts = endDate?.split("/");
    let dateFromParts = startDate?.split("/");

    // Create Date objects from the parsed components
    let selectedDateTime = new Date(
      parseInt(selectedDateParts[2]), // Year
      parseInt(selectedDateParts[1]), // Month (zero-based)
      parseInt(selectedDateParts[0]), // Day
      23, // Hours
      59, // Minutes
      59 // Seconds
    );

    let dateFromTime = new Date(
      parseInt(dateFromParts[2]), // Year
      parseInt(dateFromParts[1]), // Month (zero-based)
      parseInt(dateFromParts[0]), // Day
      0, // Hours
      0, // Minutes
      0 // Seconds
    );

    return (
      <View fixed>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: 10,
            alignItems: "flex-start",
          }}
        >
          <View>
            <Text
              style={{
                color: "black",
                fontSize: "14px",
                fontFamily: "MyFont",
                fontWeight: "bold",
              }}
            >
              {vat?.companyName}
            </Text>
            <Text
              style={{
                color: "black",
                fontSize: "12px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Day report
            </Text>
          </View>

          <View>
            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
              }}
            >
              Print Date:{" "}
              {new Date().toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
              <Text style={{ marginLeft: "5px" }}>
                {" "}
                {new Date().toLocaleTimeString()}
              </Text>
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
                marginTop: "10px",
              }}
            >
              Start Date:{" "}
              {new Date(dateFromTime).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>

            <Text
              style={{
                color: "black",
                fontSize: "8px",
                fontFamily: "MyFont",
                fontWeight: "normal",
                marginTop: "10px",
              }}
            >
              End Date:{" "}
              {new Date(selectedDateTime).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </Text>
          </View>
        </View>
      </View>
    );
  };

  const DayReportDocument = () => (
    <Document size="A4">
      {/* <Header /> */}

      <Page style={{ padding: "0px 10px" }} orientation="landscape">
        <DayReportHeader />

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Invoice Details:
          </Text>

          {Object.entries(employeeInvoices).map(([key, value]) => {
            return (
              <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "bold",
                    fontFamily: "MyFont",
                    color: "red",
                  }}
                >
                  {key}
                </Text>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Date
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Invoice Amount
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Paid
                    </Text>
                  </View>

                  <View style={{ flex: 2 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      MOP
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Cash
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      On Account
                    </Text>
                  </View>
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Card
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Online
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Cheque
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      Invoice Type
                    </Text>
                  </View>
                </View>

                {value &&
                  value.length > 0 &&
                  value?.map((e, i) => {
                    return (
                      <View
                        style={{
                          width: "100%",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          borderBottomWidth: 1,
                        }}
                      >
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "bold",
                              fontFamily: "MyFont",
                            }}
                          >
                            {new Date(
                              e?.saleDate
                                ? e?.saleDate
                                : e?.saleReturnDate
                                ? e?.saleReturnDate
                                : e?.exchangeDate
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "2-digit",
                            })}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.saleReturnDate && "-"} £
                            {Number(e?.subtotal).toFixed(2)}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £
                            {Number(e?.cash || 0) +
                              Number(e?.online || 0) +
                              Number(e?.card || 0)}
                          </Text>
                        </View>

                        <View style={{ flex: 2 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            Array?.isArray(e?.paymentMethod)
                              ? e?.paymentMethod?.map((e) => e).join(",")
                              : e?.paymentMethod}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "refund cash"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "refund cash"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.cash || 0}`}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "credit note"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "credit note"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.credit || 0}`}
                          </Text>
                        </View>
                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.paymentMethod &&
                            !Array?.isArray(e?.paymentMethod) &&
                            e?.paymentMethod?.toLowerCase() == "refund card"
                              ? `£${-Number(e?.subtotal).toFixed(2)}`
                              : e?.paymentMethod &&
                                Array?.isArray(e?.paymentMethod) &&
                                e?.paymentMethod?.some(
                                  (j) => j?.toLowerCase() == "refund card"
                                )
                              ? `£${Number(e?.subtotal).toFixed(2)}`
                              : `£${e?.card || 0}`}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £{e?.online || 0}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            £{e?.cheque || 0}
                          </Text>
                        </View>

                        <View style={{ flex: 1 }}>
                          <Text
                            style={{
                              fontSize: 8,
                              fontWeight: "normal",
                              fontFamily: "MyFont",
                            }}
                          >
                            {e?.exchangeDate ? "Exchange" : e?.status}
                          </Text>
                        </View>
                      </View>
                    );
                  })}

                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      Total
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (curr?.saleReturnDate) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.subtotal);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (
                              (pre || 0) +
                              Number(
                                Number(curr?.cash || 0) +
                                  Number(curr?.online || 0) +
                                  Number(curr?.card || 0)
                              )
                            );
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 2 }}></View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund cash"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "refund cash"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.cash || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.credit || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            if (
                              curr?.paymentMethod &&
                              !Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else if (
                              curr?.paymentMethod &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (j) => j?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.card || 0);
                            }
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.online || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      £
                      {Number(
                        value &&
                          value?.length > 0 &&
                          value?.reduce((pre, curr) => {
                            return (pre || 0) + Number(curr?.cheque || 0);
                          }, 0)
                      ).toFixed(2)}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}></View>
                </View>
              </View>
            );
          })}
        </View>

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Refund Details:
          </Text>

          {Object.keys(refundInvoices).length > 0 &&
            Object.entries(refundInvoices).map(([key, value]) => {
              return (
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      fontFamily: "MyFont",
                      color: "red",
                    }}
                  >
                    {key}
                  </Text>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Date
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Refund Amount
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Payment Method
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Transaction Type
                      </Text>
                    </View>

                    <View style={{ flex: 5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Reason
                      </Text>
                    </View>
                  </View>

                  {value &&
                    value.length > 0 &&
                    value?.map((e, i) => {
                      return (
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "bold",
                                fontFamily: "MyFont",
                              }}
                            >
                              {new Date(e?.date).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                            </Text>
                          </View>

                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              £{Number(e?.refund).toFixed(2)}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.paymentMethod[0]}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.transactionType}
                            </Text>
                          </View>

                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.reason}
                            </Text>
                          </View>
                        </View>
                      );
                    })}

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "normal",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        £
                        {Number(
                          value &&
                            value?.length > 0 &&
                            value?.reduce((pre, curr) => {
                              return (pre || 0) + Number(curr?.refund);
                            }, 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 9 }}></View>
                  </View>
                </View>
              );
            })}
        </View>

        <View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
              marginTop: "10px",
            }}
          >
            Credit Payment By Customer:
          </Text>

          {Object.keys(creditPayment).length > 0 &&
            Object.entries(creditPayment).map(([key, value]) => {
              return (
                <View>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      fontFamily: "MyFont",
                      color: "red",
                    }}
                  >
                    {key}
                  </Text>

                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Date
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total Amount
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Payment Method
                      </Text>
                    </View>

                    <View style={{ flex: 2 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Transaction Type
                      </Text>
                    </View>

                    <View style={{ flex: 5 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        Reason
                      </Text>
                    </View>
                  </View>

                  {value &&
                    value.length > 0 &&
                    value?.map((e, i) => {
                      return (
                        <View
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            borderBottomWidth: 1,
                          }}
                        >
                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "bold",
                                fontFamily: "MyFont",
                              }}
                            >
                              {new Date(e?.date).toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })}
                            </Text>
                          </View>

                          <View style={{ flex: 1 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              £{Number(e?.deductCredit).toFixed(2)}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.paymentMethod[0]}
                            </Text>
                          </View>

                          <View style={{ flex: 2 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.transactionType}
                            </Text>
                          </View>

                          <View style={{ flex: 5 }}>
                            <Text
                              style={{
                                fontSize: 8,
                                fontWeight: "normal",
                                fontFamily: "MyFont",
                              }}
                            >
                              {e?.reason}
                            </Text>
                          </View>
                        </View>
                      );
                    })}

                  <View
                    style={{
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "space-between",
                      borderBottomWidth: 1,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "normal",
                          fontFamily: "MyFont",
                        }}
                      >
                        Total
                      </Text>
                    </View>

                    <View style={{ flex: 1 }}>
                      <Text
                        style={{
                          fontSize: 8,
                          fontWeight: "bold",
                          fontFamily: "MyFont",
                        }}
                      >
                        £
                        {Number(
                          value &&
                            value?.length > 0 &&
                            value?.reduce((pre, curr) => {
                              return (pre || 0) + Number(curr?.deductCredit);
                            }, 0)
                        ).toFixed(2)}
                      </Text>
                    </View>

                    <View style={{ flex: 9 }}></View>
                  </View>
                </View>
              );
            })}
        </View>

        <View style={{ marginTop: "20px" }}>
          <Text
            style={{
              fontSize: 12,
              fontWeight: "bold",
              color: "black",
              fontFamily: "MyFont",
              width: "100%",
            }}
          >
            Expense Details:
          </Text>

          <View
            style={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Date
              </Text>
            </View>

            <View style={{ flex: 9 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                description
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Amount
              </Text>
            </View>
          </View>

          {expenses &&
            expenses.length > 0 &&
            expenses.map((e, i) => {
              return (
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderBottomWidth: 1,
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "bold",
                        fontFamily: "MyFont",
                      }}
                    >
                      {new Date(
                        e?.date ? e?.date : e?.created_at
                      ).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "2-digit",
                      })}
                    </Text>
                  </View>

                  <View style={{ flex: 9 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      {e?.description}
                    </Text>
                  </View>

                  <View style={{ flex: 1 }}>
                    <Text
                      style={{
                        fontSize: 8,
                        fontWeight: "normal",
                        fontFamily: "MyFont",
                      }}
                    >
                      £{e?.amount || 0}
                    </Text>
                  </View>
                </View>
              );
            })}

          <View
            style={{
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
              borderBottomWidth: 1,
            }}
          >
            <View style={{ flex: 10 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Total
              </Text>
            </View>

            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 8,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  expenses &&
                    expenses?.length > 0 &&
                    expenses?.reduce((pre, curr) => {
                      return (pre || 0) + Number(curr?.amount || 0);
                    }, 0)
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>

        <View style={{ marginTop: "20px" }}>
          <Text
            style={{
              fontSize: 10,
              fontWeight: "bold",
              fontFamily: "MyFont",
              borderBottomWidth: 1,
              width: "100%",
            }}
          >
            Payment Summary:
          </Text>

          <View style={{ marginTop: "10px" }}>
            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.cash || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "refund cash"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "refund cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Refunds:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  employeeRefundData && employeeRefundData?.length > 0
                    ? employeeRefundData?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  employeeCreditPayment && employeeCreditPayment?.length > 0
                    ? employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + (curr?.deductCredit || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cash Expenses:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  expenses && expenses?.length > 0
                    ? expenses?.reduce((pre, curr) => {
                        return (pre || 0) + (Number(curr?.amount) || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Remaining Cash: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "refund cash"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "refund cash"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.cash || 0);
                      }
                    }, 0)
                ) +
                  Number(
                    employeeCreditPayment &&
                      employeeCreditPayment?.length > 0 &&
                      employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    employeeRefundData &&
                      employeeRefundData?.length > 0 &&
                      employeeRefundData?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cash"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    expenses &&
                      expenses?.length > 0 &&
                      expenses?.reduce((pre, curr) => {
                        return (pre || 0) + Number(curr?.amount);
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.credit || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "credit note"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "credit note"
                          )
                        ) {
                          return (pre || 0) + (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              On Account Refunds :{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  employeeRefundData && employeeRefundData?.length > 0
                    ? employeeRefundData?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "onaccount"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total On Accounts: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "credit note"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "credit note"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.credit || 0);
                      }
                    }, 0)
                ) -
                  Number(
                    employeeRefundData &&
                      employeeRefundData?.length > 0 &&
                      employeeRefundData?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "onaccount"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.card || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card Returns:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          !Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.toLowerCase() == "refund card"
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (j) => j?.toLowerCase() == "refund card"
                          )
                        ) {
                          return (pre || 0) - (curr?.subtotal || 0);
                        } else {
                          return pre || 0;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Card Refunds :{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £(
                {Number(
                  employeeRefundData && employeeRefundData?.length > 0
                    ? employeeRefundData?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + (curr?.refund || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}
                ){" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  employeeCreditPayment && employeeCreditPayment?.length > 0
                    ? employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          curr?.paymentMethod &&
                          Array.isArray(curr?.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e) => e.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + (curr?.deductCredit || 0);
                        } else {
                          return pre;
                        }
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Remaining Card:- £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      if (
                        curr?.saleReturnDate &&
                        curr?.paymentMethod?.toLowerCase() == "refund card"
                      ) {
                        return (pre || 0) - curr?.subtotal;
                      } else if (
                        curr?.exchangeDate &&
                        Array?.isArray(curr?.paymentMethod) &&
                        curr?.paymentMethod?.some(
                          (e, i) => e?.toLowerCase() == "refund card"
                        )
                      ) {
                        return (pre || 0) + curr?.subtotal;
                      } else {
                        return (pre || 0) + (curr?.card || 0);
                      }
                    }, 0)
                ) +
                  Number(
                    employeeCreditPayment &&
                      employeeCreditPayment?.length > 0 &&
                      employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  ) -
                  Number(
                    employeeRefundData &&
                      employeeRefundData?.length > 0 &&
                      employeeRefundData?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "card"
                          )
                        ) {
                          return (pre || 0) + curr?.refund;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Bank Transfer:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.online || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  Number(
                    employeeCreditPayment && employeeCreditPayment?.length > 0
                      ? employeeCreditPayment?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) => e.toLowerCase() == "bank transfer"
                            )
                          ) {
                            return (pre || 0) + (curr?.deductCredit || 0);
                          } else {
                            return pre;
                          }
                        }, 0)
                      : 0
                  )
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
                borderBottomWidth: 1,
                borderBottomColor: "black",
              }}
            >
              Total Bank Transfer: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.online || 0);
                    }, 0)
                ) +
                  Number(
                    employeeCreditPayment &&
                      employeeCreditPayment?.length > 0 &&
                      employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "bank transfer"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Cheques:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        return (pre || 0) + (curr?.cheque || 0);
                      }, 0)
                    : 0
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{ fontSize: 10, fontWeight: "bold", fontFamily: "MyFont" }}
            >
              Credit Payments:{" "}
              <Text
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  fontFamily: "MyFont",
                }}
              >
                £
                {Number(
                  Number(
                    employeeCreditPayment && employeeCreditPayment?.length > 0
                      ? employeeCreditPayment?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) => e.toLowerCase() == "cheque"
                            )
                          ) {
                            return (pre || 0) + (curr?.deductCredit || 0);
                          } else {
                            return pre;
                          }
                        }, 0)
                      : 0
                  )
                ).toFixed(2)}{" "}
              </Text>
            </Text>

            <Text
              style={{
                color: "red",
                fontFamily: "MyFont",
                fontWeight: "bold",
                fontSize: 10,
              }}
            >
              Total Cheques: £
              {Number(
                Number(
                  shiftInvoices &&
                    shiftInvoices?.length > 0 &&
                    shiftInvoices?.reduce((pre, curr) => {
                      return (pre || 0) + (curr?.cheque || 0);
                    }, 0)
                ) +
                  Number(
                    employeeCreditPayment &&
                      employeeCreditPayment?.length > 0 &&
                      employeeCreditPayment?.reduce((pre, curr) => {
                        if (
                          Array.isArray(curr.paymentMethod) &&
                          curr?.paymentMethod?.some(
                            (e, i) => e?.toLowerCase() == "cheque"
                          )
                        ) {
                          return (pre || 0) + curr?.deductCredit;
                        } else {
                          return pre;
                        }
                      }, 0)
                  )
              ).toFixed(2)}
            </Text>

            <View style={{ marginTop: "10px", borderTopWidth: 1 }}>
              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Sales: £
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.saleDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Returns: £-
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.saleReturnDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{ color: "black", fontFamily: "MyFont", fontSize: 10 }}
              >
                Total Exchange: £
                {Number(
                  shiftInvoices &&
                    shiftInvoices.length > 0 &&
                    shiftInvoices.reduce((pre, curr) => {
                      if (curr?.exchangeDate) {
                        return (pre || 0) + Number(curr?.subtotal);
                      } else {
                        return pre;
                      }
                    }, 0)
                ).toFixed(2)}
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  color: "black",
                  borderTopWidth: 1,
                }}
              >
                Net Sales:{" "}
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "normal",
                    fontFamily: "MyFont",
                    color: "red",
                  }}
                >
                  £
                  {Number(
                    shiftInvoices && shiftInvoices?.length > 0
                      ? shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.paymentMethod &&
                            !Array.isArray(curr?.paymentMethod) &&
                            (curr?.paymentMethod?.toLowerCase() ==
                              "refund cash" ||
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note" ||
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card")
                          ) {
                            return (pre || 0) - (curr?.subtotal || 0);
                          }

                          if (
                            curr?.paymentMethod &&
                            Array.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e) =>
                                e?.toLowerCase() == "refund cash" ||
                                e?.toLowerCase() == "credit note" ||
                                e?.toLowerCase() == "refund card"
                            )
                          ) {
                            return (pre || 0) + (curr?.subtotal || 0);
                          } else {
                            return (pre || 0) + (curr?.subtotal || 0);
                          }
                        }, 0)
                      : 0
                  ).toFixed(2)}{" "}
                </Text>
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                }}
              >
                Gross Collection:{" "}
                <Text
                  style={{
                    color: "red",
                    fontFamily: "MyFont",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  £
                  {Number(
                    Number(
                      shiftInvoices &&
                        shiftInvoices?.length > 0 &&
                        shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.saleReturnDate &&
                            curr?.paymentMethod?.toLowerCase() == "refund cash"
                          ) {
                            return (pre || 0) - curr?.subtotal;
                          } else if (
                            curr?.exchangeDate &&
                            Array?.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e, i) => e?.toLowerCase() == "refund cash"
                            )
                          ) {
                            return (pre || 0) + curr?.subtotal;
                          } else {
                            return (pre || 0) + (curr?.cash || 0);
                          }
                        }, 0)
                    ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.card || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.online || 0);
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.credit || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.cheque || 0);
                          }, 0)
                      )
                  ).toFixed(2)}
                </Text>
              </Text>

              <Text
                style={{
                  fontSize: 14,
                  color: "black",
                  fontWeight: "bold",
                  fontFamily: "MyFont",
                  borderTopWidth: 1,
                  borderTopColor: "black",
                }}
              >
                Extra cash received on invoices:{" "}
                <Text
                  style={{
                    color: "red",
                    fontFamily: "MyFont",
                    fontWeight: "bold",
                    fontSize: 16,
                  }}
                >
                  £
                  {Number(
                    Number(
                      shiftInvoices &&
                        shiftInvoices?.length > 0 &&
                        shiftInvoices?.reduce((pre, curr) => {
                          if (
                            curr?.saleReturnDate &&
                            curr?.paymentMethod?.toLowerCase() == "refund cash"
                          ) {
                            return (pre || 0) - curr?.subtotal;
                          } else if (
                            curr?.exchangeDate &&
                            Array?.isArray(curr?.paymentMethod) &&
                            curr?.paymentMethod?.some(
                              (e, i) => e?.toLowerCase() == "refund cash"
                            )
                          ) {
                            return (pre || 0) + curr?.subtotal;
                          } else {
                            return (pre || 0) + (curr?.cash || 0);
                          }
                        }, 0)
                    ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "refund card"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "refund card"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.card || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.online || 0);
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            if (
                              curr?.saleReturnDate &&
                              curr?.paymentMethod?.toLowerCase() ==
                                "credit note"
                            ) {
                              return (pre || 0) - curr?.subtotal;
                            } else if (
                              curr?.exchangeDate &&
                              Array?.isArray(curr?.paymentMethod) &&
                              curr?.paymentMethod?.some(
                                (e, i) => e?.toLowerCase() == "credit note"
                              )
                            ) {
                              return (pre || 0) + curr?.subtotal;
                            } else {
                              return (pre || 0) + (curr?.credit || 0);
                            }
                          }, 0)
                      ) +
                      Number(
                        shiftInvoices &&
                          shiftInvoices?.length > 0 &&
                          shiftInvoices?.reduce((pre, curr) => {
                            return (pre || 0) + (curr?.cheque || 0);
                          }, 0)
                      ) -
                      Number(
                        shiftInvoices &&
                          shiftInvoices.length > 0 &&
                          shiftInvoices.reduce((pre, curr) => {
                            if (curr?.saleReturnDate) {
                              return (pre || 0) - Number(curr?.subtotal);
                            } else {
                              return (pre || 0) + Number(curr?.subtotal);
                            }
                          }, 0)
                      )
                  ).toFixed(2)}
                </Text>
              </Text>

              <Text style={{ fontSize: 12 }}>
                PF:
                {Number(
                  shiftInvoices && shiftInvoices?.length > 0
                    ? shiftInvoices?.reduce((pre, curr) => {
                        console.log(curr);
                        console.log(curr?.discount);
                        console.log(curr?.total);
                        console.log(
                          Number(
                            curr?.total -
                              (curr?.discount || 0) -
                              curr?.costPrice
                          )
                        );

                        return (
                          pre +
                          Number(
                            curr?.total -
                              (curr?.discount || 0) -
                              curr?.costPrice || 0
                          )
                        );
                      }, 0)
                    : 0
                ).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
  const handleClosePdf = () => {
    setPdfDownloadView({
      ...pdfDownloadView,
      stockReport: false,
      saleReport: false,
      minimumStockLevel: false,
      userSaleReport: false,
      saleReportBetweenDates: false,
      customerSpecificProductSaleReport: false,
      productSaleReportForSelectedCustomer: false,
      productSaleReportForSelectedDates: false,
      bestSellerTop100: false,
      dayReport: false,
      saleReportByDepartment: false,
    });
  };

  return (
    <Box sx={{ backgroundColor: Colors.white, minHeight: "100vh" }}>
      <Navbar currentPage={"Reports"} />

      {pdfDownloadView.dayReport ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <DayReportDocument />
          </PDFViewer>
          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.stockReport ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <StockReportDocument />
          </PDFViewer>
          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.minimumStockLevel ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <MinimumStockReportDocument />
          </PDFViewer>

          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.userSaleReport ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <UserSaleReportDocument />
          </PDFViewer>

          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.saleReportBetweenDates ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <SaleReportBetweenDatesDocument />
          </PDFViewer>

          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.productSaleReportForSelectedCustomer ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <ProductSaleReportSelectedCustomerDocument />
          </PDFViewer>

          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.customerSpecificProductSaleReport ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <CustomerSpecificProductSaleReportDocument />
          </PDFViewer>

          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.productSaleReportForSelectedDates ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <ProductSaleReportForSelectedDatesDocument />
          </PDFViewer>
          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.bestSellerTop100 ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <BestSellerProductDocument />
          </PDFViewer>
          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : pdfDownloadView.saleReportByDepartment ? (
        <Box
          style={{
            wodth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <PDFViewer width={850} height={700}>
            <DepartmentSaleReportDocument />
          </PDFViewer>
          <CloseOutlinedIcon
            fontSize="large"
            style={{ color: Colors.black, cursor: "pointer" }}
            onClick={() => handleClosePdf()}
          />
        </Box>
      ) : (
        <Box style={{ width: "100%", padding: "10px 20px" }}>
          <Box sx={{ marginTop: "20px", width: "100%" }}>
            <Box sx={{ display: "flex", marginTop: 1 }}>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "Poppins",
                  mr: 1,
                  color: Colors.text,
                }}
              >
                Search By Date
              </Typography>
              <Checkbox
                data={searchDate}
                onClick={() =>
                  setSearchDate((prevState) => ({
                    ...prevState,
                    selected: !prevState.selected,
                  }))
                }
              />
            </Box>

            <Grid
              container
              xs={12}
              sm={12}
              md={12}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                xs={12}
                sm={5}
                md={3.5}
                lg={3.4}
                xl={2.9}
                style={{ marginRight: "5px" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    mr: 1,
                    color: Colors.text,
                  }}
                >
                  From
                </Typography>
                <DatePickers
                  style={{
                    borderRadius: 10,
                    marginTop: { xs: "5px", sm: "0px" },
                  }}
                  values={startDate}
                  onChange={(e) => setStartDate(e)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={5}
                md={3.5}
                lg={3.4}
                xl={2.9}
                style={{ marginRight: "5px" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "Poppins",
                    mr: 1,
                    color: Colors.text,
                  }}
                >
                  To
                </Typography>
                <DatePickers
                  style={{
                    borderRadius: 10,
                    marginTop: { xs: "5px", sm: "0px" },
                  }}
                  values={endDate}
                  onChange={(e) => setEndDate(e)}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={4.8}
                lg={5}
                xl={6}
                style={{
                  marginRight: "5px",
                  marginTop: { xs: "5px", sm: "0px" },
                }}
              >
                <BasicSelect
                  value={selectedReportType}
                  status="Select Report Type"
                  id={reportType}
                  innerStyle={{
                    fontSize: "14px",
                    border: `0px solid ${Colors.border}`,
                    borderRadius: "5px",
                    fontWeight: "bold",
                    width: "100%",
                    padding: "5px",
                    color: Colors.button,
                  }}
                  style={{
                    border: `0px solid ${Colors.buttonLight}`,
                    width: "100%",
                    padding: "5px",
                  }}
                  onChange={(e) => setSelectedReportType(e)}
                />
              </Grid>
            </Grid>
          </Box>

          {selectedReportType?.toLowerCase() == "user sale report" && (
            <BasicSelect
              value={selectedEmployee}
              status="Select User"
              id={employees.map((e, i) => e?.full_name)}
              innerStyle={{
                fontSize: "14px",
                border: `0px solid ${Colors.border}`,
                borderRadius: "5px",
                fontWeight: "bold",
                width: "100%",
                padding: "5px",
                color: Colors.button,
              }}
              style={{
                border: `0px solid ${Colors.buttonLight}`,
                width: "100%",
                padding: "5px",
                mt: 2,
              }}
              onChange={(e) => setSelectedEmployee(e)}
            />
          )}
          <Box
            sx={{
              width: "100%",
              marginTop: { xs: "10px", sm: "10px", md: "10px" },
              border: `1px solid ${Colors.button}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 10px 5px 10px",
              margin: 0,
            }}
          >
            <TextField
              variant="standard" // <== changed this
              margin="normal"
              required
              value={text}
              fullWidth
              inputRef={customerInputRef}
              onChange={(e) => handleSearch(e.target.value)}
              InputProps={{
                disableUnderline: true,
                fontSize: "12px",
              }}
              placeholder={
                selectedSearch == "products"
                  ? "Search Products"
                  : "Search Customer"
              }
              sx={{
                width: "90%",
                fontSize: "12px",
                padding: 0,
                fontFamily: "Poppins",
                fontWeight: "bold",
                margin: 0,
              }}
            />

            <img
              src={require("../../Images/search.png")}
              style={{ height: "20px" }}
            />
          </Box>

          <Box>
            {searchCustomers && searchCustomers.length > 0
              ? searchCustomers.map((e, i) => {
                  return (
                    <Button
                      onClick={() => handleSelectCustomer(e)}
                      sx={{
                        width: "100%",
                        mt: 1,
                        backgroundColor: Colors.white,
                        p: "0px 10px",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        borderRadius: 1,
                        textAlign: "left",
                        display: "flex",
                        justifyContent: "flex-start",
                        border: `1px solid ${Colors.border}`,
                      }}
                    >
                      <p style={{ fontFamily: "Poppins", textAlign: "left" }}>
                        {e.businessName}
                      </p>
                    </Button>
                  );
                })
              : searchProducts &&
                searchProducts.length > 0 &&
                searchProducts.map((e, i) => {
                  return (
                    <Box
                      sx={{
                        width: "100%",
                        mt: 1,
                        backgroundColor: Colors.white,
                        p: "0px 10px",
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        borderRadius: 1,
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        border: `1px solid ${Colors.border}`,
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Poppins",
                          textAlign: "left",
                          marginBottom: 0,
                          color: Colors.button,
                        }}
                      >
                        {e.product_name}
                      </p>

                      <CustomButton
                        text={"Select"}
                        onClick={() => handleSelectProduct(e)}
                        style={{
                          fontFamily: "Poppins",
                          textAlign: "left",
                          marginBottom: "10px",
                          fontSize: 14,
                          // color: "black",
                          borderRadius: 2,
                          width: 250,
                          // border: "none",
                          // justifyContent: "flex-start",
                          // padding: 0,
                          marginTop: "10px",
                          // backgroundColor: "transparent",
                        }}
                      />
                    </Box>
                  );
                })}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", mt: 1 }}>
            <CustomButton
              text="Customers"
              onClick={() => setSelectedSearch("customers")}
              style={{
                width: "120px",
                borderRadius: "5px",

                backgroundColor:
                  selectedSearch == "customers" ? Colors.button : Colors.white,
                color:
                  selectedSearch == "customers" ? Colors.white : Colors.button,
              }}
            />

            <CustomButton
              text="Products"
              onClick={() => setSelectedSearch("products")}
              style={{
                width: "120px",
                ml: 1,
                borderRadius: "5px",
                backgroundColor:
                  selectedSearch == "products" ? Colors.button : Colors.white,
                color:
                  selectedSearch == "products" ? Colors.white : Colors.button,
              }}
            />
          </Box>

          <Box sx={{ mt: 2 }}>
            {selectedCustomer && (
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Customer : {selectedCustomer?.businessName}
              </Typography>
            )}
            {selectedProduct && (
              <Typography
                sx={{
                  fontSize: "18px",
                  fontFamily: "Poppins",
                  textAlign: "left",
                  fontWeight: "600",
                }}
              >
                Product Name : {selectedProduct?.productName}
              </Typography>
            )}
          </Box>

          <Box sx={{ width: "100%", display: "flex", mt: 2 }}>
            <CustomButton
              onClick={() => handleGenerateReports()}
              text={
                loading ? (
                  <RingLoader loading={loading} color={"black"} size={30} />
                ) : (
                  "Generate Report"
                )
              }
              style={{ width: "200px" }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Reports;
