import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { RingLoader } from 'react-spinners';
import { Table, TableContainer, TableHead, TableRow, TextField, TableCell, Paper, TableBody } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from './Button';
import { Grid } from '@mui/material';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import { updateProducts, updatePurchaseOrders, updateSuppliers } from '../Redux/LoginReduces';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomModal from './modal';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';
import { Document, Page, Text, View, PDFViewer, PDFDownloadLink, Font } from '@react-pdf/renderer';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';





import MyFontRegular from '../fonts/Poppins-Medium.ttf';
import MyFontBold from '../fonts/Poppins-Bold.ttf';



// import puppeteer from 'puppeteer';



export default function ViewPurchaseOrder({ barcodeData, setBarcodeRead, data, headingTextStyle, setPurchaseOrders, containerStyle, open, closeModal, buttonContainerStyle, }) {


    Font.register({
        family: 'MyFont',
        fonts: [
            { src: MyFontBold, fontWeight: 'bold' },
            { src: MyFontRegular, fontWeight: 'normal' },
        ]
    });


    const dispatch = useDispatch()


    const vat = useSelector(state => state?.loginReducer?.vat)


    const products = useSelector(state => state.loginReducer.products)

    const [searchProducts, setSearchProducts] = useState([])

    const [productText, setProductText] = useState("")


    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        maxHeight: "90vh",
        overflow: "auto",
        transform: 'translate(-50%, -50%)',
        width: { lg: "95%", md: "98%", sm: "98%", xs: "100%" },
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
    };


    const [pdfDownloadView, setPdfDownloadView] = useState(false)
    const [loading, setLoading] = useState(false)
    const [openQuantityAddModal, setOpenQuantityAddModal] = useState(false)
    const [openQtyLeftModal, setOpenQtyLeftModal] = useState(false)
    const [purchaseOrderDetails, setPurchaseOrderDetails] = React.useState({
        productDetails: [],
        supplierDetails: {},
        total_cost: null,
        total_items: null,
    })

    const departments = useSelector(
        (state) => state.loginReducer.productDepartments
    );


    const [editPO, setEditPO] = useState(false)

    const [downloadPO, setDownloadPO] = useState(false)




    const getProducts = async () => {

        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data

        axios.get(`${Base_Uri}getProducts`, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let myData = res.data

            let { data } = myData

            if (data && data.length > 0) {

                let allProducts = []

                for (var i = 0; i < data.length; i++) {

                    let product = data[i]

                    product.id = i + 1
                    product["productName"] = product?.product_name


                    if (product.status == "active") {

                        product.status = "Active"

                    }

                    if (product?.qty <= product?.reminder_qty) {

                        product.status = "Restock"

                    }

                    allProducts.push(product)
                }
                dispatch(updateProducts(allProducts))

            }


        }).catch((error) => {

            console.log(error, "error")
            // setLoading(false)

        })


    }

    const getPurchaseOrders = async () => {
        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios
            .get(`${Base_Uri}getAllPurchaseOrders`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json", // Include other headers as needed
                },
            })
            .then((res) => {
                let myData = res.data;

                let { data } = myData;

                data =
                    data &&
                    data?.length > 0 &&
                    data?.map((e, i) => {
                        return {
                            ...e,
                            id: i + 1,
                            supplier_name: e?.supplierDetails?.[0]?.supplier_name,
                            supplier_address: e?.supplierDetails?.[0]?.supplier_address,
                            supplier_mobile_number:
                                e?.supplierDetails?.[0]?.supplier_mobile_number,
                            date: e?.created_at,
                        };
                    });


                if (!data || data.length == 0) {
                } else {


                    data &&
                        data.length > 0 &&
                        data.sort((a, b) => {
                            if (a.status === "pending" && b.status !== "pending") {
                                return -1; // a comes before b
                            } else if (a.status !== "pending" && b.status === "pending") {
                                return 1; // b comes before a
                            } else {
                                return 0; // no change in order
                            }
                        });
                    dispatch(updatePurchaseOrders(data))
                    setPurchaseOrders(data)


                }
            })
            .catch((error) => {
                console.log(error, "error");
            });
    };

    useEffect(() => {


        let products = data && data?.productDetails?.length > 0 && data?.productDetails?.map((e, i) => {



            let selectedDep =
                departments &&
                departments.length > 0 &&
                departments?.filter((j) => j?.departmentName == e?.department);


            let selectedCat =
                selectedDep &&
                selectedDep[0]?.categories?.length > 0 &&
                selectedDep[0]?.categories?.filter(
                    (j) => j?.categoryName == e?.category
                );
            selectedCat = selectedCat[0];

            return {
                ...e,
                trade_price: Number(e?.trade_price),
                warehouse_price: (Number(e?.warehouse_price)),
                retail_price: Number(e?.retail_price),

            }
        })


        let purchaseData = { ...data }

        purchaseData = {
            ...data,
            productDetails: products
        }

        setPurchaseOrderDetails(purchaseData)


    }, [data])





    const Header = () => {

        return (
            <View fixed >

                <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 10, alignItems: "flex-start" }} >
                    <View>
                        <Text style={{ color: "black", fontSize: "14px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyName}</Text>
                        <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.companyAddress}</Text>
                        <Text style={{ color: "black", fontSize: "12px", fontFamily: "MyFont", fontWeight: "normal" }} >{vat?.mobileNumber}</Text>
                    </View>

                    <View>
                        <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                            Purchase Order Date:   {
                                new Date(purchaseOrderDetails?.created_at)?.toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })
                            }

                        </Text>
                        <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                            Print Date:   {
                                new Date().toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                })
                            }



                            <Text style={{ marginLeft: "5px" }} >  {new Date().toLocaleTimeString()}
                            </Text>
                        </Text>


                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                PO ID:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {purchaseOrderDetails?.purchaseOrder_number}
                            </Text>

                        </View>


                        <View style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "10px" }} >

                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                Supplier:
                            </Text>


                            <Text style={{ color: "black", fontSize: "8px", fontFamily: "MyFont", fontWeight: "normal" }} >
                                {purchaseOrderDetails?.supplier_name}
                            </Text>

                        </View>




                    </View>


                </View>


                <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", backgroundColor: "gray", alignItems: "center", padding: 10, marginTop: "20px" }}>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Barcode</Text>
                    </View>
                    <View style={{ flex: 3 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Description</Text>
                    </View>

                    <View style={{ flex: 1 }}>
                        <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>Ordered</Text>
                    </View>
                </View>

            </View>
        )
    }

    const MyDocument = () => (
        <Document size="A4"  >

            {/* <Header /> */}

            <Page style={{ padding: "0px 10px" }} >

                <Header />
                {/* Data Rows */}
                <View>
                    {purchaseOrderDetails?.productDetails?.map((item, index) => (
                        <View key={index} style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', padding: "3px 10px" }}>
                            <View style={{ flex: 1 }}>
                                <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.barcode}</Text>
                            </View>
                            <View style={{ flex: 3 }}>
                                <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{item.productName}</Text>
                            </View>

                            <View style={{ flex: 1 }}>
                                <Text style={{ fontFamily: "MyFont", fontSize: 8 }}>{Number(item.orderQty).toFixed(2)}</Text>
                            </View>
                        </View>
                    ))}



                    <View style={{ flexDirection: "row", borderBottomWidth: 1, borderColor: '#000', paddingBottom: 5, paddingTop: 5, border: "1px solid black", alignItems: "center", padding: 10 }}>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}></Text>
                        </View>
                        <View style={{ flex: 3 }}>
                            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold", textAlign: "left" }}>Total</Text>
                        </View>
                        <View style={{ flex: 1 }}>
                            <Text style={{ fontFamily: "MyFont", fontSize: 9, fontWeight: "bold" }}>

                                {Number(purchaseOrderDetails?.productDetails?.reduce((pre, cur) => {
                                    return (Number(pre) || 0) + (Number(cur?.orderQty) || 0);
                                }, 0)).toFixed(2)}

                            </Text>
                        </View>

                    </View>
                </View>

            </Page>
        </Document>
    );





    // const handleSelectProduct = (product) => {


    //     let { productDetails } = purchaseOrderDetails

    //     if (productDetails?.some((e, i) => e?.barcode == product.barcode)) {
    //         alert("This product has already added")
    //         return
    //     }

    //     productDetails.push(product)

    //     setPurchaseOrderDetails({
    //         ...purchaseOrderDetails,
    //         productDetails: productDetails
    //     })
    //     setSearchProducts([])
    //     setProductText("")
    // };




    const handleEnterCostPrice = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            let selectedDep =
                departments &&
                departments.length > 0 &&
                departments?.filter((j) => j?.departmentName == e?.department);
            let selectedCat =
                selectedDep &&
                selectedDep[0]?.categories?.length > 0 &&
                selectedDep[0]?.categories?.filter(
                    (j) => j?.categoryName == e?.category
                );
            selectedCat = selectedCat[0];



            if (ind == i) {
                return {
                    ...e,
                    cost_price: cost,
                    trade_price: Math.round(Number(cost) + ((Number(cost) * Number(selectedCat?.categoryPer?.a)) / 100)),
                    warehouse_price: Math.round(Number(cost) + ((Number(cost) * Number(selectedCat?.categoryPer?.b)) / 100)),
                    retail_price: Math.round(Number(cost) + ((Number(cost) * Number(selectedCat?.categoryPer?.c)) / 100)),

                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleEnterTradePrice = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    trade_price: cost
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleEnterWarehousePrice = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    warehouse_price: cost
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleEnterRetailPrice = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    retail_price: cost
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleEnterArrivedQty = (qty, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    arrivedQty: qty
                }
            } else {
                return e
            }

        })


        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })

    }

    const handleClickUpdateQtyArrive = () => {



        let { productDetails } = purchaseOrderDetails


        if (purchaseOrderDetails?.status == "completed") {

            alert("All quantity has already been arrived")
            return
        }

        if (productDetails && productDetails?.length > 0 && !productDetails?.some((e, i) => e?.arrivedQty)) {
            alert("Kindly Enter Arrived Qty")
            return
        }



        if (productDetails && productDetails?.length > 0 && productDetails?.some((e, i) => e?.arrivedQty > (Number(e?.orderQty) - Number(e?.arriveQty || 0)))) {
            alert("Arrived Quantity Cannot be greated than the PO Qty")
            return
        }


        setOpenQuantityAddModal(true)
    }

    const updateQtyArrivedInInventory = async () => {



        let { productDetails } = purchaseOrderDetails



        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios.put(`${Base_Uri}updateArriveQtyInPurchaseOrder`, purchaseOrderDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data
            setLoading(false)

            if (data.status) {


                productDetails = productDetails?.map((e, i) => {
                    return {
                        ...e,
                        remainingQty: (Number(e.remainingQty) || Number(e.orderQty)) - (Number(e.arrivedQty) || 0),
                        arriveQty: (Number(e.arriveQty) || 0) + (Number(e.arrivedQty) || 0),
                    }
                })


                alert(data?.message)
                setLoading(false)
                setOpenQuantityAddModal(false)
                setPurchaseOrderDetails({
                    ...purchaseOrderDetails,
                    productDetails: productDetails
                })
                getPurchaseOrders()
                getProducts()

            }
        }).catch((error) => {

            setLoading(false)
            alert(error?.message)

        })



    }

    const updateQtyLeftInInventory = async () => {



        let { productDetails } = purchaseOrderDetails

        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        axios.put(`${Base_Uri}updateLeftQtyInPurchaseOrder`, purchaseOrderDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data
            setLoading(false)

            if (data.status) {


                productDetails = productDetails?.map((e, i) => {
                    return {
                        ...e,
                        remainingQty: 0,
                        arriveQty: e?.orderQty,
                    }
                })

                alert(data?.message)
                setLoading(false)
                setOpenQtyLeftModal(false)
                closeModal()
                setPurchaseOrderDetails({
                    ...purchaseOrderDetails,
                    productDetails: productDetails
                })
                getPurchaseOrders()
                getProducts()

            }
        }).catch((error) => {

            setLoading(false)
            alert(error?.message)

        })



    }


    const handleEnterOrderQty = (cost, ind) => {

        let { productDetails } = purchaseOrderDetails

        productDetails = productDetails && productDetails?.length > 0 && productDetails?.map((e, i) => {

            if (ind == i) {
                return {
                    ...e,
                    orderQty: cost,
                    remainingQty: Number(cost) - Number(e?.arriveQty || 0)
                }
            } else {
                return e
            }

        })

        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })



    }

    const handleSaveChanges = async () => {




        let { productDetails } = purchaseOrderDetails



        let data = await sessionStorage.getItem("userData");
        data = JSON.parse(data);
        let token = data;

        setLoading(true)

        axios.put(`${Base_Uri}editPO`, purchaseOrderDetails, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data
            setLoading(false)

            if (data.status) {


                productDetails = productDetails?.map((e, i) => {
                    return {
                        ...e,
                        remainingQty: Number(e.orderQty) - (Number(e.arriveQty) || 0),
                        cost_price: e?.cost_price,
                        trade_price: e?.trade_price,
                        retail_price: e?.retail_price,
                        warehouse_price: e?.warehouse_price
                    }
                })


                alert(data?.message)
                setLoading(false)
                setEditPO(false)
                setPurchaseOrderDetails({
                    ...purchaseOrderDetails,
                    productDetails: productDetails
                })
                getPurchaseOrders()
                getProducts()

            }
        }).catch((error) => {

            setLoading(false)
            alert(error?.message)

        })





    }

    const handleDownloadPdf = async () => {


        setPdfDownloadView(true)
        window.scrollTo({
            top: 100,
            behavior: "smooth"
        })


    }



    const handleSearchProducts = (value) => {

        setProductText(value)

        if (!value) {
            setSearchProducts([])
            return
        }

        setSearchProducts(products && products.length > 0 && products?.filter((e, i) => {


            if (e?.productName?.toLowerCase().includes(value.toLowerCase()) || e?.IMEI?.includes(value.toLowerCase()) ||
                e?.barcode?.toString()?.includes(value)
            ) {
                return e
            }
        }))

    }

    const handleSelectProduct = (product) => {


        let { productDetails } = purchaseOrderDetails

        if (productDetails?.some((e, i) => e?.barcode == product.barcode)) {
            alert("This product has already added")
            return
        }

        productDetails.push(product)

        setPurchaseOrderDetails({
            ...purchaseOrderDetails,
            productDetails: productDetails
        })
        setSearchProducts([])
        setProductText("")
    };


    const handleDownloadExcel = () => {
        let data = purchaseOrderDetails?.productDetails?.map((e, i) => {
            return {
                Barcode: e?.barcode,
                Description: e?.productName,
                Qty: e?.orderQty
            };
        });

        const ws = XLSX.utils.json_to_sheet(data);


        ws['!outline'] = {
            style: 'thin', // Border style
            color: { rgb: '00000000' }, // Border color (black)
        };

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        // Convert workbook to binary array and create Blob
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/octet-stream' });

        // Trigger download
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `purchaseOrder${purchaseOrderDetails?.purchaseOrder_number}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        }, 0);
    };




    useEffect(() => {
        if (barcodeData?.length > 0) {

            let product =
                products &&
                products?.length > 0 &&
                products?.find((e) => e?.barcode == barcodeData);

            
            if (product) {
                handleSelectProduct(product);
                setBarcodeRead("");
            }
        }
    }, [barcodeData]);

    return (


        <div>
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                {loading ? <Box style={{ width: "100%", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }} >
                    <RingLoader loading={loading} color={Colors.black} size={100} />
                </Box> :
                    pdfDownloadView ? <Box style={{ wodth: "100%", height: "100%", border: "1px solid red", display: "flex", justifyContent: "center" }} >
                        <PDFViewer width={850} height={700} style={{ marginTop: 20 }} >
                            <MyDocument />
                        </PDFViewer>

                        <CloseOutlinedIcon fontSize="large" style={{ color: Colors.black, marginTop: "20px", cursor: "pointer", }} onClick={() => setPdfDownloadView(false)} />

                    </Box> :
                        <Box sx={[style, { containerStyle }]}>
                            <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                                <CloseIcon fontSize="large" />
                            </Box>
                            <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                                Purchase Order Details
                            </Typography>


                            <Box style={{ width: "100%", justifyContent: "space-between", display: "flex" }} >



                                {purchaseOrderDetails?.supplierDetails && <Box>

                                    <Typography sx={{ fontSize: "24px", fontFamily: "Poppins", fontWeight: "bold" }} >
                                        Supplier Details:
                                    </Typography>


                                    <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                        Supplier Name: {purchaseOrderDetails?.supplierDetails?.[0]?.supplier_name}
                                    </Typography>

                                    <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                        Address: {purchaseOrderDetails?.supplierDetails?.[0]?.supplier_address}
                                    </Typography>

                                    <Typography sx={{ fontSize: "18px", fontFamily: "Poppins" }} >
                                        Mobile Number: {purchaseOrderDetails?.supplierDetails?.[0]?.supplier_mobile_number}
                                    </Typography>




                                </Box>}

                                <Box>


                                    <CustomButton onClick={() => setDownloadPO(!downloadPO)} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px" }} text={"Download PO"} />





                                    {editPO && <CustomButton onClick={() => handleSaveChanges()} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px" }} text={"Save Changes"} />}





                                    <CustomButton onClick={() => setEditPO(!editPO)} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px" }} text={editPO ? "Remove Edit" : "Edit PO"} />



                                    <CustomButton onClick={() => handleClickUpdateQtyArrive()} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px" }} text={"Update Quantity Arrived"} />


                                    <CustomButton onClick={() => setOpenQtyLeftModal(true)} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px" }} text={"Add Quantity Left"} />

                                </Box>

                            </Box>



                            {downloadPO && <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%" }} >


                                <CustomButton onClick={() => handleDownloadPdf()} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px", padding: 1, borderRadius: 2, backgroundColor: Colors.red }} text={"Download PDF"} />
                                <CustomButton onClick={() => handleDownloadExcel()} style={{ fontSize: "12px", padding: "10px 5px", margin: "0px", width: "200px", marginRight: "3px", padding: 1, borderRadius: 2, backgroundColor: "green" }} text={"Download Excel"} />


                            </Box>}


                            {editPO && <Box>
                                <TextField
                                    variant="standard"
                                    margin="normal"
                                    required
                                    onChange={(e) => handleSearchProducts(e.target.value)}

                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                        fontSize: "12px",
                                        style: {
                                            textAlign: "center",
                                            fontSize: "16px",
                                            alignSelf: "center",
                                        },
                                    }}
                                    value={productText}
                                    inputProps={{
                                        disableUnderline: true,
                                        fontSize: "12px",

                                    }}
                                    placeholder={"Search Product Name or IMEI or Barcode"}
                                    sx={{
                                        width: "100%",
                                        // height: width < 600 ? "25px" : "35px",
                                        fontSize: "12px",
                                        padding: 1,
                                        border: `1px solid ${Colors.border}`,
                                        borderRadius: "5px",
                                        marginBottom: 0.5,
                                        textAlign: "center", // Move textAlign to sx
                                        fontFamily: "Poppins",
                                        fontWeight: "bold",

                                    }}
                                />

                                <Box sx={{ maxHeight: "300px", overflow: "auto" }} >
                                    {searchProducts &&
                                        searchProducts.length > 0 &&
                                        searchProducts.map((e, i) => {
                                            return (
                                                <Button
                                                    onClick={() => handleSelectProduct(e)}
                                                    sx={{
                                                        width: "100%",
                                                        mt: 0.5,
                                                        backgroundColor: Colors.white,
                                                        p: "0px 10px",
                                                        fontFamily: "Poppins",
                                                        fontWeight: "bold",
                                                        borderRadius: 1,
                                                        textAlign: "left",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        justifyContent: "center",
                                                        alignItems: "flex-start",
                                                        border: `1px solid ${Colors.border}`,
                                                    }}
                                                >
                                                    <p
                                                        style={{
                                                            fontFamily: "Poppins",
                                                            textAlign: "left",
                                                            marginBottom: 0,
                                                        }}
                                                    >
                                                        {e.product_name}
                                                    </p>
                                                    {/* <p style={{ fontFamily: "Poppins", textAlign: "left" }}> */}
                                                    Available qty: {e.qty}
                                                    {/* </p> */}
                                                </Button>
                                            );
                                        })}
                                </Box>
                            </Box>
                            }


                            {purchaseOrderDetails && purchaseOrderDetails?.productDetails && purchaseOrderDetails?.productDetails?.length > 0 &&

                                <Box style={{ width: "100%", marginTop: 10 }}>
                                    <TableContainer component={Paper}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Barcode</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Product Name</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Make</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Model</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Color</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>IMEI</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Qty</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Arrived</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Remaining</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Qty Arrived</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Cost</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Trade</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Warehouse</TableCell>
                                                    <TableCell style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "Poppins", border: "1px solid black" }}>Retail</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody  >
                                                {purchaseOrderDetails && purchaseOrderDetails?.productDetails && purchaseOrderDetails?.productDetails?.length > 0 && purchaseOrderDetails?.productDetails?.map((e, i) => (
                                                    <TableRow key={i}>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.barcode}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.productName}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.make}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.model}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.product_color}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.IMEI ?? "-"}</Typography>
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: editPO && "80px" }}>
                                                            {editPO ? <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.orderQty ?? 0}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterOrderQty(e.target.value, i)}
                                                                placeholder="Cost"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            /> : <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.orderQty}</Typography>}
                                                        </TableCell>
                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.arriveQty ?? 0}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black" }}>
                                                            <Typography style={{ fontSize: "14px", fontFamily: "Poppins", color: "red" }}>{e?.remainingQty ?? e?.orderQty}</Typography>
                                                        </TableCell>

                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: "50px" }}>
                                                            <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.arrivedQty ?? 0}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterArrivedQty(e.target.value, i)}
                                                                placeholder="Arrived Qty"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            />
                                                        </TableCell>


                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: editPO && "70px" }}>
                                                            {editPO ? <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.cost_price}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterCostPrice(e.target.value, i)}
                                                                placeholder="Cost Price"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            /> : <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.cost_price}</Typography>}
                                                        </TableCell>

                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: "50px" }}>
                                                            {editPO ? <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.trade_price}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterTradePrice(e.target.value, i)}
                                                                placeholder="Trade Price"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            /> : <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.trade_price}</Typography>}
                                                        </TableCell>

                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: "50px" }}>
                                                            {editPO ? <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.warehouse_price}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterWarehousePrice(e.target.value, i)}
                                                                placeholder="Warehouse Price"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            /> : <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.warehouse_price}</Typography>}
                                                        </TableCell>

                                                        <TableCell style={{ border: e?.selected ? "2px solid red" : "1px solid black", width: "50px" }}>
                                                            {editPO ? <TextField

                                                                variant="standard"
                                                                required
                                                                InputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    style: {
                                                                        textAlign: "center",
                                                                        fontSize: "16px",
                                                                        alignSelf: "center",
                                                                    },
                                                                }}
                                                                value={e?.retail_price}
                                                                inputProps={{
                                                                    disableUnderline: true,
                                                                    fontSize: "12px",
                                                                    textAlign: "center"
                                                                }}
                                                                onChange={(e) => handleEnterRetailPrice(e.target.value, i)}
                                                                placeholder="Retail Price"
                                                                sx={{
                                                                    width: "100%",
                                                                    fontSize: "12px",
                                                                    border: `1px solid ${Colors.border}`,
                                                                    borderRadius: "5px",
                                                                    margin: 0,
                                                                    padding: 0,
                                                                    textAlign: "center",
                                                                    fontFamily: "Poppins",
                                                                    fontWeight: "bold",
                                                                }}
                                                            /> : <Typography style={{ fontSize: "14px", fontFamily: "Poppins" }}>{e?.retail_price}</Typography>}
                                                        </TableCell>






                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>


                            }


                            {openQuantityAddModal && <CustomModal confirm={() => updateQtyArrivedInInventory()} heading="Update Qty Arrived" text="Are you sure you add this in inventory" open={openQuantityAddModal} closeModal={() => setOpenQuantityAddModal(false)} />}

                            {openQtyLeftModal && <CustomModal confirm={() => updateQtyLeftInInventory()} heading="Update Qty Left" text="Are you sure you add all qty left in inventory" open={openQtyLeftModal} closeModal={() => setOpenQtyLeftModal(false)} />}


                        </Box>}



            </Modal>
        </div>
    );
}