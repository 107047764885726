import React, { useEffect, useState } from "react";
import Navbar from "../../Components/Navbar";
import axios from "axios";
import { Base_Uri } from "../../Constant/BaseUri";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Button, TextField } from "@mui/material";
import moment from "moment";
import { Popconfirm } from "antd";
import { useSelector } from "react-redux";

const Shifts = () => {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [dateRange, setDateRange] = useState({ start: "", end: "" });
  useEffect(() => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;
    axios
      .get(`${Base_Uri}getShift`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((items) => {
        console.log(items.data);
        setRows(items.data.data.map((e) => ({ ...e, id: e._id })).reverse());
        setFilteredRows(
          items.data.data.map((e) => ({ ...e, id: e._id })).reverse()
        );
      });
  }, []);

  const handleEndShift = async (row) => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;
    try {
      const response = await axios.put(
        `${Base_Uri}endShift`,
        {
          employeeId: row.EmpId,
          employeeName: row.shiftStartBy,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.status) {
        alert("Shift ended successfully");
        // Update the row to mark the shift as ended
        setFilteredRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id
              ? {
                  ...r,
                  isshiftEnd: true,
                  shiftEndDate: new Date(),
                  shiftEndTime: moment().format("HH:mm:ss"),
                }
              : r
          )
        );
        setRows((prevRows) =>
          prevRows.map((r) =>
            r.id === row.id
              ? {
                  ...r,
                  isshiftEnd: true,
                  shiftEndDate: new Date(),
                  shiftEndTime: moment().format("HH:mm:ss"),
                }
              : r
          )
        );
      }
    } catch (error) {
      console.error("Error ending shift:", error);
      alert("Failed to end shift. Please try again.");
    }
  };
  const userData = useSelector((state) => state.loginReducer.updatedState);
  console.log(userData,"userData")
  const handleEndDay = async () => {
    let data = sessionStorage.getItem("userData");
    data = JSON.parse(data);
    let token = data;
    const lastShift = filteredRows.find((row) => !row.isshiftEnd);
    if (lastShift) return;

    try {
      const response = await axios.put(`${Base_Uri}endDay`, {
        employeeId: userData.employeeId,
        employeeName: userData.fullName,
      }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.status) {
        alert("Day ended successfully");
      }
      else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error("Error ending day:", error);
      alert("Failed to end day. Please try again.");
    }
  };

  const isDayEndEnabled = () => {
    const shiftsNotEnded = filteredRows.filter((row) => !row.isshiftEnd);
    return shiftsNotEnded.length === 0; // Enable if only one shift is not ended
  };

  const columns = [
    // { field: "id", headerName: "ID", width: 250 },
    {
      field: "date",
      headerName: "Date",
      width: 200,
      valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
    },
    {
      field: "shiftStartTime",
      headerName: "Start Time",
      width: 150,
      valueFormatter: (params) =>
        moment(params.value, "HH:mm:ss").format("hh:mm A"),
    },
    { field: "shiftStartBy", headerName: "Started By", width: 150 },
    { field: "isshiftStart", headerName: "Shift Start", width: 120 },
    { field: "isshiftEnd", headerName: "Shift End", width: 120 },
    {
      field: "shiftEndTime",
      headerName: "End Time",
      width: 150,
      valueFormatter: (params) =>
        params.value
          ? moment(params.value, "HH:mm:ss").format("hh:mm A")
          : "Not Found",
    },
    {
      field: "shiftEndDate",
      headerName: "End Date",
      width: 200,
      valueFormatter: (params) =>
        params.value ? moment(params.value).format("YYYY-MM-DD") : "Not Found",
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) =>
        !params.row.isshiftEnd ? (
          <Popconfirm
            title={"Shift end"}
            description={"Are You Sure"}
            onConfirm={() => handleEndShift(params.row)}
          >
            <Button variant="contained" color="primary">
              End Shift
            </Button>
          </Popconfirm>
        ) : (
          <span>Completed</span>
        ),
    },
  ];

  const filterToday = () => {
    const today = moment().startOf("day");
    setFilteredRows(
      rows.filter((row) => moment(row.date).isSame(today, "day"))
    );
  };

  const filterByDateRange = () => {
    const { start, end } = dateRange;
    if (!start || !end) return;
    setFilteredRows(
      rows.filter((row) =>
        moment(row.date).isBetween(moment(start), moment(end), "day", "[]")
      )
    );
  };

  const filterByShiftStatus = (status) => {
    setFilteredRows(
      rows.filter((row) =>
        status === "start"
          ? row.isshiftStart && !row.isshiftEnd
          : row.isshiftEnd
      )
    );
  };
  const ClearFilter = () => {
    setFilteredRows(rows);
  };

  return (
    <>
      <Navbar />
      <Box sx={{ padding: "20px 100px" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
            <Button variant="contained" onClick={filterToday}>
              Filter Today
            </Button>
            <TextField
              type="date"
              //   label="Start Date"
              value={dateRange.start}
              onChange={(e) =>
                setDateRange((prev) => ({ ...prev, start: e.target.value }))
              }
            />
            <TextField
              type="date"
              //   label="End Date"
              value={dateRange.end}
              onChange={(e) =>
                setDateRange((prev) => ({ ...prev, end: e.target.value }))
              }
            />
            <Button variant="contained" onClick={filterByDateRange}>
              Filter Date Range
            </Button>
            <Button
              variant="contained"
              onClick={() => filterByShiftStatus("start")}
            >
              Filter Shift Start
            </Button>
            <Button
              variant="contained"
              onClick={() => filterByShiftStatus("end")}
            >
              Filter Shift End
            </Button>
            <Button variant="contained" onClick={() => ClearFilter()}>
              Clear Filter
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleEndDay}
              disabled={!isDayEndEnabled()}
            >
              End Day
            </Button>
          </Box>
          <DataGrid rows={filteredRows} columns={columns} pageSize={5} />
        </Box>
      </Box>
    </>
  );
};

export default Shifts;
