import React, { useState } from "react";
import { Input } from "antd";

const ProductSearch = ({ products, setSelectProductFromSearch }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const handleSearchProducts = (value) => {
    const searchValue = value.trim().toLowerCase();
    if (!searchValue) {
      setSearchResults([]);
      setDropdownVisible(false); // Hide the dropdown if no input
      return;
    }

    // Tokenize search input
    const searchTokens = searchValue.split(" ");

    // Build filtered products
    const filteredProducts = products.filter((item) =>
      searchTokens.every((token) =>
        [item.productName, item.color, item.sku, item.barcode?.toString()]
          .filter(Boolean) // Filter out undefined/null values
          .some((field) => field.toLowerCase().includes(token))
      )
    );

    // Update search results
    setSearchResults(filteredProducts);
    setDropdownVisible(filteredProducts.length > 0); // Show dropdown if results exist
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {/* Search Input */}
      <Input
        placeholder="Search products"
        onChange={(e) => handleSearchProducts(e.target.value)}
        style={{ width: "100%", height: "100%" }}
      />

      {/* Results Container */}
      {isDropdownVisible && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            maxHeight: "200px",
            overflowY: "auto",
            background: "white",
            border: "1px solid #d9d9d9",
            borderRadius: "4px",
            zIndex: 1000,
            marginTop: 10,
          }}
        >
          {searchResults.length > 0 ? (
            searchResults.map((product) => (
              <div
                key={product.id}
                style={{
                  padding: "8px 12px",
                  cursor: "pointer",
                  borderBottom: "1px solid #f0f0f0",
                  textAlign: "left",
                }}
                onClick={() => {
                    setSelectProductFromSearch({})
                  setSelectProductFromSearch(product);
                  setDropdownVisible(false);
                }}
              >
                {product.productName}
              </div>
            ))
          ) : (
            <div style={{ padding: "8px 12px", color: "#999" }}>
              No results found.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ProductSearch;
