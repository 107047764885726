import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Colors } from '../Constant/Color';
import { InputAdornment, TextField } from '@mui/material';
import Checkbox from './checkbox';
import { RingLoader } from 'react-spinners';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Base_Uri } from '../Constant/BaseUri';
import CloseIcon from '@mui/icons-material/Close';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: "35%", md: "45%", sm: "60%", xs: "100%" },
    borderRadius: "10px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
};

function EditSupplierModal({ data, onChangeDay, setOpenEditSupplierModal, getAllSuppliers, onChangeMonth, onChangeYear, text2, onChange1, inputStyle, buttonText, heading, onChange, text, headingTextStyle, textStyle, containerStyle, open, closeModal, buttonContainerStyle, buttonStyle, buttonStyle2, confirm, text1 }) {

    let initialData = {
        qty: "",
        cost_price: "",
        trade_price: "",
        warehouse_price: "",
        retail_price: ""
    }



    let [restockData, setRestockData] = useState(initialData)

    const [supplierData, setSupplierData] = useState(data)

    const [loading, setLoading] = useState(false)







    const handleEditDetails = async () => {


        if (!supplierData?.supplierName) {
            alert("Kindly enter supplier name")
            return
        }


        if (!supplierData?.shopDetails) {
            alert("Kindly enter shop details")
            return
        }

        if (!supplierData?.mobileNumber) {
            alert("Kindly enter mobile number")
            return
        }


        let myData = await sessionStorage.getItem("userData");
        myData = JSON.parse(myData);
        let token = myData


        setLoading(true)
        axios.put(`${Base_Uri}editSupplier`, supplierData, {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json", // Include other headers as needed
            },
        }).then((res) => {

            let data = res.data

            if (!data?.status) {
                setLoading(false)
                alert(data.message)
            } else {

                alert(data.message)
                getAllSuppliers()
                setLoading(false)
                setOpenEditSupplierModal(false)

            }

        }).catch((error) => {

            alert(error.message)
            setLoading(false)
        })


    }

    return (

        <Box>

            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={[style, { containerStyle }]}>

                <Box onClick={() => closeModal()} style={{ position: "absolute", top: 10, right: 10 }} >
                        <CloseIcon fontSize="large" />
                    </Box>

                    <Typography sx={{ fontSize: "20px", color: Colors.button, fontWeight: "bold", fontFamily: "Poppins", textAlign: "center", ...headingTextStyle }} id="modal-modal-title" variant="h6" component="h2">
                        {"Edit Suppliers"}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Supplier Name
                        </Typography>

                        <TextField
                            onChange={(e) => setSupplierData({ ...supplierData, supplierName: e.target.value })}
                            variant="standard" // <== changed this
                            margin="normal"

                            required
                            value={supplierData?.supplierName}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />
                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Shop Details
                        </Typography>

                        <TextField
                            onChange={(e) => setSupplierData({ ...supplierData, shopDetails: e.target.value })}
                            variant="standard" // <== changed this
                            margin="normal"
                            required
                            value={supplierData?.shopDetails}
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",


                                // <== added this
                                // fontSize: "2px"
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>

                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", p: 0, alignItems: "center", mt: 1 }} >
                        <Typography sx={{ fontSize: "12px", width: "25%", color: Colors.black, fontWeight: "600", fontFamily: "Poppins", textAlign: "left", textTransform: "capitalize", ...textStyle }} id="modal-modal-description" >
                            Mobile Number
                        </Typography>

                        <TextField
                            onChange={(e) => setSupplierData({ ...supplierData, mobileNumber: e.target.value })}
                            variant="standard" // <== changed this
                            margin="normal"

                            value={supplierData?.mobileNumber}
                            required
                            // fullWidth
                            InputProps={{
                                // startAdornment: <AccountCircle />, // <== adjusted this
                                disableUnderline: true,
                                fontSize: "12px",
                            }}
                            placeholder=""
                            sx={{ width: "75%", fontSize: "12px", border: `1px solid ${Colors.border}`, padding: 1, fontFamily: "Poppins", fontWeight: "bold", ml: 1, margin: 0, ...inputStyle }}
                        />

                    </Box>





                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-around", ...buttonContainerStyle }} >



                        <Button onClick={() => handleEditDetails()} sx={{ backgroundColor: Colors.button, color: Colors.white, border: `1px solid ${Colors.button}`, borderRadius: "20px", width: "150px", marginTop: 2, fontFamily: "Poppins", ...buttonStyle2 }} >
                            {loading ? <RingLoader loading={loading} size={30} /> : "Edit Details"}
                        </Button>


                    </Box>



                </Box>



            </Modal>
        </Box>

    )
}

export default EditSupplierModal